import React from "react";
import PropTypes from "prop-types";
import { propTypeChatMessage } from "../../reducers/chatMessages";
import Chat from "./Chat";
import Safety from "./Safety/Safety";

const ChatWrapper = ({ messages }) => (
  <>
    <Chat messages={messages} />
    <Safety />
  </>
);

ChatWrapper.propTypes = {
  messages: PropTypes.arrayOf(propTypeChatMessage).isRequired,
};

export default ChatWrapper;
