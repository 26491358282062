import { size } from "polished";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { ReactComponent as ExclamationIcon } from "../../../img/icons/exclamation.svg";
import { TradeWarning, TradeWarningDescription } from "./styles";

const WarningIcon = styled(ExclamationIcon)`
  ${size(16)}
  fill: ${(props) => props.theme.colors.darkRed};
`;

const ElementTradeWarning = ({ isVisual }) => (
  <TradeWarning isVisual={isVisual}>
    <WarningIcon />
    <TradeWarningDescription isVisual={isVisual}>
      This player is already included in a proposed transaction.
    </TradeWarningDescription>
  </TradeWarning>
);

ElementTradeWarning.propTypes = {
  isVisual: PropTypes.bool,
};

ElementTradeWarning.defaultProps = {
  isVisual: false,
};

export default ElementTradeWarning;
