import { ellipsis } from "polished";
import styled, { css } from "styled-components/macro";
import {
  IPickBox,
  IPickBoxMain,
  IPickBoxMainCurrent,
  IPickBoxesBox,
} from "./types";

export const PickBoxes = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    border-width: 0 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.lightGrey};
  }
`;

export const PickBoxesItem = styled.div`
  display: flex;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1;

    &:not(:last-child) {
      border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
    }
  }
`;

export const PickBoxesBox = styled.div<IPickBoxesBox>`
  flex: 0 0 50%;

  ${({ isPrimary, theme }) =>
    isPrimary
      ? css`
          flex-basis: 100%;

          @media (min-width: ${theme.breakpoints[4]}) {
            border-bottom: 1px solid ${theme.colors.lightGrey};
          }
        `
      : css`
          border-bottom: 1px solid ${theme.colors.lightGrey};
        `}
`;

export const PickBox = styled.div<IPickBox>`
  padding: ${(props) => props.theme.space[2]};
  background-color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.lightGrey : "transparent"};
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-align: center;
`;

export const PickBoxHeading = styled.h4`
  margin-bottom: ${(props) => props.theme.space[1]};
  color: ${(props) => props.theme.colors.primary};
  font-size: inherit;
`;

const PickBoxMainShared = css`
  ${ellipsis()}
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: ${(props) => props.theme.colors.lightPurple};
  font-size: ${(props) => props.theme.fontSizes[4]};
  line-height: 1;
  vertical-align: middle;
`;

export const PickBoxMain = styled.div<IPickBoxMain>`
  ${PickBoxMainShared}
  ${({ isNext, theme }) =>
    isNext &&
    css`
      padding-right: 0.4rem;
      padding-left: 0.4rem;
      background-color: ${theme.colors.darkRed};
      color: #fff;
      font-family: ${theme.fonts.bold};
      font-weight: 700;
      text-transform: uppercase;
    `}
`;

export const PickBoxMainCurrent = styled.div<IPickBoxMainCurrent>`
  ${PickBoxMainShared}

  ${({ isMe, theme }) =>
    isMe
      ? css`
          color: ${theme.colors.darkRed};
        `
      : css`
          padding-top: 0.3rem;
          padding-bottom: 0.4rem;
          font-size: 1.5rem;

          @media (min-width: ${theme.breakpoints[4]}) {
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            font-size: ${(props) => props.theme.fontSizes[4]};
          }
        `}
`;

export const PickBoxAside = styled.p<IPickBox>`
  color: ${(props) => (props.isCurrent ? "#61605c" : "#76766f")};
`;
