import styled from "styled-components/macro";
import pattern2Crop from "../../img/pattern/pattern-2-crop-90.png";

export const StyledPanel = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};
  border-radius: ${(props) => props.theme.radii[1]};
  background-repeat: no-repeat;
  background-position:
    0,
    right top,
    0;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 10px,
      rgba(255, 255, 255, 0.2) 30px,
      white 60px
    ),
    url(${pattern2Crop}),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.lightBlue},
      ${(props) => props.theme.colors.darkBlue}
    );
  box-shadow: 0 8px 12px 0 rgba(107, 107, 107, 0.08);
  overflow: auto;
`;

export const PanelHeading = styled.h4`
  margin: ${(props) => props.theme.space[3]} 0;
  padding: 0 ${(props) => props.theme.space[2]};
  font-size: 1.7rem;
`;

export const PanelBody = styled.div`
  margin: ${(props) => props.theme.space[2]};
`;

export const PanelCentre = styled.div`
  width: 200px;
  margin: ${(props) => props.theme.space[2]} auto;
  text-align: center;
`;

export const PanelBadge = styled.img`
  display: block;
  width: 100px;
  min-height: 100px;
  margin: 3.5rem auto 1.5rem;
`;
