import PropTypes from "prop-types";
import { propTypePick } from "../../reducers/picks";
import { ElementRow } from "../Pitch/styles";
import PitchElement from "../PitchElement";
import { VisuallyHidden } from "../styles";
import BenchHeading from "./BenchHeading";
import { BenchAbbr } from "./BenchHeading/styles";
import { BenchInner, StyledBench, StyledBenchUnit } from "./styles";

const BenchUnit = ({ children, position }) => (
  <StyledBenchUnit aria-labelledby={`ism-pos-label-${position}`}>
    {children}
  </StyledBenchUnit>
);

BenchUnit.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.number.isRequired,
};

const Bench = ({ picks, ...rest }) => (
  <StyledBench>
    <BenchInner>
      <VisuallyHidden as="h3">Bench</VisuallyHidden>
      <ElementRow>
        <BenchHeading position={1}>
          <BenchAbbr title="Goalkeeper">GK</BenchAbbr>
        </BenchHeading>
        <BenchUnit position={1}>
          <PitchElement pick={picks[11]} {...rest} />
        </BenchUnit>
        <BenchHeading position={2}>
          <span>1</span>
        </BenchHeading>
        <BenchUnit position={2}>
          <PitchElement pick={picks[12]} {...rest} />
        </BenchUnit>
        <BenchHeading position={3}>
          <span>2</span>
        </BenchHeading>
        <BenchUnit position={3}>
          <PitchElement pick={picks[13]} {...rest} />
        </BenchUnit>
        <BenchHeading position={4}>
          <span>3</span>
        </BenchHeading>
        <BenchUnit position={4}>
          <PitchElement pick={picks[14]} {...rest} />
        </BenchUnit>
      </ElementRow>
    </BenchInner>
  </StyledBench>
);

Bench.propTypes = {
  picks: PropTypes.arrayOf(propTypePick).isRequired,
};

export default Bench;
