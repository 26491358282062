import PropTypes from "prop-types";
import { propTypeElementType } from "../../../reducers/elementTypes";
import { propTypeElement } from "../../../reducers/elements";
import { propTypeTeam } from "../../../reducers/teams";
import { propTypeTransferInflated } from "../../../reducers/transactions";
import Alert from "../../Alert";
import SubmitBar from "../../SubmitBar/SubmitBar";
import { TableWrap } from "../../Table";
import { PrimaryIcon, SquadButton, SquadButtonText } from "../../squad/styles";
import TransactionElements from "../TransactionElements";
import {
  ProposedTransferAction,
  ProposedTransfersTable,
  TransactionElementsCell,
  TransactionNumberCell,
} from "./styles";

const Transfer = ({
  elementDialogShow,
  elementIn,
  elementOut,
  index,
  removeTransfer,
  teamsById,
  typesById,
}) => (
  <tr>
    <TransactionNumberCell>{index + 1}</TransactionNumberCell>
    <TransactionElementsCell>
      <TransactionElements
        elementDialogShow={elementDialogShow}
        elementIn={elementIn}
        elementOut={elementOut}
        teamsById={teamsById}
        typesById={typesById}
      />
    </TransactionElementsCell>
    <ProposedTransferAction>
      <SquadButton
        variant="primary"
        type="button"
        onClick={removeTransfer}
        data-id={elementIn.id}
      >
        <SquadButtonText>Cancel</SquadButtonText>
        <PrimaryIcon>
          <use xlinkHref="#ism-cancel" />
        </PrimaryIcon>
      </SquadButton>
    </ProposedTransferAction>
  </tr>
);

Transfer.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementIn: propTypeElement.isRequired,
  elementOut: propTypeElement.isRequired,
  index: PropTypes.number.isRequired,
  removeTransfer: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

const ProposedTransfers = ({
  errorElement,
  saveTransfers,
  transfers,
  ...rest
}) => (
  <TableWrap>
    <ProposedTransfersTable>
      <thead>
        <tr>
          <th>#</th>
          <th>Transaction</th>
          <th>Cancel?</th>
        </tr>
      </thead>
      <tbody>
        {transfers.map((transfer, index) => (
          <Transfer
            elementIn={transfer.element_in}
            elementOut={transfer.element_out}
            index={index}
            key={`${transfer.element_in.id}-${transfer.element_out.id}`}
            {...rest}
          />
        ))}
      </tbody>
    </ProposedTransfersTable>
    {transfers.length > 0 && (
      <div>
        <Alert>
          Once confirmed the signings are final. All released players are
          available to sign during the next waiver process.
        </Alert>
        {errorElement && (
          <Alert>
            <strong>{errorElement.web_name}</strong> has recently been selected
            by another player in your league. Please amend you proposed changes.
          </Alert>
        )}
        <SubmitBar
          buttonText="Confirm Signings"
          clickMe={saveTransfers}
          isDisabled={Boolean(errorElement)}
        />
      </div>
    )}
  </TableWrap>
);

ProposedTransfers.propTypes = {
  errorElement: propTypeElement,
  removeTransfer: PropTypes.func.isRequired,
  saveTransfers: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  transfers: PropTypes.arrayOf(propTypeTransferInflated).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};
ProposedTransfers.defaultProps = {
  errorElement: null,
};

export default ProposedTransfers;
