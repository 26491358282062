import PropTypes from "prop-types";
import {
  ACTIVE_ENTRY_CHANGE,
  ADD_PLAYER,
  CREATE_LEAGUE_SUCCESS,
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  JOIN_LEAGUE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../actions/ActionTypes";

const initialState = {
  data: {
    email: null,
    entry_set: [],
    first_name: null,
    id: null,
    last_name: null,
    region: null,
    region_code_long: null,
    region_code_short: null,
    region_name: null,
    username: null,
  },
  activeEntry: null,
};

function playerReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_ENTRY_CHANGE: {
      return {
        ...state,
        activeEntry: action.data.entry,
      };
    }
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data.player,
      };

    case ADD_PLAYER:
      // Need to do this to handle unauthenticated bootstrap
      if (!action.data.entry_set) {
        return {
          ...state,
          data: {},
        };
      }
      return {
        ...state,
        data: action.data,
      };

    case CREATE_LEAGUE_SUCCESS:
    case JOIN_LEAGUE_SUCCESS: {
      const updatedState = {
        ...state,
        data: {
          ...state.data,
          entry_set: state.data.entry_set.concat(action.data.entry.id),
        },
      };
      return updatedState;
    }
    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          entry_set: state.data.entry_set.filter(
            (id) => id !== action.data.entry,
          ),
        },
      };
    }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        data: {},
        activeEntry: null,
      };

    default:
      return state;
  }
}

export default playerReducer;

// Selectors
const getPlayer = (state) => state.player;

export const getActiveEntryId = (state) => getPlayer(state).activeEntry;

export const getPlayerData = (state) => getPlayer(state).data;

export const isLoggedIn = (state) => Boolean(getPlayerData(state).id);

export const getDefaultURL = (state) => {
  if (!isLoggedIn(state)) {
    return "/";
  }
  if (getActiveEntryId(state)) {
    return "/";
  }
  return "/entry/join-create";
};

// PropTypes
export const propTypePlayer = PropTypes.shape({
  first_name: PropTypes.string,
  id: PropTypes.number,
  last_name: PropTypes.string,
});
