import { hideVisually } from "polished";
import styled from "styled-components/macro";

export const StyledLastChoiceElement = styled.p`
  padding: ${(props) => props.theme.space[1]};
  color: ${(props) => props.theme.colors.lightPurple};
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.lightGrey};
  }
`;

export const LastChoiceElementName = styled.span`
  @media (max-width: ${(props) => props.theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;
