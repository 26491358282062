import React from "react";
import { BasicHyperlink } from "../../Link";
import Copy from "../../Copy";
import { SafetyBody, SafetyHeading, SafetyUnit, StyledSafety } from "./styles";
import { SafetyLink } from "./styles";

const Safety = () => (
  <StyledSafety>
    <Copy>
      <SafetyHeading id="ism-safety">Your Safety Online</SafetyHeading>
      <SafetyBody>
        <SafetyUnit>
          <p>
            <BasicHyperlink href="https://ceop.police.uk/safety-centre/">
              Visit the Child Exploitation and Online Protection website
            </BasicHyperlink>{" "}
            for confidential support if something has happened online which has
            made you feel unsafe, if you are worried about someone else or to
            report online abuse.
            <SafetyLink href="https://ceop.police.uk/safety-centre/">
              <img
                src="/img/safety/ceop.gif"
                alt="Click CEOP. Internet Safety."
              />
            </SafetyLink>
          </p>
          <p>
            <strong>
              You should contact the Police by calling 999 if you or anybody
              else is in any sort of danger.
            </strong>
          </p>
        </SafetyUnit>
        <SafetyUnit>
          <p>
            <BasicHyperlink href="https://www.thinkuknow.co.uk/">
              Visit CEOP’s Thinkuknow website
            </BasicHyperlink>{" "}
            for advice and guidance on safe surfing and staying safe online for
            example when using mobile phones, blogs, social media, chatting,
            online gaming and emailing.
            <SafetyLink href="https://www.thinkuknow.co.uk/">
              <img
                src="/img/safety/thinkuknow-134.jpg"
                srcSet="/img/safety/thinkuknow-134.jpg 134w,
                                        /img/safety/thinkuknow-268.jpg 268w,
                                        /img/safety/thinkuknow-533.jpg 533w,
                                    "
                sizes="134px"
                alt="thinkuknow"
              />
            </SafetyLink>
          </p>
          <p>
            You can also{" "}
            <BasicHyperlink href="https://www.premierleague.com/safeguarding">
              visit the Premier League safeguarding page
            </BasicHyperlink>{" "}
            for more information.
          </p>
        </SafetyUnit>
      </SafetyBody>
    </Copy>
  </StyledSafety>
);

export default Safety;
