import { FETCH_ELEMENT_STATUS_SUCCESS } from "./ActionTypes";
import { fetchGet, handleUnexpectedError } from "../helpers";

export const fetchElementStatusSuccess = (data, league) => ({
  type: FETCH_ELEMENT_STATUS_SUCCESS,
  data: {
    league: league.id,
    element_status: data,
  },
});

export const fetchElementStatus = (league) => (dispatch) => {
  const url = `/api/league/${league.id}/element-status`;
  return fetchGet(url).then(
    (d) => dispatch(fetchElementStatusSuccess(d.data.element_status, league)),
    (e) => handleUnexpectedError(e, dispatch),
  );
};
