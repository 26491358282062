import styled from "styled-components/macro";
import Table from "../Table";
import { DragCell, PriorityCell } from "../sortable/Row/styles";
import {
  ActionCell,
  FixtureCell,
  PrimaryCell,
  SortCell,
  StatusCell,
} from "./WatchlistRows/WatchlistCells/styles";

export const StyledWatchlistTable = styled(Table)`
  table-layout: fixed;

  thead {
    ${PriorityCell}, ${SortCell}, ${FixtureCell}, ${ActionCell} {
      text-align: center;
    }
  }

  td {
    padding-top: 0.7px;
    padding-bottom: 0.7px;
  }

  ${DragCell} {
    padding: 0;
  }

  ${StatusCell} {
    padding: 0;
  }

  ${PrimaryCell} {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
`;
