import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getGame } from "./game";
import { getLeagueForEntry } from "./leagues";
import { getActiveEntryId, getPlayerData, isLoggedIn } from "./player";
import {
  ADD_ENTRIES,
  ADD_ENTRY_HISTORY,
  ADD_PUBLIC_ENTRY,
  CREATE_LEAGUE_SUCCESS,
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  DRAFT_STARTED,
  DRAFT_COMPLETED,
  EDIT_ENTRY_SUCCESS,
  ENTRY_UNSUBSCRIBED,
  FETCH_DRAFT_CHOICES_SUCCESS,
  JOIN_LEAGUE_SUCCESS,
  LOGIN_SUCCESS,
} from "../actions/ActionTypes";

// The entries state contains entries for the logged in player. Entries
// in the users leagues are stored in leagueEntries.

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.entries;
export const l2g = (local) => ({ entries: local });

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_ENTRIES: {
      const newState = { ...state };
      action.data.forEach((entry) => {
        newState[entry.id] = entry;
      });
      return newState;
    }

    case LOGIN_SUCCESS: {
      const newState = { ...state };
      action.data.entries.forEach((entry) => {
        newState[entry.id] = entry;
      });
      return newState;
    }

    case CREATE_LEAGUE_SUCCESS:
    case JOIN_LEAGUE_SUCCESS:
    case EDIT_ENTRY_SUCCESS:
    case ENTRY_UNSUBSCRIBED:
      return {
        ...state,
        [action.data.entry.id]: action.data.entry,
      };

    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.data.entry];
      return newState;
    }

    case FETCH_DRAFT_CHOICES_SUCCESS: {
      const active = action.data.entryId;
      const idles = action.data.idle;

      // Now idle
      if (!state[active].auto_draft && idles.indexOf(active) > -1) {
        return {
          ...state,
          [active]: {
            ...state[active],
            auto_draft: true,
          },
        };
      }

      // Now active
      if (state[active].auto_draft && idles.indexOf(active) === -1) {
        return {
          ...state,
          [active]: {
            ...state[active],
            auto_draft: false,
          },
        };
      }

      return state;
    }

    case DRAFT_STARTED: {
      // Need to iterate and change draft_game_status to drafting for
      // action.league
      const newState = {};
      Object.keys(state).forEach((id) => {
        let entry = state[id];
        if (entry.league_set[0] === action.data.league) {
          entry = {
            ...entry,
            draft_game_status: "drafting",
          };
        }
        newState[id] = entry;
      });
      return newState;
    }

    case DRAFT_COMPLETED: {
      // Need to iterate and change draft_game_status to picked for
      // action.league
      const newState = {};
      Object.keys(state).forEach((id) => {
        let entry = state[id];
        if (entry.league_set[0] === action.data.league) {
          entry = {
            ...entry,
            draft_game_status: "picked",
          };
        }
        newState[id] = entry;
      });
      return newState;
    }

    case ADD_PUBLIC_ENTRY: {
      const entryData = action.data.entry;
      // Known so merge missing fields
      if (state[entryData.id]) {
        Object.keys(state[entryData.id]).forEach((key) => {
          if (entryData[key] === undefined) {
            entryData[key] = state[entryData.id][key];
          }
        });
      }
      return {
        ...state,
        [entryData.id]: entryData,
      };
    }

    default:
      return state;
  }
};

const unsubscribed = (state = false, action) => {
  switch (action.type) {
    case ENTRY_UNSUBSCRIBED:
      return true;

    default:
      return state;
  }
};

const history = (state = {}, action) => {
  switch (action.type) {
    case ADD_ENTRY_HISTORY:
      return {
        ...state,
        [action.data.entryId]: action.data.history,
      };

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  unsubscribed,
  history,
});

// Selectors
const getEntries = (state) => state.entries;

export const getActiveEntry = (state) => {
  return getEntries(state).byId[getActiveEntryId(state)];
};

export const getActiveEntryStatus = createSelector(
  isLoggedIn,
  getActiveEntry,
  (loggedIn, activeEntry) => {
    if (!loggedIn) return "logged_out";
    if (!activeEntry) return "no_entry";
    return activeEntry.draft_game_status;
  },
);

export const getActiveEntryHubStatus = createSelector(
  getActiveEntryStatus,
  getGame,
  (status, game) => {
    if (status !== "started") return status;
    if (!game.next_event) return "finished";
    if (!game.current_event_finished) return "points";
    if (!game.waivers_processed) return "waivers";
    return "free_agency";
  },
);

export const getEntry = (state, id) => getEntries(state).byId[id] || {};

export const getMyEntries = createSelector(
  getEntries,
  getPlayerData,
  getActiveEntryId,
  (entries, player, activeEntryId) => {
    const activeEntries = [];
    if (player && player.entry_set) {
      player.entry_set.forEach((id) => {
        activeEntries.push({
          ...entries.byId[id],
          isActive: activeEntryId === id,
        });
      });
    }
    return activeEntries;
  },
);

export const getMyEntriesWithScoring = (state) => {
  const entries = getMyEntries(state).map((entry) => {
    const scoringEntry = { ...entry };
    scoringEntry.leagueScoring = getLeagueForEntry(state, entry).scoring;
    return scoringEntry;
  });
  return entries;
};

export const getUnsubscribed = (state) => getEntries(state).unsubscribed;

export const getHistory = (state) => g2l(state).history;

export const getHistoryForEntry = (state, entryId) => {
  const full = getHistory(state);
  if (full[entryId]) {
    return full[entryId];
  }
  return [];
};

// PropTypes
export const propTypeEntry = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const propTypeEntryWithScoring = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  leagueScoring: PropTypes.string,
});

export const propTypeEntryHistory = PropTypes.shape({
  id: PropTypes.number,
  event: PropTypes.number,
});

export const propTypeActiveEntryStatus = PropTypes.oneOf([
  "logged_out",
  "no_entry",
  "preparing",
  "drafting",
  "picked",
  "started",
]);

export const propTypeHubStatus = PropTypes.oneOf([
  "logged_out",
  "no_entry",
  "preparing",
  "drafting",
  "picked",
  "points",
  "waivers",
  "free_agency",
  "finished",
]);
