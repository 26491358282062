import PropTypes from "prop-types";
import { propTypeElementStatus } from "../../reducers/elementStatus";
import { propTypeElementType } from "../../reducers/elementTypes";
import { propTypeElement, propTypeSortStat } from "../../reducers/elements";
import { propTypeEntry } from "../../reducers/entries";
import { propTypeLeagueEntry } from "../../reducers/leagueEntries";
import { propTypeLeague } from "../../reducers/leagues";
import { propTypeTeam } from "../../reducers/teams";
import {
  propTypeTrade,
  propTypeTransferInflated,
  propTypeWaiverInflated,
} from "../../reducers/transactions";
import { propTypeEventTeamFixture } from "../../store/fixtures/slice";
import ElementListControl from "../ElementListControl";
import { PrimaryContainer } from "../PrimaryContainer";
import Tabs from "../Tabs";
import TabPanel from "../Tabs/TabPanel";
import WatchlistTable from "../WatchlistTable";
import SquadHeader from "../squad/SquadHeader";
import SquadPlayers from "../squad/SquadPlayers";
import ProposedTrades from "./ProposedTrades";
import ProposedTransactions from "./ProposedTransactions";
import ProposedTransfers from "./ProposedTransfers";
import ProposedWaivers from "./ProposedWaivers";

const TransactionAdd = ({
  canActiveLeagueTrade,
  changeWaiver,
  elementDialogShow,
  elements,
  elementsById,
  elementStatus,
  entriesById,
  entry,
  errorElement,
  league,
  locationHash,
  next3Fixtures,
  proposeMe,
  removeFromWatchlist,
  removeTransfer,
  removeWaiver,
  saveTransfers,
  sortStat,
  teamsById,
  tradeMe,
  transfers,
  typesById,
  waivers,
  tradesOffered,
  watchedElements,
  watchlist,
  withdrawTradeOffer,
}) => {
  const proposedTab = {
    waivers: (
      <TabPanel
        label={`Proposed (${waivers.length + tradesOffered.length})`}
        link="proposed"
      >
        <ProposedTransactions
          elementsShownLabel="proposed waivers shown"
          numberOfElements={waivers.length}
        >
          <ProposedWaivers
            changeWaiver={changeWaiver}
            elementDialogShow={elementDialogShow}
            removeWaiver={removeWaiver}
            teamsById={teamsById}
            typesById={typesById}
            waivers={waivers}
          />
        </ProposedTransactions>
        <ProposedTransactions
          elementsShownLabel="proposed trades shown"
          numberOfElements={tradesOffered.length}
        >
          <ProposedTrades
            elementDialogShow={elementDialogShow}
            elementsById={elementsById}
            entriesById={entriesById}
            withdrawTradeOffer={withdrawTradeOffer}
            teamsById={teamsById}
            trades={tradesOffered}
            typesById={typesById}
          />
        </ProposedTransactions>
      </TabPanel>
    ),
    "free-agency": (
      <TabPanel label={`Proposed (${transfers.length})`} link="proposed">
        <ProposedTransactions
          elementsShownLabel="proposed free agent signings shown"
          numberOfElements={transfers.length}
        >
          <ProposedTransfers
            elementDialogShow={elementDialogShow}
            errorElement={errorElement}
            removeTransfer={removeTransfer}
            saveTransfers={saveTransfers}
            teamsById={teamsById}
            transfers={transfers}
            typesById={typesById}
          />
        </ProposedTransactions>
      </TabPanel>
    ),
  };
  return (
    <PrimaryContainer>
      <ElementListControl />
      <Tabs isCentered={true} locationHash={locationHash}>
        <TabPanel label="All players" link="players">
          <SquadHeader numberOfElements={elements.length} hasFilter={true} />
          <SquadPlayers
            actionMe={proposeMe}
            actionLabel="Sign"
            elements={elements}
          />
        </TabPanel>
        <TabPanel label={`Watchlist (${watchlist.length})`} link="watchlist">
          <SquadHeader numberOfElements={watchlist.length} hasFilter={true} />
          <WatchlistTable
            actionMe={proposeMe}
            actionLabel="Sign"
            canActiveLeagueTrade={canActiveLeagueTrade}
            elementDialogShow={elementDialogShow}
            elements={watchedElements}
            elementStatus={elementStatus}
            entriesById={entriesById}
            entry={entry}
            next3Fixtures={next3Fixtures}
            removeFromWatchlist={removeFromWatchlist}
            sortStat={sortStat}
            teamsById={teamsById}
            tradeMe={tradeMe}
            typesById={typesById}
            watchlist={watchlist}
          />
        </TabPanel>
        {proposedTab[league.transaction_mode]}
      </Tabs>
    </PrimaryContainer>
  );
};

TransactionAdd.propTypes = {
  addToWatchlist: PropTypes.func.isRequired,
  canActiveLeagueTrade: PropTypes.bool.isRequired,
  changeWaiver: PropTypes.func.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  elementStatus: PropTypes.objectOf(propTypeElementStatus).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  entry: propTypeEntry.isRequired,
  errorElement: propTypeElement,
  league: propTypeLeague.isRequired,
  locationHash: PropTypes.string.isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  removeFromWatchlist: PropTypes.func.isRequired,
  removeTransfer: PropTypes.func.isRequired,
  removeWaiver: PropTypes.func.isRequired,
  saveTransfers: PropTypes.func.isRequired,
  sortStat: propTypeSortStat.isRequired,
  transfers: PropTypes.arrayOf(propTypeTransferInflated).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  tradeMe: PropTypes.func.isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
  waivers: PropTypes.arrayOf(propTypeWaiverInflated).isRequired,
  tradesOffered: PropTypes.arrayOf(propTypeTrade).isRequired,
  watchedElements: PropTypes.arrayOf(propTypeElement).isRequired,
  watchlist: PropTypes.arrayOf(PropTypes.number).isRequired,
  withdrawTradeOffer: PropTypes.func.isRequired,
};
TransactionAdd.defaultProps = {
  errorElement: null,
};

export default TransactionAdd;
