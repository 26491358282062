import { hideVisually, size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as Locked } from "../../../img/icons/locked.svg";

export const StyledElementAction = styled.div`
  padding: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 1;
  text-align: center;
`;

export const ElementActionLabel = styled.span`
  @media (max-width: ${(props) => props.theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;

export const ElementActionIcon = styled.span`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;

export const LockedIcon = styled(Locked)`
  ${size("2rem")}
`;
