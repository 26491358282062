import styled from "styled-components/macro";
import greyClawBg from "../../../img/grey-claw-bg.png";
import greyClawBg2x from "../../../img/grey-claw-bg@2x.png";
import { hiDPI } from "polished";

export const StyledPostDraftStatus = styled.div`
  padding: ${(props) => props.theme.space[4]} 5%;
  background: url(${greyClawBg}) left center no-repeat
    ${(props) => props.theme.colors.lightGrey};
  color: white;
  text-align: center;

  ${hiDPI(2)} {
    background-image: url(${greyClawBg2x});
    background-size: 289px 150px;
  }

  ${(props) => props.theme.breakpoints[4]} {
    padding-right: 32%;
    padding-left: 32%;
  }
`;

export const ActionBlockHeading = styled.h3`
  margin: 0 15%;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  background-color: ${(props) => props.theme.colors.darkPink};
  color: #fff;
`;

export const ActionBlockMessage = styled.p`
  margin-bottom: ${(props) => props.theme.space[2]};
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 2.4rem;
`;
