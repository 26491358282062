import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { getLeague, propTypeLeague } from "../reducers/leagues";
import { fetchLeagueCode } from "../store/leagueCodes/slice";
import ButtonLink, { ButtonHyperlink } from "./ButtonLink";
import Copy from "./Copy";
import { Main, Wrapper } from "./Layout";

const mailText = (name, code) =>
  `You have been invited to play in the ${name} FPL Draft league. Join the game here:  https://draft.premierleague.com` +
  `\n\nOnce you have logged in or registered click on 'Start' and then 'Join a league'. You can then enter the league code ${code} to join the league.`;

const inviteMailtoLink = (body, subject) =>
  `mailto:?to=&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body,
  )}`;

const LeagueInvite = ({ league }) => {
  const dispatch = useDispatch();

  const code = useSelector(
    (state) => state.leagueCodes.byLeagueId[league.id] || "",
  );

  useEffect(() => {
    dispatch(fetchLeagueCode(league.id));
  }, [league.id, dispatch]);

  return (
    <Wrapper>
      <Main>
        <Copy>
          <h2>{league.name}</h2>
          <h3>Code to join this league: {code}</h3>
          <p>
            <ButtonHyperlink
              href={inviteMailtoLink(
                mailText(league.name, code),
                `Invitation to ${league.name} at FPL Draft`,
              )}
            >
              Invite friends by email
            </ButtonHyperlink>
          </p>

          <p>
            When you click the link above, the link will attempt to open your
            default email program and start a new email.
          </p>
          <p>
            If you do not have a default email program, like Outlook or Mail,
            enabled on your computer, clicking the link may not result in any
            action or you may be prompted to set up an email program.
          </p>
          <p>
            If you use a browser based webmail application, like Gmail or
            Yahoo!, you can copy and paste the text below:
          </p>
          <p>
            <Box bg="lightGrey" p={4} mb={3}>
              {mailText(league.name, code)}
            </Box>
          </p>

          <p>
            <ButtonLink to="/">Back to Home</ButtonLink>
          </p>
        </Copy>
      </Main>
    </Wrapper>
  );
};

LeagueInvite.propTypes = {
  league: propTypeLeague.isRequired,
};

export { LeagueInvite as LeagueInviteTest };

const mapStateToProps = (state, ownProps) => ({
  league: getLeague(state, Number(ownProps.leagueId)),
});

export default connect(mapStateToProps)(LeagueInvite);
