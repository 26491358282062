import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { elementDialogShow as eds } from "../../../actions/Element";
import { changeEntry } from "../../../actions/Entry";
import { deleteLeagueEntry } from "../../../actions/League";
import {
  DISPLAY_DRAFT_DATE_FORMAT,
  DISPLAY_DRAFT_TIME_FORMAT,
  SELECT_DRAFT_DATE_TIME_FORMAT,
} from "../../../constants";
import {
  getActiveChatMessages,
  propTypeChatMessage,
} from "../../../reducers/chatMessages";
import { propTypeEntry } from "../../../reducers/entries";
import {
  getActiveLeagueEntries,
  propTypeLeagueEntry,
} from "../../../reducers/leagueEntries";
import { getActiveLeague, propTypeLeague } from "../../../reducers/leagues";
import ButtonLink from "../../ButtonLink";
import ChatWrapper from "../../Chat";
import DraftStart from "../../DraftStart";
import HelmetHead from "../../HelmetHead";
import LatestAlert from "../../LatestAlert";
import { Main, Secondary, Wrapper } from "../../Layout";
import {
  PrimaryContainer,
  PrimaryContainerContent,
  PrimaryContainerHeading,
} from "../../PrimaryContainer";
import TabHeading from "../../TabHeading";
import Entry from "../../entry/Entry";
import EntryHeader from "../EntryHeader";
import Trades from "../Trades";
import LeagueEntries from "./LeagueEntries/LeagueEntries";
import {
  HubButtonWrap,
  HubCta,
  HubCtaHeading,
  HubCtaWrap,
  HubList,
  HubListHeading,
  HubListItem,
  HubListValue,
  HubUnit,
  HubUnitScroll,
  HubWrap,
} from "./styles";

const Hub = ({
  activeEntry,
  activeLeague,
  confirmDelete,
  entries,
  leagueEntries,
  messages,
}) => {
  const draftDateInTz = new Date(
    formatInTimeZone(
      activeLeague.draft_dt,
      activeLeague.draft_tz_show,
      SELECT_DRAFT_DATE_TIME_FORMAT,
    ),
  );
  // Get league administrator into a displayable format
  let leagueAdminName = "None";
  if (activeLeague.admin_entry) {
    const admins = leagueEntries.filter(
      (le) => le.entry_id === activeLeague.admin_entry,
    );
    if (admins.length === 1) {
      leagueAdminName = `${admins[0].player_first_name} ${admins[0].player_last_name}`;
    }
  }
  const tradesLabels = {
    n: "None",
    y: "All",
    a: "Administrator veto",
    m: "Manager veto",
  };
  return (
    <Wrapper>
      {/* eslint-disable max-len */}
      <HelmetHead
        title="Hub - Fantasy Premier League Draft 2024/25"
        description="View information for your league including draft date and time along with a ‘Countdown to Draft’, visit the official website of the Premier League."
      />
      {/* eslint-enable max-len */}
      <Main>
        <LatestAlert />
        <EntryHeader activeEntry={activeEntry} entries={entries} />
        {activeLeague.trades !== "n" &&
          activeLeague.draft_status === "post" && (
            <Trades activeLeague={activeLeague} />
          )}
        {activeLeague.draft_status !== "post" && (
          <HubCtaWrap>
            <HubUnit>
              <HubCta>
                <TabHeading title="Build your watchlist" isBlock={true} />
                <HubButtonWrap>
                  <ButtonLink to="/draft">Enter the Draft Room</ButtonLink>
                </HubButtonWrap>
              </HubCta>
            </HubUnit>
            <HubUnit>
              <DraftStart league={activeLeague} />
            </HubUnit>
          </HubCtaWrap>
        )}

        <PrimaryContainer>
          <HubCtaHeading>{activeLeague.name}</HubCtaHeading>
          <HubWrap>
            <HubUnit>
              <LeagueEntries
                league={activeLeague}
                entries={leagueEntries}
                activeEntry={activeEntry}
                confirmDelete={confirmDelete}
              />
            </HubUnit>
            <HubUnit>
              <div>
                <PrimaryContainerHeading>
                  League information
                </PrimaryContainerHeading>
                <PrimaryContainerContent>
                  <HubUnitScroll>
                    <HubList>
                      <HubListItem>
                        <HubListHeading>League Administrator:</HubListHeading>
                        <HubListValue>{leagueAdminName}</HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Draft Date:</HubListHeading>
                        <HubListValue>
                          {format(draftDateInTz, DISPLAY_DRAFT_DATE_FORMAT)}
                        </HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Draft Time:</HubListHeading>
                        <HubListValue>
                          {format(draftDateInTz, DISPLAY_DRAFT_TIME_FORMAT)}
                        </HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Draft Time Zone:</HubListHeading>
                        <HubListValue>
                          {activeLeague.draft_tz_show}
                        </HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Starting Gameweek:</HubListHeading>
                        <HubListValue>{activeLeague.start_event}</HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Min. League Size:</HubListHeading>
                        <HubListValue>{activeLeague.min_entries}</HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Max. League Size:</HubListHeading>
                        <HubListValue>{activeLeague.max_entries}</HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Scoring Mode:</HubListHeading>
                        <HubListValue>
                          {activeLeague.scoring === "c"
                            ? "Classic"
                            : "Head-to-Head"}
                        </HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Pick Time Limit:</HubListHeading>
                        <HubListValue>
                          {activeLeague.draft_pick_time_limit} seconds
                        </HubListValue>
                      </HubListItem>
                      <HubListItem>
                        <HubListHeading>Player Trades:</HubListHeading>
                        <HubListValue>
                          {tradesLabels[activeLeague.trades]}
                        </HubListValue>
                      </HubListItem>
                    </HubList>
                  </HubUnitScroll>
                  {activeLeague.can_admin && (
                    <HubButtonWrap>
                      <ButtonLink
                        to={`/league/${activeLeague.id}/edit`}
                        fullwidth="true"
                      >
                        Edit League
                      </ButtonLink>
                    </HubButtonWrap>
                  )}
                </PrimaryContainerContent>
              </div>
            </HubUnit>
          </HubWrap>
        </PrimaryContainer>
        {activeLeague.variety === "x" && <ChatWrapper messages={messages} />}
      </Main>
      <Secondary>
        <Entry />
      </Secondary>
    </Wrapper>
  );
};

Hub.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  activeLeague: propTypeLeague.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(propTypeEntry).isRequired,
  leagueEntries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
  messages: PropTypes.arrayOf(propTypeChatMessage).isRequired,
};

export { Hub as HubTest };

const mapStateToProps = (state) => ({
  activeLeague: getActiveLeague(state),
  leagueEntries: getActiveLeagueEntries(state),
  messages: getActiveChatMessages(state),
});

const mapDispatchToProps = {
  elementDialogShow: eds,
  confirmDelete: deleteLeagueEntry,
  handleActiveEntryChange: (e) => changeEntry(e.target.value),
};

export default connect(mapStateToProps, mapDispatchToProps)(Hub);
