import styled, { css } from "styled-components/macro";
import { Hyperlink } from "../Link";

export const StyledChatWrap = styled.div`
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    overflow: visible;
  }
`;

export const StyledChat = styled.div`
  position: relative;
  margin-top: ${(props) => props.theme.space[4]};
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChatHeading = styled.h3`
  display: inline-block;
  padding: ${(props) => props.theme.space[2]};
  background-color: ${(props) => props.theme.colors.lightBlue};
  font-size: 1.9rem;
`;

export const SafetyLink = styled(Hyperlink)`
  margin-right: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-right: 0;
  }
`;

const ChatGradient = css`
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.lightBlue},
    ${(props) => props.theme.colors.lightBlue} 66px,
    ${(props) => props.theme.colors.darkBlue}
  );
`;

export const ChatBody = styled.div`
  ${ChatGradient}
  padding: ${(props) => props.theme.space[4]} 0;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-bottom: 0;
  }
`;

export const ChatForm = styled.form`
  ${ChatGradient}
  padding-right: ${(props) => props.theme.space[2]};
  padding-left: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    padding-right: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[4]};
    overflow: auto;
  }
`;

export const MessageWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    margin-bottom: ${(props) => props.theme.space[4]};
  }
`;

export const MessageInput = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1 0 80%;
    margin-bottom: 0;
    padding-right: ${(props) => props.theme.space[2]};
  }
`;

export const MessageSubmit = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1 0 20%;
  }
`;
