import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { elementDialogShow as eds } from "../../../actions/Element";
import {
  eventTradesFetch,
  transactionDataFetch,
} from "../../../actions/Transaction";
import { getElementTypesById } from "../../../reducers/elementTypes";
import { getElementsById } from "../../../reducers/elements";
import { getNextEvent } from "../../../reducers/events";
import {
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../../../reducers/leagueEntries";
import { getActiveLeague } from "../../../reducers/leagues";
import { getTeamsById } from "../../../reducers/teams";
import {
  getCurrentTradesGrouped,
  getTradesForLeagueEventGrouped,
  propTypeTradeGrouped,
} from "../../../reducers/transactions";
import getTradeStateName from "../../../utils/getTradeStateName";
import { PatternWrapMain } from "../../GraphicPatterns";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import SubHeading from "../../SubHeading";
import { TableWrap } from "../../Table";
import Tabs from "../../Tabs";
import TabPanel from "../../Tabs/TabPanel";
import TransactionElementGroup from "../../transactions/TransactionElementGroup";
import {
  StyledTransaction,
  TransactionUnit,
  TransactionUnitHeading,
} from "../../transactions/styles";
import {
  EntryCell,
  StatusCell,
  StatusHeading,
  StyledEventTradesTable,
  TradeCell,
  TradeHeading,
} from "./styles";

const EventTrade = ({ entriesById, trade, ...rest }) => (
  <tr>
    <EntryCell>
      <LeagueEntryShortName entryId={trade.offered_entry} />
    </EntryCell>
    <TradeCell>
      <StyledTransaction>
        <TransactionUnit>
          <TransactionUnitHeading isIn={true}>Offered</TransactionUnitHeading>
          <TransactionElementGroup elementIds={trade.elementsOut} {...rest} />
        </TransactionUnit>
        <TransactionUnit>
          <TransactionUnitHeading>Requested</TransactionUnitHeading>
          <TransactionElementGroup elementIds={trade.elementsIn} {...rest} />
        </TransactionUnit>
      </StyledTransaction>
    </TradeCell>
    <EntryCell>
      <LeagueEntryShortName entryId={trade.received_entry} />
    </EntryCell>
    <StatusCell>{getTradeStateName(trade.state)}</StatusCell>
  </tr>
);

EventTrade.propTypes = {
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  trade: propTypeTradeGrouped.isRequired,
};

const EventTradesTable = ({ trades, ...rest }) => (
  <TableWrap>
    <StyledEventTradesTable>
      <thead>
        <tr>
          <EntryCell as="th">Offered by</EntryCell>
          <TradeHeading>Trade</TradeHeading>
          <EntryCell as="th">Offered to</EntryCell>
          <StatusHeading>Status</StatusHeading>
        </tr>
      </thead>
      <tbody>
        {trades.map((t) => (
          <EventTrade key={t.id} trade={t} {...rest} />
        ))}
      </tbody>
    </StyledEventTradesTable>
  </TableWrap>
);

EventTradesTable.propTypes = {
  trades: PropTypes.arrayOf(propTypeTradeGrouped).isRequired,
};

export { EventTradesTable as EventTradesTableTest };

const EventTrades = ({ trades, leagueTrades, ...props }) => (
  <div>
    <SubHeading>Current Gameweek Trades</SubHeading>
    <PatternWrapMain>
      <Tabs>
        <TabPanel label="My Trades" link="entry">
          <Box my={4}>
            <EventTradesTable trades={trades} {...props} />
          </Box>
        </TabPanel>
        <TabPanel label="League Trades" link="league">
          <Box my={4}>
            <EventTradesTable trades={leagueTrades} {...props} />
          </Box>
        </TabPanel>
      </Tabs>
    </PatternWrapMain>
  </div>
);

EventTrades.propTypes = {
  trades: PropTypes.arrayOf(propTypeTradeGrouped).isRequired,
  leagueTrades: PropTypes.arrayOf(propTypeTradeGrouped).isRequired,
};

class EventTradesContainer extends Component {
  componentDidMount() {
    this.props.fetchEventTradesData();
    this.props.fetchTransactionData();
  }

  render() {
    return <EventTrades {...this.props} />;
  }
}

EventTradesContainer.propTypes = {
  fetchEventTradesData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const next = getNextEvent(state);
  const league = getActiveLeague(state);
  return {
    elementsById: getElementsById(state),
    entriesById: getActiveLeagueEntriesByEntryId(state),
    leagueTrades:
      next && league
        ? getTradesForLeagueEventGrouped(state, league.id, next.id)
        : [],
    teamsById: getTeamsById(state),
    trades: getCurrentTradesGrouped(state),
    typesById: getElementTypesById(state),
  };
};

const mapDispatchToProps = {
  elementDialogShow: eds,
  fetchEventTradesData: eventTradesFetch,
  fetchTransactionData: transactionDataFetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventTradesContainer);
