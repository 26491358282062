import { connect } from "react-redux";
import { unsubscribeEntry } from "../actions/Entry";
import { getUnsubscribed } from "../reducers/entries";
import Button from "./Button";
import Copy from "./Copy";
import { Main } from "./Layout";
import Title from "./Title";

const EntryUnsubscribe = ({ doUnsubscribe, entryUnsubscribed, signature }) => (
  <Main>
    <Copy>
      <Title>Unsubscribe</Title>
      {entryUnsubscribed ? (
        <p>
          You have been unsubscribed from all emails about your Draft Fantasy
          Premier League team.
        </p>
      ) : (
        <>
          <p>
            Unsubscribe from all emails about your Draft Fantasy Premier League
            team.
          </p>
          <Button fullwidth="true" onClick={() => doUnsubscribe(signature)}>
            Unsubscribe
          </Button>
        </>
      )}
    </Copy>
  </Main>
);

export { EntryUnsubscribe as EntryUnsubscribeTest };

const mapStateToProps = (state, ownProps) => ({
  entryUnsubscribed: getUnsubscribed(state),
  signature: ownProps.signature,
});

const mapDispatchToProps = {
  doUnsubscribe: unsubscribeEntry,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryUnsubscribe);
