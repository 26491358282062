import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { getFormSubmitErrors, reduxForm } from "redux-form";
import { joinPublicLeague as onSubmit } from "../actions/League";
import Alert from "./Alert";
import Button from "./Button";
import EntryFields, { validateEntryFields } from "./EntryFields";
import Form from "./FocusErrorsForm";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import LeagueScoringField, {
  validateLeagueScoringField,
} from "./LeagueScoringField";
import LeagueSizeField, { validateLeagueSizeField } from "./LeagueSizeField";
import TermsFields, { validateTermsFields } from "./TermsFields";
import Copy from "./Copy";
import Title from "./Title";
import { Fieldset, Legend } from "./FieldRenderers";

const JoinPublicLeague = ({ error, handleSubmit, submitErrors, valid }) => (
  <Wrapper>
    <HelmetHead
      title="Create or Join - Fantasy Premier League Draft 2024/25"
      description="Create or join a league in Fantasy Premier League Draft, visit the official website of the Premier League."
    />
    <Main>
      <Copy>
        <Title>Join a Public League</Title>
        <p>
          Fill out your details and choose a League Scoring mode to join the
          next available public league.
        </p>

        <Form error={error} onSubmit={handleSubmit} errors={submitErrors}>
          {error && (
            <Alert type="error" canFocus>
              {error}
            </Alert>
          )}

          <EntryFields />
          <TermsFields />

          <Fieldset>
            <Legend>League</Legend>
            <Box mb={4}>
              <LeagueSizeField />
            </Box>
            <LeagueScoringField />
          </Fieldset>

          <Button type="submit" fullwidth="true" disabled={!valid}>
            Join Public League
          </Button>
        </Form>
      </Copy>
    </Main>
  </Wrapper>
);

JoinPublicLeague.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  valid: PropTypes.bool.isRequired,
};

JoinPublicLeague.defaultProps = {
  error: null,
  submitErrors: null,
};

const validate = (values) => {
  const errors = {};

  // League Scoring validation
  Object.assign(errors, validateLeagueScoringField(values));

  // League Size validation
  Object.assign(errors, validateLeagueSizeField(values));

  // Team validation
  Object.assign(errors, validateEntryFields(values));

  // Terms validation
  Object.assign(errors, validateTermsFields(values));

  return errors;
};

export { validate, JoinPublicLeague as JoinPublicLeagueTest };

const formName = "joinPublicLeague";

const mapStateToProps = (state) => ({
  submitErrors: getFormSubmitErrors(formName)(state),
});

const mapDispatchToProps = {
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    destroyOnUnmount: false,
    form: formName,
    validate,
  })(JoinPublicLeague),
);
