import arrayMove from "array-move";
import { fetchPost, handleUnexpectedError } from "../helpers";
import { getFilteredWatchedElements } from "../reducers/elements";
import { getActiveEntryId } from "../reducers/player";
import { getWatchlist, getWatchlistAPI } from "../reducers/watchlist";
import {
  WATCHLIST_ADD,
  WATCHLIST_REMOVE,
  WATCHLIST_UPDATE,
} from "./ActionTypes";

// regular action creators
export const watchlistUpdate = (data) => ({
  type: WATCHLIST_UPDATE,
  data,
});

// thunk action creators
const updateWatchlist = (dispatch, getState) =>
  fetchPost(
    `/api/watchlist/${getActiveEntryId(getState())}`,
    getWatchlistAPI(getState()),
  ).then(
    (d) => dispatch(watchlistUpdate(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const watchlistAdd = (id) => (dispatch, getState) => {
  dispatch({
    type: WATCHLIST_ADD,
    value: id,
  });
  return updateWatchlist(dispatch, getState);
};

export const watchlistRemove = (id) => (dispatch, getState) => {
  dispatch({
    type: WATCHLIST_REMOVE,
    value: id,
  });
  return updateWatchlist(dispatch, getState);
};

export const getChangedWatchlist = (state, oldIndex, newIndex) => {
  const watchlist = getWatchlist(state);
  const viewElements = getFilteredWatchedElements(state).map((e) => e.id);
  const movedViewIndex = viewElements.indexOf(watchlist[oldIndex]);
  const displacedViewIndex = viewElements.indexOf(watchlist[newIndex]);
  const viewLength = viewElements.length;

  // Dodgy data or nothing to do, we'll handle a large newIndex
  if (movedViewIndex === -1 || newIndex < 0 || oldIndex === newIndex) {
    return watchlist;
  }

  // If we are working on the whole watchlist or the target priority
  // doesn't replace an item on the filtered watchlist then we can
  // do a simple move
  if (watchlist.length === viewLength || displacedViewIndex === -1) {
    return arrayMove(
      watchlist,
      oldIndex,
      watchlist[newIndex] ? newIndex : viewLength - 1,
    );
  }

  const reorderedViewElements = arrayMove(
    viewElements,
    movedViewIndex,
    displacedViewIndex,
  );
  return watchlist.map((id) => {
    if (viewElements.indexOf(id) > -1) {
      return reorderedViewElements.shift();
    }
    return id;
  });
};

export const watchlistChange = (oldPos, newPos) => (dispatch, getState) => {
  // Bail quickly if nothing to do
  if (oldPos === newPos) return;
  dispatch(
    watchlistUpdate(getChangedWatchlist(getState(), oldPos - 1, newPos - 1)),
  );
  updateWatchlist(dispatch, getState);
};
