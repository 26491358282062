import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { fetchEventStatus } from "../../../actions/EventStatus";
import {
  getEventStatus,
  propTypeEventStatus,
} from "../../../reducers/eventStatus";
import { getCurrentEvent, propTypeEvent } from "../../../reducers/events";
import {
  EventStatusFinished,
  EventStatusInfo,
  EventStatusLeagueInfo,
  EventStatusLeagues,
  EventStatusTable,
} from "./styles";

const pointsText = {
  "": "",
  l: "Live",
  p: "Provisional",
  r: "Confirmed",
};

const EventStatusDay = ({
  hasBonusAdded,
  longDate,
  pointsStatus,
  shortDate,
}) => (
  <tr>
    <th scope="row">{longDate}</th>
    <td>
      {pointsStatus === "r" ? (
        <EventStatusInfo>{pointsText[pointsStatus]}</EventStatusInfo>
      ) : (
        <>{pointsText[pointsStatus] ? pointsText[pointsStatus] : <>&nbsp;</>}</>
      )}
    </td>
    <EventStatusFinished>
      {hasBonusAdded && <span>Added</span>}
    </EventStatusFinished>
  </tr>
);

EventStatusDay.propTypes = {
  hasBonusAdded: PropTypes.bool,
  pointsStatus: PropTypes.string,
  longDate: PropTypes.string.isRequired,
  shortDate: PropTypes.string.isRequired,
};

EventStatusDay.defaultProps = {
  hasBonusAdded: false,
  pointsStatus: "",
};

const EventStatus = ({ eventStatus, now }) => {
  return (
    <Box mb={4}>
      <EventStatusTable>
        <thead>
          <tr>
            <th scope="col">Day</th>
            <th scope="col">Match Points</th>
            <th scope="col">Bonus Points</th>
          </tr>
        </thead>
        <tbody>
          {eventStatus.status.map((day) => (
            <EventStatusDay
              hasBonusAdded={day.bonus_added}
              key={day.date}
              longDate={moment(day.date).format("dddd D MMMM")}
              pointsStatus={day.points}
              shortDate={moment(day.date).format("ddd D MMM")}
            />
          ))}
          <EventStatusLeagues>
            <th scope="row">League Tables</th>
            <td colSpan="2">
              {eventStatus.leagues === "Updated" ? (
                <EventStatusLeagueInfo>
                  <span>{eventStatus.leagues}</span>
                </EventStatusLeagueInfo>
              ) : (
                <>{eventStatus.leagues}</>
              )}
            </td>
          </EventStatusLeagues>
        </tbody>
      </EventStatusTable>
    </Box>
  );
};

EventStatus.propTypes = {
  eventStatus: propTypeEventStatus.isRequired,
  now: propTypeEvent.isRequired,
};

export { EventStatus as EventStatusTest };

class EventStatusContainer extends React.Component {
  componentDidMount() {
    this.props.fetchEventStatus();
  }

  render() {
    return <EventStatus {...this.props} />;
  }
}

EventStatusContainer.propTypes = {
  fetchEventStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eventStatus: getEventStatus(state),
  now: getCurrentEvent(state),
});

const mapDispatchToProps = {
  fetchEventStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventStatusContainer);
