import { createGlobalStyle } from "styled-components/macro";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${theme.fonts.regular};
    font-size: 62.5%;
  }

  body {
    margin: 0;
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes[2]};
  }

	.ism {
		*, *:before, *:after {
			box-sizing: inherit;
		}


		p {
			line-height: 1.6;
		}

		strong {
			font-family: ${theme.fonts.bold};
		}

		input[type="search"] {
			box-sizing: inherit;
		}

		fieldset {
			margin: 0;
			padding: 0;
			border: 0;
		}
	}
`;

export default GlobalStyle;
