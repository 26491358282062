export const ACTIVE_ENTRY_CHANGE = "ACTIVE_ENTRY_CHANGE";
export const ADD_CHAT_MESSAGE_SUCCESS = "ADD_CHAT_MESSAGE_SUCCESS";
export const ADD_CURRENT_LEAGUE_TRADES = "ADD_CURRENT_LEAGUE_TRADES";
export const ADD_CURRENT_TRADES = "ADD_CURRENT_TRADES";
export const ADD_DREAM_TEAM = "ADD_DREAM_TEAM";
export const ADD_ELEMENT_EVENT_STATS = "ADD_ELEMENT_EVENT_STATS";
export const ADD_ELEMENT_STATS = "ADD_ELEMENT_STATS";
export const ADD_ELEMENT_TYPES = "ADD_ELEMENT_TYPES";
export const ADD_ELEMENTS = "ADD_ELEMENTS";
export const ADD_ENTRIES = "ADD_ENTRIES";
export const ADD_ENTRY_EVENT_PICKS = "ADD_ENTRY_EVENT_PICKS";
export const ADD_ENTRY_EVENT_SUBS = "ADD_ENTRY_EVENT_SUBS";
export const ADD_ENTRY_HISTORY = "ADD_ENTRY_HISTORY";
export const ADD_EVENTS = "ADD_EVENTS";
export const ADD_FIXTURES = "ADD_FIXTURES";
export const ADD_GAME = "ADD_GAME";
export const ADD_LEAGUE_TRADES = "ADD_LEAGUE_TRADES";
export const ADD_LEAGUE_TRANSACTIONS = "ADD_LEAGUE_TRANSACTIONS";
export const ADD_LEAGUES = "ADD_LEAGUES";
export const ADD_MY_PICKS = "ADD_MY_PICKS";
export const ADD_PLAYER = "ADD_PLAYER";
export const ADD_PUBLIC_ENTRY = "ADD_PUBLIC_ENTRY";
export const ADD_SETTINGS = "ADD_SETTINGS";
export const ADD_TEAMS = "ADD_TEAMS";
export const ADD_TOP_ELEMENTS = "ADD_TOP_ELEMENTS";
export const ADD_TRADES_FOR_APPROVAL = "ADD_TRADES_FOR_APPROVAL";
export const ADD_TRANSACTION_ERROR_ELEMENT = "ADD_TRANSACTION_ERROR_ELEMENT";
export const CANCEL_ELEMENT_TRANSACTION = "CANCEL_ELEMENT_TRANSACTION";
export const CHAT_MESSAGE = "CHAT_MESSAGE";
export const CREATE_LEAGUE = "CREATE_LEAGUE";
export const CREATE_LEAGUE_ERROR = "CREATE_LEAGUE_ERROR";
export const CREATE_LEAGUE_SUCCESS = "CREATE_LEAGUE_SUCCESS";
export const DELETE_ENTRY_SUCCESS = "DELETE_ENTRY_SUCCESS";
export const DELETE_LEAGUE_ENTRY_SUCCESS = "DELETE_LEAGUE_ENTRY_SUCCESS";
export const DELETE_LEAGUE_SUCCESS = "DELETE_LEAGUE_SUCCESS";
export const DRAFT_COMPLETED = "DRAFT_COMPLETED";
export const DRAFT_ELEMENT = "DRAFT_ELEMENT";
export const DRAFT_STARTED = "DRAFT_STARTED";
export const EDIT_ENTRY_ERROR = "EDIT_ENTRY_ERROR";
export const EDIT_ENTRY_SUCCESS = "EDIT_ENTRY_SUCCESS";
export const EDIT_LEAGUE_ERROR = "EDIT_LEAGUE_ERROR";
export const EDIT_LEAGUE_SUCCESS = "EDIT_LEAGUE_SUCCESS";
export const ELEMENT_DIALOG_HIDE = "ELEMENT_DIALOG_HIDE";
export const ELEMENT_DIALOG_SHOW = "ELEMENT_DIALOG_SHOW";
export const ENTRY_UNSUBSCRIBED = "ENTRY_UNSUBSCRIBED";
export const FETCH_CHAT_MESSAGES_SUCCESS = "FETCH_CHAT_MESSAGES_SUCCESS";
export const FETCH_DRAFT_CHOICES_SUCCESS = "FETCH_DRAFT_CHOICES_SUCCESS";
export const FETCH_ELEMENT_STATUS_SUCCESS = "FETCH_ELEMENT_STATUS_SUCCESS";
export const FETCH_ELEMENT_SUMMARY_SUCCESS = "FETCH_ELEMENT_SUMMARY_SUCCESS";
export const FETCH_EVENT_STATUS_SUCCESS = "FETCH_EVENT_STATUS_SUCCESS";
export const FETCH_LEAGUE_ENTRIES_SUCCESS = "FETCH_LEAGUE_ENTRIES_SUCCESS";
export const FETCH_LEAGUE_MATCHES_SUCCESS = "FETCH_LEAGUE_MATCHES_SUCCESS";
export const FETCH_LEAGUE_STANDINGS_SUCCESS = "FETCH_LEAGUE_STANDINGS_SUCCESS";
export const FETCH_LEAGUE_SUCCESS = "FETCH_LEAGUE_SUCCESS";
export const JOIN_LEAGUE_ERROR = "JOIN_LEAGUE_ERROR";
export const JOIN_LEAGUE_SUCCESS = "JOIN_LEAGUE_SUCCESS";
export const LEAGUE_ENTRIES_CHANGED = "LEAGUE_ENTRIES_CHANGED";
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const PROPOSE_ELEMENT_TRANSACTION = "PROPOSE_ELEMENT_TRANSACTION";
export const PROPOSE_TRADE_INITIAL = "PROPOSE_TRADE_INITIAL";
export const SUBSTITUTION_PROCESS = "SUBSTITUTION_PROCESS";
export const SUBSTITUTION_START = "SUBSTITUTION_START";
export const SUBSTITUTION_STOP = "SUBSTITUTION_STOP";
export const TRANSFER_ADD = "TRANSFER_ADD";
export const TRANSFER_ERROR_RESET = "TRANSFER_ERROR_RESET";
export const TRANSFER_REMOVE = "TRANSFER_REMOVE";
export const TRANSFER_RESET = "TRANSFER_RESET";
export const UPDATE_FIXTURES = "UPDATE_FIXTURES";
export const UPDATE_LEAGUE_TRANSACTION_MODE = "UPDATE_LEAGUE_TRANSACTION_MODE";
export const WAIVER_ADD = "WAIVER_ADD";
export const WAIVER_CHANGE = "WAIVER_CHANGE";
export const WAIVER_REMOVE = "WAIVER_REMOVE";
export const WAIVER_UPDATE = "WAIVER_UPDATE";
export const WATCHLIST_ADD = "WATCHLIST_ADD";
export const WATCHLIST_REMOVE = "WATCHLIST_REMOVE";
export const WATCHLIST_RESTRICT = "WATCHLIST_RESTRICT";
export const WATCHLIST_UPDATE = "WATCHLIST_UPDATE";
