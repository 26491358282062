import { em, size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as Drag } from "../../../img/icons/drag-h.svg";
import Table from "../../Table";

export const SquadElementTableWrap = styled.div`
  height: 55vh;
  max-height: 550px;
  background-color: white;
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-right: ${(props) => props.theme.space[2]};
    margin-left: ${(props) => props.theme.space[2]};
  }
`;

export const SquadPlayersTable = styled(Table)`
  th,
  td {
    :not(:nth-child(2)) {
      text-align: center;
    }
  }

  thead {
    position: sticky;
    top: 0px;
    z-index: 100;

    th {
      background-color: #fff;
      font-size: 1rem;

      /* next 3 */
      @media (max-width: ${em("680px")}) {
        &.opp-col {
          display: none;
        }
      }
    }
  }

  td {
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    font-size: ${(props) => props.theme.fontSizes[0]};

    :first-child {
      padding: 0;
    }

    :nth-child(2) {
      position: relative;

      ::after {
        content: "";
        position: absolute;
        top: 1rem;
        right: 0;
        bottom: 1rem;
        display: block;
        width: 1px;
        border-radius: 1px;
        background: ${(props) => props.theme.colors.lightGrey};
      }
    }

    @media (max-width: ${em("680px")}) {
      &.opp-col {
        display: none;
      }
    }
  }
`;

export const DragBar = styled.div`
  padding: ${(props) => props.theme.space[2]};
  border: 2px solid #e4e4e4;
  background-color: #f7f7f6;
  line-height: 1;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`;

export const DragIcon = styled(Drag)`
  ${size("3.5rem", "1.3rem")};
  display: inline-block;
  color: ${(props) => props.theme.colors.lightGrey};
  line-height: 1;
  vertical-align: middle;
  fill: ${(props) => props.theme.colors.darkGrey};
`;
