import ReconnectingWebSocket from "reconnecting-websocket";
import { fetchDraftChoicesSuccess } from "../actions/Draft";
import { getDraftableWatchlist } from "../reducers/draftChoices";
import { fetchLeagueSuccess } from "../actions/League";
import { getActiveEntry } from "../reducers/entries";
import { getActiveEntryId } from "../reducers/player";
import { getActiveLeague } from "../reducers/leagues";
import {
  ACTIVE_ENTRY_CHANGE,
  ADD_CHAT_MESSAGE_SUCCESS,
  CHAT_MESSAGE,
  DRAFT_COMPLETED,
  DRAFT_ELEMENT,
  LEAGUE_ENTRIES_CHANGED,
  LOGOUT_SUCCESS,
  WATCHLIST_RESTRICT,
} from "../actions/ActionTypes";

let socket = null;
const heartbeatClose = "--closeSocket--";
const heartbeatMsg = "--heartbeat--";
let heartbeatInterval = null;
let heartbeatsMissed = 0;

export const getSocketUrl = (getState) => {
  let url = null;
  const state = getState();
  const activeEntry = getActiveEntry(state);
  const activeLeague = getActiveLeague(state);
  const host = process.env.REACT_APP_WSS_HOST || window.location.host;
  if (activeEntry && activeLeague) {
    url = `wss://${host}/ws/${activeLeague.id}-${activeEntry.id}`;
  }
  return url;
};

const stopHeartbeat = () => {
  if (heartbeatInterval) {
    clearInterval(heartbeatInterval);
    heartbeatInterval = null;
  }
};

const closeSocket = () => {
  stopHeartbeat();
  if (socket) {
    socket.close(1000, "", { keepClosed: true, fastClose: true });
  }
};

export const processMessage = (event, dispatch, getState) => {
  const data = JSON.parse(event.data);

  // Handle close order
  if (data.payload && data.payload === heartbeatClose) {
    closeSocket();
    return;
  }

  // Handle heartbeat
  if (data.payload && data.payload === heartbeatMsg) {
    heartbeatsMissed = 0;
    return;
  }

  switch (data.type) {
    case "MESSAGE_CREATED":
      dispatch({
        type: ADD_CHAT_MESSAGE_SUCCESS,
        data: data.data,
      });
      break;

    case "DRAFT_CHOICES_CREATED": {
      const league = getActiveLeague(getState());
      const entryId = getActiveEntryId(getState());
      dispatch(fetchDraftChoicesSuccess(data.data, league, entryId));
      dispatch({
        type: WATCHLIST_RESTRICT,
        allowed: getDraftableWatchlist(getState()),
      });
      break;
    }

    case "LEAGUE_ENTRIES_CHANGED":
      dispatch({
        type: LEAGUE_ENTRIES_CHANGED,
        data: {
          entries: data.data,
          league: getActiveLeague(getState()),
        },
      });
      break;

    case "LEAGUE_UPDATED":
      dispatch(
        fetchLeagueSuccess({
          league: data.data,
        }),
      );
      break;

    case "DRAFT_COMPLETED":
      dispatch({
        type: DRAFT_COMPLETED,
        data: data.data,
      });
      break;

    case "ERROR":
      // Error is available in data.text
      closeSocket();
      window.location = "/";
      break;

    default:
      break;
  }
};

const startHeartbeat = () => {
  if (heartbeatInterval === null) {
    heartbeatsMissed = 0;
    heartbeatInterval = setInterval(() => {
      try {
        heartbeatsMissed += 1;
        if (heartbeatsMissed > 3) {
          throw new Error("Too many missed heartbeats.");
        }
        socket.send(
          JSON.stringify({
            stream: "core",
            payload: { text: heartbeatMsg },
          }),
        );
      } catch (e) {
        stopHeartbeat();
        socket.close(1000, `Closing connection. Reason: ${e.message}`);
      }
    }, 20000);
  }
};

const openSocket = (dispatch, getState) => {
  closeSocket();
  const url = getSocketUrl(getState);
  if (url) {
    const socketOptions = {
      maxRetries: 3,
      maxReconnectionDelay: 10000,
      minReconnectionDelay: 1500,
      reconnectionDelayGrowFactor: 1.3,
    };
    socket = new ReconnectingWebSocket(url, [], socketOptions);
    // Add hook for fetching latest data dependent on active entry status?
    socket.onopen = () => {
      // Start hearbeat if draft is in progress or about to start
      // const league = getActiveLeague(getState());
      // if (league && league.draft_status !== 'post') {
      startHeartbeat();
      // }
    };
    socket.onmessage = (event) => processMessage(event, dispatch, getState);
  }
  return socket;
};

const ACTIONS = {
  [CHAT_MESSAGE]: (action) => {
    socket.send(
      JSON.stringify({
        stream: "chat",
        payload: { text: action.text },
      }),
    );
  },
  [DRAFT_ELEMENT]: (action) => {
    socket.send(
      JSON.stringify({
        stream: "draft",
        payload: { id: action.data.id, element: action.data.element },
      }),
    );
  },
};

export const shouldActionConnectSocket = (action) => {
  if (action.type === ACTIVE_ENTRY_CHANGE) return true;
  if (action.type === "BATCHING_REDUCER.BATCH") {
    return action.payload.some((a) => a.type === ACTIVE_ENTRY_CHANGE);
  }
  return false;
};

const socketMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    // Send a chat message
    if (ACTIONS[action.type]) {
      if (!socket || socket.readyState !== WebSocket.OPEN) {
        socket = openSocket(dispatch, getState);
        socket.addEventListener("open", () => ACTIONS[action.type](action));
      } else {
        ACTIONS[action.type](action);
      }
    } else if (action.type === LOGOUT_SUCCESS) {
      closeSocket();
    }

    // Call next dispatch
    const result = next(action);

    // Re-establish socket as active entry / league could have changed
    if (shouldActionConnectSocket(action)) {
      socket = openSocket(dispatch, getState);
    }

    return result;
  };

export default socketMiddleware;
