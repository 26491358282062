import PropTypes from "prop-types";
import { propTypeElementStatus } from "../../../reducers/elementStatus";
import { propTypeElementType } from "../../../reducers/elementTypes";
import { propTypeElement, propTypeSortStat } from "../../../reducers/elements";
import { propTypeTeam } from "../../../reducers/teams";
import { propTypeEventTeamFixture } from "../../../store/fixtures/slice";
import { getShortNameFromId } from "../../../utils/events";
import { fixtureSuffix } from "../../../utils/fixtures";
import Abbr from "../../Abbr/Abbr";
import Copy from "../../Copy";
import ElementInTable from "../../ElementInTable";
import ElementStatusButton from "../../ElementStatusButton";
import { TeamShortName } from "../../ElementTable";
import {
  PrimaryContainer,
  PrimaryContainerHeading,
  PrimaryContainerHeadingValue,
} from "../../PrimaryContainer";
import { TableWrap } from "../../Table";
import {
  ElementActionIcon,
  ElementActionLabel,
  StyledElementAction,
} from "../../squad/ElementAction/styles";
import { PrimaryIcon, SquadButton, SquadButtonText } from "../../squad/styles";
import { VisuallyHidden } from "../../styles";
import {
  ActionCell,
  ElementCell,
  FixturesCell,
  SortStatCell,
  StatusCell,
  StyledTransactionRemoveTable,
} from "./styles";

const TransactionRemoveRow = ({
  actionMe,
  actionLabel,
  element,
  next3Fixtures,
  proposed,
  sortStat,
  teamsById,
}) => (
  <tr key={element.id}>
    <StatusCell as="td">
      <ElementStatusButton elementId={element.id} />
    </StatusCell>
    <ElementCell as="td">
      <ElementInTable elementId={element.id} />
    </ElementCell>
    <SortStatCell as="td">{sortStat && element[sortStat.name]}</SortStatCell>
    {next3Fixtures.map((data) => (
      <FixturesCell as="td" key={data.event.id}>
        <ul key={element.id}>
          {data.fixtures[element.team].map((f) => (
            <li key={f.id}>
              <TeamShortName>{teamsById[f.opponent].short_name}</TeamShortName>
              {fixtureSuffix(f.id, f.isHome)}
            </li>
          ))}
        </ul>
      </FixturesCell>
    ))}
    <ActionCell>
      {proposed ? (
        <StyledElementAction>
          <ElementActionLabel>Proposed</ElementActionLabel>
          <ElementActionIcon>
            <svg className="ism-icon--pending">
              <use xlinkHref="#ism-pending" />
            </svg>
          </ElementActionIcon>
        </StyledElementAction>
      ) : (
        <SquadButton
          variant="primary"
          type="button"
          onClick={actionMe}
          data-id={element.id}
        >
          <SquadButtonText>{actionLabel}</SquadButtonText>
          <PrimaryIcon>
            <use xlinkHref={`#ism-${actionLabel.toLowerCase()}`} />
          </PrimaryIcon>
        </SquadButton>
      )}
    </ActionCell>
  </tr>
);

TransactionRemoveRow.propTypes = {
  actionMe: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  element: propTypeElement.isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  proposed: PropTypes.bool.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  sortStat: propTypeSortStat.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

const TransactionRemoveTable = ({
  actionMe,
  actionLabel,
  elements,
  elementStatus,
  elementDialogShow,
  sortStat,
  next3Fixtures,
  teamsById,
  typesById,
}) => (
  <TableWrap>
    <StyledTransactionRemoveTable>
      <thead>
        <tr>
          <StatusCell>
            <VisuallyHidden>Info</VisuallyHidden>
          </StatusCell>
          <ElementCell>Player</ElementCell>
          <SortStatCell>
            {sortStat && (
              <Abbr
                longName={sortStat.label}
                shortName={sortStat.abbreviation}
              />
            )}
          </SortStatCell>
          {next3Fixtures.map((data) => (
            <FixturesCell key={data.event.id}>
              {getShortNameFromId(data.event.id)}
            </FixturesCell>
          ))}
          <ActionCell>{actionLabel}</ActionCell>
        </tr>
      </thead>
      <tbody>
        {elements.map((element) => (
          <TransactionRemoveRow
            actionMe={actionMe}
            actionLabel={actionLabel}
            element={element}
            key={element.id}
            proposed={elementStatus[element.id].proposed}
            elementDialogShow={elementDialogShow}
            sortStat={sortStat}
            next3Fixtures={next3Fixtures}
            teamsById={teamsById}
            typesById={typesById}
          />
        ))}
      </tbody>
    </StyledTransactionRemoveTable>
  </TableWrap>
);

TransactionRemoveTable.propTypes = {
  actionMe: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementStatus: PropTypes.objectOf(propTypeElementStatus).isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  sortStat: propTypeSortStat.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

const TransactionRemove = ({
  cancelMe,
  elementStatus,
  next3Fixtures,
  proposedElement,
  removeMe,
  replaceableElements,
  elementDialogShow,
  sortStat,
  teamsById,
  typesById,
}) => {
  if (!proposedElement) {
    return (
      <Copy>
        <p>No new player proposed</p>
      </Copy>
    );
  }
  return (
    <PrimaryContainer>
      <PrimaryContainerHeading as="p">
        You have requested to sign
        <PrimaryContainerHeadingValue>
          {" "}
          {proposedElement.web_name}
        </PrimaryContainerHeadingValue>
        .
      </PrimaryContainerHeading>
      <TransactionRemoveTable
        actionMe={cancelMe}
        actionLabel="Cancel"
        next3Fixtures={next3Fixtures}
        elements={[proposedElement]}
        elementStatus={elementStatus}
        elementDialogShow={elementDialogShow}
        sortStat={sortStat}
        teamsById={teamsById}
        typesById={typesById}
      />
      <PrimaryContainerHeading as="p">
        Which player would you like
        <PrimaryContainerHeadingValue>
          {" "}
          {proposedElement.web_name}{" "}
        </PrimaryContainerHeadingValue>
        to replace?
      </PrimaryContainerHeading>
      <TransactionRemoveTable
        actionMe={removeMe}
        actionLabel="Replace"
        next3Fixtures={next3Fixtures}
        elements={replaceableElements}
        elementStatus={elementStatus}
        elementDialogShow={elementDialogShow}
        sortStat={sortStat}
        teamsById={teamsById}
        typesById={typesById}
      />
    </PrimaryContainer>
  );
};

TransactionRemove.propTypes = {
  cancelMe: PropTypes.func.isRequired,
  elementStatus: PropTypes.objectOf(propTypeElementStatus).isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  proposedElement: propTypeElement,
  removeMe: PropTypes.func.isRequired,
  replaceableElements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  sortStat: propTypeSortStat.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

TransactionRemove.defaultProps = {
  proposedElement: null,
};

export default TransactionRemove;
