import styled from "styled-components/macro";
import SubHeading from "../SubHeading";

export const ExplainHeading = styled(SubHeading)`
  text-align: center;
`;

export const ExplainScore = styled.span`
  margin: 0 ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]};
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
`;
