import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import { FETCH_LEAGUE_MATCHES_SUCCESS } from "../actions/ActionTypes";
import { getCurrentEvent, getNextEvent } from "./events";
import { getActiveLeagueEntry } from "./leagueEntries";
import { getActiveLeague } from "./leagues";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.leagueMatches;
export const l2g = (local) => ({ leagueMatches: local });

const byLeagueId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LEAGUE_MATCHES_SUCCESS: {
      const matches = {};
      action.data.matches.forEach((m) => {
        if (!matches[m.event]) matches[m.event] = [];
        matches[m.event].push(m);
      });
      return {
        ...state,
        [action.data.league.id]: { byEventId: matches },
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  byLeagueId,
});

// Selectors
export const getAllLeagueMatches = (state) => g2l(state);

export const getLeagueEntryFilter = (state) => {
  const matchesFilter = getFormValues("EntryMatchesFilter")(state);

  if (!matchesFilter || !matchesFilter.entryNames) {
    return 0;
  }

  return parseInt(matchesFilter.entryNames, 10);
};

export const getActiveLeagueMatchesByEvent = createSelector(
  getAllLeagueMatches,
  getActiveLeague,
  (all, league) => {
    if (all.byLeagueId[league.id]) {
      return all.byLeagueId[league.id].byEventId;
    }
    return {};
  },
);

export const getFilteredActiveLeagueMatchesByEvent = createSelector(
  getActiveLeagueMatchesByEvent,
  getLeagueEntryFilter,
  (matchesByEvent, leagueEntryId) => {
    if (!leagueEntryId) {
      return matchesByEvent;
    }
    return Object.keys(matchesByEvent).reduce((matches, eventId) => {
      Object.assign(matches, {
        [eventId]: matchesByEvent[eventId].filter(
          (match) =>
            match.league_entry_1 === leagueEntryId ||
            match.league_entry_2 === leagueEntryId,
        ),
      });
      return matches;
    }, {});
  },
);

export const getFilteredActiveLeagueFixturesByEvent = createSelector(
  getFilteredActiveLeagueMatchesByEvent,
  getCurrentEvent,
  (matches, now) => {
    if (!now) {
      return matches;
    }

    const fixtureStartEventId = now.finished ? now.id + 1 : now.id;

    return Object.keys(matches).reduce((fixtures, eventId) => {
      if (eventId >= fixtureStartEventId) {
        return Object.assign(fixtures, {
          [eventId]: matches[eventId],
        });
      }
      return fixtures;
    }, {});
  },
);

export const getFilteredActiveLeagueResultsByEvent = createSelector(
  getFilteredActiveLeagueMatchesByEvent,
  getCurrentEvent,
  (matches, now) => {
    if (!now) {
      return {};
    }

    const resultsStartEventId = now.finished ? now.id : now.id - 1;

    return Object.keys(matches).reduce((results, eventId) => {
      if (eventId <= resultsStartEventId) {
        return Object.assign(results, {
          [eventId]: matches[eventId],
        });
      }
      return results;
    }, {});
  },
);

export const getActiveEntryMatchThisEvent = createSelector(
  getActiveLeagueEntry,
  getActiveLeagueMatchesByEvent,
  getCurrentEvent,
  (leagueEntry, matches, now) => {
    if (!leagueEntry || !now || !matches[now.id]) {
      return undefined;
    }
    const finds = matches[now.id].filter(
      (m) =>
        m.league_entry_1 === leagueEntry.id ||
        m.league_entry_2 === leagueEntry.id,
    );
    return finds.length ? finds[0] : undefined;
  },
);

export const getActiveEntryMatchNextEvent = createSelector(
  getActiveLeagueEntry,
  getActiveLeagueMatchesByEvent,
  getNextEvent,
  (leagueEntry, matches, nxt) => {
    if (!leagueEntry || !nxt || !matches[nxt.id]) {
      return undefined;
    }
    const finds = matches[nxt.id].filter(
      (m) =>
        m.league_entry_1 === leagueEntry.id ||
        m.league_entry_2 === leagueEntry.id,
    );
    return finds.length ? finds[0] : undefined;
  },
);

export const getActiveLeagueResultsThisEvent = createSelector(
  getCurrentEvent,
  getActiveLeagueMatchesByEvent,
  (now, matches) => {
    if (!now || !now.id || !now.finished) return {};
    if (!matches || !matches[now.id]) return {};
    return matches[now.id].reduce((memo, m) => {
      if (m.league_entry_1_points > m.league_entry_2_points) {
        return Object.assign(memo, {
          [m.league_entry_1]: "W",
          [m.league_entry_2]: "L",
        });
      } else if (m.league_entry_1_points < m.league_entry_2_points) {
        return Object.assign(memo, {
          [m.league_entry_1]: "L",
          [m.league_entry_2]: "W",
        });
      }
      return Object.assign(memo, {
        [m.league_entry_1]: "D",
        [m.league_entry_2]: "D",
      });
    }, {});
  },
);

export const getActiveEntryResultThisEvent = createSelector(
  getActiveLeagueEntry,
  getActiveLeagueResultsThisEvent,
  (leagueEntry, results) => {
    if (!leagueEntry || !results) {
      return undefined;
    }
    return results[leagueEntry.id];
  },
);

// PropTypes
export const propTypeLeagueMatch = PropTypes.shape({
  league_entry_1: PropTypes.number,
  league_entry_2: PropTypes.number,
});

export const propTypeResult = PropTypes.oneOf(["W", "L", "D", undefined]);
