import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeEntry } from "../../../reducers/entries";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { propTypeResult } from "../../../reducers/leagueMatches";
import { propTypeLeagueStanding } from "../../../reducers/leagueStandings";
import { propTypeLeague } from "../../../reducers/leagues";
import BoldLink, { BoldLinkWrap } from "../../BoldLink";
import Link from "../../Link";
import Panel from "../../Panel";
import Result from "../../Result";
import Movement from "../../league/Movement";
import { StandingsRow } from "../../styles";
import {
  MiniStandingsEvent,
  MiniStandingsRank,
  MiniStandingsTable,
  MiniStandingsTeam,
  MiniStandingsTotal,
} from "./styles";

const MiniStanding = ({
  activeEntry,
  entry,
  league,
  linkEventId,
  results,
  standing,
}) => (
  <StandingsRow mine={activeEntry.id === entry.entry_id}>
    <td>
      {standing.rank || "-"}{" "}
      <Movement lastRank={standing.last_rank} rank={standing.rank} />
    </td>
    {linkEventId ? (
      <td>
        {entry.entry_id ? (
          <Link to={`/entry/${entry.entry_id}/event/${linkEventId}`}>
            <strong>{entry.entry_name}</strong>
            <br />
            {entry.player_first_name} {entry.player_second_name}
          </Link>
        ) : (
          <div>
            <strong>AVERAGE</strong>
            <br />
            AVERAGE
          </div>
        )}
      </td>
    ) : (
      <td>
        <strong>{entry.entry_id ? entry.entry_name : "AVERAGE"}</strong>
        <br />
        {entry.entry_id
          ? `${entry.player_first_name} ${entry.player_second_name}`
          : "AVERAGE"}
      </td>
    )}
    {league.scoring === "h" ? (
      <MiniStandingsEvent>
        {results[entry.id] ? <Result resultChar={results[entry.id]} /> : "-"}
      </MiniStandingsEvent>
    ) : (
      <MiniStandingsEvent>{standing.event_total}</MiniStandingsEvent>
    )}
    <MiniStandingsTotal>{standing.total}</MiniStandingsTotal>
  </StandingsRow>
);

MiniStanding.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entry: propTypeLeagueEntry.isRequired,
  league: propTypeLeague.isRequired,
  linkEventId: PropTypes.number.isRequired,
  results: PropTypes.objectOf(propTypeResult).isRequired,
  standing: propTypeLeagueStanding.isRequired,
};

const MiniStandings = ({
  activeEntry,
  entriesById,
  linkEventId,
  league,
  results,
  standings,
}) => (
  <Panel headingText={league.name}>
    <Box mx={-2}>
      <MiniStandingsTable>
        <thead>
          <tr>
            <MiniStandingsRank>Rank</MiniStandingsRank>
            <MiniStandingsTeam>Team</MiniStandingsTeam>
            <MiniStandingsEvent as="th">GW</MiniStandingsEvent>
            <MiniStandingsTotal as="th">PTS</MiniStandingsTotal>
          </tr>
        </thead>
        <tbody>
          {standings.map((standing) => (
            <MiniStanding
              activeEntry={activeEntry}
              entry={entriesById[standing.league_entry]}
              key={standing.league_entry}
              league={league}
              linkEventId={linkEventId}
              results={results}
              standing={standing}
            />
          ))}
        </tbody>
      </MiniStandingsTable>
    </Box>
    <BoldLinkWrap>
      <BoldLink to="/league">Full Table</BoldLink>
    </BoldLinkWrap>
  </Panel>
);

MiniStandings.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  league: propTypeLeague.isRequired,
  linkEventId: PropTypes.number.isRequired,
  results: PropTypes.objectOf(propTypeResult).isRequired,
  standings: PropTypes.arrayOf(propTypeLeagueStanding),
};

MiniStandings.defaultProps = {
  standings: [],
};

export default MiniStandings;
