import styled from "styled-components/macro";
import { IStyledSquadHeader } from "./types";
import { VerticallyCenter } from "../../styles";

export const StyledSquadHeader = styled.div<IStyledSquadHeader>`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    position: ${(props) => (props.hasFilter ? "relative" : "static")};
  }
`;

export const FilterWrap = styled.div`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    ${VerticallyCenter}
    right: 0;
  }
`;

export const ElementsShown = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }
`;

export const ElementsShownText = styled.p`
  color: #fff;
  text-align: center;
`;

export const ElementsShownNumber = styled.strong`
  color: ${(props) => props.theme.colors.fantasy};
`;
