import { useSelector } from "react-redux";
import { getElement } from "../../../reducers/elements";
import { RootState } from "../../../reducers/rootReducer";
import ElementStatusButton from "../../ElementStatusButton";
import {
  ElementBlock,
  StyledTransactionElement,
  TransactionElementStatus,
} from "./styles";
import { ITransactionElement } from "./types";
import ElementInTable from "../../ElementInTable";

const TransactionElement: React.FC<ITransactionElement> = ({ elementId }) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId),
  );

  return (
    <StyledTransactionElement>
      <TransactionElementStatus>
        <ElementStatusButton elementId={element.id} />
      </TransactionElementStatus>
      <ElementBlock>
        <ElementInTable elementId={element.id} />
      </ElementBlock>
    </StyledTransactionElement>
  );
};

export default TransactionElement;
