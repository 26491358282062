import styled from "styled-components/macro";
import scoutBg from "../../img/pattern/scout-bg.png";
import Table from "../Table";

export const Featured = styled.div`
  background-image: url(${scoutBg}),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.lightBlue},
      ${(props) => props.theme.colors.fantasy}
    );
  background-position:
    right -75px bottom -230px,
    0;
  background-repeat: no-repeat;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: ${(props) => props.theme.space[2]};
  }
`;

export const ScoutImg = styled.img`
  display: block;
  transition: 0.2s;
  width: 100%;
`;

export const HeroThumb = styled.a`
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;

  :hover ${ScoutImg} {
    transform: scale(1.05);
  }
`;

export const ScoutFigure = styled.figure`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: flex;
  }
`;

export const ScoutImgWrap = styled.div`
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex: 1;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-basis: 40%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    flex-basis: 32%;
  }
`;

export const ScoutCaption = styled.figcaption`
  padding: ${(props) => props.theme.space[4]};

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex: 1;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-basis: 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    flex-basis: 68%;
  }
`;

export const ScoutTitle = styled.h4`
  margin-bottom: ${(props) => props.theme.space[2]};
  font-size: 2.6rem;
  line-height: 1.1;
`;

export const PublishedDate = styled.time`
  display: block;
  margin-top: 1rem;
  font-size: 1.2rem;
`;

export const HomeSteps = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const HomeStepText = styled.p`
  margin-bottom: ${(props) => props.theme.space[2]};
`;

export const ElementStatInfo = styled.td`
  width: 13%;
`;

export const ElementStatBoldHeading = styled.th`
  width: 15%;
`;

export const ElementStatBold = styled.td`
  font-family: ${(props) => props.theme.fonts.bold};
  text-align: center;
`;

export const ElementStatLined = styled(ElementStatBold)`
  position: relative;

  ::after {
    display: block;
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0;
    width: 1px;
    background: #dbd9d9;
    content: "";
  }
`;

export const ElementStatShirt = styled.td`
  width: 10%;
  text-align: right;
`;

export const ElementStatName = styled.td`
  width: 32%;
`;

export const ElementStatTable = styled(Table)`
  table-layout: fixed;

  ${ElementStatBoldHeading} {
    text-align: center;
  }

  ${ElementStatInfo} {
    padding: 0;
  }
`;
