import PropTypes from "prop-types";
import { propTypeElement } from "../../reducers/elements";
import {
  propTypeTradeItem,
  propTypeTradeWarningElement,
} from "../../reducers/transactions";
import Button from "../Button";
import Copy from "../Copy";
import Dialog from "../Dialog";
import { ConfirmDialogBody } from "../Dialog/styles";
import ElementTradeWarning from "./ElementTradeWarning";
import TradeSummaryTable from "./TradeSummaryTable";

const TradeActionDialog = ({
  buttonLabel,
  children,
  closeDialog,
  elementsById,
  header,
  onConfirm,
  tradeId,
  tradeItems,
  tradeWarningElements,
}) => {
  const TradeActionDialogBody = () => (
    <ConfirmDialogBody>
      <Copy>
        {children}
        <TradeSummaryTable
          elementsById={elementsById}
          tradeItems={tradeItems}
          tradeWarningElements={tradeWarningElements}
        />
        {tradeWarningElements &&
          Object.keys(tradeWarningElements).length > 0 && (
            <ElementTradeWarning isVisual />
          )}
        <Button dataId={tradeId} onClick={onConfirm} fullwidth="true">
          {buttonLabel}
        </Button>
      </Copy>
    </ConfirmDialogBody>
  );
  return (
    <Dialog closeDialog={closeDialog}>
      <Dialog.Header closeDialog={closeDialog}>{header}</Dialog.Header>
      <TradeActionDialogBody>{children}</TradeActionDialogBody>
    </Dialog>
  );
};

TradeActionDialog.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  closeDialog: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  tradeId: PropTypes.number,
  tradeItems: PropTypes.arrayOf(propTypeTradeItem).isRequired,
  tradeWarningElements: PropTypes.objectOf(propTypeTradeWarningElement),
};

TradeActionDialog.defaultProps = {
  tradeId: null,
  tradeWarningElements: null,
};

export default TradeActionDialog;
