import React from "react";
import Alert from "./Alert";

const LatestAlert = () => {
  const showMessage = false;
  if (!showMessage) {
    return null;
  }
  const message = (
    <div style={{ fontSize: 14 }}>
      <strong>Fixtures Postponed</strong>
      <br />
      <a
        href="https://www.premierleague.com/news/1647234"
        style={{ color: "#000", textDecoration: "underline" }}
      >
        Learn how the postponement of Premier League fixtures will affect teams
        in Fantasy Premier League Draft.
      </a>
      <br />
    </div>
  );
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Alert>{message}</Alert>
    </div>
  );
};
export default LatestAlert;
