import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field, getFormSubmitErrors, reduxForm } from "redux-form";
import { joinPrivateLeague as onSubmit } from "../actions/League";
import Alert from "./Alert";
import Button from "./Button";
import EntryFields, { validateEntryFields } from "./EntryFields";
import { Fieldset, InputField, Legend } from "./FieldRenderers";
import Form from "./FocusErrorsForm";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import TermsFields, { validateTermsFields } from "./TermsFields";
import Title from "./Title";
import Copy from "./Copy";
import { Box } from "rebass/styled-components";

const JoinPrivateLeague = ({ error, handleSubmit, submitErrors, valid }) => (
  <Wrapper>
    <HelmetHead
      title="Create or Join - Fantasy Premier League Draft 2024/25"
      description="Create or join a league in Fantasy Premier League Draft, visit the official website of the Premier League."
    />
    <Main>
      <Copy>
        <Title>Join a Private League</Title>

        <p>
          Enter the league code sent by the league administrator below and fill
          out your details to join the private league.
        </p>

        <Form error={error} onSubmit={handleSubmit} errors={submitErrors}>
          {error && (
            <Alert type="error" canFocus>
              {error}
            </Alert>
          )}

          <Fieldset>
            <Legend>League</Legend>
            <Box mb={4}>
              <Field
                id="leaguePrivateCode"
                name="leaguePrivateCode"
                component={InputField}
                type="text"
                label="Private League Code"
                maxLength="6"
              />
            </Box>
          </Fieldset>

          <EntryFields />
          <TermsFields />

          <Button type="submit" fullwidth="true" disabled={!valid}>
            Join Private League
          </Button>
        </Form>
      </Copy>
    </Main>
  </Wrapper>
);

JoinPrivateLeague.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  valid: PropTypes.bool.isRequired,
};

JoinPrivateLeague.defaultProps = {
  error: null,
  submitErrors: null,
};

const validate = (values) => {
  const errors = {};
  const codeRegexp = /^[a-z0-9]{6}$/;

  // League validation
  if (values.leaguePrivateCode && !values.leaguePrivateCode.match(codeRegexp)) {
    errors.leaguePrivateCode =
      "Private league codes contain 6 lower case letters and numbers.";
  }

  // Team validation
  Object.assign(errors, validateEntryFields(values));

  // Terms validation
  Object.assign(errors, validateTermsFields(values));

  return errors;
};

export { validate, JoinPrivateLeague as JoinPrivateLeagueTest };

const formName = "joinPrivateLeague";

const mapStateToProps = (state) => ({
  submitErrors: getFormSubmitErrors(formName)(state),
});

const mapDispatchToProps = {
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    destroyOnUnmount: false,
    form: formName,
    validate,
  })(JoinPrivateLeague),
);
