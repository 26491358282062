import { ellipsis, hideVisually, rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { IStandingsRow } from "./types";
import Table from "./Table";

export const ButtonReset = css`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
`;

export const UnstyledButton = styled.button`
  ${ButtonReset}
  font-family: ${({ theme }) => theme.fonts.regular};
  cursor: pointer;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const TextGradient = css`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

export const GradientBorder = css`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${rgba("white", 0.5)} 50%,
      ${rgba("white", 0)} 100%
    );
  }
`;

export const Ellipsis = styled.div`
  ${ellipsis()}
`;

export const VerticallyCenter = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const StandingsRow = styled.tr<IStandingsRow>`
  ${(props) =>
    props.mine &&
    css`
      td {
        background-color: ${({ theme }) => theme.colors.primary};
        color: white;
        > a {
          color: ${({ theme }) => theme.colors.fantasy};
        }
      }
    `}
`;

// https://css-tricks.com/responsive-data-tables/
export const StackTable = styled(Table)`
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    font-size: ${(props) => props.theme.fontSizes[2]};

    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      ${hideVisually}
    }

    tr {
      background-color: #fafafa;

      :not(:first-child) {
        border-top: ${(props) => props.theme.space[2]} solid #fff;
      }
    }

    td {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      // Behave  like a "row"
      position: relative;
      padding-left: 35%;
      border: 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};

      ::before {
        position: absolute;
        // Top/left values mimic padding
        top: 6px;
        left: 6px;
        width: 30%;
        padding-right: ${(props) => props.theme.space[2]};
        color: #666;
        font-family: ${(props) => props.theme.fonts.bold};
        white-space: nowrap;
        content: attr(data-label);
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    td {
      padding-left: 40%;

      ::before {
        width: 35%;
      }
    }
  }
`;
