interface IStatDetails {
  description: string;
  label: string;
}

export interface IStatsByName
  extends Readonly<{
    [key: string]: Readonly<IStatDetails>;
  }> {}

const statDetails: Record<string, IStatDetails | undefined> = {
  influence: {
    description:
      "Influence evaluates a player’s impact on a match, taking into account actions that could directly or indirectly affect the match outcome. Part of the ICT index.",
    label: "Influence",
  },
  creativity: {
    description:
      "Creativity assesses player performance in terms of producing goalscoring opportunities for other players. Part of the ICT index.",
    label: "Creativity",
  },
  threat: {
    description:
      "Threat gauges players who are most likely to score goals. Part of the ICT index.",
    label: "Threat",
  },
  ict_index: {
    description:
      "Statistical index developed specifically to assess a player as an FPL asset, combining Influence, Creativity and Threat scores.",
    label: "ICT Index",
  },
};

export const getStatDetails = (name: string) => statDetails[name] || null;

export const getStatNames = () => Object.keys(statDetails);

export const getStatDescription = (statName: string) => {
  const stat = getStatDetails(statName);
  return stat ? stat.description : "No description available";
};
