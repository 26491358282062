import React from "react";
import PropTypes from "prop-types";
import ActiveEntrySelect from "../ActiveEntrySelect";
import { propTypeEntry } from "../../reducers/entries";
import styled from "styled-components/macro";

const EntryHeading = styled.h2`
  margin: ${({ theme }) => `0 ${theme.space[2]} ${theme.space[2]}`};
  font-size: ${({ theme }) => theme.fontSizes[3]};

  > span {
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-bottom: ${({ theme }) => theme.space[4]};
    font-size: 2.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const EntryHeader = ({ activeEntry, entries }) => {
  if (entries.length > 1) {
    return <ActiveEntrySelect isInline={true} />;
  }
  return (
    <EntryHeading>
      Current team<span> - {activeEntry.name}</span>
    </EntryHeading>
  );
};

EntryHeader.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entries: PropTypes.arrayOf(propTypeEntry).isRequired,
};

export default EntryHeader;
