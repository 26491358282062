import styled from "styled-components/macro";

export const CountdownTimer = styled.div`
  display: flex;
  padding: 0 10%;
  background-image: linear-gradient(
    to bottom,
    transparent,
    transparent 63%,
    ${(props) => props.theme.colors.lightPurple} 63%
  );
  color: ${(props) => props.theme.colors.primary};
  line-height: 1;
  text-align: center;
`;

export const CountdownTimerUnit = styled.div`
  flex: 0 0 20%;
  padding: 0 ${(props) => props.theme.space[1]};
`;

export const CountdownTimerDivider = styled.div`
  flex: 0 0 5%;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 3.6rem;
`;

export const CountdownTimerValue = styled.div`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 3.6rem;
`;

export const CountdownTimerLabel = styled.div`
  padding-top: ${(props) => props.theme.space[1]};
  padding-bottom: ${(props) => props.theme.space[1]};
  color: #fff;
`;
