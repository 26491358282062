import styled from "styled-components/macro";
import Table from "../../Table";

export const TransactionNumberCell = styled.td`
  width: 25%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 13%;
  }
`;

export const TransactionElementsCell = styled.td``;

export const ProposedTransferAction = styled.td`
  width: 25%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 22%;
  }
`;

export const ProposedTransfersTable = styled(Table)`
  thead th {
    text-align: center;
  }

  ${TransactionElementsCell} {
    padding: 0;
  }
`;
