import { Link } from "@gatsbyjs/reach-router";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { propTypeEntry, propTypeHubStatus } from "../../../reducers/entries";
import { propTypeEvent } from "../../../reducers/events";
import { propTypeResult } from "../../../reducers/leagueMatches";
import { propTypeLeagueStanding } from "../../../reducers/leagueStandings";
import { propTypeLeague } from "../../../reducers/leagues";
import getOrdinal from "../../../utils/getOrdinal";
import Result from "../../Result";
import TabHeadingLight from "../../TabHeadingLight";
import Movement from "../../league/Movement";
import { TextGradient } from "../../styles";
import {
  EntrySummary,
  EntrySummaryBody,
  EntrySummaryExtra,
  EntrySummaryHeading,
  EntrySummaryItem,
  EntrySummaryItemInner,
  EntrySummaryMovement,
  EntrySummaryStat,
  EntrySummaryWrap,
} from "./styles";

const EntrySummaryLink = styled(Link)`
  ${TextGradient}
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const EntryEventSummary = ({
  activeEntry,
  activeLeague,
  activeStanding,
  currentEvent,
  resultThis,
  status,
}) => (
  <Box mb={2}>
    <EntrySummaryWrap>
      <TabHeadingLight>{currentEvent.name}</TabHeadingLight>
      <EntrySummary>
        <EntrySummaryItem>
          <EntrySummaryItemInner>
            <EntrySummaryHeading>
              {currentEvent.name} Points
            </EntrySummaryHeading>
            <EntrySummaryBody>
              <EntrySummaryStat>
                <EntrySummaryLink
                  to={`/entry/${activeEntry.id}/event/${currentEvent.id}`}
                >
                  {activeEntry.event_points}
                </EntrySummaryLink>
              </EntrySummaryStat>
              {status !== "points" && resultThis && (
                <EntrySummaryExtra>
                  <Result resultChar={resultThis} />
                </EntrySummaryExtra>
              )}
            </EntrySummaryBody>
          </EntrySummaryItemInner>
        </EntrySummaryItem>
        <EntrySummaryItem>
          <EntrySummaryItemInner>
            <EntrySummaryHeading>{activeLeague.name}</EntrySummaryHeading>
            <EntrySummaryBody>
              {!activeStanding ? (
                "-"
              ) : (
                <>
                  <EntrySummaryStat>
                    <EntrySummaryLink to="/league">
                      {activeStanding.rank
                        ? `${getOrdinal(activeStanding.rank)}`
                        : "-"}
                    </EntrySummaryLink>
                  </EntrySummaryStat>
                  <EntrySummaryExtra>
                    <Movement
                      lastRank={activeStanding.last_rank}
                      rank={activeStanding.rank}
                    />
                    {activeStanding.last_rank && (
                      <EntrySummaryMovement>
                        {activeStanding.last_rank - activeStanding.rank || ""}
                      </EntrySummaryMovement>
                    )}
                  </EntrySummaryExtra>
                </>
              )}
            </EntrySummaryBody>
          </EntrySummaryItemInner>
        </EntrySummaryItem>
      </EntrySummary>
    </EntrySummaryWrap>
  </Box>
);

EntryEventSummary.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  activeLeague: propTypeLeague.isRequired,
  activeStanding: propTypeLeagueStanding.isRequired,
  currentEvent: propTypeEvent,
  resultThis: propTypeResult,
  status: propTypeHubStatus.isRequired,
};

EntryEventSummary.defaultProps = {
  currentEvent: null,
  resultThis: null,
  standings: null,
};

export default EntryEventSummary;
