import styled from "styled-components/macro";
import Table from "../Table";

export const PrintableElements = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
  }
`;

export const PrintableElementsUnit = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 50%;
  }
`;

export const PrintableElementList = styled(Table)`
  table-layout: fixed;
`;

export const Name = styled.th`
  width: 30%;
`;
export const Team = styled.th`
  width: 30%;
`;
export const Rank = styled.th`
  width: 20%;
`;
export const Points = styled.th`
  width: 20%;
`;
