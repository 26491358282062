import { rgba } from "polished";
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";

const Item = styled.li`
  flex: 1;
`;

export const TabLink = styled.a`
  display: block;
  margin: 0.2rem;
  padding: 0.7rem ${({ theme }) => theme.space[2]};
  border-radius: 2px;
  background-color: transparent;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;

  ${(props) =>
    props.selected &&
    css`
      background-color: white;
      box-shadow:
        0 3px 4px 0 ${rgba("black", 0.08)},
        0 3px 3px -2px ${rgba("black", 0.08)},
        0 1px 8px 0 ${rgba("black", 0.16)};
      border-radius: ${({ theme }) => theme.radii[0]};
    `}
`;

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
  }

  _onClick(e) {
    e.preventDefault();
    this.props.onClick(this.props.index);
  }

  render() {
    const { label, link, selected } = this.props;
    return (
      <Item>
        <TabLink href={`#${link}`} onClick={this._onClick} selected={selected}>
          {label}
        </TabLink>
      </Item>
    );
  }
}
Tab.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Tab;
