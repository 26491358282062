import styled from "styled-components/macro";
import { StyledPitchElement } from "../../PitchElement/styles";

export const StyledTopElement = styled(StyledPitchElement)`
  width: 9rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 11rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    width: 12rem;
  }
`;
