import styled from "styled-components/macro";
import Table from "../../Table";

export const MiniStandingsRank = styled.th`
  width: 15%;
`;

export const MiniStandingsTeam = styled.th`
  width: 55%;
`;

export const MiniStandingsEvent = styled.td`
  width: 15%;
`;

export const MiniStandingsTotal = styled.td`
  width: 15%;
`;

export const MiniStandingsTable = styled(Table)`
  table-layout: fixed;

  ${MiniStandingsTotal}, ${MiniStandingsEvent} {
    text-align: center;
  }
`;
