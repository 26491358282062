import { navigate } from "@gatsbyjs/reach-router";
import qs from "qs";
import {
  ADD_ELEMENTS,
  ADD_ELEMENT_STATS,
  ADD_ELEMENT_TYPES,
  ADD_ENTRIES,
  ADD_EVENTS,
  ADD_GAME,
  ADD_LEAGUES,
  ADD_PLAYER,
  ADD_SETTINGS,
  ADD_TEAMS,
} from "../../actions/ActionTypes";
import { updateActiveEntry } from "../../actions/Entry";
import { fetchGet, handleUnexpectedError } from "../../helpers";
import { getGame } from "../../reducers/game";
import { getDefaultURL } from "../../reducers/player";
import { RootState } from "../../reducers/rootReducer";
import { IElement, IElementStat, IElementType } from "../elements/types";
import { IEntry } from "../entries/types";
import { IEvent } from "../events/types";
import { IGame } from "../game/types";
import { AppDispatch } from "../types";
import { IAddLeague } from "../leagues/types";
import { IPlayer } from "../player/types";
import { ISettings } from "../settings/types";
import { ITeam } from "../teams/types";
import {
  bootstrapError,
  bootstrapStart,
  bootstrapSuccess,
  boostrapUpdated,
} from "./slice";
import { IBootstrapDynamicData, IBootstrapStaticData } from "./types";
import { getBootstrapLastUpdated } from "./selectors";
import { addFixtures } from "../fixtures/slice";
// Regular action creators
export const addElementStats = (data: IElementStat[]) => ({
  type: ADD_ELEMENT_STATS,
  data,
});

export const addElementTypes = (data: IElementType[]) => ({
  type: ADD_ELEMENT_TYPES,
  data,
});

export const addElements = (data: IElement[]) => ({ type: ADD_ELEMENTS, data });

export const addEntries = (data: IEntry[]) => ({ type: ADD_ENTRIES, data });

export const addEvents = (data: IEvent[]) => ({ type: ADD_EVENTS, data });

export const addGame = (data: IGame) => ({ type: ADD_GAME, data });

export const addLeagues = (data: IAddLeague[]) => ({ type: ADD_LEAGUES, data });

export const addPlayer = (data: IPlayer | null) => ({ type: ADD_PLAYER, data });

export const addSettings = (data: ISettings) => ({ type: ADD_SETTINGS, data });

export const addTeams = (data: ITeam[]) => ({ type: ADD_TEAMS, data });

export const bootstrapStaticData = (
  dispatch: AppDispatch,
  {
    elements,
    events,
    fixtures,
    settings,
    teams,
    element_stats,
    element_types,
  }: IBootstrapStaticData,
) => {
  dispatch(addElementStats(element_stats));
  dispatch(addElementTypes(element_types));
  dispatch(addElements(elements));
  dispatch(addEvents(events));
  dispatch(addFixtures(fixtures));
  dispatch(addSettings(settings));
  dispatch(addTeams(teams));
};

export const bootstrapDynamicData = (
  dispatch: AppDispatch,
  { entries, leagues, player }: IBootstrapDynamicData,
) => {
  dispatch(addEntries(entries));
  dispatch(addLeagues(leagues));
  dispatch(addPlayer(player));
};

export const bootstrapStaticIfNeeded = async (
  dispatch: AppDispatch,
  getState: () => RootState,
) => {
  const maxAge = 60 * 15;
  const lastBootstrap = getBootstrapLastUpdated(getState().bootstrap);
  const age = lastBootstrap
    ? Math.round(new Date().getTime() / 1000) - lastBootstrap
    : maxAge + 1;
  if (age > maxAge) {
    try {
      const { data } = await fetchGet("/api/bootstrap-static");
      bootstrapStaticData(dispatch, data);
      dispatch(boostrapUpdated());
    } catch (error) {
      // TODO: catch the error
      console.error("bootstrapStaticIfNeeded", error);
    }
  }
};

export const checkGame =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const game = getGame(getState());
    try {
      const { data } = await fetchGet("/api/game");
      // Reload if rolled over, event finished or waivers processed
      if (
        game.current_event !== data.current_event ||
        game.current_event_finished !== data.current_event_finished ||
        game.waivers_processed !== data.waivers_processed
      ) {
        window.location.reload();
      } else {
        dispatch(addGame(data));
        bootstrapStaticIfNeeded(dispatch, getState);
      }
    } catch (error) {
      // TODO: catch the error
      console.error("checkGame", error);
    }
  };

export const bootstrap =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(bootstrapStart());

    Promise.all([
      fetchGet("/api/bootstrap-static"),
      fetchGet("/api/bootstrap-dynamic"),
      fetchGet("/api/game"),
    ]).then(
      ([staticData, dynamicData, gameData]) => {
        bootstrapStaticData(dispatch, staticData.data);
        bootstrapDynamicData(dispatch, dynamicData.data);

        dispatch(addGame(gameData.data));
        updateActiveEntry(dispatch, dynamicData.data.active).then(() => {
          dispatch(bootstrapSuccess(dynamicData.data));
          // This is pl specific (handling sso login, should be better ...)
          const query = qs.parse(document.location.search);
          if (query.state && query.state === "success") {
            navigate(getDefaultURL(getState()), {
              replace: true,
            });
          }
        });
      },
      (error) => {
        dispatch(bootstrapError());
        handleUnexpectedError(error, dispatch);
      },
    );
  };
