import PropTypes from "prop-types";
import { Deadline, DeadlineHeading, StyledDeadlineHeader } from "./styles";

const DeadlineHeader = ({
  deadlineISO,
  deadlineLocal,
  deadlineText,
  headingText,
}) => (
  <StyledDeadlineHeader>
    <DeadlineHeading>{headingText}</DeadlineHeading>
    <p>
      {`${deadlineText} `}
      <strong>
        <Deadline dateTime={deadlineISO}>{deadlineLocal}</Deadline>
      </strong>
    </p>
  </StyledDeadlineHeader>
);

DeadlineHeader.propTypes = {
  deadlineISO: PropTypes.string.isRequired,
  deadlineLocal: PropTypes.string.isRequired,
  deadlineText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
};

export default DeadlineHeader;
