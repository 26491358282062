const baseUrl = "https://footballapi.pulselive.com";

export interface IPulseResponse {
  pageInfo: any;
  content: Array<any>;
}

async function _fetch(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

// Broadcasters
export async function fetchBroadcastersForFixtures(
  startDate: string,
  endDate: string,
): Promise<IPulseResponse> {
  const url = [
    `${baseUrl}/football/broadcasting-schedule/fixtures`,
    `?comps=1&pageSize=100&page=0&altIds=true`,
    `&startDate=${startDate}&endDate=${endDate}`,
    // `&countryCodes=ES`
  ];
  return _fetch(url.join(""));
}
