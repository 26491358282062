import PropTypes from "prop-types";
import { propTypeTrade } from "../../reducers/transactions";
import {
  HighlightIcon,
  PrimaryIcon,
  SquadButton,
  SquadButtonText,
} from "../squad/styles";
import { ResponseButton } from "./styles";

const TradeResponseButton = ({ actionMe, trade, isNo, label }) => (
  <ResponseButton>
    {isNo ? (
      <SquadButton
        type="button"
        variant="highlight"
        data-id={trade.id}
        onClick={actionMe}
      >
        <SquadButtonText>{label}</SquadButtonText>
        <HighlightIcon width="25" height="28" viewBox="0 0 25 28">
          <use xlinkHref="#ism-thumbs-down" />
        </HighlightIcon>
      </SquadButton>
    ) : (
      <SquadButton
        type="button"
        variant="primary"
        data-id={trade.id}
        onClick={actionMe}
      >
        <SquadButtonText>{label}</SquadButtonText>
        <PrimaryIcon width="25" height="28" viewBox="0 0 25 28">
          <use xlinkHref="#ism-thumbs-up" />
        </PrimaryIcon>
      </SquadButton>
    )}
  </ResponseButton>
);

TradeResponseButton.propTypes = {
  actionMe: PropTypes.func.isRequired,
  trade: propTypeTrade.isRequired,
  isNo: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

TradeResponseButton.defaultProps = {
  isNo: false,
};

export default TradeResponseButton;
