import styled from "styled-components/macro";

export const StatusCell = styled.td`
  width: 5%;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 4%;
  }
`;

export const PrimaryCell = styled.td`
  width: 24%;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 1rem;
    display: block;
    width: 2px;
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 15%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 26%;
  }
`;

export const SortCell = styled.td`
  width: 16%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 12%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 9%;
  }
`;

export const FixtureCell = styled.td`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: table-cell;
    width: 10%;
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 8%;
  }
`;

export const ActionCell = styled.td`
  width: 16%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 13%;
  }
`;
