import PropTypes from "prop-types";
import { propTypeElementExplainFixture } from "../../reducers/elements";
import { propTypeTeam } from "../../reducers/teams";
import { ExplainHeading, ExplainScore } from "./styles";
import { Box } from "rebass/styled-components";
import Table from "../Table";
import { VisuallyHidden } from "../styles";

const ElementExplainStat = ({ stat }) => (
  <tr>
    <td>{stat.name}</td>
    <td>{stat.value}</td>
    <td>{stat.points}</td>
  </tr>
);

ElementExplainStat.propTypes = {
  stat: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    points: PropTypes.number,
  }).isRequired,
};

const ElementExplain = ({ explainData, teamsById }) => (
  <div>
    {explainData.map((fixtureData) => (
      <div key={fixtureData.fixture.id}>
        <ExplainHeading>
          {`${teamsById[fixtureData.fixture.team_h].name} `}
          <ExplainScore>
            {fixtureData.fixture.team_h_score} -{" "}
            {fixtureData.fixture.team_a_score}
          </ExplainScore>
          {` ${teamsById[fixtureData.fixture.team_a].name}`}
        </ExplainHeading>
        <Box mb={2}>
          <Table>
            <thead>
              <tr>
                <th>Statistic</th>
                <th>
                  <VisuallyHidden>Value</VisuallyHidden>
                </th>
                <th>Pts</th>
              </tr>
            </thead>
            <tbody>
              {fixtureData.explain.map((stat) => (
                <ElementExplainStat stat={stat} key={stat.name} />
              ))}
            </tbody>
          </Table>
        </Box>
      </div>
    ))}
  </div>
);

ElementExplain.propTypes = {
  explainData: PropTypes.arrayOf(propTypeElementExplainFixture).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

export default ElementExplain;
