import styled from "styled-components/macro";
import { ReactComponent as BaseNextIcon } from "../../img/icons/next.svg";
import { ReactComponent as BasePrevIcon } from "../../img/icons/previous.svg";

export const PrevIcon = styled(BasePrevIcon)`
  margin-right: 0.6rem;
  fill: currentColor;
  vertical-align: middle;
`;

export const NextIcon = styled(BaseNextIcon)`
  margin-left: 0.6rem;
  fill: currentColor;
  vertical-align: middle;
`;
