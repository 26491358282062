import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Flex, Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import { getElementStats, propTypeElementStat } from "../reducers/elementStats";
import { getElementTypes, propTypeElementType } from "../reducers/elementTypes";
import { getCurrentEvent } from "../reducers/events";
import { getTeams, propTypeTeam } from "../reducers/teams";
import { SearchField, SelectField } from "./FieldRenderers";
import styled from "styled-components/macro";

const PrimaryFilter = styled.div`
  flex: 1 0 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 50%;
  }
`;

const SecondaryFilter = styled.div`
  flex: 1 0 50%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 25%;
  }
`;

const ElementListControl = ({ disabledTypes, stats, teams, types }) => (
  <div>
    <h3 className="visuallyhidden">Filters</h3>
    <form onSubmit={(e) => e.preventDefault()}>
      <Flex flexWrap="wrap" mb={2} pt={4}>
        <PrimaryFilter>
          <Box px={2} mb={4}>
            <Field
              id="search"
              name="search"
              component={SearchField}
              maxLength="20"
              placeholder="Search players"
              label="Search"
              weight="light"
            />
          </Box>
        </PrimaryFilter>
        <SecondaryFilter>
          <Box px={2} mb={4}>
            <Field
              id="filter"
              name="filter"
              component={SelectField}
              label="View"
              props={{
                weight: "light",
                theme: "primary",
              }}
            >
              <option value="">All players</option>
              {types.map((et) => (
                <option
                  disabled={disabledTypes.indexOf(et.id) > -1}
                  key={et.singular_name_short}
                  value={`et_${et.id}`}
                >
                  {et.plural_name}
                </option>
              ))}
              {teams.map((t) => (
                <option value={`te_${t.id}`} key={t.id}>
                  {t.name}
                </option>
              ))}
            </Field>
          </Box>
        </SecondaryFilter>
        <SecondaryFilter>
          <Box px={2} mb={4}>
            <Field
              id="sort"
              name="sort"
              component={SelectField}
              label="Sort"
              props={{
                weight: "light",
                theme: "primary",
              }}
            >
              {stats.map((s) => (
                <option value={s.name} key={s.name}>
                  {s.label}
                </option>
              ))}
            </Field>
          </Box>
        </SecondaryFilter>
      </Flex>
    </form>
  </div>
);

ElementListControl.defaultProps = {
  disabledTypes: [],
};

ElementListControl.propTypes = {
  disabledTypes: PropTypes.arrayOf(PropTypes.number),
  stats: PropTypes.arrayOf(propTypeElementStat).isRequired,
  teams: PropTypes.arrayOf(propTypeTeam).isRequired,
  types: PropTypes.arrayOf(propTypeElementType).isRequired,
};

export { ElementListControl as ElementListControlTest };

const mapStateToProps = (state) => {
  const now = getCurrentEvent(state);
  return {
    initialValues: {
      filter: "",
      sort: now ? "total_points" : "draft_rank",
    },
    stats: getElementStats(state),
    teams: getTeams(state),
    types: getElementTypes(state),
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "elementListControl",
  })(ElementListControl),
);
