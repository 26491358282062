import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeDraftStatus } from "../../../reducers/draftChoices";
import { propTypeElement } from "../../../reducers/elements";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { propTypeLeague } from "../../../reducers/leagues";
import { MoreLink } from "../../Link";
import LastChoiceElement from "../LastChoiceElement";
import {
  ActionBlockHeading,
  ActionBlockMessage,
  StyledPostDraftStatus,
} from "./styles";

const PostDraftStatus = ({ elementsById, entriesById, league, status }) => (
  <Box mb={4}>
    <StyledPostDraftStatus>
      <ActionBlockHeading>{league.name}</ActionBlockHeading>
      <ActionBlockMessage>Draft has ended!</ActionBlockMessage>
      {league.scoring === "m" ? (
        <MoreLink to="entry/join-create">Create or Join a League</MoreLink>
      ) : (
        <MoreLink to="/team/my">Manage My Team</MoreLink>
      )}
    </StyledPostDraftStatus>
    <LastChoiceElement
      elementsById={elementsById}
      entriesById={entriesById}
      lastChoice={status.lastChoice}
    />
  </Box>
);

PostDraftStatus.propTypes = {
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  league: propTypeLeague.isRequired,
  status: propTypeDraftStatus.isRequired,
};

export default PostDraftStatus;
