import React from "react";
import { Box } from "rebass/styled-components";
import ButtonLink from "./ButtonLink";
import Copy from "./Copy";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import SubHeading from "./SubHeading";
import TextDivider from "./TextDivider";
import Title from "./Title";

const JoinOrCreateLeague: React.FC = () => (
  <Wrapper>
    <HelmetHead
      title="Create or Join - Fantasy Premier League Draft 2024/25"
      description="Create or join a league in Fantasy Premier League Draft, visit the official website of the Premier League."
    />
    <Main>
      <Box mx={2}>
        <Title>Create or Join a League</Title>
      </Box>
      <Box mx={2}>
        <SubHeading>Play Against Your Friends</SubHeading>
      </Box>
      <Copy>
        <p>
          Create a new private league and invite your friends, or join others in
          an existing private league.
        </p>
        <Box mx="20%">
          <ButtonLink to="/entry/create" fullwidth="true">
            Create a Private League
          </ButtonLink>
        </Box>
        <TextDivider>Or</TextDivider>
        <Box mx="20%">
          <ButtonLink to="/entry/join-private" fullwidth="true">
            Join a Private League
          </ButtonLink>
        </Box>
      </Copy>
      <Copy>
        <SubHeading>Play in a Public League</SubHeading>
        <p>
          Join a public league and face-off against other members of the public.
        </p>
        <Box mx="20%">
          <ButtonLink to="/entry/join-public" fullwidth="true">
            Join a Public League
          </ButtonLink>
        </Box>
      </Copy>
      <Copy>
        <SubHeading>New to FPL Draft?</SubHeading>
        <p>Why not take part in a mock draft and learn how the draft works?</p>
        <Box mx="20%">
          <ButtonLink to="/entry/join-mock" fullwidth="true">
            Join a Mock Draft
          </ButtonLink>
        </Box>
      </Copy>
    </Main>
  </Wrapper>
);

export default JoinOrCreateLeague;
