import { size } from "polished";
import styled, { css } from "styled-components/macro";
import tick from "../../../img/icons/tick.svg";
import Table from "../../Table";
import { IFixtureCell } from "./types";
import { ReduxFormCheckbox } from "../../FieldRenderers";

interface ITradeRow {
  isDisabled: boolean;
}

export const TradeLabel = styled.label`
  display: block;
  cursor: pointer;
`;

export const TradeRow = styled.tr<ITradeRow>`
  ${(props) =>
    props.isDisabled &&
    css`
      td {
        opacity: 0.4;
      }

      ${TradeLabel} {
        cursor: default;
      }

      ${ReduxFormCheckbox}:not(:checked):disabled
        + ${ReduxFormCheckbox}::before {
        border-color: #e4e4e4;
        background-image: none;
      }
    `}
`;

export const InfoCell = styled.td`
  width: 15%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 12%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 10%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 8%;
  }
`;

export const NameCell = styled.td`
  width: 49%;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 1rem;
    display: block;
    width: 2px;
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 43%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 36%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 31%;
  }
`;

export const StatCell = styled.td`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: table-cell;
    width: 15%;
    font-family: ${(props) => props.theme.fonts.bold};
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 18%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 11%;
  }
`;

export const FixtureCell = styled.td<IFixtureCell>`
  display: none;

  ${({ eventNumber, theme }) =>
    eventNumber === 1 &&
    css`
      @media (min-width: ${theme.breakpoints[3]}) {
        display: table-cell;
        width: 17%;
      }
    `}

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: table-cell;
    width: 13%;
  }
`;

export const TradeCheckbox = styled.div`
  ${size("2rem")}
  display: inline-block;
  position: relative;
  padding: 0;
  line-height: 1.6;
  vertical-align: middle;

  ::before {
    ${size("1.6rem")}
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-right: ${(props) => props.theme.space[2]};
    transform: translateY(-50%);
    border: 2px solid ${(props) => props.theme.colors.lightGrey};
    border-radius: ${(props) => props.theme.radii[0]};
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    vertical-align: middle;
  }
`;

export const CheckboxCell = styled.td`
  width: 24%;

  ${ReduxFormCheckbox} {
    :hover + ${TradeCheckbox}::before {
      border-color: ${(props) => props.theme.colors.darkGrey};
      background-image: url(${tick});
    }

    :focus + ${TradeCheckbox} {
      outline: -webkit-focus-ring-color auto 5px;
    }

    :checked + ${TradeCheckbox}::before {
      background-color: ${(props) => props.theme.colors.success};
      background-image: url(${tick});
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 21%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 12%;
  }
`;

export const WarningCell = styled.td`
  width: 12%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 6%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 4%;
  }
`;

export const StyledTradeSquadTable = styled(Table)`
  table-layout: fixed;

  th,
  td {
    :not(:nth-child(2)) {
      text-align: center;
    }
  }

  ${InfoCell} {
    padding: 0;
  }

  ${NameCell} {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
`;

export const TradeElement = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: flex;
    align-items: center;
  }
`;

export const TradeElementShirt = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: block;
    margin-right: ${(props) => props.theme.space[2]};
  }
`;

export const TradeElementText = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex: 1;
  }
`;
