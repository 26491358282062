import PropTypes from "prop-types";
import getOrdinal from "../../utils/getOrdinal";
import { PickBox, PickBoxAside, PickBoxHeading, PickBoxMain } from "./styles";

const DraftMyPick = ({
  isNextRound,
  isNoNextPick,
  pickNumber,
  picksUntilMine,
}) => (
  <PickBox>
    <PickBoxHeading>My next pick:</PickBoxHeading>

    {isNoNextPick ? (
      <>
        <PickBoxMain>Finished</PickBoxMain>
        <PickBoxAside>(No more picks)</PickBoxAside>
      </>
    ) : (
      <>
        {picksUntilMine === 1 ? (
          <PickBoxMain isNext={true}>Next</PickBoxMain>
        ) : (
          <PickBoxMain>
            in <strong> {picksUntilMine} </strong> turns
          </PickBoxMain>
        )}
        <PickBoxAside>
          ({getOrdinal(pickNumber)} {isNextRound ? "next" : "this"} round)
        </PickBoxAside>
      </>
    )}
  </PickBox>
);

DraftMyPick.propTypes = {
  isNoNextPick: PropTypes.bool,
  isNextRound: PropTypes.bool,
  pickNumber: PropTypes.number,
  picksUntilMine: PropTypes.number,
};

DraftMyPick.defaultProps = {
  isNoNextPick: false,
  isNextRound: false,
  pickNumber: null,
  picksUntilMine: null,
};

export default DraftMyPick;
