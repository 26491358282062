import { ellipsis } from "polished";
import styled from "styled-components";

export const ElementName = styled.div`
  ${ellipsis()}
  font-family: ${(props) => props.theme.fonts.bold};
`;

export const ElementInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const ElementPosition = styled.span`
  margin-left: ${(props) => props.theme.space[2]};
`;
