import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActiveEntry, propTypeEntry } from "../../../reducers/entries";
import { getCurrentEvent } from "../../../reducers/events";
import {
  getActiveLeagueEntriesById,
  propTypeLeagueEntry,
} from "../../../reducers/leagueEntries";
import {
  getActiveEntryMatchNextEvent,
  getActiveEntryMatchThisEvent,
  getActiveLeagueResultsThisEvent,
  propTypeLeagueMatch,
  propTypeResult,
} from "../../../reducers/leagueMatches";
import {
  getActiveLeagueStandings,
  propTypeLeagueStanding,
} from "../../../reducers/leagueStandings";
import { getActiveLeague, propTypeLeague } from "../../../reducers/leagues";
import { MatchesTable } from "../../MatchesTable";
import H2HMatch from "../../league/H2HMatch";
import MiniStandings from "../MiniStandings";
import { MatchHeading, StyledLeagueSummaryMatch } from "./styles";

const LeagueSummaryMatch = ({
  activeEntry,
  entry1,
  entry2,
  heading,
  linkEventId,
  match,
}) => (
  <StyledLeagueSummaryMatch>
    <MatchHeading>{heading}</MatchHeading>
    <MatchesTable>
      <tbody>
        <H2HMatch
          activeEntry={activeEntry}
          entry1={entry1}
          entry2={entry2}
          linkEventId={linkEventId}
          match={match}
          noHighlight
        />
      </tbody>
    </MatchesTable>
  </StyledLeagueSummaryMatch>
);

LeagueSummaryMatch.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entry1: propTypeLeagueEntry.isRequired,
  entry2: propTypeLeagueEntry.isRequired,
  heading: PropTypes.string.isRequired,
  linkEventId: PropTypes.number.isRequired,
  match: propTypeLeagueMatch.isRequired,
};

const LeagueSummary = ({
  activeEntry,
  activeEntryMatchNextEvent,
  activeEntryMatchThisEvent,
  entriesById,
  linkEventId,
  league,
  results,
  standings,
}) => (
  <div>
    <MiniStandings
      activeEntry={activeEntry}
      entriesById={entriesById}
      linkEventId={linkEventId}
      league={league}
      results={results}
      standings={standings}
    />
    {league.scoring === "h" && (
      <div>
        {activeEntryMatchThisEvent && (
          <LeagueSummaryMatch
            activeEntry={activeEntry}
            entry1={entriesById[activeEntryMatchThisEvent.league_entry_1]}
            entry2={entriesById[activeEntryMatchThisEvent.league_entry_2]}
            heading="This Gameweek's Fixture"
            linkEventId={linkEventId}
            match={activeEntryMatchThisEvent}
          />
        )}

        {activeEntryMatchNextEvent && (
          <LeagueSummaryMatch
            activeEntry={activeEntry}
            entry1={entriesById[activeEntryMatchNextEvent.league_entry_1]}
            entry2={entriesById[activeEntryMatchNextEvent.league_entry_2]}
            heading="Next Gameweek's Fixture"
            linkEventId={linkEventId}
            match={activeEntryMatchNextEvent}
          />
        )}
      </div>
    )}
  </div>
);

LeagueSummary.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  activeEntryMatchNextEvent: propTypeLeagueMatch,
  activeEntryMatchThisEvent: propTypeLeagueMatch,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  league: propTypeLeague.isRequired,
  linkEventId: PropTypes.number.isRequired,
  results: PropTypes.objectOf(propTypeResult).isRequired,
  standings: PropTypes.arrayOf(propTypeLeagueStanding),
};

LeagueSummary.defaultProps = {
  activeEntryMatchNextEvent: null,
  activeEntryMatchThisEvent: null,
  standings: [],
};

const mapStateToProps = (state) => {
  const now = getCurrentEvent(state);
  const league = getActiveLeague(state);
  let linkEventId = 0;
  if (now && now.id >= league.start_event) {
    linkEventId = now.id;
  }
  return {
    activeEntry: getActiveEntry(state),
    activeEntryMatchNextEvent: getActiveEntryMatchNextEvent(state),
    activeEntryMatchThisEvent: getActiveEntryMatchThisEvent(state),
    entriesById: getActiveLeagueEntriesById(state),
    league,
    linkEventId,
    results: getActiveLeagueResultsThisEvent(state),
    standings: getActiveLeagueStandings(state),
  };
};

export default connect(mapStateToProps)(LeagueSummary);
