import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeElementType } from "../../../reducers/elementTypes";
import { propTypeElement } from "../../../reducers/elements";
import { propTypeTeam } from "../../../reducers/teams";
import {
  propTypeTradeStat,
  propTypeTradeWarningElement,
} from "../../../reducers/transactions";
import { propTypeEventTeamFixture } from "../../../store/fixtures/slice";
import { getShortNameFromId } from "../../../utils/events";
import { fixtureSuffix } from "../../../utils/fixtures";
import ElementShirt from "../../ElementShirt";
import ElementStatusButton from "../../ElementStatusButton";
import { TeamShortName } from "../../ElementTable";
import { ReduxFormCheckbox } from "../../FieldRenderers";
import TabHeading from "../../TabHeading";
import { VisuallyHidden } from "../../styles";
import ElementTradeWarning from "../ElementTradeWarning";
import {
  CheckboxCell,
  FixtureCell,
  InfoCell,
  NameCell,
  StatCell,
  StyledTradeSquadTable,
  TradeCheckbox,
  TradeElement,
  TradeElementShirt,
  TradeElementText,
  TradeLabel,
  TradeRow,
  WarningCell,
} from "./styles";

const ElementTradeWarningCell = () => (
  <WarningCell>
    <ElementTradeWarning />
  </WarningCell>
);

const ElementTradeCheckCell = ({ disabled, element }) => {
  return (
    <CheckboxCell>
      <TradeLabel htmlFor={`ism-trade-${element.id}`}>
        <ReduxFormCheckbox
          component="input"
          disabled={disabled}
          id={`ism-trade-${element.id}`}
          name={`ism-trade-${element.id}`}
          type="checkbox"
        />
        <TradeCheckbox>
          <span className="visuallyhidden">Trade</span>
        </TradeCheckbox>
      </TradeLabel>
    </CheckboxCell>
  );
};

ElementTradeCheckCell.propTypes = {
  disabled: PropTypes.bool.isRequired,
  element: propTypeElement.isRequired,
};

const ElementTradeMode = ({ element, tradeWarningElements, ...rest }) => {
  const tradeWarningElement = tradeWarningElements[element.id];
  if (tradeWarningElement) {
    const transactions = tradeWarningElement.transactions;
    if (transactions.indexOf("accepted-trade") > -1) {
      return (
        <>
          <CheckboxCell>Accepted</CheckboxCell>
          <ElementTradeWarningCell />
        </>
      );
    } else if (
      transactions.indexOf("proposed-trade") > -1 ||
      transactions.indexOf("proposed-waiver") > -1
    ) {
      return (
        <>
          <ElementTradeCheckCell element={element} {...rest} />
          <ElementTradeWarningCell />
        </>
      );
    }
  }
  return (
    <>
      <ElementTradeCheckCell element={element} {...rest} />
      <td>&nbsp;</td>
    </>
  );
};

ElementTradeMode.propTypes = {
  element: propTypeElement.isRequired,
  tradeWarningElements: PropTypes.objectOf(propTypeTradeWarningElement)
    .isRequired,
};

const TradeSquadElement = ({
  disabled,
  element,
  elementDialogShow,
  elementType,
  next3Fixtures,
  teamsById,
  tradeStat,
  ...rest
}) => {
  const elementTeam = teamsById[element.team];

  return (
    <TradeRow isDisabled={disabled}>
      <InfoCell>
        <ElementStatusButton elementId={element.id} />
      </InfoCell>
      <NameCell>
        <TradeLabel htmlFor={`ism-trade-${element.id}`}>
          <TradeElement>
            <TradeElementShirt>
              <ElementShirt elementId={element.id} sizes="16px" />
            </TradeElementShirt>
            <TradeElementText>
              <div className="ism-table--el__name">{element.web_name}</div>
              <TeamShortName>{elementTeam.short_name}</TeamShortName>
              <span className="ism-table--el__pos">
                {elementType.singular_name_short}
              </span>
            </TradeElementText>
          </TradeElement>
        </TradeLabel>
      </NameCell>
      <StatCell>{element[tradeStat.name]}</StatCell>
      {next3Fixtures.map((fixture) => (
        <FixtureCell key={fixture.event.id} eventNumber={fixture.event.id}>
          <ul>
            {fixture.fixtures[element.team].map((f) => (
              <li key={f.id}>
                <TeamShortName>
                  {teamsById[f.opponent].short_name}
                </TeamShortName>
                {fixtureSuffix(f.id, f.isHome)}
              </li>
            ))}
          </ul>
        </FixtureCell>
      ))}
      {/* eslint-enable max-len */}
      <ElementTradeMode disabled={disabled} element={element} {...rest} />
    </TradeRow>
  );
};

TradeSquadElement.propTypes = {
  disabled: PropTypes.bool.isRequired,
  element: propTypeElement.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementType: propTypeElementType.isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  tradeStat: propTypeTradeStat.isRequired,
};

export { TradeSquadElement as TradeSquadElementTest };

const TradeSquadTable = ({
  disabledElements,
  elementDialogShow,
  elements,
  elementType,
  next3Fixtures,
  tradeStat,
  ...rest
}) => (
  <>
    <Box mx={2}>
      <TabHeading title={elementType.plural_name} hasBorder={true} />
      <StyledTradeSquadTable>
        <thead>
          <tr>
            <InfoCell as="th">
              <VisuallyHidden>Info</VisuallyHidden>
            </InfoCell>
            <NameCell as="th">
              <VisuallyHidden>Player</VisuallyHidden>
            </NameCell>
            <StatCell as="th">{tradeStat.abbreviation}</StatCell>
            {next3Fixtures.map((fixture) => (
              <FixtureCell
                as="th"
                key={fixture.event.id}
                eventNumber={fixture.event.id}
              >
                {getShortNameFromId(fixture.event.id)}
              </FixtureCell>
            ))}
            <CheckboxCell as="th">Trade</CheckboxCell>
            <WarningCell as="th">
              <VisuallyHidden>Warning</VisuallyHidden>
            </WarningCell>
          </tr>
        </thead>
        <tbody>
          {elements.map((element) => (
            <TradeSquadElement
              disabled={Boolean(disabledElements[element.id])}
              element={element}
              elementDialogShow={elementDialogShow}
              elementType={elementType}
              key={element.id}
              next3Fixtures={next3Fixtures}
              tradeStat={tradeStat}
              {...rest}
            />
          ))}
        </tbody>
      </StyledTradeSquadTable>
    </Box>
  </>
);

TradeSquadTable.propTypes = {
  disabledElements: PropTypes.objectOf(PropTypes.bool).isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementType: propTypeElementType.isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  tradeStat: propTypeTradeStat.isRequired,
};

export default TradeSquadTable;
