import ClassNames from "classnames";
import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeElementType } from "../reducers/elementTypes";
import { propTypeElement } from "../reducers/elements";
import { propTypePick } from "../reducers/picks";
import { propTypeTeam } from "../reducers/teams";
import ElementInTable from "./ElementInTable";
import ElementStatusButton from "./ElementStatusButton";
import TabHeading from "./TabHeading";
import Table, { PrimaryCell, ScrollTable } from "./Table";
import { VisuallyHidden } from "./styles";

// TODO: Move ListViewRow into a subdirectory of ListView and refactor to Styled Components
const ListViewRow = ({
  element,
  elementTypesById,
  menuLinkTitle,
  pick,
  renderElementMenu,
  renderExtraListViewCells,
}) => {
  const subStatusClass = ClassNames({
    "ismjs-sub-instigator": pick.subStatus === "instigator",
    "ismjs-sub-invalid": pick.subStatus === "invalid",
    "ismjs-sub-target": pick.subStatus === "target",
  });
  return (
    <tr className={subStatusClass}>
      <td className="ism-table--el__status">
        <ElementStatusButton elementId={element.id} />
      </td>
      <PrimaryCell>
        <button
          type="button"
          onClick={() => renderElementMenu(element, pick)}
          title={menuLinkTitle}
          className="ism-table--el__primary__button"
        >
          <ElementInTable elementId={element.id} />
        </button>
      </PrimaryCell>
      <td>{elementTypesById[element.element_type].singular_name_short}</td>
      {renderExtraListViewCells(element).map((data) => (
        <td key={data.key}>{data.content}</td>
      ))}
    </tr>
  );
};

export { ListViewRow as ListViewRowTest };

ListViewRow.propTypes = {
  element: propTypeElement.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementTypesById: PropTypes.objectOf(propTypeElementType).isRequired,
  menuLinkTitle: PropTypes.string,
  pick: propTypePick.isRequired,
  renderElementMenu: PropTypes.func.isRequired,
  renderExtraListViewCells: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

ListViewRow.defaultProps = {
  menuLinkTitle: "",
};

const ListViewTable = ({
  elementsById,
  isStarters,
  picks,
  renderExtraListViewHeaders,
  ...rest
}) => {
  const slicedPicks = isStarters ? picks.slice(0, 11) : picks.slice(-4);
  return (
    <>
      <TabHeading
        title={isStarters ? "Starters" : "Substitutes"}
        hasBorder={true}
      />
      <Box mb={3}>
        <Table>
          <thead>
            <tr>
              <th>
                <VisuallyHidden>Info</VisuallyHidden>
              </th>
              <th>
                <VisuallyHidden>Player</VisuallyHidden>
              </th>
              <th>POS</th>
              {renderExtraListViewHeaders().map((data) => (
                <th key={data.key}>{data.content}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {slicedPicks.map((p) => (
              <ListViewRow
                key={p.element}
                element={elementsById[p.element]}
                pick={p}
                {...rest}
              />
            ))}
          </tbody>
        </Table>
      </Box>
    </>
  );
};

export { ListViewTable as ListViewTableTest };

ListViewTable.propTypes = {
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  isStarters: PropTypes.bool,
  picks: PropTypes.arrayOf(propTypePick).isRequired,
  renderExtraListViewHeaders: PropTypes.func.isRequired,
};

ListViewTable.defaultProps = {
  isStarters: false,
};

const ListView = ({ ...props }) => (
  <ScrollTable>
    <ListViewTable {...props} isStarters />
    <ListViewTable {...props} />
  </ScrollTable>
);

export default ListView;
