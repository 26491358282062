import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  getActiveLeagueEntries,
  propTypeLeagueEntry,
} from "../reducers/leagueEntries";
import { Form, SelectField } from "./FieldRenderers";

const EntryMatchesFilter = ({ leagueEntries }) => (
  <Form>
    <Field
      id="entryNames"
      name="entryNames"
      component={SelectField}
      label="Filter matches"
    >
      <option value="0">None</option>
      {leagueEntries.map((leagueEntry) => (
        <option key={leagueEntry.id} value={leagueEntry.id}>
          {leagueEntry.entry_name || "AVERAGE"}
        </option>
      ))}
    </Field>
  </Form>
);

EntryMatchesFilter.propTypes = {
  leagueEntries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
};

export { EntryMatchesFilter as EntryMatchesFilterTest };

const mapStateToProps = (state) => ({
  leagueEntries: getActiveLeagueEntries(state),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "EntryMatchesFilter",
  })(EntryMatchesFilter),
);
