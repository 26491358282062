import React from "react";
import PropTypes from "prop-types";
import { AbbrShort, StyledAbbr } from "./styles";

const Abbr = ({ longName, shortName }) => (
  <StyledAbbr title={longName}>
    <AbbrShort className="ism-abbr__short">{shortName}</AbbrShort>
  </StyledAbbr>
);

Abbr.propTypes = {
  longName: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
};

export default Abbr;
