import * as Sentry from "@sentry/browser";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import "./drafty.css";
import "./polyfills";
import registerServiceWorker from "./registerServiceWorker";
import store from "./store/";
import loadSVG from "./utils/loadSVG";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://6182bca4fd2d4e81babb9c6cb5bd3619@o118622.ingest.sentry.io/4505036098895872",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
    ],
    tracesSampleRate: 0.000001,

    tracePropagationTargets: [
      /^https:\/\/pldpl\.pldev\.app/,
      /^https:\/\/pldraft\.staging\.ismgames\.com/,
      /^https:\/\/draft\.premierleague\.com/,
    ],

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.00001,
    allowUrls: [/pldpl\.pldev\.app/, /premierleague\.com/, /ismgames\.com/],
    ignoreErrors: [
      /Unexpected token/,
      /from accessing a cross-origin frame/,
      /NetworkError when attempting to fetch/,
      /Failed to fetch/,
      /Unexpected end of input/,
      /Service Unavailable/,
      /contextMenuMessageHandler/,
      /find variable: webkit/,
      /NPObject deleted/,
      /touchDownX/,
      /SecurityError/,
      /ntp is not defined/,
      /Permission denied to access property/,
      /NPMethod called on non-NPObject/,
      /Failed to fetch/,
      /GVLError/,
      /Load failed/,
      /Unexpected end of input/,
    ],
  });
}

loadSVG("/img/svg/processed/icons.svg");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);

registerServiceWorker();
