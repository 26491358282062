const getStatusTitle = (copnr) => {
  let copnrTitle = "";

  switch (copnr) {
    case 0:
      copnrTitle = "Unlikely to play next round";
      break;
    case 25:
      copnrTitle = "25% chance of playing next round";
      break;
    case 50:
      copnrTitle = "50% chance of playing next round";
      break;
    case 75:
      copnrTitle = "75% chance of playing next round";
      break;
    default:
      copnrTitle = "View player information";
  }
  return copnrTitle;
};

export default getStatusTitle;
