import { reduxBatch } from "@manaflair/redux-batch";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import socketMiddleware from "../actions/Socket";
import rootReducer from "../reducers/rootReducer";
import actionTypes from "redux-form/lib/actionTypes";
import {
  ACTIVE_ENTRY_CHANGE,
  CREATE_LEAGUE_SUCCESS,
  EDIT_LEAGUE_SUCCESS,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_ELEMENT_STATUS_SUCCESS,
  FETCH_LEAGUE_SUCCESS,
  WATCHLIST_UPDATE,
} from "../actions/ActionTypes";

const reduxFormActionTypes = Object.values(actionTypes) as unknown as string[];

export const middleware = [
  ...getDefaultMiddleware({
    // This middleware (only used in dev) was very noisy. Increasing the limit
    // shuts it up but if performance becomes an issue can disable it
    // https://redux-toolkit.js.org/api/immutabilityMiddleware
    immutableCheck: {
      warnAfter: 100,
    },
    // Ignore serializableCheck for Redux Form actions
    // https://redux-toolkit.js.org/api/serializabilityMiddleware
    serializableCheck: {
      ignoredActions: [
        ACTIVE_ENTRY_CHANGE,
        CREATE_LEAGUE_SUCCESS,
        EDIT_LEAGUE_SUCCESS,
        FETCH_CHAT_MESSAGES_SUCCESS,
        FETCH_ELEMENT_STATUS_SUCCESS,
        FETCH_LEAGUE_SUCCESS,
        WATCHLIST_UPDATE,
        ...reduxFormActionTypes,
      ],
    },
  }),
  socketMiddleware,
];

export default configureStore({
  reducer: rootReducer,
  middleware,
  enhancers: [reduxBatch],
});
