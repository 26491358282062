import { hideVisually, rgba, size } from "polished";
import styled, { css } from "styled-components/macro";
import { ButtonReset } from "../styles";
import { ISquadButton, IWatchIcon } from "./types";

export const SquadTableWrapper = styled.div`
  min-height: 55vh;
  background-color: #fff;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin: 0 ${(props) => props.theme.space[2]};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    min-height: 580px;
  }
`;

export const SquadButton = styled.button<ISquadButton>`
  ${ButtonReset}
  font-family: ${(props) => props.theme.fonts.bold};
  padding: ${(props) => props.theme.space[1]};
  border-radius: ${(props) => props.theme.radii[0]};
  font-size: ${(props) => props.theme.fontSizes[1]};
  line-height: 1;
  transition: all 0.2s;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: 0.9rem;
  }

  ${({ theme, variant }) => {
    if (variant === "primary") {
      return css`
        background-image: linear-gradient(
          to right,
          ${theme.colors.lightBlue},
          ${theme.colors.fantasy}
        );
        box-shadow: 0 1px 8px 0 ${rgba("#000", 0.06)};

        [disabled] {
          opacity: 0.5;

          @media (min-width: ${theme.breakpoints[4]}) {
            padding: 0.8rem;
          }
        }
      `;
    }

    if (variant === "watch") {
      return css`
        background-color: ${theme.colors.lightGrey};
        color: ${theme.colors.black};
      `;
    }

    if (variant === "highlight") {
      return css`
        background-color: ${theme.colors.darkPink};
        color: #fff;
      `;
    }
  }}
`;

export const SquadButtonText = styled.span`
  @media (max-width: ${(props) => props.theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;

const SquadButtonIcon = styled.svg`
  ${size("2rem")}

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;

export const WatchIcon = styled(SquadButtonIcon)<IWatchIcon>`
  fill: ${({ isWatched, theme }) => (isWatched ? theme.colors.black : "#fff")};
`;

export const HighlightIcon = styled(SquadButtonIcon)`
  fill: #fff;
`;

export const PrimaryIcon = styled(SquadButtonIcon)`
  fill: ${(props) => props.theme.colors.primary};
`;
