import styled from "styled-components/macro";

export const TableWrap = styled.div`
  background-color: #fff;
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0 ${(props) => props.theme.space[2]};
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
`;

export const StatusCell = styled.td``;

export const PrimaryCell = styled.td`
  position: relative;
  text-align: left;

  ::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 1rem;
    display: block;
    width: 2px;
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

  th,
  td {
    padding: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    font-size: 1.1rem;

    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      font-size: 1.3rem;
    }
  }

  ${PrimaryCell} {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  thead th {
    padding: 0.6rem 1rem;
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: 1rem;
    text-align: left;
  }

  tbody th {
    text-align: left;
  }

  tfoot td {
    font-weight: ${(props) => props.theme.fonts.bold};
  }
`;

export default Table;
