import PropTypes from "prop-types";
import { propTypeEvent } from "../../reducers/events";
import {
  NextLink as NextLinkButton,
  PagerItem,
  PagerItemNext,
  PreviousLink,
  EventPager as StyledEventPager,
} from "../pager";
import { EventPagerHeading, EventPagerWrap } from "./styles";

const EventPager = ({ backLink, nextLink, event }) => (
  <EventPagerWrap>
    <EventPagerHeading>{event.name}</EventPagerHeading>
    {(backLink || nextLink) && (
      <StyledEventPager>
        {backLink && (
          <PagerItem>
            <PreviousLink to={backLink} />
          </PagerItem>
        )}
        {/* eslint-disable max-len */}
        {nextLink && (
          <PagerItemNext>
            <NextLinkButton to={nextLink} />
          </PagerItemNext>
        )}
        {/* eslint-enable max-len */}
      </StyledEventPager>
    )}
  </EventPagerWrap>
);

EventPager.propTypes = {
  backLink: PropTypes.string,
  nextLink: PropTypes.string,
  event: propTypeEvent.isRequired,
};

EventPager.defaultProps = {
  backLink: null,
  nextLink: null,
};

export default EventPager;
