import PropTypes from "prop-types";
import React from "react";
import { HubButtonWrap, HubUnitScroll } from "..";
import { propTypeEntry } from "../../../../reducers/entries";
import { propTypeLeagueEntry } from "../../../../reducers/leagueEntries";
import { propTypeLeague } from "../../../../reducers/leagues";
import Button from "../../../Button";
import ButtonLink from "../../../ButtonLink";
import ConfirmDialog from "../../../ConfirmDialog";
import DialogManager from "../../../DialogManager";
import {
  PrimaryContainerContent,
  PrimaryContainerHeading,
  PrimaryContainerValue,
} from "../../../PrimaryContainer";
import { VisuallyHidden } from "../../../styles";
import { EntriesTable } from "./styles";

const LeagueEntries = ({ confirmDelete, league, entries, activeEntry }) => {
  const canDeleteLeagueEntry = (leagueEntry) =>
    league.can_admin &&
    league.draft_status === "pre" &&
    leagueEntry.entry_id !== activeEntry.id;
  return (
    <div>
      <PrimaryContainerHeading>
        League entrants:
        <PrimaryContainerValue> {entries.length}</PrimaryContainerValue>
      </PrimaryContainerHeading>
      <PrimaryContainerContent>
        <HubUnitScroll>
          <EntriesTable>
            <thead>
              <tr>
                <th>Team</th>
                <th>Manager</th>
                <th>
                  <VisuallyHidden>Delete</VisuallyHidden>
                </th>
              </tr>
            </thead>
            <tbody>
              {entries &&
                entries.length > 0 &&
                entries.map((e) => (
                  <tr key={e.entry_id}>
                    <td>{e.entry_id ? e.entry_name : "AVERAGE"}</td>
                    <td>
                      <strong>
                        {e.entry_id
                          ? `${e.player_first_name} ${e.player_last_name}`
                          : "AVERAGE"}
                      </strong>
                    </td>
                    <td>
                      {canDeleteLeagueEntry(e) && (
                        <DialogManager
                          render={(showDialog, handleShow, handleHide) => (
                            <React.Fragment>
                              <Button fullwidth="true" onClick={handleShow}>
                                Delete
                              </Button>
                              {showDialog && (
                                <ConfirmDialog
                                  closeDialog={handleHide}
                                  header="Delete team?"
                                  onConfirm={() =>
                                    confirmDelete(league.id, e.entry_id)
                                  }
                                >
                                  <p>
                                    Are you sure you want to delete this team?
                                  </p>
                                </ConfirmDialog>
                              )}
                            </React.Fragment>
                          )}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </EntriesTable>
        </HubUnitScroll>
        {league.can_admin && (
          <HubButtonWrap>
            <ButtonLink to={`/league/${league.id}/invite`} fullwidth="true">
              Invite Players
            </ButtonLink>
          </HubButtonWrap>
        )}
      </PrimaryContainerContent>
    </div>
  );
};

LeagueEntries.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  league: propTypeLeague.isRequired,
  entries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
  activeEntry: propTypeEntry.isRequired,
};

export default LeagueEntries;
