import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components/macro";
import Tab from "./Tab";
import TabPanel from "./TabPanel";
import { StyledTabs } from "./styles";

export const TabListShared = css`
  display: flex;
  align-items: center;
  margin-right: 20%;
  padding: 0;
  border-radius: 8px;
  list-style-type: none;
  background-color: #edf1f9;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-right: 33%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 66%;
  }
`;

const TabList = styled.ul`
  ${TabListShared}

  ${(props) =>
    props.isCentered &&
    css`
      margin: 0;

      @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
        margin: 0 15%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        margin: 0 22%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
        margin: 0 28%;
      }
    `}
`;

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { selected: this.getSelectedFromLocationHash() };
  }

  getSelectedFromLocationHash() {
    // Default to prop, can override with location hash
    let selected = this.props.selected;
    if (this.props.locationHash) {
      React.Children.forEach(this.props.children, (panel, index) => {
        if (`#${panel.props.link}` === this.props.locationHash) {
          selected = index;
        }
      });
    }
    return selected;
  }

  handleClick(index) {
    this.setState({ selected: index });
  }

  render() {
    return (
      <div>
        <StyledTabs>
          <TabList isCentered={this.props.isCentered}>
            {React.Children.map(this.props.children, (panel, index) => (
              <Tab
                key={index}
                index={index}
                label={panel.props.label}
                link={panel.props.link}
                onClick={this.handleClick}
                selected={this.state.selected === index}
              />
            ))}
          </TabList>
        </StyledTabs>
        {this.props.children[this.state.selected]}
      </div>
    );
  }
}

Tabs.propTypes = {
  children: (props, propName, componentName) => {
    let error = null;
    React.Children.forEach(props[propName], (child) => {
      if (child.type !== TabPanel) {
        error = new Error(
          `${componentName} children should be of type TabPanel`,
        );
      }
    });
    return error;
  },
  locationHash: PropTypes.string,
  selected: PropTypes.number,
  theme: PropTypes.string,
};

Tabs.defaultProps = {
  children: null,
  locationHash: "",
  selected: 0,
  theme: "light",
};

export default Tabs;
