import PropTypes from "prop-types";
import { ADD_GAME } from "../actions/ActionTypes";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_GAME: {
      return action.data;
    }

    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getGame = (state) => state.game;

// PropTypes
export const propTypeGame = PropTypes.shape({
  current_event: PropTypes.number,
  current_event_finished: PropTypes.bool,
  processing_status: PropTypes.oneOf(["n", "w", "r", "l"]),
  waivers_processed: PropTypes.bool,
});
