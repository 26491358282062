import styled from "styled-components/macro";

const TabHeadingLight = styled.h3`
  margin: ${({ theme }) => `0 ${theme.space[3]} ${theme.space[3]}`};
  padding: 0.8rem ${({ theme }) => theme.space[2]};
  border-radius: 0 0 1rem 1rem;
  font-size: inherit;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  line-height: 1;
  text-align: center;
`;

export default TabHeadingLight;
