const stateNames = {
  o: "Offered",
  w: "Withdrawn",
  r: "Rejected",
  a: "Accepted",
  i: "Invalid",
  v: "Vetoed",
  e: "Expired",
  p: "Processed",
};

const getTradeStateName = (key) => stateNames[key];

export default getTradeStateName;
