import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import {
  ADD_DREAM_TEAM,
  ADD_ELEMENT_EVENT_STATS,
  ADD_ELEMENTS,
  ADD_TOP_ELEMENTS,
  ELEMENT_DIALOG_HIDE,
  ELEMENT_DIALOG_SHOW,
} from "../actions/ActionTypes";
import { getElementStat } from "./elementStats";
import { getActiveLeagueElementStatus } from "./elementStatus";
import { getCurrentEvent } from "./events";
import { getFixturesByEvent, propTypeFixture } from "../store/fixtures/slice";
import { getWatchlist } from "./watchlist";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.elements;
export const l2g = (local) => ({ elements: local });

const formatNameForSearching = (name) => {
  const search = name.toLowerCase();
  const from = "ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșşšŝťțţŭùúüűûñÿýçżźž.";
  const to = "aaaaaaaaaccceeeeeghiiiijllnnoooooooossssstttuuuuuunyyczzz ";
  return search.replace(/.{1}/g, (c) => {
    const index = from.indexOf(c);
    return index === -1 ? c : to[index];
  });
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_ELEMENTS: {
      const newState = { ...state };
      action.data.forEach((element) => {
        newState[element.id] = {
          ...element,
          search_name: formatNameForSearching(element.web_name),
        };
      });
      return newState;
    }

    default:
      return state;
  }
};

const statsByEvent = (state = {}, action) => {
  switch (action.type) {
    case ADD_ELEMENT_EVENT_STATS: {
      return {
        ...state,
        [action.data.event]: action.data.stats,
      };
    }

    default:
      return state;
  }
};

const topByEvent = (state = {}, action) => {
  switch (action.type) {
    case ADD_TOP_ELEMENTS: {
      return action.data;
    }

    default:
      return state;
  }
};

const dialog = (state = 0, action) => {
  switch (action.type) {
    case ELEMENT_DIALOG_SHOW:
      return action.data;
    case ELEMENT_DIALOG_HIDE:
      return 0;
    default:
      return state;
  }
};

const dreamTeam = (state = {}, action) => {
  switch (action.type) {
    case ADD_DREAM_TEAM: {
      return {
        ...state,
        [action.data.eventId]: action.data.elements,
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  statsByEvent,
  topByEvent,
  dreamTeam,
  dialog,
});

// Selectors
export const getElementsById = (state) => g2l(state).byId;

export const getElementsWithLeagueStatus = createSelector(
  getElementsById,
  getActiveLeagueElementStatus,
  (elements, status) =>
    Object.keys(elements).reduce(
      (byElementId, id) =>
        Object.assign(byElementId, {
          [id]: {
            ...elements[id],
            leagueStatus: status[id],
          },
        }),
      {},
    ),
);

export const getElement = (state, id) => getElementsById(state)[id];

export const getElements = (state) => {
  const localState = g2l(state).byId;
  return Object.keys(localState).map((key) => localState[key]);
};

export const getElementCount = createSelector(
  getElements,
  (elements) => elements.length,
);

const getElementListControlValues = getFormValues("elementListControl");

const getFilters = createSelector(getElementListControlValues, (formValues) => {
  const filters = {};
  if (formValues && formValues.filter) {
    const filterRegexp = /^(te|et)_(\d+)$/;
    const matches = formValues.filter.match(filterRegexp);
    if (matches) {
      const key = matches[1];
      const value = parseInt(matches[2], 10);
      switch (key) {
        case "te":
          filters.team = value;
          break;
        case "et":
          filters.element_type = value;
          break;
        default:
          break;
      }
    }
  }
  return filters;
});

export const getSafeSearchRegexp = createSelector(
  getElementListControlValues,
  (formValues) => {
    if (formValues && formValues.search) {
      const safeValue = formatNameForSearching(formValues.search).replace(
        /[-/\\^$*+?.()|[\]{}]/g,
        "\\$&",
      );
      return new RegExp(`(^${safeValue}| ${safeValue})`);
    }
    return undefined;
  },
);

const filterElements = (elements, filters, search) =>
  elements.filter((element) => {
    let allow = true;
    ["team", "element_type"].forEach((key) => {
      if (filters[key] && filters[key] !== element[key]) {
        allow = false;
      }
    });
    if (allow && search) {
      if (!element.search_name.match(search)) {
        allow = false;
      }
    }
    return allow;
  });

export const getFilteredElements = createSelector(
  getElements,
  getFilters,
  getSafeSearchRegexp,
  (elements, filters, search) => filterElements(elements, filters, search),
);

export const getSortStat = (state) => {
  const formValues = getElementListControlValues(state);
  if (formValues && formValues.sort) {
    return getElementStat(state, formValues.sort);
  }
  return getElementStat(state, "total_points");
};

export const getSortedElements = createSelector(
  getFilteredElements,
  getSortStat,
  (elements, sortStat) =>
    elements.sort((a, b) => {
      if (sortStat) {
        const sortVal = sortStat.name;
        const sortMultiplier = sortStat.sort === "asc" ? 1 : -1;
        if (a[sortVal] !== b[sortVal]) {
          return sortMultiplier * (a[sortVal] - b[sortVal]);
        }
      }
      if (a.team !== b.team) {
        return a.team - b.team;
      }
      if (a.element_type !== b.element_type) {
        return a.element_type - b.element_type;
      }
      return a.web_name.localeCompare(b.web_name);
    }),
);

export const getWatchedElements = createSelector(
  getWatchlist,
  getElementsById,
  (watchlist, elements) => watchlist.map((id) => elements[id]),
);

export const getFilteredWatchedElements = createSelector(
  getWatchedElements,
  getFilters,
  getSafeSearchRegexp,
  (elements, filters, search) => filterElements(elements, filters, search),
);

export const getLatestInjuries = createSelector(getElements, (elements) =>
  elements
    .filter((e) => e.news_added && e.status !== "a" && e.status !== "p")
    .sort((a, b) => {
      if (a.news_added > b.news_added) return -1;
      if (a.news_added < b.news_added) return 1;
      return 0;
    }),
);

export const getElementStatsByEvent = (state) => g2l(state).statsByEvent;

export const getElementStatsForEvent = (state, eventId) =>
  getElementStatsByEvent(state)[eventId] || {};

export const getExplainElementEvent = (state, elementId, eventId) => {
  // Make sure we have some data
  const stats = getElementStatsForEvent(state, eventId);
  const fixtures = getFixturesByEvent(state, eventId);
  if (!stats[elementId] || !fixtures[eventId]) return [];

  // Create a map for easy fixture retrieval
  const fixtureMap = fixtures[eventId].reduce(
    (memo, fixture) => Object.assign(memo, { [fixture.id]: fixture }),
    {},
  );

  // explain is a list [[list of explain stats], fixtures].
  return stats[elementId].explain.map((data) => ({
    explain: data[0],
    fixture: fixtureMap[data[1]],
  }));
};

export const getTopElements = (state) => g2l(state).topByEvent;

export const getDreamTeam = (state) => g2l(state).dreamTeam;

export const getDreamTeamCurrent = createSelector(
  getDreamTeam,
  getCurrentEvent,
  (teams, now) => {
    const data = {
      totalPoints: 0,
      elements: [],
    };
    if (!now || !now.id) return data;
    if (!teams[now.id]) return data;
    data.elements = teams[now.id];
    data.totalPoints = data.elements.reduce(
      (memo, element) => memo + element.total_points,
      0,
    );
    return data;
  },
);

export const getDreamTeamOverall = createSelector(getDreamTeam, (teams) => {
  const data = {
    totalPoints: 0,
    elements: [],
  };
  if (!teams[0]) return data;
  data.elements = teams[0];
  data.totalPoints = data.elements.reduce(
    (memo, element) => memo + element.total_points,
    0,
  );
  return data;
});

export const getElementDialog = (state) => g2l(state).dialog;

// PropTypes
export const propTypeElement = PropTypes.shape({
  id: PropTypes.number,
});

export const propTypeSortStat = PropTypes.shape({
  abbreviation: PropTypes.string,
});

export const propTypeElementEventStat = PropTypes.shape({
  explain: PropTypes.Object,
  stats: PropTypes.Object,
});

export const propTypeElementExplainFixture = PropTypes.shape({
  explain: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      points: PropTypes.number,
      value: PropTypes.number,
    }),
  ),
  fixture: propTypeFixture,
});

export const propTypeTopElement = PropTypes.shape({
  element: PropTypes.number,
  total_points: PropTypes.number,
});

export const propTypeDreamTeam = PropTypes.shape({
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      element_id: PropTypes.number,
      total_points: PropTypes.numbr,
    }),
  ),
  total_points: PropTypes.number,
});
