import styled from "styled-components/macro";
import { IDifficultyCell } from "./types";

export const DifficultyCell = styled.td<IDifficultyCell>`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  background-color: ${({ difficulty, theme }) =>
    theme.colors.difficulties[difficulty].bg};
  color: ${({ difficulty, theme }) =>
    theme.colors.difficulties[difficulty].color};
  text-align: center;
`;
