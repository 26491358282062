import PropTypes from "prop-types";
import React from "react";
import { propTypeDraftStatus } from "../../../reducers/draftChoices";
import { propTypeElement } from "../../../reducers/elements";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { propTypeLeague } from "../../../reducers/leagues";
import Button from "../../Button";
import DraftCountdown from "../DraftCountdown";
import DraftCurrentPick from "../DraftCurrentPick";
import DraftMyPick from "../DraftMyPick";
import DraftRound from "../DraftRound";
import LastChoiceElement from "../LastChoiceElement";
import { PickBoxes, PickBoxesBox, PickBoxesItem } from "../styles";
import { Idle, IdleHeading, StickyStatus } from "./styles";

class DuringDraftStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stuck: false };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.initialStatusPageTop = this.stickyStatus.offsetTop;
    this.primaryEl = document.getElementById("ism-primary-container");
    this.primaryElMarginTop = parseFloat(
      getComputedStyle(this.primaryEl).marginTop,
    );
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getElXPadding(el) {
    this.elComputedStyle = getComputedStyle(el);
    this.leftPadding = parseFloat(this.elComputedStyle.paddingLeft);
    this.rightPadding = parseFloat(this.elComputedStyle.paddingRight);
    return this.leftPadding + this.rightPadding;
  }

  getElContentWidth(el) {
    this.padding = this.getElXPadding(el);
    this.elContentWidth = el.clientWidth - this.padding;
    return `${this.elContentWidth}px`;
  }

  handleScroll() {
    const statusViewportTop = this.stickyStatus.getBoundingClientRect().top;

    if (
      statusViewportTop <= 0 &&
      window.pageYOffset > this.initialStatusPageTop
    ) {
      this.setState({ stuck: true });
      this.stickyStatus.style.width = this.getElContentWidth(
        this.stickyStatus.parentElement,
      );
      const PrimaryElStatusMargin =
        this.primaryElMarginTop + this.stickyStatus.offsetHeight;
      this.primaryEl.style.marginTop = `${PrimaryElStatusMargin}px`;
    } else {
      this.setState({ stuck: false });
      this.primaryEl.style.marginTop = `${this.primaryElMarginTop}px`;
    }
  }

  render() {
    return (
      <StickyStatus
        isStuck={this.state.stuck}
        ref={(node) => {
          this.stickyStatus = node;
        }}
      >
        <DraftRound
          isDuring={this.props.status.stage === "during"}
          leagueName={this.props.league.name}
          round={this.props.status.thisChoice.round}
          squadSize={this.props.squadSize}
        />
        <PickBoxes>
          <PickBoxesItem>
            <PickBoxesBox>
              <DraftCurrentPick
                isMyPick={this.props.status.myPick}
                thisChoice={this.props.status.thisChoice}
              />
            </PickBoxesBox>
            <PickBoxesBox>
              {this.props.status.myNextChoice ? (
                <DraftMyPick
                  isNextRound={
                    this.props.status.myNextChoice.round >
                    this.props.status.thisChoice.round
                  }
                  pickNumber={this.props.status.myNextChoice.pick}
                  picksUntilMine={
                    this.props.status.myNextChoice.index -
                    this.props.status.thisChoice.index
                  }
                />
              ) : (
                <DraftMyPick isNoNextPick />
              )}
            </PickBoxesBox>
          </PickBoxesItem>
          <PickBoxesItem>
            <PickBoxesBox isPrimary={true}>
              {this.props.isAutoDraft ? (
                <Idle>
                  <IdleHeading>You&apos;ve Gone Idle</IdleHeading>
                  <Button onClick={this.props.markOnline}>I'm back!</Button>
                </Idle>
              ) : (
                <DraftCountdown
                  isMyPick={this.props.status.myPick}
                  nextIndex={
                    this.props.status.nextChoice
                      ? this.props.status.nextChoice.index
                      : null
                  }
                  pickTimeLimit={this.props.league.draft_pick_time_limit}
                  secondsToPick={this.props.status.thisChoice.seconds_to_pick}
                />
              )}
            </PickBoxesBox>
          </PickBoxesItem>
        </PickBoxes>

        {this.props.status.lastChoice && (
          <LastChoiceElement
            elementsById={this.props.elementsById}
            entriesById={this.props.entriesById}
            lastChoice={this.props.status.lastChoice}
          />
        )}
      </StickyStatus>
    );
  }
}

DuringDraftStatus.propTypes = {
  className: PropTypes.string,
  elementsById: PropTypes.objectOf(propTypeElement),
  entriesById: PropTypes.objectOf(propTypeLeagueEntry),
  isAutoDraft: PropTypes.bool,
  league: propTypeLeague.isRequired,
  markOnline: PropTypes.func,
  status: propTypeDraftStatus.isRequired,
  squadSize: PropTypes.number,
};

DuringDraftStatus.defaultProps = {
  className: "",
  elementsById: null,
  entriesById: null,
  isAutoDraft: false,
  league: {
    can_admin: false,
    name: undefined,
  },
  markOnline: null,
  squadSize: null,
};

export default DuringDraftStatus;
