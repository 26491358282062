import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { elementDialogShow as eds } from "../../../actions/Element";
import { leagueTransactionsFetch } from "../../../actions/Transaction";
import {
  getElementTypesById,
  propTypeElementType,
} from "../../../reducers/elementTypes";
import { getActiveEntryHubStatus } from "../../../reducers/entries";
import {
  getCurrentEvent,
  getNextEvent,
  propTypeEvent,
} from "../../../reducers/events";
import {
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../../../reducers/leagueEntries";
import { getActiveLeague, propTypeLeague } from "../../../reducers/leagues";
import { getTeamsById, propTypeTeam } from "../../../reducers/teams";
import {
  getForLeagueFreeAgentsEvent,
  getForLeagueWaiversEvent,
  propTypeTransactionInflated,
} from "../../../reducers/transactions";
import { PatternWrapMain } from "../../GraphicPatterns";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import SubHeading from "../../SubHeading";
import { TableWrap } from "../../Table";
import Tabs from "../../Tabs";
import TabPanel from "../../Tabs/TabPanel";
import TransactionElements from "../../transactions/TransactionElements";
import {
  PlayerCell,
  StyledRecentTransactionsTable,
  TransactionCell,
  TransactionHeading,
  TransactionTypeCell,
} from "./styles";

const RecentTransactionRow = ({
  elementDialogShow,
  leagueEntry,
  transaction,
  teamsById,
  typesById,
}) => (
  <tr>
    <td>
      <LeagueEntryShortName entryId={leagueEntry.entry_id} />
    </td>
    <TransactionCell>
      <TransactionElements
        elementDialogShow={elementDialogShow}
        elementIn={transaction.element_in}
        elementOut={transaction.element_out}
        teamsById={teamsById}
        typesById={typesById}
      />
    </TransactionCell>
    <td>
      {transaction.kind === "w" ? (
        <span>
          WA{" "}
          {transaction.result === "a" ? (
            <svg
              className="ism-icon--small ism-icon--thumbs-up"
              width="25"
              height="28"
              viewBox="0 0 25 28"
            >
              <use xlinkHref="#ism-thumbs-up" />
            </svg>
          ) : (
            <svg
              className="ism-icon--small ism-icon--thumbs-down"
              width="25"
              height="28"
              viewBox="0 0 25 28"
            >
              <use xlinkHref="#ism-thumbs-down" />
            </svg>
          )}
        </span>
      ) : (
        <span>FA</span>
      )}
    </td>
  </tr>
);

RecentTransactionRow.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  leagueEntry: propTypeLeagueEntry.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  transaction: propTypeTransactionInflated.isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

const RecentTransactionsTable = ({
  elementDialogShow,
  leagueEntriesByEntryId,
  transactions,
  teamsById,
  typesById,
}) => (
  <TableWrap>
    <StyledRecentTransactionsTable>
      <thead>
        <tr>
          <PlayerCell>Manager</PlayerCell>
          <TransactionHeading>Transaction</TransactionHeading>
          <TransactionTypeCell>Type</TransactionTypeCell>
        </tr>
      </thead>
      <tbody>
        {transactions.map((t) => (
          <RecentTransactionRow
            key={t.id}
            elementDialogShow={elementDialogShow}
            leagueEntry={leagueEntriesByEntryId[t.entry]}
            transaction={t}
            teamsById={teamsById}
            typesById={typesById}
          />
        ))}
      </tbody>
    </StyledRecentTransactionsTable>
  </TableWrap>
);

RecentTransactionsTable.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  leagueEntriesByEntryId: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  transactions: PropTypes.arrayOf(propTypeTransactionInflated).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

const RecentTransactions = ({ freeAgents, showCurrent, waivers, ...rest }) => (
  <div>
    <SubHeading>
      {showCurrent ? "Last Gameweek Transactions" : "Latest Transactions"}
    </SubHeading>
    <PatternWrapMain>
      <Tabs>
        <TabPanel label="Waivers" link="waivers">
          <Box my={4}>
            <RecentTransactionsTable {...rest} transactions={waivers} />
          </Box>
        </TabPanel>
        <TabPanel label="Free Agents" link="free">
          <Box my={4}>
            <RecentTransactionsTable {...rest} transactions={freeAgents} />
          </Box>
        </TabPanel>
      </Tabs>
    </PatternWrapMain>
  </div>
);

RecentTransactions.propTypes = {
  freeAgents: PropTypes.arrayOf(propTypeTransactionInflated).isRequired,
  showCurrent: PropTypes.bool.isRequired,
  waivers: PropTypes.arrayOf(propTypeTransactionInflated).isRequired,
};

export { RecentTransactions as RecentTransactionsTest };

class RecentTransactionsContainer extends React.Component {
  componentDidMount() {
    if (this.props.showEvent) {
      this.props.fetchLeagueTransactions(this.props.activeLeague.id);
    }
  }

  render() {
    return this.props.showEvent ? <RecentTransactions {...this.props} /> : null;
  }
}

RecentTransactionsContainer.propTypes = {
  activeLeague: propTypeLeague.isRequired,
  fetchLeagueTransactions: PropTypes.func.isRequired,
  showEvent: propTypeEvent.isRequired,
};

const mapStateToProps = (state) => {
  const activeLeague = getActiveLeague(state);
  const currentEvent = getCurrentEvent(state);
  const nextEvent = getNextEvent(state);
  const status = getActiveEntryHubStatus(state);
  let showEvent = null;
  if (status === "free_agency") {
    showEvent = nextEvent;
  } else if (currentEvent) {
    showEvent = currentEvent;
  }
  const showCurrent = Boolean(showEvent && showEvent === currentEvent);
  return {
    activeLeague,
    currentEvent,
    freeAgents: getForLeagueFreeAgentsEvent(
      state,
      activeLeague.id,
      showEvent.id,
    ),
    leagueEntriesByEntryId: getActiveLeagueEntriesByEntryId(state),
    nextEvent,
    showCurrent,
    showEvent,
    teamsById: getTeamsById(state),
    typesById: getElementTypesById(state),
    waivers: getForLeagueWaiversEvent(state, activeLeague.id, showEvent.id),
  };
};

const mapDispatchToProps = {
  elementDialogShow: eds,
  fetchLeagueTransactions: leagueTransactionsFetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecentTransactionsContainer);
