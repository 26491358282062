import React from "react";
import PropTypes from "prop-types";

class DialogManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDialog: false };
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  handleShow() {
    this.setState({ showDialog: true });
  }

  handleHide() {
    this.setState({ showDialog: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.render(
          this.state.showDialog,
          this.handleShow,
          this.handleHide,
        )}
      </React.Fragment>
    );
  }
}

DialogManager.propTypes = {
  render: PropTypes.func.isRequired,
};

export default DialogManager;
