import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import {
  getElementsInTrade,
  propTypeTrade,
  propTypeTradeWarningElement,
} from "../../../reducers/transactions";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import TradeResponse from "../TradeResponse";
import TransactionElementGroup from "../TransactionElementGroup";
import {
  ActionCell,
  EntryCell,
  StyledReceivedTradesTable,
  TradeCell,
  TradeHeading,
} from "./style";
import {
  StyledTransaction,
  TransactionUnit,
  TransactionUnitHeading,
} from "../styles";

export const getMultiTradeWarnings = (tradeWarningElements, tradeItems) => {
  const elementsInTrade = getElementsInTrade(tradeItems);
  const multiTradeWarnings = {};
  Object.keys(tradeWarningElements).forEach((el) => {
    const isInCurrentTrade = elementsInTrade.indexOf(parseInt(el, 10)) > -1;
    const tradeWarningEl = tradeWarningElements[el];
    const transactions = tradeWarningEl.transactions;
    const isInProposedWaiver = transactions.indexOf("proposed-waiver") > -1;
    const isInProposedTrade = transactions.indexOf("proposed-trade") > -1;
    const isInOtherTrades =
      tradeWarningEl.tradeIds && tradeWarningEl.tradeIds.length > 1;

    /* To be included in this you need to be in this trade and either a proposed waiver or in
        an additional trade */
    if (
      isInCurrentTrade &&
      (isInProposedWaiver || (isInProposedTrade && isInOtherTrades))
    ) {
      multiTradeWarnings[el] = tradeWarningEl;
    }
  });
  return multiTradeWarnings;
};

const ReceivedTrade = ({
  acceptTradeOffer,
  offeredEntry,
  rejectTradeOffer,
  trade,
  tradeWarningElements,
  ...rest
}) => (
  <tr>
    <EntryCell>
      <LeagueEntryShortName entryId={offeredEntry.entry_id} />
    </EntryCell>
    <TradeCell>
      <StyledTransaction>
        <TransactionUnit>
          <TransactionUnitHeading isIn={true}>Offered</TransactionUnitHeading>
          <TransactionElementGroup elementIds={trade.elementsOut} {...rest} />
        </TransactionUnit>
        <TransactionUnit>
          <TransactionUnitHeading>Requested</TransactionUnitHeading>
          <TransactionElementGroup elementIds={trade.elementsIn} {...rest} />
        </TransactionUnit>
      </StyledTransaction>
    </TradeCell>
    <ActionCell>
      <TradeResponse
        noAction={rejectTradeOffer}
        noHeader="Decline Trade?"
        noLabel="Decline"
        noMessage="Please confirm that you would like to reject the following trade offer:"
        offeredEntry={offeredEntry}
        trade={trade}
        tradeWarningElements={getMultiTradeWarnings(
          tradeWarningElements,
          trade.tradeitem_set,
        )}
        yesAction={acceptTradeOffer}
        yesHeader="Accept Trade?"
        yesMessage="Please confirm that you would like to accept the following trade offer:"
        yesLabel="Accept"
        {...rest}
      />
    </ActionCell>
  </tr>
);

ReceivedTrade.propTypes = {
  acceptTradeOffer: PropTypes.func.isRequired,
  rejectTradeOffer: PropTypes.func.isRequired,
  offeredEntry: propTypeLeagueEntry.isRequired,
  trade: propTypeTrade.isRequired,
  tradeWarningElements: PropTypes.objectOf(propTypeTradeWarningElement)
    .isRequired,
};

const ReceivedTradesTable = ({ entriesById, tradesReceived, ...rest }) => (
  <Box mb={4}>
    <StyledReceivedTradesTable>
      <thead>
        <tr>
          <EntryCell as="th">Manager</EntryCell>
          <TradeHeading>Trade</TradeHeading>
          <ActionCell as="th">Response</ActionCell>
        </tr>
      </thead>
      <tbody>
        {tradesReceived.map((trade) => (
          <ReceivedTrade
            offeredEntry={entriesById[trade.offered_entry]}
            key={trade.id}
            trade={trade}
            {...rest}
          />
        ))}
      </tbody>
    </StyledReceivedTradesTable>
  </Box>
);

ReceivedTradesTable.propTypes = {
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  tradesReceived: PropTypes.arrayOf(propTypeTrade).isRequired,
};

export default ReceivedTradesTable;
