import { Box } from "rebass/styled-components";
import { Field } from "redux-form";
import { CheckboxField, Fieldset, Legend } from "./FieldRenderers";
import Link from "./Link";

const TermsFields = () => (
  <Fieldset>
    <Box mb={1}>
      <Legend>
        Terms &amp; Conditions (<Link to="/help#tac">View</Link>)
      </Legend>
    </Box>
    <Box mb={4}>
      <Field
        id="entryTerms"
        name="entryTerms"
        component={CheckboxField}
        type="checkbox"
        label="Agree to Terms &amp; Conditions"
      />
    </Box>
  </Fieldset>
);

const validateTermsFields = (values: any) => {
  interface IErrors {
    [key: string]: string;
  }
  const errors: IErrors = {};

  // Entry validation
  if (!values.entryTerms) {
    errors.entryTerms = "Required";
  }

  return errors;
};

export { validateTermsFields };
export default TermsFields;
