import styled from "styled-components/macro";
import { CollapsibleHeading } from "./Collapsible/styles";

const Copy = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]};

  h4,
  h5,
  p,
  ul,
  ol,
  table {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  h6 {
    margin-bottom: ${({ theme }) => theme.space[1]};
  }

  ul,
  ol {
    padding-left: 4rem;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  ${CollapsibleHeading} {
    margin-bottom: 0;
  }
`;

export default Copy;
