import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import {
  ADD_ENTRY_EVENT_PICKS,
  ADD_MY_PICKS,
  SUBSTITUTION_PROCESS,
  SUBSTITUTION_START,
  SUBSTITUTION_STOP,
} from "../actions/ActionTypes";
import { getElement, getElementsById } from "./elements";
import { getElementTypes } from "./elementTypes";
import {
  getPositionTypeLocks,
  getSetting,
  getTeamSelectionLimits,
} from "./settings";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.picks;
export const l2g = (local) => ({ picks: local });

const mine = (state = { saved: [], edited: [], justSaved: false }, action) => {
  switch (action.type) {
    case ADD_MY_PICKS:
      return {
        saved: action.data.picks,
        justSaved: action.data.saved,
        edited: action.data.picks.map((p) => ({
          ...p,
          et: action.data.elementsById[p.element].element_type,
          subStatus: "",
        })),
      };

    case SUBSTITUTION_PROCESS: {
      // Find the pick who instigated the substitution
      const instigator = state.edited.reduce(
        (memo, p) => (p.subStatus === "instigator" ? p : memo),
        null,
      );
      if (!instigator) return state;

      // Swap positions and reset subStatus
      const pickPosition = action.data.pick.position;
      const instigatorPosition = instigator.position;
      let newPicks = state.edited.map((p) => {
        const newPick = { ...p };
        if (p.id === instigator.id) {
          newPick.position = pickPosition;
        } else if (p.id === action.data.pick.id) {
          newPick.position = instigatorPosition;
        }
        newPick.subStatus = "";
        return newPick;
      });

      // Order by position, factoring in et for starters
      const subStart = action.data.subStart;
      newPicks.sort((a, b) => {
        if (a.position < subStart && b.position < subStart) {
          return a.et * 99 + a.position - (b.et * 99 + b.position);
        }
        return a.position - b.position;
      });

      // Reset position based on index
      newPicks = newPicks.map((p, index) => {
        const newPick = { ...p };
        newPick.position = index + 1;
        return newPick;
      });

      return {
        ...state,
        edited: newPicks,
      };
    }

    case SUBSTITUTION_START: {
      const newPicks = state.edited.map((p) => {
        const newPick = { ...p };
        if (p === action.data.pick) {
          newPick.subStatus = "instigator";
        } else if (action.data.possibles.indexOf(p) > -1) {
          newPick.subStatus = "target";
        } else {
          newPick.subStatus = "invalid";
        }
        return newPick;
      });
      return {
        ...state,
        edited: newPicks,
        justSaved: false,
      };
    }

    case SUBSTITUTION_STOP:
      return {
        ...state,
        edited: state.edited.map((p) => ({ ...p, subStatus: "" })),
      };

    default:
      return state;
  }
};

const history = (state = {}, action) => {
  switch (action.type) {
    case ADD_ENTRY_EVENT_PICKS: {
      const newState = { ...state };
      const eventId = action.data.event;
      if (!newState[eventId]) {
        newState[eventId] = {};
      }
      newState[eventId][action.data.entry] = action.data.picks;
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  mine,
  history,
});

// Selectors
export const getMine = (state) => g2l(state).mine.edited;

export const getMineSaved = (state) => g2l(state).mine.saved;

export const getJustSaved = (state) => g2l(state).mine.justSaved;

export const getMineById = (state, id) =>
  getMine(state).reduce((memo, p) => (p.id === id ? p : memo), null);

export const getMyElementIds = createSelector(getMine, (picks) =>
  picks.map((p) => p.element),
);

export const getMyElementsByType = createSelector(
  getMine,
  getElementsById,
  getElementTypes,
  (picks, elements, types) => {
    const keyFromId = (id) => `et${id}`;
    const data = types.reduce(
      (memo, et) => Object.assign(memo, { [keyFromId(et.id)]: [] }),
      {},
    );
    picks.forEach((pick) => {
      const element = elements[pick.element];
      data[keyFromId(element.element_type)].push(element);
    });
    return data;
  },
);

export const getMyStarters = createSelector(
  getMine,
  (state) => getSetting(state, "squad", "play"),
  (picks, play) => picks.slice(0, play),
);

export const getMySubs = createSelector(
  getMine,
  (state) => getSetting(state, "squad", "play"),
  (picks, play) => picks.slice(play),
);

export const getMyTotalsByType = createSelector(
  getMyStarters,
  getElementsById,
  getElementTypes,
  (picks, elements, types) => {
    const typeCounts = types.reduce(
      (memo, et) => Object.assign(memo, { [et.id]: 0 }),
      {},
    );
    return picks.reduce((memo, pick) => {
      // I think this is OK in a reduce!
      // eslint-disable-next-line no-param-reassign
      memo[elements[pick.element].element_type] += 1;
      return memo;
    }, typeCounts);
  },
);

export const getMyFormation = createSelector(getMyTotalsByType, (totals) =>
  Object.keys(totals)
    .map((et) => totals[et])
    .join("-"),
);

export const isSub = (state, pick) =>
  pick.position > getSetting(state, "squad", "play");

export const isLegalSwap = (state, p1, p2, totals) => {
  const p1Type = getElement(state, p1.element).element_type;
  const p2Type = getElement(state, p2.element).element_type;
  const p1Sub = isSub(state, p1);
  const p2Sub = isSub(state, p2);
  const limits = getTeamSelectionLimits(state);
  const locks = getPositionTypeLocks(state);
  let validSwap = false;

  // Swapping starters disallowed
  if (!p1Sub && !p2Sub) return false;

  // Swapping self disallowed
  if (p1.id === p2.id) return false;

  // Can always swap like for like
  if (p1Type === p2Type) return true;

  // Can swap subs unless positions locked
  if (p1Sub && p2Sub) {
    validSwap = true;
  } else if (p1Sub) {
    // Bringing p1 in, p2 out
    validSwap =
      totals[p1Type] < limits[p1Type].max &&
      totals[p2Type] > limits[p2Type].min;
  } else {
    // Bringing p2 in, p1 out
    validSwap =
      totals[p2Type] < limits[p2Type].max &&
      totals[p1Type] > limits[p1Type].min;
  }

  // Also need to handle locked positions!
  if (locks[p1.position] && p2Type !== locks[p1.position]) validSwap = false;
  if (locks[p2.position] && p1Type !== locks[p2.position]) validSwap = false;

  return validSwap;
};

export const getMyPossibleReplacementsForPick = (state, pick1) => {
  const possibles = [];
  const totals = getMyTotalsByType(state);
  getMine(state).forEach((pick2) => {
    if (isLegalSwap(state, pick1, pick2, totals)) {
      possibles.push(pick2);
    }
  });
  return possibles;
};

export const haveMineChanged = createSelector(
  getMine,
  getMineSaved,
  (edited, saved) => {
    // Check that element has same position, is_captain, is_vice_captain
    const savedMap = saved.reduce(
      (memo, p) => Object.assign(memo, { [p.element]: p }),
      {},
    );
    return !edited.every((p) =>
      ["position", "is_captain", "is_vice_captain"].every(
        (attr) => p[attr] === savedMap[p.element][attr],
      ),
    );
  },
);

export const getEditedAPI = createSelector(getMine, (myPicks) => ({
  picks: myPicks.map((p) => ({
    element: p.element,
    position: p.position,
    is_captain: p.is_captain,
    is_vice_captain: p.is_vice_captain,
  })),
}));

export const getHistory = (state) => g2l(state).history;

export const getForEntryEvent = (state, entryId, eventId) => {
  const fullHistory = getHistory(state);
  if (fullHistory[eventId] && fullHistory[eventId][entryId]) {
    return fullHistory[eventId][entryId];
  }
  return [];
};

export const getFormationForEntryEvent = (state, entryId, eventId) => {
  const play = getSetting(state, "squad", "play");
  const starters = getForEntryEvent(state, entryId, eventId).slice(0, play);
  const elementsById = getElementsById(state);
  const types = getElementTypes(state);
  const typeCounts = types.reduce(
    (memo, et) => Object.assign(memo, { [et.id]: 0 }),
    {},
  );
  const totals = starters.reduce((memo, pick) => {
    // I think this is OK in a reduce!
    // eslint-disable-next-line no-param-reassign
    memo[elementsById[pick.element].element_type] += 1;
    return memo;
  }, typeCounts);
  return Object.keys(totals)
    .map((et) => totals[et])
    .join("-");
};

// PropTypes
export const propTypePick = PropTypes.shape({
  element: PropTypes.number,
  entry: PropTypes.number,
  id: PropTypes.number,
  is_captain: PropTypes.bool,
  is_vice_captain: PropTypes.bool,
  multiplier: PropTypes.number,
  position: PropTypes.number,
  purchase_date: PropTypes.string,
});
