import React from "react";
import { connect } from "react-redux";
import {
  getActiveEntry,
  getActiveEntryHubStatus,
  getMyEntries,
  propTypeHubStatus,
} from "../reducers/entries";
import Home from "./home/Home";
import Hub from "./home/Hub";
import Status from "./home/Status";

const HomeChooser = ({ status, ...rest }) => {
  switch (status) {
    case "logged_out":
    case "no_entry":
      return <Home {...rest} status={status} />;
    case "preparing":
    case "drafting":
    case "picked":
      return <Hub {...rest} status={status} />;
    case "points":
    case "waivers":
    case "free_agency":
    case "finished":
      return <Status {...rest} status={status} />;
    default:
      return <div>{status}</div>;
  }
};

HomeChooser.propTypes = {
  status: propTypeHubStatus.isRequired,
};

export { HomeChooser as HomeChooserTest };

const mapStateToProps = (state) => ({
  activeEntry: getActiveEntry(state),
  entries: getMyEntries(state),
  status: getActiveEntryHubStatus(state),
});

export default connect(mapStateToProps)(HomeChooser);
