import PropTypes, { instanceOf } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { elementDialogShow as eds } from "../../../actions/Element";
import {
  approveAcceptedTrade,
  objectAcceptedTrade,
  tradesForApprovalFetch,
} from "../../../actions/Transaction";
import { getElementTypesById } from "../../../reducers/elementTypes";
import { getElementsById } from "../../../reducers/elements";
import {
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../../../reducers/leagueEntries";
import { getActiveLeague, propTypeLeague } from "../../../reducers/leagues";
import { getTeamsById } from "../../../reducers/teams";
import {
  getNextWaiverDate,
  getTradesForApprovalGrouped,
  propTypeTrade,
} from "../../../reducers/transactions";
import formatDateAsLocal, { formatDateAsISO } from "../../../utils/datetime";
import Copy from "../../Copy";
import DeadlineHeader from "../../DeadlineHeader";
import DialogManager from "../../DialogManager";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import TradeActionDialog from "../../transactions/TradeActionDialog";
import TradeResponseButton from "../../transactions/TradeResponseButton";
import TransactionElementGroup from "../../transactions/TransactionElementGroup";
import {
  Response,
  StyledTransaction,
  TransactionUnit,
  TransactionUnitHeading,
} from "../../transactions/styles";
import {
  ActionCell,
  ActionHeading,
  EntryCell,
  TradeApprovalTable,
  TradeCell,
} from "./styles";

const TradeForApproval = ({
  activeLeague,
  approveTrade,
  entriesById,
  objectionLabel,
  objectTrade,
  trade,
  ...rest
}) => {
  const objectionMode = {
    a: (
      <DialogManager
        render={(showDialog, handleShow, handleHide) => (
          <>
            <TradeResponseButton
              actionMe={handleShow}
              isNo
              label={objectionLabel}
              trade={trade}
              {...rest}
            />
            {showDialog && (
              <TradeActionDialog
                buttonLabel={`${objectionLabel}`}
                closeDialog={handleHide}
                header="Veto trade?"
                onConfirm={objectTrade}
                tradeId={trade.id}
                tradeItems={trade.tradeitem_set}
                {...rest}
              >
                <p>
                  Please confirm that you would like to veto the following
                  trade:
                </p>
              </TradeActionDialog>
            )}
          </>
        )}
      />
    ),
    m: (
      <DialogManager
        render={(showDialog, handleShow, handleHide) => (
          <>
            <TradeResponseButton
              actionMe={handleShow}
              isNo
              label={objectionLabel}
              trade={trade}
              {...rest}
            />
            {showDialog && (
              <TradeActionDialog
                buttonLabel={`${objectionLabel}`}
                closeDialog={handleHide}
                header="Object to trade?"
                onConfirm={objectTrade}
                tradeId={trade.id}
                tradeItems={trade.tradeitem_set}
                {...rest}
              >
                <p>
                  Please confirm that you would like to object to the following
                  trade:
                </p>
              </TradeActionDialog>
            )}
          </>
        )}
      />
    ),
  };
  return (
    <tr>
      <EntryCell>
        <LeagueEntryShortName entryId={trade.offered_entry} />
      </EntryCell>
      <ActionCell>
        <StyledTransaction>
          <TransactionUnit>
            <TransactionUnitHeading isIn={true}>Offered</TransactionUnitHeading>
            <TransactionElementGroup elementIds={trade.elementsOut} {...rest} />
          </TransactionUnit>
          <TransactionUnit>
            <TransactionUnitHeading>Requested</TransactionUnitHeading>
            <TransactionElementGroup elementIds={trade.elementsIn} {...rest} />
          </TransactionUnit>
        </StyledTransaction>
      </ActionCell>
      <EntryCell>
        <LeagueEntryShortName entryId={trade.received_entry} />
      </EntryCell>
      <ActionCell>
        <Response>
          <DialogManager
            render={(showDialog, handleShow, handleHide) => (
              <>
                <TradeResponseButton
                  actionMe={handleShow}
                  label="Approve"
                  trade={trade}
                  {...rest}
                />
                {showDialog && (
                  <TradeActionDialog
                    buttonLabel="Approve"
                    closeDialog={handleHide}
                    header="Approve Trade?"
                    onConfirm={approveTrade}
                    tradeId={trade.id}
                    tradeItems={trade.tradeitem_set}
                    {...rest}
                  >
                    <p>
                      Please confirm that you would like to approve the
                      following trade:
                    </p>
                  </TradeActionDialog>
                )}
              </>
            )}
          />
          {objectionMode[activeLeague.trades]}
        </Response>
      </ActionCell>
    </tr>
  );
};

TradeForApproval.propTypes = {
  activeLeague: propTypeLeague.isRequired,
  approveTrade: PropTypes.func.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  objectionLabel: PropTypes.string.isRequired,
  objectTrade: PropTypes.func.isRequired,
  trade: propTypeTrade.isRequired,
};

const TradesForApprovalTable = ({ objectionLabel, trades, ...rest }) => (
  <Box mb={4}>
    <TradeApprovalTable>
      <thead>
        <tr>
          <EntryCell as="th">Manager 1</EntryCell>
          <TradeCell as="th">Trade</TradeCell>
          <EntryCell as="th">Manager 2</EntryCell>
          <ActionHeading>Approve?</ActionHeading>
        </tr>
      </thead>
      <tbody>
        {trades.map((trade) => (
          <TradeForApproval
            key={trade.id}
            objectionLabel={objectionLabel}
            trade={trade}
            {...rest}
          />
        ))}
      </tbody>
    </TradeApprovalTable>
  </Box>
);

TradesForApprovalTable.propTypes = {
  objectionLabel: PropTypes.string.isRequired,
  trades: PropTypes.arrayOf(propTypeTrade).isRequired,
};

const TradesForApproval = ({
  activeLeague,
  nextWaiverDate,
  trades,
  ...rest
}) => {
  const objectionMode = {
    a: (
      <div>
        <DeadlineHeader
          deadlineISO={formatDateAsISO(nextWaiverDate)}
          deadlineLocal={formatDateAsLocal(nextWaiverDate)}
          deadlineText="Trades must be vetoed before"
          headingText="Trades Awaiting Approval"
        />
        <TradesForApprovalTable
          activeLeague={activeLeague}
          objectionLabel="Veto"
          trades={trades}
          {...rest}
        />
      </div>
    ),
    m: (
      <div>
        <DeadlineHeader
          deadlineISO={formatDateAsISO(nextWaiverDate)}
          deadlineLocal={formatDateAsLocal(nextWaiverDate)}
          deadlineText="Trades must be objected to before"
          headingText="Trades Awaiting Approval"
        />
        <TradesForApprovalTable
          activeLeague={activeLeague}
          objectionLabel="Object"
          trades={trades}
          {...rest}
        />
      </div>
    ),
  };
  return (
    <div>
      {trades.length > 0 ? (
        objectionMode[activeLeague.trades]
      ) : (
        <Copy>
          <p>There are no trade decisions to be made.</p>
        </Copy>
      )}
    </div>
  );
};

TradesForApproval.propTypes = {
  activeLeague: propTypeLeague.isRequired,
  nextWaiverDate: instanceOf(Date).isRequired,
  trades: PropTypes.arrayOf(propTypeTrade).isRequired,
};

export { TradesForApproval as TradesForApprovalTest };

class TradesForApprovalContainer extends React.Component {
  componentDidMount() {
    this.props.fetchTradesForApproval();
  }

  render() {
    if (!this.props.nextWaiverDate) {
      return null;
    }
    return <TradesForApproval {...this.props} />;
  }
}

TradesForApprovalContainer.propTypes = {
  fetchTradesForApproval: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  elementsById: getElementsById(state),
  entriesById: getActiveLeagueEntriesByEntryId(state),
  activeLeague: getActiveLeague(state),
  nextWaiverDate: getNextWaiverDate(state),
  teamsById: getTeamsById(state),
  trades: getTradesForApprovalGrouped(state),
  typesById: getElementTypesById(state),
});

const idFromEvent = (e) =>
  parseInt(e.currentTarget.getAttribute("data-id"), 10);

const mapDispatchToProps = {
  approveTrade: (e) => approveAcceptedTrade(idFromEvent(e)),
  elementDialogShow: eds,
  fetchTradesForApproval: tradesForApprovalFetch,
  objectTrade: (e) => objectAcceptedTrade(idFromEvent(e)),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TradesForApprovalContainer);
