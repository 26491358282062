import styled from "styled-components/macro";

export const TradeButton = styled.button`
  display: block;
  width: 100%;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border: 0;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.lightPurple};
  color: #fff;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  transition: all 0.2s;
`;

export const TradeButtonLabel = styled.span`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: inline;
  }
`;
