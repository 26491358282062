import styled, { css } from "styled-components/macro";
import { IEntryRow } from "./types";

const EntryRow = styled.tr<IEntryRow>`
  ${({ isMe, theme }) =>
    isMe &&
    css`
      td {
        background-color: ${theme.colors.primary};
        color: white;
        > a {
          color: ${theme.colors.fantasy};
        }
      }
    `}
`;

export default EntryRow;
