import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { NumberInput } from "../../FieldRenderers";
import { DragCell, PriorityCell, StyledRow } from "./styles";
import { RowProps } from "./types";

const Row: React.ForwardRefExoticComponent<RowProps> = forwardRef(
  (
    {
      changeAction,
      dragHandle,
      children,
      id,
      isDragging,
      maxPriority,
      priority,
      style,
      ...rest
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const changePriority = (
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement>,
    ) => {
      if (event instanceof KeyboardEvent && event.key !== "Enter") return;
      dispatch(
        changeAction(
          Number(event.currentTarget.defaultValue),
          Number(event.currentTarget.value),
        ),
      );
    };

    return (
      <StyledRow {...rest} ref={ref} style={style} isDragging={isDragging}>
        <DragCell>{dragHandle}</DragCell>
        <PriorityCell>
          <NumberInput
            type="number"
            defaultValue={priority}
            key={priority}
            min="1"
            max={maxPriority}
            name={`${String(priority)}-${id}`}
            onBlur={changePriority}
            onKeyPress={changePriority}
          />
        </PriorityCell>
        {children}
      </StyledRow>
    );
  },
);

export default Row;
