import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { reduxForm } from "redux-form";
import { elementDialogShow as eds } from "../../../actions/Element";
import { suggestElementTransaction } from "../../../actions/Transaction";
import { watchlistAdd, watchlistRemove } from "../../../actions/Watchlist";
import {
  getElementTypes,
  getElementTypesById,
  propTypeElementType,
} from "../../../reducers/elementTypes";
import { propTypeElement } from "../../../reducers/elements";
import { getTeamsById, propTypeTeam } from "../../../reducers/teams";
import {
  getAvailableWatchlist,
  getTopElementsByForm,
} from "../../../reducers/transactions";
import {
  getNext3EventsFixturesByTeam,
  propTypeEventTeamFixture,
} from "../../../store/fixtures/slice";
import { getShortNameFromId } from "../../../utils/events";
import { fixtureSuffix } from "../../../utils/fixtures";
import { ButtonHyperlink } from "../../ButtonLink";
import ElementInTable from "../../ElementInTable";
import ElementStatusButton from "../../ElementStatusButton";
import { TeamShortName } from "../../ElementTable";
import {
  Form,
  FormControl,
  FormInfo,
  ReduxFormSelect,
  SelectWrap,
} from "../../FieldRenderers";
import { PatternWrapMain } from "../../GraphicPatterns";
import SubHeading from "../../SubHeading";
import { PrimaryCell, StatusCell, TableWrap } from "../../Table";
import WatchlistButton from "../../WatchlistButton";
import { PrimaryIcon, SquadButton, SquadButtonText } from "../../squad/styles";
import { VisuallyHidden } from "../../styles";
import {
  TopAvailableAction,
  TopAvailableElement,
  TopAvailableFixture,
  TopAvailableForm,
  TopAvailableFormWrap,
  TopAvailableHeader,
  TopAvailableLinkWrap,
  TopAvailableStatus,
  TopAvailableTable,
} from "./styles";

const TopAvailableElementRow = ({
  addToWatchlist,
  element,
  elementDialogShow,
  elementTypesById,
  next3Fixtures,
  proposeMe,
  removeFromWatchlist,
  teamsById,
  watchlist,
}) => (
  <tr>
    <StatusCell>
      <ElementStatusButton
        elementId={element.id}
        elementDialogShow={elementDialogShow}
      />
    </StatusCell>
    <PrimaryCell>
      <ElementInTable elementId={element.id} />
    </PrimaryCell>
    <TopAvailableForm as="td">{element.form}</TopAvailableForm>
    {next3Fixtures.map((eventFixtures) => (
      <TopAvailableFixture as="td" key={eventFixtures.event.id}>
        <ul>
          {eventFixtures.fixtures[element.team].map((f) => (
            <li key={f.id}>
              <TeamShortName>{teamsById[f.opponent].short_name}</TeamShortName>
              {fixtureSuffix(f.id, f.isHome)}
            </li>
          ))}
        </ul>
      </TopAvailableFixture>
    ))}
    <TopAvailableAction as="td">
      <WatchlistButton elementId={element.id} />
    </TopAvailableAction>
    <TopAvailableAction as="td">
      <SquadButton
        variant="primary"
        type="button"
        onClick={proposeMe}
        data-id={element.id}
      >
        <SquadButtonText>Sign</SquadButtonText>
        <PrimaryIcon>
          <use xlinkHref="#ism-add" />
        </PrimaryIcon>
      </SquadButton>
    </TopAvailableAction>
  </tr>
);

TopAvailableElementRow.propTypes = {
  addToWatchlist: PropTypes.func.isRequired,
  element: propTypeElement.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementTypesById: PropTypes.objectOf(propTypeElementType).isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  proposeMe: PropTypes.func.isRequired,
  removeFromWatchlist: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  watchlist: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const TopAvailableElements = ({
  addToWatchlist,
  elementDialogShow,
  elementTypes,
  elementTypesById,
  next3Fixtures,
  proposeMe,
  removeFromWatchlist,
  teamsById,
  topElementsByForm,
  watchlist,
}) => (
  <div>
    <SubHeading>Top Available Players</SubHeading>
    <PatternWrapMain>
      <Box mb={2}>
        <TopAvailableHeader>
          <TopAvailableFormWrap>
            <Form isInline={true} onSubmit={(e) => e.preventDefault()}>
              <FormInfo>
                <label htmlFor="ism-el-type-select">Position:</label>
              </FormInfo>
              <FormControl>
                <SelectWrap>
                  <ReduxFormSelect
                    name="filter"
                    component="select"
                    id="ism-el-type-select"
                  >
                    <option value="0">All Positions</option>
                    {elementTypes.map((et) => (
                      <option key={et.id} value={et.id}>
                        {et.plural_name}
                      </option>
                    ))}
                  </ReduxFormSelect>
                </SelectWrap>
              </FormControl>
            </Form>
          </TopAvailableFormWrap>
          <TopAvailableLinkWrap>
            <ButtonHyperlink to="/team/transactions" variant="light">
              Transactions
            </ButtonHyperlink>
          </TopAvailableLinkWrap>
        </TopAvailableHeader>
      </Box>
      <TableWrap>
        <TopAvailableTable>
          <thead>
            <tr>
              <TopAvailableStatus>
                <VisuallyHidden>Status</VisuallyHidden>
              </TopAvailableStatus>
              <TopAvailableElement>Player</TopAvailableElement>
              <TopAvailableForm>Form</TopAvailableForm>
              {next3Fixtures.map((fixture) => (
                <TopAvailableFixture key={fixture.event.id}>
                  {getShortNameFromId(fixture.event.id)}
                </TopAvailableFixture>
              ))}
              <TopAvailableAction>Watch</TopAvailableAction>
              <TopAvailableAction>Sign</TopAvailableAction>
            </tr>
          </thead>
          <tbody>
            {topElementsByForm.map((element) => (
              <TopAvailableElementRow
                addToWatchlist={addToWatchlist}
                key={element.id}
                element={element}
                elementDialogShow={elementDialogShow}
                elementTypesById={elementTypesById}
                next3Fixtures={next3Fixtures}
                proposeMe={proposeMe}
                removeFromWatchlist={removeFromWatchlist}
                teamsById={teamsById}
                watchlist={watchlist}
              />
            ))}
          </tbody>
        </TopAvailableTable>
      </TableWrap>
    </PatternWrapMain>
  </div>
);

TopAvailableElements.propTypes = {
  addToWatchlist: PropTypes.func.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementTypes: PropTypes.arrayOf(propTypeElementType).isRequired,
  elementTypesById: PropTypes.objectOf(propTypeElementType).isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  proposeMe: PropTypes.func.isRequired,
  removeFromWatchlist: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  topElementsByForm: PropTypes.arrayOf(propTypeElement).isRequired,
  watchlist: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => ({
  elementDialogShow: eds,
  elementTypes: getElementTypes(state),
  elementTypesById: getElementTypesById(state),
  next3Fixtures: getNext3EventsFixturesByTeam(state),
  teamsById: getTeamsById(state),
  topElementsByForm: getTopElementsByForm(state),
  watchlist: getAvailableWatchlist(state),
});

const idFromEvent = (e) =>
  parseInt(e.currentTarget.getAttribute("data-id"), 10);

const mapDispatchToProps = {
  addToWatchlist: (e) => watchlistAdd(idFromEvent(e)),
  elementDialogShow: eds,
  proposeMe: (e) => suggestElementTransaction(idFromEvent(e)),
  removeFromWatchlist: (e) => watchlistRemove(idFromEvent(e)),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "topAvailableElementsFilter",
  })(TopAvailableElements),
);
