import React, { forwardRef } from "react";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Drag } from "../../../img/icons/drag.svg";
import { IDragButton, IDragHandle } from "./types";

const StyledDrag = styled(Drag)`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.theme.colors.darkGrey};
`;

const DragButton = styled.button<IDragButton>`
  padding: 0;
  border: none;
  background-color: transparent;

  ${(props) =>
    props.isDragging &&
    css`
      cursor: grab;
    `}
`;

const DragHandle: React.ForwardRefExoticComponent<IDragHandle> = forwardRef(
  ({ isDragging = false, ...props }, ref) => {
    return (
      <DragButton {...props} ref={ref} isDragging={isDragging}>
        <StyledDrag />
      </DragButton>
    );
  },
);

export default DragHandle;
