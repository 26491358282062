import { FETCH_EVENT_STATUS_SUCCESS } from "./ActionTypes";
import { fetchGet, handleUnexpectedError } from "../helpers";

export const fetchEventStatusSuccess = (data) => ({
  type: FETCH_EVENT_STATUS_SUCCESS,
  data,
});

export const fetchEventStatus = () => (dispatch) => {
  const url = "/api/pl/event-status";
  return fetchGet(url).then(
    (d) => dispatch(fetchEventStatusSuccess(d.data)),
    (e) => handleUnexpectedError(e, dispatch),
  );
};
