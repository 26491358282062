import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { Field } from "redux-form";
import { getTeams } from "../reducers/teams";
import { ITeam } from "../store/teams/types";
import {
  CheckboxField,
  Fieldset,
  Hint,
  InputField,
  Legend,
  SelectField,
} from "./FieldRenderers";

interface IProps {
  hideFaveTeam?: boolean;
  teams?: ITeam[];
}

const EntryFields: React.FC<IProps> = ({ hideFaveTeam, teams }) => (
  <>
    <Fieldset>
      <Legend>Team</Legend>
      <Box mb={4}>
        <Field
          id="entryName"
          name="entryName"
          component={InputField}
          type="text"
          label="Team Name"
          maxLength="20"
        />
      </Box>
      {!hideFaveTeam && teams && (
        <Box mb={4}>
          <Field
            id="entryFaveTeam"
            name="entryFaveTeam"
            component={SelectField}
            label="Favourite Team"
          >
            <option value="">Please select</option>
            {teams.map((t) => (
              <option value={t.id} key={t.id}>
                {t.name}
              </option>
            ))}
            <option value="0">None</option>
          </Field>
        </Box>
      )}
    </Fieldset>

    <Fieldset>
      <Box mb={1}>
        <Legend>Game Notifications</Legend>
      </Box>
      <Hint>
        Receive emails about your draft, league and trades. You can unsubscribe
        at any time.
      </Hint>
      <Box mb={4}>
        <Field
          id="entryEmail"
          name="entryEmail"
          component={CheckboxField}
          type="checkbox"
          label="Yes, I would like to receive game notifications via email"
        />
      </Box>
    </Fieldset>
  </>
);

const validateEntryFields = (values: any) => {
  interface IErrors {
    [key: string]: string;
  }
  const errors: IErrors = {};

  // Team validation
  if (!values.entryName) {
    errors.entryName = "Required";
  }
  if (!values.entryFaveTeam && values.entryFaveTeam !== 0) {
    errors.entryFaveTeam = "Required";
  }

  // Entry validation
  if (!values.entryTerms) {
    errors.entryTerms = "Required";
  }

  return errors;
};

const mapStateToProps = (state: any) => ({
  teams: getTeams(state),
});

export { validateEntryFields, EntryFields as EntryFieldsTest };

export default connect(mapStateToProps, null)(EntryFields);
