import { rgba } from "polished";
import styled from "styled-components/macro";
import { PitchUnit } from "../Pitch/styles";

export const StyledBench = styled.div`
  max-width: 410px;
  margin: auto;
  margin-top: ${(props) => props.theme.space[2]};
  background-image: linear-gradient(to bottom, transparent 60%, white);

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: 620px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: none;
    padding: 0 ${(props) => props.theme.space[2]};
  }
`;

export const BenchInner = styled.div`
  position: relative;
  padding: ${(props) => props.theme.space[1]} 0;
  border-radius: ${({ theme }) => `${theme.radii[0]} ${theme.radii[0]}`} 0 0;
  background: ${rgba("white", 0.25)};
  background-color: rgba(114, 207, 159, 0.9);
  box-shadow: 0 8px 12px 0 ${rgba("black", 0.08)};
  background-image: linear-gradient(to bottom, transparent 60%, white);

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[3]} 0;
  }
`;

export const StyledBenchUnit = styled(PitchUnit)`
  padding-top: ${(props) => props.theme.space[1]};
  padding-bottom: ${(props) => props.theme.space[4]};

  :first-of-type {
    position: relative;
    padding-right: 0.1rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: 3.2rem;
  }
`;
