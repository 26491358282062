import styled, { css } from "styled-components/macro";
import { IChatMessage } from "./types";
import { wordWrap } from "polished";

export const StyledChatMessages = styled.div`
  height: 220px;
  max-height: 220px;
  margin: ${(props) => `0 ${props.theme.space[2]} ${props.theme.space[4]}`};
  padding: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.grey};
  background-color: #fff;
  overflow-y: scroll;
`;

export const ChatMessage = styled.li<IChatMessage>`
  position: relative;
  margin-bottom: ${(props) => props.theme.space[2]};
  padding: ${(props) => props.theme.space[2]};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.lightGrey};

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    transform: translateY(-50%);
    border-width: ${(props) => props.theme.space[2]};
    border-style: solid;
    border-color: transparent;
    z-index: 1;
  }

  ${({ isMe, theme }) =>
    isMe
      ? css`
          margin-right: ${theme.space[2]};

          &::before {
            right: -${theme.space[4]};
            border-left-color: ${theme.colors.lightGrey};
          }
        `
      : css`
          margin-left:: ${theme.space[2]};

          &::before {
            left:: -${theme.space[4]};
            border-right-color: ${theme.colors.lightGrey};
          }
        `}
`;

export const ChatMessageHeading = styled.h4`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-size: ${(props) => props.theme.fontSizes[1]};
`;

export const ChatMessageName = styled.span`
  padding-right: ${(props) => props.theme.space[1]};
`;

export const ChatMessageTime = styled.span`
  font-family: ${(props) => props.theme.fonts.regular};
`;

export const ChatMessageText = styled.p`
  ${wordWrap("break-word")}
  font-size: ${(props) => props.theme.fontSizes[0]};
`;
