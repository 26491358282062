import { darken, size } from "polished";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components/macro";
import {
  elementDialogShow as eds,
  fetchTopElements,
} from "../../../actions/Element";
import leftArrow from "../../../img/icons/chevron-left.svg";
import rightArrow from "../../../img/icons/chevron-right.svg";
import { ReactComponent as DreamTeamIcon } from "../../../img/icons/dreamteam.svg";
import {
  getElementsById,
  getTopElements,
  propTypeElement,
  propTypeTopElement,
} from "../../../reducers/elements";
import {
  getCurrentEvent,
  getEvents,
  propTypeEvent,
} from "../../../reducers/events";
import { getShortNameFromId } from "../../../utils/events";
import ElementShirt from "../../ElementShirt";
import Panel from "../../Panel";
import PlaceholderShirt from "../../PlaceholderShirt";
import { UnstyledButton } from "../../styles";
import { StyledTopElement } from "./styles";

const StyledSlider = styled(Slider)`
  min-height: 0px;
  min-width: 0px;
  margin: -1rem;
  padding: 2rem 2.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-right: 3.2rem;
    padding-left: 3.2rem;
  }

  .slick-prev {
    left: 0;
    border-radius: ${({ theme }) => `0 ${theme.radii[1]} ${theme.radii[1]} 0`};

    ::before {
      background: url(${leftArrow});
    }
  }

  .slick-next {
    right: 0;
    border-radius: ${({ theme }) => `${theme.radii[1]} 0 0 ${theme.radii[1]}`};

    ::before {
      background: url(${rightArrow});
    }
  }

  .slick-prev,
  .slick-next {
    top: 40%;
    bottom: auto;
    width: auto;
    height: auto;
    transition: all 0.2s;
    padding: 0.8rem 1.2rem;
    background-color: #edf1f9;

    :hover,
    :focus {
      background-color: ${darken(0.05, "#edf1f9")};
    }

    ::before {
      ${size(15, 9)};
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 14px 24px;
    }

    .slick-disabled {
      cursor: default;

      :hover,
      :focus {
        border-color: #ebebeb;
        background-color: #f6f6f5;
      }
    }

    .slick-list,
    .slick-track {
      min-height: 0px;
      min-width: 0px;
    }
  }
`;

export const TopElementName = styled.div`
  padding: 0.3rem 0.2rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

export const TopElementValue = styled.div`
  padding: 0.2rem;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  border-radius: 0 0 3px 3px;
  font-size: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

export const TopElementValueInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
`;

const Gameweek = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const Points = styled.span`
  margin-left: 1rem;
`;

export const EventTopElement = ({
  elementDialogShow,
  elementsById,
  event,
  topElement,
}) => {
  let element;
  let totalPoints;
  if (topElement) {
    element = elementsById[topElement.element];
    if (element) {
      totalPoints = topElement.total_points;
    }
  }

  // In case of gw being postponed and no top element ...
  const nullEvents = [];
  const shirtSizes = "(min-width: 1024px) 55px, (min-width: 610px) 44px, 37px";
  return element && nullEvents.indexOf(event.id) === -1 ? (
    <StyledTopElement>
      <UnstyledButton
        type="button"
        onClick={() => elementDialogShow(element.id)}
      >
        <ElementShirt
          elementId={element.id}
          hasShadow={true}
          sizes={shirtSizes}
        />
        <div>
          <TopElementName>{element.web_name}</TopElementName>
          <TopElementValue>
            <TopElementValueInner>
              <Gameweek>{getShortNameFromId(event.id)}</Gameweek>
              <Points>{`${totalPoints} pts`}</Points>
            </TopElementValueInner>
          </TopElementValue>
        </div>
      </UnstyledButton>
    </StyledTopElement>
  ) : (
    <StyledTopElement>
      <div>
        <PlaceholderShirt sizes={shirtSizes} hasShadow={true} useBlank={true} />
        <div>
          <TopElementName>-</TopElementName>
          <TopElementValue>
            <Gameweek>{getShortNameFromId(event.id)}</Gameweek>
          </TopElementValue>
        </div>
      </div>
    </StyledTopElement>
  );
};

EventTopElement.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  event: propTypeEvent.isRequired,
  topElement: propTypeTopElement,
};

EventTopElement.defaultProps = {
  topElement: null,
};

const TopElementCarousel = ({ events, now, topElements, ...rest }) => {
  const getInitialSlide = (slidesToShow) =>
    now && now.id > slidesToShow ? now.id - slidesToShow : 0;

  const settings = {
    infinite: true,
    initialSlide: getInitialSlide(6),
    responsive: [
      {
        breakpoint: 400,
        settings: {
          initialSlide: getInitialSlide(3),
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          initialSlide: getInitialSlide(4),
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          initialSlide: getInitialSlide(5),
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          initialSlide: getInitialSlide(4),
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          initialSlide: getInitialSlide(5),
          slidesToShow: 5,
        },
      },
    ],
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <Panel
      headingText={
        <Flex alignItems="center">
          <DreamTeamIcon />
          <Box ml={2}>2024/25 Player of the Week</Box>
        </Flex>
      }
    >
      <StyledSlider {...settings}>
        {events.map((event) => (
          <div key={event.id}>
            <EventTopElement
              topElement={topElements[event.id]}
              event={event}
              {...rest}
            />
          </div>
        ))}
      </StyledSlider>
    </Panel>
  );
};

TopElementCarousel.propTypes = {
  events: PropTypes.arrayOf(propTypeEvent).isRequired,
  now: propTypeEvent,
  topElements: PropTypes.objectOf(propTypeTopElement).isRequired,
};

TopElementCarousel.defaultProps = {
  now: null,
};

export { TopElementCarousel as TopElementCarouselTest };

class TopElementCarouselContainer extends React.Component {
  componentDidMount() {
    this.props.fetchTopElements();
  }

  render() {
    return <TopElementCarousel {...this.props} />;
  }
}

TopElementCarouselContainer.propTypes = {
  fetchTopElements: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  elementsById: getElementsById(state),
  events: getEvents(state),
  now: getCurrentEvent(state),
  topElements: getTopElements(state),
});

const mapDispatchToProps = {
  elementDialogShow: eds,
  fetchTopElements,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopElementCarouselContainer);
