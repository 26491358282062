import styled from "styled-components";
import { IStyledElementStatusBar } from "./types";

export const StyledElementStatusBar = styled.div<IStyledElementStatusBar>`
  padding: 0.4rem ${(props) => props.theme.space[3]};
  background-color: ${(props) =>
    props.theme.colors.elementStatus[props.copnr].bg};
  color: ${(props) => props.theme.colors.elementStatus[props.copnr].color};
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const ElementStatusBarContent = styled.div`
  color: currentColor;
  line-height: 1.5;
`;
