import styled from "styled-components/macro";
import Table from "../../Table";

export const StatusCell = styled.th`
  width: 15%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 10%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 7%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 7%;
  }
`;

export const ElementCell = styled.th`
  width: 50%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 35%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 28%;
  }
`;

export const SortStatCell = styled.th`
  width: 15%;
  font-family: ${(props) => props.theme.fonts.bold};

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 10%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 14%;
  }
`;

export const FixturesCell = styled.th`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: table-cell;
    width: 10%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 11%;
  }
`;

export const ActionCell = styled.th`
  width: 20%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 15%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 18%;
  }
`;

export const StyledTransactionRemoveTable = styled(Table)`
  table-layout: fixed;

  th,
  td {
    :not(:nth-child(2)) {
      text-align: center;
    }
  }

  ${StatusCell} {
    padding: 0;
  }

  ${ElementCell} {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
`;
