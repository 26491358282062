import { Link } from "@gatsbyjs/reach-router";
import { rgba } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { IButtonStyledProps } from "./Button";

const ButtonLinkStyles = css<IButtonStyledProps>`
  display: inline-block;
  padding: ${({ theme }) => theme.space[3]};
  border: 0;
  box-shadow: 0 1px 8px 0 ${rgba("black", 0.12)};
  border-radius: ${({ theme }) => theme.radii[0]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  color: inherit;
  text-decoration: none;
  text-align: center;

  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background: ${({ theme }) => theme.colors.lightPurple};
      color: white;
      box-shadow: 0 1px 8px 0 ${rgba("black", 0.06)};
    `}

	${(props) =>
    props.variant === "light" &&
    css`
      padding-top: ${({ theme }) => theme.space[2]};
      padding-bottom: ${({ theme }) => theme.space[2]};
      background: ${rgba("white", 0.75)};
      color: ${({ theme }) => theme.colors.primary};
    `}
`;

export const ButtonHyperlink = styled.a<IButtonStyledProps>`
  ${ButtonLinkStyles}
`;

const StyledButtonLink = styled(Link)<IButtonStyledProps>`
  ${ButtonLinkStyles}
`;

interface IProps {
  onClick?: () => void;
  to: string;
  children: React.ReactNode;
}

type Props = IProps & IButtonStyledProps;

const ButtonLink: React.FC<Props> = ({
  children,
  fullwidth,
  onClick,
  to,
  variant = "primary",
}) => (
  <StyledButtonLink
    to={to}
    onClick={onClick}
    variant={variant}
    fullwidth={fullwidth}
  >
    {children}
  </StyledButtonLink>
);

export default ButtonLink;
