import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { elementDialogShow } from "../actions/Element";
import { refreshFixturesForEvent } from "../actions/Fixture";
import {
  actionSubstitution,
  myTeamDataFetch,
  updatePicks,
} from "../actions/Pick";
import { getElementTypesById } from "../reducers/elementTypes";
import { getElementsById, propTypeElement } from "../reducers/elements";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
  propTypeEvent,
} from "../reducers/events";
import {
  getJustSaved,
  getMine,
  getMyFormation,
  haveMineChanged,
  propTypePick,
} from "../reducers/picks";
import { getTeamsById, propTypeTeam } from "../reducers/teams";
import {
  getNext3EventsFixturesByTeam,
  getNextEventFixturesByTeam,
  propTypeEventTeamFixture,
} from "../store/fixtures/slice";
import { formatRawAsISO, formatRawAsLocal } from "../utils/datetime";
import { fixtureSuffix } from "../utils/fixtures";
import Alert from "./Alert";
import Button from "./Button";
import { ButtonGroup, ButtonGroupItem } from "./ButtonGroup";
import DeadlineBar from "./DeadlineBar";
import Dialog from "./Dialog";
import { ConfirmDialogBody } from "./Dialog/styles";
import { Glass, PatternWrapMain } from "./GraphicPatterns";
import HelmetHead from "./HelmetHead";
import { Main, Secondary, Wrapper } from "./Layout";
import ListView from "./ListView";
import PitchView from "./PitchView";
import SubmitBar from "./SubmitBar";
import Tabs from "./Tabs";
import TabPanel from "./Tabs/TabPanel";
import Title from "./Title";
import Entry from "./entry/Entry";
import Fixtures from "./fixtures/Fixtures";

class MyTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixturesEvent: this.props.nextEvent || this.props.currentEvent,
      pickForMenu: null,
    };
    this.handleShowMenuForPick = this.handleShowMenuForPick.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  componentDidMount() {
    this.props.fetchMyTeamData();
  }

  onChangeFixturesEvent(id) {
    this.props.refreshFixtures(id);
    this.setState({ fixturesEvent: this.props.eventsById[id] });
  }

  handleShowMenuForPick(element, pick) {
    this.setState({ pickForMenu: pick });
  }

  handleHide() {
    this.setState({ pickForMenu: null });
  }

  renderMenu() {
    const pick = this.state.pickForMenu;
    if (!pick) return null;
    const element = this.props.elementsById[pick.element];
    return (
      <Dialog closeDialog={this.handleHide}>
        <Dialog.Header closeDialog={this.handleHide}>
          {element.first_name} {element.second_name}
        </Dialog.Header>
        <Dialog.Body>
          <ConfirmDialogBody>
            <ButtonGroup>
              {pick.subStatus !== "invalid" && (
                <ButtonGroupItem>
                  <Button
                    variant="secondary"
                    fullwidth="true"
                    onClick={() => {
                      this.props.actionSubstitution(pick.id);
                      this.handleHide();
                    }}
                  >
                    {pick.subStatus === "instigator" ? "Cancel" : "Switch"}
                  </Button>
                </ButtonGroupItem>
              )}
              <ButtonGroupItem>
                <Button
                  variant="tertiary"
                  fullwidth="true"
                  onClick={() => {
                    this.props.elementDialogShow(element.id);
                    this.handleHide();
                  }}
                >
                  View Information
                </Button>
              </ButtonGroupItem>
            </ButtonGroup>
          </ConfirmDialogBody>
        </Dialog.Body>
      </Dialog>
    );
  }

  render() {
    const {
      elementsById,
      eventsById,
      fetchMyTeamData,
      next3Fixtures,
      nextEvent,
      justSaved,
      picks,
      picksChanged,
      save,
      teamsById,
      ...rest
    } = this.props;

    const nextFixture = next3Fixtures[0];

    // I'm not sure this is ever called, one in PitchElementData seems to be used
    const renderElementData = (element) => {
      if (nextFixture && nextFixture.fixtures[element.team].length) {
        const elementFixtures = nextFixture.fixtures[element.team].map(
          (f) =>
            `${teamsById[f.opponent].short_name} ${fixtureSuffix(
              f.id,
              f.isHome,
            )}`,
        );
        return <span>{elementFixtures.join(", ")}</span>;
      }
      return "";
    };

    const renderExtraListViewHeaders = () => [
      { content: "F", key: "f" },
      { content: "GP", key: "gp" },
      { content: "TP", key: "tp" },
      { content: "FIX", key: "fix" },
    ];

    const renderExtraListViewCells = (element) => [
      { content: element.form, key: "f" },
      { content: element.event_points, key: "ep" },
      { content: element.total_points, key: "tp" },
      {
        content:
          nextFixture && nextFixture.fixtures[element.team].length ? (
            <ul key={element.id}>
              {nextFixture.fixtures[element.team].map((f) => (
                <li key={f.id}>
                  {teamsById[f.opponent].short_name}
                  {fixtureSuffix(f.id, f.isHome)}
                </li>
              ))}
            </ul>
          ) : (
            ""
          ),
        key: "nf",
      },
    ];

    return (
      <Wrapper>
        <HelmetHead
          title="Pick Your Fantasy Football Draft Team 2024/25"
          description="To pick your Fantasy Premier League Draft team, visit the official website of the Premier League."
        />
        <Main>
          <Box mx={2}>
            <Title>My Team</Title>
          </Box>

          <PatternWrapMain>
            {nextEvent && (
              <Glass>
                <DeadlineBar
                  deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
                  deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
                  heading={nextEvent.name}
                  label={nextEvent.name}
                />
              </Glass>
            )}

            {picks.length && (
              <Box mx={2}>
                <Tabs isCentered={true}>
                  <TabPanel label="Pitch View" link="pitch">
                    <Box mx={-2} pt={4}>
                      <PitchView
                        elementsById={elementsById}
                        teamsById={teamsById}
                        picks={picks}
                        renderElementData={renderElementData}
                        renderElementMenu={this.handleShowMenuForPick}
                        {...rest}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel label="List View" link="list">
                    <Box mx={-2} my={4}>
                      <ListView
                        elementsById={elementsById}
                        menuLinkTitle="Manage pick"
                        nextFixture={next3Fixtures[0]}
                        picks={picks}
                        teamsById={teamsById}
                        renderElementMenu={this.handleShowMenuForPick}
                        renderExtraListViewCells={renderExtraListViewCells}
                        renderExtraListViewHeaders={renderExtraListViewHeaders}
                        {...rest}
                      />
                    </Box>
                  </TabPanel>
                </Tabs>
              </Box>
            )}
          </PatternWrapMain>

          <SubmitBar
            buttonText="Save Your Team"
            clickMe={save}
            isDisabled={!picksChanged}
          />

          {justSaved && (
            <Box mx={2} role="status" aria-live="polite">
              <Alert canFocus>Your team has been saved</Alert>
            </Box>
          )}

          <Fixtures />
        </Main>
        <Secondary>
          <Entry />
        </Secondary>
        {this.renderMenu()}
      </Wrapper>
    );
  }
}

MyTeam.propTypes = {
  actionSubstitution: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  fetchMyTeamData: PropTypes.func.isRequired,
  justSaved: PropTypes.bool.isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  eventsById: PropTypes.objectOf(propTypeEvent).isRequired,
  currentEvent: propTypeEvent,
  nextEvent: propTypeEvent,
  picks: PropTypes.arrayOf(propTypePick).isRequired,
  picksChanged: PropTypes.bool.isRequired,
  refreshFixtures: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

MyTeam.defaultProps = {
  currentEvent: null,
  nextEvent: null,
};

export { MyTeam as MyTeamTest };

const mapStateToProps = (state) => ({
  elementsById: getElementsById(state),
  elementTypesById: getElementTypesById(state),
  eventsById: getEventsById(state),
  eventFixtures: getNextEventFixturesByTeam(state),
  formation: getMyFormation(state),
  justSaved: getJustSaved(state),
  next3Fixtures: getNext3EventsFixturesByTeam(state),
  currentEvent: getCurrentEvent(state),
  nextEvent: getNextEvent(state),
  picks: getMine(state),
  picksChanged: haveMineChanged(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = {
  actionSub: (e) =>
    actionSubstitution(parseInt(e.currentTarget.getAttribute("data-id"), 10)),
  actionSubstitution,
  elementDialogShow,
  fetchMyTeamData: myTeamDataFetch,
  refreshFixtures: refreshFixturesForEvent,
  save: updatePicks,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);
