import PropTypes from "prop-types";
import { propTypePick } from "../reducers/picks";
import { PitchRow, PitchUnit } from "./Pitch/styles";
import PitchElement from "./PitchElement";

const ThreeFourThree = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
    </PitchRow>
  </>
);

const ThreeFiveTwo = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
      <PitchUnit />
    </PitchRow>
  </>
);

const FourThreeThree = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
    </PitchRow>
  </>
);

const FourFourTwo = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
      <PitchUnit />
    </PitchRow>
  </>
);

const FourFiveOne = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
  </>
);

const FiveTwoThree = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
    </PitchRow>
  </>
);

const FiveFourOne = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
      <PitchUnit>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit />
      <PitchUnit />
      <PitchUnit>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
      <PitchUnit />
      <PitchUnit />
    </PitchRow>
  </>
);

const FiveThreeTwo = ({ picks, ...rest }) => (
  <>
    <PitchRow>
      <PitchUnit unitsInRow={5} />
      <PitchUnit unitsInRow={5} />
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[0]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5} />
      <PitchUnit unitsInRow={5} />
    </PitchRow>
    <PitchRow>
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[1]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[2]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[3]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[4]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[5]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit unitsInRow={3}>
        <PitchElement pick={picks[6]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={3}>
        <PitchElement pick={picks[7]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={3}>
        <PitchElement pick={picks[8]} {...rest} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit unitsInRow={5} />
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[9]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5} />
      <PitchUnit unitsInRow={5}>
        <PitchElement pick={picks[10]} {...rest} />
      </PitchUnit>
      <PitchUnit unitsInRow={5} />
    </PitchRow>
  </>
);

const formationPropTypes = {
  picks: PropTypes.arrayOf(propTypePick).isRequired,
};

ThreeFourThree.propTypes = {
  ...formationPropTypes,
};

ThreeFiveTwo.propTypes = {
  ...formationPropTypes,
};

FourThreeThree.propTypes = {
  ...formationPropTypes,
};

FourFourTwo.propTypes = {
  ...formationPropTypes,
};

FourFiveOne.propTypes = {
  ...formationPropTypes,
};

FiveTwoThree.propTypes = {
  ...formationPropTypes,
};

FiveThreeTwo.propTypes = {
  ...formationPropTypes,
};

FiveFourOne.propTypes = {
  ...formationPropTypes,
};

const PitchFormation = ({ formation, ...rest }) => {
  const formations = {
    "1-3-4-3": <ThreeFourThree {...rest} />,
    "1-3-5-2": <ThreeFiveTwo {...rest} />,
    "1-4-3-3": <FourThreeThree {...rest} />,
    "1-4-4-2": <FourFourTwo {...rest} />,
    "1-4-5-1": <FourFiveOne {...rest} />,
    "1-5-2-3": <FiveTwoThree {...rest} />,
    "1-5-3-2": <FiveThreeTwo {...rest} />,
    "1-5-4-1": <FiveFourOne {...rest} />,
  };

  return <>{formations[formation]}</>;
};

PitchFormation.propTypes = {
  formation: PropTypes.oneOf([
    "1-3-4-3",
    "1-3-5-2",
    "1-4-3-3",
    "1-4-4-2",
    "1-4-5-1",
    "1-5-2-3",
    "1-5-3-2",
    "1-5-4-1",
  ]).isRequired,
};

export default PitchFormation;
