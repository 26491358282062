import React from "react";
import { connect } from "react-redux";
import { formValueSelector, getFormSubmitErrors, reduxForm } from "redux-form";
import { createLeague as onSubmit } from "../actions/League";
import { getLastDraftDate } from "../reducers/events";
import { getSetting } from "../reducers/settings";
import { getDefaultTZ } from "../utils/datetime";
import Alert from "./Alert";
import Button from "./Button";
import Copy from "./Copy";
import EntryFields, { validateEntryFields } from "./EntryFields";
import Form from "./FocusErrorsForm";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import LeagueFields, { validateLeagueFields } from "./LeagueFields";
import TermsFields, { validateTermsFields } from "./TermsFields";
import Title from "./Title";

interface IProps {
  error?: string;
  handleSubmit: () => void;
  leagueTradesValue?: string;
  submitErrors: object;
  timeZoneValue?: string;
  valid: boolean;
}

const CreateLeague: React.FC<IProps> = ({
  error,
  handleSubmit,
  leagueTradesValue,
  submitErrors,
  timeZoneValue,
  valid,
}) => (
  <Wrapper>
    {/* eslint-disable max-len */}
    <HelmetHead
      title="Create or Join - Fantasy Premier League Draft 2024/25"
      description="Create or join a league in Fantasy Premier League Draft, visit the official website of the Premier League."
    />
    <Main>
      <Copy>
        <Title>Create Your League</Title>

        <Form onSubmit={handleSubmit} errors={submitErrors} error={error}>
          {error && (
            <Alert type="error" canFocus>
              {error}
            </Alert>
          )}

          <EntryFields />

          <LeagueFields
            hidePickTime
            leagueTradesValue={leagueTradesValue}
            timeZoneValue={timeZoneValue}
          />

          <TermsFields />

          <Button type="submit" disabled={!valid}>
            Create League
          </Button>
        </Form>
      </Copy>
    </Main>
  </Wrapper>
);

const validate = (values: any, ownProps: any) => {
  const errors = {};

  Object.assign(errors, validateEntryFields(values));

  Object.assign(errors, validateLeagueFields(values, ownProps.lastDraftDate));

  Object.assign(errors, validateTermsFields(values));

  return errors;
};

export { validate, CreateLeague as CreateLeagueTest };

const formName = "CreateLeague";
const valueSelector = formValueSelector(formName);

const mapStateToProps = (state: any) => ({
  initialValues: {
    leagueDraftTZ: getDefaultTZ(),
    leagueMinSize: 2,
    leagueMaxSize: getSetting(state, "league", "default_entries"),
    leagueScoring: "h",
  },
  lastDraftDate: getLastDraftDate(state),
  leagueTradesValue: valueSelector(state, "leagueTrades"),
  timeZoneValue: valueSelector(state, "leagueDraftTZ"),
  submitErrors: getFormSubmitErrors("createLeague")(state),
});

const mapDispatchToProps = {
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    touchOnChange: true,
    destroyOnUnmount: false,
    form: formName,
    validate,
  })(CreateLeague),
);
