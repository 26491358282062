import styled, { css } from "styled-components/macro";
import { IStyledBenchHeading } from "./types";

export const StyledBenchHeading = styled.h4<IStyledBenchHeading>`
  position: absolute;
  bottom: 0.2rem;
  width: 25%;
  color: #61605c;
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-align: center;

  ${(props) =>
    props.position === 1 &&
    css`
      left: 0;
    `}

  ${(props) =>
    props.position === 2 &&
    css`
      left: 25%;
    `}

    ${(props) =>
    props.position === 3 &&
    css`
      left: 50%;
    `}

    ${(props) =>
    props.position === 4 &&
    css`
      left: 75%;
    `}

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    bottom: 1.5rem;
    font-size: ${(props) => props.theme.fontSizes[4]};
  }
`;

export const BenchAbbr = styled.abbr`
  border-bottom: 0;
`;
