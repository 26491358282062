import styled from "styled-components/macro";
import pattern1437 from "../../../img/pattern/pattern-1-437.png";
import pattern1874 from "../../../img/pattern/pattern-1-874.png";
import { hiDPI } from "polished";

export const HubWrap = styled.div`
  padding: 0 ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const HubCtaWrap = styled.div`
  padding: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const HubUnit = styled.div`
  :not(:last-child) {
    margin-bottom: ${(props) => props.theme.space[4]};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 calc(50% - ${(props) => props.theme.space[2]});

    :not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export const HubUnitScroll = styled.div`
  height: 260px;
  overflow: auto;
`;

export const HubCta = styled.div`
  padding-bottom: 3.6rem;
  border-radius: ${(props) => props.theme.radii[1]};
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(${pattern1437}), url(${pattern1437});
  background-position:
    -76px -10px,
    right -220px bottom -120px;
  background-repeat: no-repeat;
  background-size: 366px 415px;
  text-align: center;

  ${hiDPI(2)} {
    background-image: url(${pattern1874}), url(${pattern1874});
  }
`;

export const HubCtaHeading = styled.h3`
  margin: ${({ theme }) => `0 ${theme.space[3]} ${theme.space[3]}`};
  padding: 0.8rem ${(props) => props.theme.space[2]};
  border-radius: 0 0 1rem 1rem;
  font-size: inherit;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.fantasy},
    ${(props) => props.theme.colors.lightBlue}
  );
  line-height: 1;
  text-align: center;
`;

export const HubButtonWrap = styled.div`
  margin-top: ${(props) => props.theme.space[4]};
  margin-bottom: ${(props) => props.theme.space[4]};
  padding: 0 10%;
`;

export const HubList = styled.ul`
  margin: ${(props) => props.theme.space[2]} 0;
  padding: 0 ${(props) => props.theme.space[2]};
`;

export const HubListItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export const HubListHeading = styled.h5`
  flex: 1;
  padding: ${(props) => props.theme.space[2]};
  font-family: inherit;
  font-size: inherit;
  text-align: right;
`;

export const HubListValue = styled.div`
  flex: 1;
  padding: ${(props) => props.theme.space[2]};
  font-family: ${(props) => props.theme.fonts.bold};
`;
