import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { changeEntry } from "../../actions/Entry";
import {
  getActiveEntry,
  getMyEntries,
  propTypeEntry,
} from "../../reducers/entries";
import { getCurrentEvent, propTypeEvent } from "../../reducers/events";
import { getActiveLeague, propTypeLeague } from "../../reducers/leagues";
import { getTeam, propTypeTeam } from "../../reducers/teams";
import ActiveEntrySelect from "../ActiveEntrySelect";
import BoldLink, { BoldHyperlink, BoldLinkWrap } from "../BoldLink";
import { ButtonBox } from "../Button";
import { ButtonGroup, ButtonGroupItem } from "../ButtonGroup";
import ButtonLink from "../ButtonLink";
import Panel from "../Panel";
import { PanelCentredContent } from "../Panel/Panel";
import { PanelBadge } from "../Panel/styles";
import EntryBar from "./EntryBar";
import EntrySummary from "./EntrySummary";
import EntryTransactions from "./EntryTransactions";

const Entry = ({
  activeLeague,
  currentEvent,
  entries,
  entry,
  favouriteTeam,
  ownEntry,
}) => {
  if (!entry) return null;
  const app = "pldpl-web";
  const plusers = process.env.REACT_APP_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const update = `${plusers}/accounts/manage/?app=${app}&redirect_uri=${redirect}`;

  const getBadgePath = (code, density = 1) =>
    `//resources.premierleague.com/premierleague/badges/100/t${code}${
      density === 2 ? "@x2" : ""
    }.png`;

  return (
    <div>
      <Panel headingText={<EntryBar entry={entry} />}>
        <EntrySummary entry={entry} currentEvent={currentEvent} />
      </Panel>
      {favouriteTeam && (
        <Panel hasGutters headingText="Favourite Club">
          <PanelCentredContent>
            <PanelBadge
              srcSet={`
                  ${getBadgePath(favouriteTeam.code)},
                  ${getBadgePath(favouriteTeam.code, 2)} 2x
                `}
              alt={favouriteTeam.name}
              title={favouriteTeam.name}
            />
          </PanelCentredContent>
        </Panel>
      )}
      {ownEntry && entries.length > 1 && (
        <Box mb={4}>
          <ActiveEntrySelect />
        </Box>
      )}
      {ownEntry && (
        <ButtonBox>
          <ButtonLink to="/entry/join-create" fullwidth="true">
            Create or Join Leagues
          </ButtonLink>
        </ButtonBox>
      )}
      <EntryTransactions entry={entry} />
      {ownEntry && (
        <Panel hasGutters headingText="Admin">
          <ButtonGroup>
            <ButtonGroupItem>
              <BoldLinkWrap>
                <BoldLink to={`/entry/${entry.id}/edit`}>Team Details</BoldLink>
              </BoldLinkWrap>
            </ButtonGroupItem>
            {activeLeague.can_admin && (
              <ButtonGroupItem>
                <BoldLinkWrap>
                  <BoldLink to={`/league/${activeLeague.id}/edit`}>
                    Edit League
                  </BoldLink>
                </BoldLinkWrap>
              </ButtonGroupItem>
            )}
            <ButtonGroupItem>
              <BoldLinkWrap>
                <BoldHyperlink href={update} fullwidth="true">
                  User Profile
                </BoldHyperlink>
              </BoldLinkWrap>
            </ButtonGroupItem>
          </ButtonGroup>
        </Panel>
      )}
    </div>
  );
};

Entry.propTypes = {
  activeLeague: propTypeLeague.isRequired,
  currentEvent: propTypeEvent,
  entry: propTypeEntry.isRequired,
  entries: PropTypes.arrayOf(propTypeEntry).isRequired,
  favouriteTeam: propTypeTeam,
  ownEntry: PropTypes.bool.isRequired,
};

Entry.defaultProps = {
  currentEvent: null,
  favouriteTeam: null,
};

export { Entry as EntryTest };

const mapStateToProps = (state, ownProps) => {
  // Work out if we are looking at one of our own entries
  const entry = ownProps.entry || getActiveEntry(state) || {};
  const entries = getMyEntries(state);
  const ownEntry = entries.reduce(
    (memo, e) => (e.id === entry.id ? true : memo),
    false,
  );
  const favouriteTeam = getTeam(state, entry.favourite_team);
  return {
    activeLeague: getActiveLeague(state),
    currentEvent: getCurrentEvent(state),
    entries,
    entry,
    favouriteTeam,
    ownEntry,
  };
};

const mapDispatchToProps = {
  handleActiveEntryChange: (e) => changeEntry(e.target.value),
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
