import styled from "styled-components/macro";
import Table from "../../Table";

export const StyledTradeSummaryTable = styled(Table)`
  table-layout: fixed;
`;

export const TradeSummaryColumn = styled.th`
  width: 50%;
`;

export const TradeSummaryName = styled.span`
  margin-right: ${(props) => props.theme.space[1]};
`;
