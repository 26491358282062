import { Box } from "rebass/styled-components";
import { Field } from "redux-form";
import { SelectField } from "./FieldRenderers";

const LeagueSizeField = () => (
  <Box mb={4}>
    <Field
      id="leagueSize"
      name="leagueSize"
      component={SelectField}
      label="League Size"
      parse={(value) => parseInt(value, 10)}
    >
      <option value="">Please Select</option>
      <option value="4">4</option>
      <option value="6">6</option>
      <option value="8">8</option>
    </Field>
  </Box>
);

const validateLeagueSizeField = (values) => {
  const errors = {};

  // League Scoring validation
  if (!values.leagueSize) {
    errors.leagueSize = "Required";
  }

  return errors;
};

export { validateLeagueSizeField, LeagueSizeField as LeagueSizeFieldTest };
export default LeagueSizeField;
