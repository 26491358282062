import { navigate } from "@gatsbyjs/reach-router";
import {
  ADD_ELEMENT_EVENT_STATS,
  ADD_ENTRY_EVENT_PICKS,
  ADD_ENTRY_EVENT_SUBS,
  ADD_MY_PICKS,
  ADD_PUBLIC_ENTRY,
  SUBSTITUTION_PROCESS,
  SUBSTITUTION_START,
  SUBSTITUTION_STOP,
} from "./ActionTypes";
import { checkGame } from "../store/bootstrap/actions";
import { updateFixtures } from "../store/fixtures/slice";
import { fetchGet, fetchPost, handleUnexpectedError } from "../helpers";
import { getElementsById } from "../reducers/elements";
import {
  getEditedAPI,
  getMine,
  getMyPossibleReplacementsForPick,
} from "../reducers/picks";
import { getActiveEntryId } from "../reducers/player";
import { getSetting } from "../reducers/settings";

export const addEntryEventPicks = (picks, entry, event) => ({
  type: ADD_ENTRY_EVENT_PICKS,
  data: {
    picks,
    entry,
    event,
  },
});

export const addEntryEventSubs = (subs, entry, event) => ({
  type: ADD_ENTRY_EVENT_SUBS,
  data: {
    subs,
    entry,
    event,
  },
});

export const addMyPicks = (picks, elementsById, saved = false) => ({
  type: ADD_MY_PICKS,
  data: {
    picks,
    elementsById,
    saved,
  },
});

export const addElementStatsForEvent = (event, stats) => ({
  type: ADD_ELEMENT_EVENT_STATS,
  data: {
    event,
    stats,
  },
});

export const addPublicEntry = (entry) => ({
  type: ADD_PUBLIC_ENTRY,
  data: {
    entry,
  },
});

export const processSubstitution = (pick, subStart) => ({
  type: SUBSTITUTION_PROCESS,
  data: {
    pick,
    subStart,
  },
});

export const startSubstitution = (pick, possibles) => ({
  type: SUBSTITUTION_START,
  data: {
    pick,
    possibles,
  },
});

export const stopSubstitution = () => ({
  type: SUBSTITUTION_STOP,
});

export const myTeamDataFetch = () => (dispatch, getState) => {
  dispatch(checkGame());
  const entryId = getActiveEntryId(getState());
  return fetchGet(`/api/entry/${entryId}/my-team`).then(
    (d) => {
      dispatch(addMyPicks(d.data.picks, getElementsById(getState())));
    },
    (e) => {
      if (e.reason === "NOT_2XX" && e.response.status === 403) {
        if (e.error.detail && e.error.detail === "League is processing") {
          return navigate("/processing", { replace: true });
        }
      }
      return handleUnexpectedError(e, dispatch);
    },
  );
};

export const actionSubstitution = (pickId) => (dispatch, getState) => {
  const pick = getMine(getState()).reduce(
    (memo, p) => (p.id === pickId ? p : memo),
    null,
  );
  if (!pick) return null;
  if (pick.subStatus === "") {
    dispatch(
      startSubstitution(
        pick,
        getMyPossibleReplacementsForPick(getState(), pick),
      ),
    );
  } else if (pick.subStatus === "instigator") {
    dispatch(stopSubstitution());
  } else if (pick.subStatus === "target") {
    dispatch(
      processSubstitution(pick, getSetting(getState(), "squad", "play") + 1),
    );
  }
  return null;
};

export const updatePicks = () => (dispatch, getState) =>
  fetchPost(
    `/api/entry/${getActiveEntryId(getState())}/my-team`,
    getEditedAPI(getState()),
  ).then(
    (d) =>
      dispatch(addMyPicks(d.data.picks, getElementsById(getState()), true)),
    (e) => handleUnexpectedError(e, dispatch),
  );

export const entryPublicFetch = (entryId) => (dispatch) =>
  new Promise((resolve) => {
    fetchGet(`/api/entry/${entryId}/public`).then(
      (d) => {
        dispatch(addPublicEntry(d.data.entry));
        resolve();
      },
      (e) => {
        handleUnexpectedError(e, dispatch);
        resolve();
      },
    );
  });

export const entryEventPicksFetch = (entryId, eventId) => (dispatch) => {
  dispatch(checkGame());
  Promise.all([
    fetchGet(`/api/entry/${entryId}/event/${eventId}`),
    fetchGet(`/api/event/${eventId}/live`),
    dispatch(entryPublicFetch(entryId)),
  ]).then(
    (d) => {
      dispatch([
        addEntryEventPicks(d[0].data.picks, entryId, eventId),
        addEntryEventSubs(d[0].data.subs, entryId, eventId),
        addElementStatsForEvent(eventId, d[1].data.elements),
        updateFixtures({
          event: eventId,
          fixtures: d[1].data.fixtures,
        }),
      ]);
    },
    (e) => handleUnexpectedError(e, dispatch),
  );
};
