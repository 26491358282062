import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { elementDialogShow as eds } from "../actions/Element";
import { eventTradesFetch } from "../actions/Transaction";
import { getElementsById, propTypeElement } from "../reducers/elements";
import {
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../reducers/leagueEntries";
import { getActiveLeague } from "../reducers/leagues";
import {
  getFilteredTradesForLeagueGrouped,
  propTypeTrade,
} from "../reducers/transactions";
import { formatRawAsDate } from "../utils/datetime";
import { getShortNameFromId } from "../utils/events";
import getTradeStateName from "../utils/getTradeStateName";
import { LinkButton } from "./LinkButton";
import SubHeading from "./SubHeading";
import { StackTable } from "./styles";

const LeagueTradesHistoryRow = ({
  elementDialogShow,
  elementsById,
  offeredEntry,
  receivedEntry,
  trade,
}) => {
  // https://stackoverflow.com/questions/23618744/rendering-comma-separated-list-of-links
  const getElementNames = (elements) =>
    elements.map((e, i) => (
      <React.Fragment key={e}>
        {!!i && ", "}
        <LinkButton onClick={() => elementDialogShow(e)}>
          <span>{elementsById[e].web_name}</span>
        </LinkButton>
      </React.Fragment>
    ));
  // const getElementNames = elements => elements.map(e => elementsById[e].web_name).join(', ');
  return (
    <tr>
      <td data-label="GW">{getShortNameFromId(trade.event, true)}</td>
      <td data-label="Offered By">
        {offeredEntry.player_first_name} {offeredEntry.player_last_name}
      </td>
      <td data-label="Offered To">
        {receivedEntry.player_first_name} {receivedEntry.player_last_name}
      </td>
      <td data-label="Offered">{getElementNames(trade.elementsIn)}</td>
      <td data-label="Requested">{getElementNames(trade.elementsOut)}</td>
      <td data-label="Result">{getTradeStateName(trade.state)}</td>
      <td data-label="Date">{formatRawAsDate(trade.offer_time)}</td>
    </tr>
  );
};

LeagueTradesHistoryRow.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  offeredEntry: propTypeLeagueEntry.isRequired,
  receivedEntry: propTypeLeagueEntry.isRequired,
  trade: propTypeTrade.isRequired,
};
class LeagueTradesHistory extends Component {
  componentDidMount() {
    this.props.fetchEventTradesData();
  }
  render() {
    const { elementDialogShow, elementsById, entriesById, trades } = this.props;

    return (
      <div>
        <SubHeading>Trades History</SubHeading>
        <Box mb={4}>
          <StackTable>
            <thead>
              <tr>
                <th>GW</th>
                <th>Offered By</th>
                <th>Offered To</th>
                <th>Offered</th>
                <th>Requested</th>
                <th>Result</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {trades.length ? (
                trades.map((t) => (
                  <LeagueTradesHistoryRow
                    elementDialogShow={elementDialogShow}
                    elementsById={elementsById}
                    key={t.id}
                    offeredEntry={entriesById[t.offered_entry]}
                    receivedEntry={entriesById[t.received_entry]}
                    trade={t}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <p className="ism-align-center">
                      No trades have been made.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </StackTable>
        </Box>
      </div>
    );
  }
}

LeagueTradesHistory.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  fetchEventTradesData: PropTypes.func.isRequired,
  trades: PropTypes.arrayOf(propTypeTrade).isRequired,
};

export { LeagueTradesHistory as LeagueTradesHistoryTest };

const mapStateToProps = (state) => {
  const league = getActiveLeague(state);
  return {
    elementsById: getElementsById(state),
    entriesById: getActiveLeagueEntriesByEntryId(state),
    trades: league ? getFilteredTradesForLeagueGrouped(state, league.id) : [],
  };
};

const mapDispatchToProps = {
  elementDialogShow: eds,
  fetchEventTradesData: eventTradesFetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeagueTradesHistory);
