import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { Box } from "rebass/styled-components";
import { VisuallyHidden } from "../styles";
import {
  CloseButton,
  CloseIcon,
  DialogOverlay,
  DialogTitle,
  StyledDialog,
  StyledDialogHeader,
} from "./styles";

const isVisible = (elem) =>
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

const DialogHeader = ({ children, closeDialog }) => {
  return (
    <StyledDialogHeader>
      <Box mb={4}>
        <DialogTitle id="ism-dialog-title">{children}</DialogTitle>
      </Box>
      <CloseButton onClick={closeDialog}>
        <VisuallyHidden>Close</VisuallyHidden>
        <CloseIcon>
          <use xlinkHref="#ism-close" />
        </CloseIcon>
      </CloseButton>
    </StyledDialogHeader>
  );
};

DialogHeader.propTypes = {
  children: PropTypes.node.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

const DialogBody = ({ children }) => <div>{children}</div>;

DialogBody.propTypes = {
  children: PropTypes.node.isRequired,
};

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleOutsideMouseClick = this.handleOutsideMouseClick.bind(this);
    this.focusReturn = document.activeElement;
    this.docref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown);
    this.docref.current.querySelector(this.props.focusable).focus();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
    if (this.focusReturn.focus) {
      this.focusReturn.focus();
    }
  }

  handleKeydown(e) {
    if (e.keyCode === 27) {
      this.props.closeDialog();
    } else if (e.keyCode === 9) {
      this.handleTab(e);
    }
  }

  handleOutsideMouseClick(e) {
    if (e.target === e.currentTarget) {
      this.props.closeDialog();
      e.stopPropagation();
    }
  }

  handleTab(e) {
    const focusedItem = document.activeElement;
    const focusableItems = Array.prototype.slice
      .call(this.docref.current.querySelectorAll(this.props.focusable))
      .filter(isVisible);
    const numFocusableItems = focusableItems.length;
    const focusedIndex = focusableItems.indexOf(focusedItem);

    if (!e.shiftKey && focusedIndex === numFocusableItems - 1) {
      // Moving past last focusable item so focus first
      focusableItems[0].focus();
      e.preventDefault();
    } else if (e.shiftKey && focusedIndex === 0) {
      // Moving before first focusable item so focus last
      focusableItems[numFocusableItems - 1].focus();
      e.preventDefault();
    }
  }

  render() {
    const { isElementDialog = false } = this.props;
    return ReactDOM.createPortal(
      <DialogOverlay onClick={this.handleOutsideMouseClick} role="presentation">
        <StyledDialog
          aria-labelledby="ismjs-dialog-title"
          isElementDialog={isElementDialog}
          open
        >
          <div role="document" ref={this.docref}>
            {this.props.children}
          </div>
        </StyledDialog>
      </DialogOverlay>,
      document.getElementById("root-dialog"),
    );
  }
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  closeDialog: PropTypes.func.isRequired,
  focusable: PropTypes.string,
};

Dialog.defaultProps = {
  focusable:
    "a[href], area[href], input:not([disabled]), " +
    "select:not([disabled]), textarea:not([disabled]), " +
    "button:not([disabled]), iframe, object, embed, " +
    "*[tabindex], *[contenteditable]",
};

Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;

export default Dialog;
