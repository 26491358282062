import { fetchGet, handleUnexpectedError } from "../helpers";
import { updateFixtures } from "../store/fixtures/slice";

// thunk action creators
export const refreshFixturesForEvent = (eventId) => (dispatch) =>
  fetchGet(`/api/event/${eventId}/fixtures`).then(
    (d) => dispatch(updateFixtures({ event: eventId, fixtures: d.data })),
    (error) => handleUnexpectedError(error, dispatch),
  );

export default refreshFixturesForEvent;
