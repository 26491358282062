import "@tanstack/react-table";
import {
  Row,
  RowData,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { elementDialogShow } from "../../../actions/Element";
import { getSortStat } from "../../../reducers/elements";
import { getNext3Events } from "../../../reducers/events";
import { IElement } from "../../../store/elements/types";
import { getNext3EventsFixturesByTeam } from "../../../store/fixtures/slice";
import ElementEventFixtures from "../../ElementEventFixtures";
import ElementInTable from "../../ElementInTable";
import ElementStatus from "../../ElementStatus";
import Tooltip, { TooltipLabel } from "../../Tooltip";
import WatchlistButton from "../../WatchlistButton";
import { VisuallyHidden } from "../../styles";
import ElementAction from "../ElementAction";
import {
  DragBar,
  DragIcon,
  SquadElementTableWrap,
  SquadPlayersTable,
} from "./styles";
import { IDraftableElement, ISquadPlayers } from "./types";

declare module "@tanstack/table-core" {
  // https://tanstack.com/table/latest/docs/api/core/column-def#meta
  // Had to disable this as couldn't see where we use TData and TValue but couldn't get the interface to work without them
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    className: string;
  }
}

const SquadPlayers: React.FC<ISquadPlayers> = ({
  actionMe,
  actionLabel,
  elements,
  isDisabled = false,
}) => {
  const sortStat = useSelector(getSortStat);
  const next3EventsFixturesByTeam = useSelector(getNext3EventsFixturesByTeam);
  const next3Events = useSelector(getNext3Events);

  const dispatch = useDispatch();

  const columnHelper = createColumnHelper<IDraftableElement>();

  const helperForEvent = (key: "event1" | "event2" | "event3") =>
    columnHelper.accessor(key, {
      cell: (info) => (
        <ElementEventFixtures
          fixtures={info.getValue().fixtures}
          teamId={info.getValue().element.team}
        />
      ),
      header: (ctx) => `GW${next3Events[ctx.header.index - 3].id}`,
      meta: {
        className: "opp-col",
      },
    });

  const helperForEvents = [
    helperForEvent("event1"),
    helperForEvent("event2"),
    helperForEvent("event3"),
  ].slice(0, next3Events.length);

  // TODO: Fix inference of type when elements is type IElement[] instead of any
  const data: IDraftableElement[] = elements.map((e: IElement) => ({
    elementId: e.id,
    sortStat: sortStat ? e[sortStat.name] : null,
    ...next3EventsFixturesByTeam.reduce(
      (acc, cur, i) => ({
        ...acc,
        [`event${i + 1}`]: {
          element: e,
          ...cur,
        },
      }),
      {},
    ),
  }));

  const columns = [
    columnHelper.accessor("elementId", {
      id: "info",
      cell: (info) => (
        <button
          type="button"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => dispatch(elementDialogShow(info.getValue()))}
          className="ism-table--el__status-link"
        >
          <ElementStatus elementId={info.getValue()} />
        </button>
      ),
      header: () => <VisuallyHidden>Info</VisuallyHidden>,
    }),
    columnHelper.accessor("elementId", {
      header: "Player",
      cell: (info) => <ElementInTable elementId={info.getValue()} />,
    }),
    columnHelper.accessor("sortStat", {
      header: () => (
        <Tooltip content={sortStat.label}>
          <span>
            <TooltipLabel>{sortStat.abbreviation}</TooltipLabel>
          </span>
        </Tooltip>
      ),
    }),
    ...helperForEvents,
    columnHelper.accessor("elementId", {
      id: "watch",
      header: "Watchlist",
      cell: (info) => <WatchlistButton elementId={info.getValue()} />,
    }),
    columnHelper.accessor("elementId", {
      id: "sign",
      header: "Sign",
      cell: (info) => (
        <ElementAction
          actionMe={actionMe}
          actionLabel={actionLabel}
          elementId={info.getValue()}
          isDisabled={isDisabled}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 55,
    overscan: 5,
  });

  const paddingTop =
    rowVirtualizer.getVirtualItems().length > 0
      ? rowVirtualizer.getVirtualItems()?.[0]?.start || 0
      : 0;
  const paddingBottom =
    rowVirtualizer.getVirtualItems().length > 0
      ? rowVirtualizer.getTotalSize() -
        (rowVirtualizer.getVirtualItems()?.[
          rowVirtualizer.getVirtualItems().length - 1
        ]?.end || 0)
      : 0;

  return (
    <>
      <SquadElementTableWrap ref={tableContainerRef}>
        <SquadPlayersTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={header.column.columnDef.meta?.className}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<IDraftableElement>;
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={cell.column.columnDef.meta?.className}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </SquadPlayersTable>
      </SquadElementTableWrap>
      <DragBar>
        <DragIcon />
      </DragBar>
    </>
  );
};

export default SquadPlayers;
