import styled from "styled-components/macro";

export const StyledDraftRound = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  background-color: ${(props) => props.theme.colors.primary};
`;

export const DraftRoundName = styled.h3`
  color: ${(props) => props.theme.colors.fantasy};
  font-size: 1.7rem;
`;

export const DraftRoundText = styled.p`
  margin-right: ${(props) => props.theme.space[2]};
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes[1]};
`;

export const DraftRoundTextRound = styled.strong`
  color: ${(props) => props.theme.colors.fantasy};
`;
