import { createSelector } from "reselect";
import { getElementTypes } from "./elementTypes";
import { ADD_SETTINGS } from "../actions/ActionTypes";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.settings;
export const l2g = (local) => ({ settings: local });

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SETTINGS: {
      return action.data;
    }

    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getAllSettings = (state) => g2l(state);

export const getSettingSection = (state, section) =>
  getAllSettings(state)[section];

export const getSetting = (state, section, field) =>
  getSettingSection(state, section)[field];

export const getPositionTypeLocks = createSelector(
  getElementTypes,
  (state) => getSetting(state, "squad", "position_type_locks"),
  (types, locks) => {
    const typeMap = types.reduce(
      (memo, et) => Object.assign(memo, { [et.singular_name_short]: et.id }),
      {},
    );
    return Object.keys(locks || []).reduce(
      (memo, position) =>
        Object.assign(memo, { [position]: typeMap[locks[position]] }),
      {},
    );
  },
);

export const getSquadSelectionLimits = createSelector(
  getElementTypes,
  (state) => getSettingSection(state, "squad"),
  (types, settings) =>
    types.reduce(
      (memo, et) =>
        Object.assign(memo, {
          [et.id]: settings[`select_${et.singular_name_short}`],
        }),
      {},
    ),
);

export const getTeamSelectionLimits = createSelector(
  getElementTypes,
  (state) => getSettingSection(state, "squad"),
  (types, settings) =>
    types.reduce(
      (memo, et) =>
        Object.assign(memo, {
          [et.id]: {
            min: settings[`min_play_${et.singular_name_short}`],
            max: settings[`max_play_${et.singular_name_short}`],
          },
        }),
      {},
    ),
);
