import PropTypes from "prop-types";
import React from "react";
import { propTypeTrade } from "../../reducers/transactions";
import DialogManager from "../DialogManager";
import TradeActionDialog from "./TradeActionDialog";
import TradeResponseButton from "./TradeResponseButton";
import { Response } from "./styles";

const TradeResponse = ({
  noAction,
  noHeader,
  noLabel,
  noMessage,
  trade,
  yesAction,
  yesHeader,
  yesLabel,
  yesMessage,
  ...rest
}) => (
  <Response>
    <DialogManager
      render={(showDialog, handleShow, handleHide) => (
        <React.Fragment>
          <TradeResponseButton
            actionMe={handleShow}
            label={yesLabel}
            trade={trade}
            {...rest}
          />
          {showDialog && (
            <TradeActionDialog
              buttonLabel={`${yesLabel}`}
              closeDialog={handleHide}
              header={yesHeader}
              onConfirm={yesAction}
              tradeId={trade.id}
              tradeItems={trade.tradeitem_set}
              {...rest}
            >
              <p>{yesMessage}</p>
            </TradeActionDialog>
          )}
        </React.Fragment>
      )}
    />
    <DialogManager
      render={(showDialog, handleShow, handleHide) => (
        <React.Fragment>
          <TradeResponseButton
            actionMe={handleShow}
            isNo
            label={noLabel}
            trade={trade}
            {...rest}
          />
          {showDialog && (
            <TradeActionDialog
              buttonLabel={`${noLabel}`}
              closeDialog={handleHide}
              header={noHeader}
              onConfirm={noAction}
              tradeId={trade.id}
              tradeItems={trade.tradeitem_set}
              {...rest}
            >
              <p>{noMessage}</p>
            </TradeActionDialog>
          )}
        </React.Fragment>
      )}
    />
  </Response>
);

TradeResponse.propTypes = {
  noAction: PropTypes.func.isRequired,
  noHeader: PropTypes.string.isRequired,
  noLabel: PropTypes.string.isRequired,
  noMessage: PropTypes.string.isRequired,
  trade: propTypeTrade.isRequired,
  yesAction: PropTypes.func.isRequired,
  yesHeader: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  yesMessage: PropTypes.string.isRequired,
};

export default TradeResponse;
