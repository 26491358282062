import { formatInTimeZone } from "date-fns-tz";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { formValueSelector, reduxForm } from "redux-form";
import { deleteLeague, editLeague } from "../actions/League";
import { SELECT_DRAFT_DATE_TIME_FORMAT } from "../constants";
import { getLastDraftDate } from "../reducers/events";
import {
  getLeagueEntries,
  propTypeLeagueEntry,
} from "../reducers/leagueEntries";
import { getLeague, propTypeLeague } from "../reducers/leagues";
import Button from "./Button";
import ConfirmDialog from "./ConfirmDialog";
import Copy from "./Copy";
import DialogManager from "./DialogManager";
import { Main, Wrapper } from "./Layout";
import LeagueFields, { validateLeagueFields } from "./LeagueFields";
import Title from "./Title";

const EditLeague = ({
  confirmDelete,
  dirty,
  extraFields,
  error,
  handleSubmit,
  league,
  leagueEntries,
  leagueTradesValue,
  timeZoneValue,
  valid,
}) => (
  <Wrapper>
    <Main>
      <Copy>
        <Title>Edit {league.name}</Title>
        <Box mb={4}>
          <form onSubmit={handleSubmit}>
            {error && <p>{error}</p>}
            <LeagueFields
              league={league}
              leagueEntries={leagueEntries}
              extraFields={extraFields}
              leagueTradesValue={leagueTradesValue}
              timeZoneValue={timeZoneValue}
            />
            <Button type="submit" disabled={!(valid && dirty)}>
              Update League
            </Button>
          </form>
        </Box>

        <Title>Delete Your League</Title>
        <p>
          As league administrator you may delete this league before scoring
          starts. This will also delete all the teams in the league and
          can&rsquo;t be reversed.
        </p>
      </Copy>

      <DialogManager
        render={(showDialog, handleShow, handleHide) => (
          <Box px={2}>
            <Button onClick={handleShow}>Delete League</Button>
            {showDialog && (
              <ConfirmDialog
                closeDialog={handleHide}
                header="Delete league?"
                onConfirm={() => confirmDelete(league)}
              >
                <Copy>
                  <p>Are you sure you want to delete this league?</p>
                </Copy>
              </ConfirmDialog>
            )}
          </Box>
        )}
      />
    </Main>
  </Wrapper>
);

EditLeague.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  error: PropTypes.string,
  extraFields: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  league: propTypeLeague.isRequired,
  leagueEntries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
  leagueTradesValue: PropTypes.string,
  timeZoneValue: PropTypes.string,
  valid: PropTypes.bool.isRequired,
};

EditLeague.defaultProps = {
  error: null,
  extraFields: true,
  leagueTradesValue: "",
  timeZoneValue: "",
};

const validate = (values, ownProps) =>
  validateLeagueFields(values, ownProps.lastDraftDate, ownProps.extraFields);

const initialValuesFromLeague = (league) => {
  const leagueDraftDate = new Date(
    formatInTimeZone(
      league.draft_dt,
      league.draft_tz_show,
      SELECT_DRAFT_DATE_TIME_FORMAT,
    ),
  );
  return {
    leagueName: league.name,
    leagueScoring: league.scoring,
    leagueMinSize: league.min_entries,
    leagueMaxSize: league.max_entries,
    leagueAdministrator: league.admin_entry,
    leagueDraftDate,
    leagueDraftTZ: league.draft_tz_show,
    leagueDraftPickTime: league.draft_pick_time_limit,
    leagueTrades: league.trades,
  };
};

export { EditLeague as EditLeagueTest, initialValuesFromLeague, validate };

const formName = "editLeague";
const valueSelector = formValueSelector(formName);

const mapStateToProps = (state, ownProps) => {
  const leagueId = Number(ownProps.leagueId);
  const league = getLeague(state, leagueId);
  return {
    lastDraftDate: getLastDraftDate(state),
    league,
    leagueEntries: getLeagueEntries(state, leagueId),
    initialValues: initialValuesFromLeague(league),
    extraFields: !league || league.draft_status === "pre",
    leagueTradesValue: valueSelector(state, "leagueTrades"),
    timeZoneValue: valueSelector(state, "leagueDraftTZ"),
  };
};

const mapDispatchToProps = {
  confirmDelete: deleteLeague,
  onSubmit: editLeague,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: formName,
    touchOnChange: true,
    validate,
  })(EditLeague),
);
