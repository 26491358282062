import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeElementType } from "../../reducers/elementTypes";
import { propTypeElement } from "../../reducers/elements";
import { propTypeTeam } from "../../reducers/teams";
import { propTypeEventTeamFixture } from "../../store/fixtures/slice";
import { fixtureSuffix } from "../../utils/fixtures";
import ElementInTable from "../ElementInTable";
import {
  ElementListFixture,
  ElementListPlayer,
  ElementListTotal,
} from "../ElementList";
import ElementStatusButton from "../ElementStatusButton";
import { ElementTable } from "../ElementTable";
import TabHeading from "../TabHeading";
import { PrimaryCell, StatusCell } from "../Table";
import { VisuallyHidden } from "../styles";

const SquadTableElement = ({
  element,
  elementDialogShow,
  nextFixture,
  teamsById,
  typesById,
}) => {
  if (element) {
    return (
      <tr>
        <StatusCell>
          <ElementStatusButton elementId={element.id} />
        </StatusCell>
        <PrimaryCell>
          <ElementInTable elementId={element.id} />
        </PrimaryCell>
        <ElementListFixture as="td">
          {nextFixture && nextFixture.fixtures[element.team].length > 0 && (
            <ul key={element.id}>
              {nextFixture.fixtures[element.team].map((f) => (
                <li key={f.id}>
                  {teamsById[f.opponent].short_name}
                  {fixtureSuffix(f.id, f.isHome)}
                </li>
              ))}
            </ul>
          )}
        </ElementListFixture>
      </tr>
    );
  }
  return (
    <tr colSpan="3">
      <td>Missing</td>
    </tr>
  );
};
SquadTableElement.propTypes = {
  element: propTypeElement,
  elementDialogShow: PropTypes.func.isRequired,
  nextFixture: propTypeEventTeamFixture,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};
SquadTableElement.defaultProps = {
  element: null,
  nextFixture: undefined,
};

const SquadTablePosition = ({
  elementDialogShow,
  elements,
  elementType,
  nextFixture,
  teamsById,
  typesById,
}) => (
  <tbody>
    <tr>
      <ElementListTotal>
        <VisuallyHidden>Info</VisuallyHidden>
      </ElementListTotal>
      <ElementListPlayer>
        <VisuallyHidden>Player</VisuallyHidden>
      </ElementListPlayer>
      <ElementListFixture>FIX</ElementListFixture>
    </tr>
    {elements.map((element, index) => (
      <SquadTableElement
        element={element}
        elementDialogShow={elementDialogShow}
        key={element ? `squad_${element.id}` : `squad_${index}`}
        nextFixture={nextFixture}
        teamsById={teamsById}
        typesById={typesById}
      />
    ))}
  </tbody>
);
SquadTablePosition.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementType: propTypeElementType.isRequired,
  nextFixture: propTypeEventTeamFixture,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

SquadTablePosition.defaultProps = {
  nextFixture: undefined,
};

const SquadTable = ({
  elementDialogShow,
  elementTypes,
  myElementsByType,
  next3Fixtures,
  teamsById,
  typesById,
}) =>
  elementTypes.map((et) => (
    <div key={et.id}>
      <TabHeading title={et.plural_name} hasBorder={true} />
      <Box mb={3}>
        <ElementTable>
          <SquadTablePosition
            elementDialogShow={elementDialogShow}
            elementType={et}
            key={et.id}
            elements={myElementsByType[`et${et.id}`]}
            nextFixture={next3Fixtures[0]}
            teamsById={teamsById}
            typesById={typesById}
          />
        </ElementTable>
      </Box>
    </div>
  ));
SquadTable.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementTypes: PropTypes.arrayOf(propTypeElementType).isRequired,
  myElementsByType: PropTypes.objectOf(PropTypes.arrayOf(propTypeElement))
    .isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

export default SquadTable;
