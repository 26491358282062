import { navigate } from "@gatsbyjs/reach-router";
import { zonedTimeToUtc } from "date-fns-tz";
import { SubmissionError } from "redux-form";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  handleUnexpectedError,
} from "../helpers";
import { getDefaultURL } from "../reducers/player";
import {
  CREATE_LEAGUE_ERROR,
  CREATE_LEAGUE_SUCCESS,
  DELETE_LEAGUE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  EDIT_LEAGUE_ERROR,
  EDIT_LEAGUE_SUCCESS,
  FETCH_LEAGUE_ENTRIES_SUCCESS,
  FETCH_LEAGUE_MATCHES_SUCCESS,
  FETCH_LEAGUE_STANDINGS_SUCCESS,
  FETCH_LEAGUE_SUCCESS,
  JOIN_LEAGUE_ERROR,
  JOIN_LEAGUE_SUCCESS,
} from "./ActionTypes";
import { updateActiveEntry } from "./Entry";

// regular action creators
export const createLeagueError = (error) => ({
  type: CREATE_LEAGUE_ERROR,
  error,
});

export const createLeagueSuccess = (data) => ({
  type: CREATE_LEAGUE_SUCCESS,
  data,
});

export const fetchLeagueEntriesSuccess = (data) => ({
  type: FETCH_LEAGUE_ENTRIES_SUCCESS,
  data,
});

export const fetchLeagueMatchesSuccess = (data) => ({
  type: FETCH_LEAGUE_MATCHES_SUCCESS,
  data,
});

export const fetchLeagueStandingsSuccess = (data) => ({
  type: FETCH_LEAGUE_STANDINGS_SUCCESS,
  data,
});

export const fetchLeagueSuccess = (data) => ({
  type: FETCH_LEAGUE_SUCCESS,
  data,
});

export const joinLeagueError = (error) => ({
  type: JOIN_LEAGUE_ERROR,
  error,
});

export const joinLeagueSuccess = (data) => ({
  type: JOIN_LEAGUE_SUCCESS,
  data,
});

export const editLeagueError = (error) => ({
  type: EDIT_LEAGUE_ERROR,
  error,
});

export const editLeagueSuccess = (data) => ({
  type: EDIT_LEAGUE_SUCCESS,
  data,
});

export const deleteLeagueEntrySuccess = (data) => ({
  type: DELETE_LEAGUE_ENTRY_SUCCESS,
  data,
});

export const deleteLeagueSuccess = (data) => ({
  type: DELETE_LEAGUE_SUCCESS,
  data,
});

// thunk action creators

const entryData = (data) => ({
  name: data.entryName,
  favourite_team: parseInt(data.entryFaveTeam, 10) || null,
  email: data.entryEmail || false,
  terms_agreed: data.entryTerms || false,
});

const leagueData = (values) => {
  const draftDateUtc = zonedTimeToUtc(
    values.leagueDraftDate,
    values.leagueDraftTZ,
  ).toISOString();

  const data = {
    name: values.leagueName,
    scoring: values.leagueScoring,
    min_entries: values.leagueMinSize,
    max_entries: values.leagueMaxSize,
    admin_entry: values.leagueAdministrator,
    draft_tz_show: values.leagueDraftTZ,
    draft_dt: draftDateUtc,
    trades: values.leagueTrades,
  };
  if (values.leagueDraftPickTime !== undefined) {
    data.draft_pick_time_limit = values.leagueDraftPickTime;
  }
  return data;
};

export { entryData as entryDataTest, leagueData as leagueDataTest };

export const fetchLeagueEntries = (leagueId) => (dispatch) =>
  fetchGet(`/api/league/${leagueId}/entries`).then(
    (d) => dispatch(fetchLeagueEntriesSuccess(d.data)),
    (e) => handleUnexpectedError(e, dispatch),
  );

export const createLeague = (values) => (dispatch) =>
  fetchPost("/api/entry-league-create/", {
    entry: entryData(values),
    league: leagueData(values),
  }).then(
    (d) => {
      dispatch(createLeagueSuccess(d.data));
      updateActiveEntry(dispatch, d.data.active).then(() => navigate("/"));
    },
    (e) => {
      dispatch(createLeagueError(e));
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        if (
          e.error.league &&
          e.error.league.non_field_errors &&
          e.error.league.non_field_errors[0] ===
            "maximum number of private leagues entered"
        ) {
          throw new SubmissionError({
            _error: "You've entered the maximum number of private leagues.",
          });
        }
        const errorText =
          typeof e.error === "string" ? e.error : JSON.stringify(e.error);
        throw new SubmissionError({ _error: errorText });
      }
      handleUnexpectedError(e, dispatch);
    },
  );

export const editLeague = (values, _, props) => (dispatch) =>
  fetchPost(`/api/league/${props.league.id}/edit`, leagueData(values)).then(
    (d) => {
      dispatch(editLeagueSuccess(d.data));
      navigate("/");
    },
    (e) => {
      dispatch(editLeagueError(e));
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        const errorText =
          typeof e.error === "string" ? e.error : JSON.stringify(e.error);
        throw new SubmissionError({ _error: errorText });
      }
      handleUnexpectedError(e, dispatch);
    },
  );

export const deleteLeague = (league) => (dispatch, getState) =>
  fetchDelete(`/api/league/${league.id}/delete`).then(
    (d) => {
      updateActiveEntry(dispatch, d.data.active).then(() => {
        navigate("/");
        dispatch(deleteLeagueSuccess(d.data));
      });
    },
    (e) => {
      if (e.reason === "NOT_2XX" && e.response.status === 403) {
        if (e.error.detail && e.error.detail === "League is processing") {
          return navigate("/processing");
        }
      }
      return handleUnexpectedError(e, dispatch);
    },
  );

export const deleteLeagueEntry = (leagueId, entryId) => (dispatch, getState) =>
  fetchDelete(`/api/league/${leagueId}/entry/${entryId}/delete`).then(
    (d) => {
      dispatch(deleteLeagueEntrySuccess(d.data));
      navigate(getDefaultURL(getState()));
    },
    (error) => handleUnexpectedError(error, dispatch),
  );

const seasonOver = (e) =>
  e.error &&
  e.error.errors &&
  e.error.errors.non_field_errors &&
  e.error.errors.non_field_errors[0] === "Season over";

export const joinPrivateLeague = (values) => (dispatch) =>
  fetchPost("/api/entry-private-join/", {
    entry: entryData(values),
    league: {
      code: values.leaguePrivateCode,
    },
  }).then(
    (d) => {
      dispatch(joinLeagueSuccess(d.data));
      updateActiveEntry(dispatch, d.data.active).then(() => navigate("/"));
    },
    (e) => {
      dispatch(joinLeagueError(e));
      const errors = {};
      // Handle errors we know about
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        if (seasonOver(e)) {
          errors._error = "This game has now finished.";
        } else if (e.error.league) {
          if (e.error.league.code) {
            switch (e.error.league.code[0]) {
              case "Invalid code":
                errors.leaguePrivateCode = "Invalid code";
                break;

              case "Player already in league":
                errors.leaguePrivateCode = "You are already in this league";
                break;

              case "League closed":
                errors.leaguePrivateCode = "League closed to new entries";
                break;

              case "League full":
                errors.leaguePrivateCode =
                  "League has the maximum number of teams";
                break;

              case "Draft started":
                errors.leaguePrivateCode = "League draft has already started";
                break;

              default:
                break;
            }
          }
          if (
            e.error.league.non_field_errors &&
            e.error.league.non_field_errors[0] ===
              "maximum number of private leagues entered"
          ) {
            errors.leaguePrivateCode =
              "You've entered the maximum number of private leagues.";
          }
        } else {
          errors._error =
            typeof error === "string" ? e.error : JSON.stringify(e.error);
        }
        throw new SubmissionError(errors);
      }
      handleUnexpectedError(e, dispatch);
    },
  );

export const joinPublicLeague = (values) => (dispatch) =>
  fetchPost("/api/entry-public-join/", {
    entry: entryData(values),
    league: {
      scoring: values.leagueScoring,
      size: parseInt(values.leagueSize, 10),
    },
  }).then(
    (d) => {
      dispatch(joinLeagueSuccess(d.data));
      updateActiveEntry(dispatch, d.data.active).then(() => navigate("/"));
    },
    (e) => {
      dispatch(joinLeagueError(e));
      const errors = {};
      // Handle errors we know about
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        if (seasonOver(e)) {
          errors._error = "This game has now finished.";
        } else if (e.error.league) {
          if (
            e.error.league.non_field_errors &&
            e.error.league.non_field_errors[0] ===
              "maximum number of public leagues entered"
          ) {
            errors.leagueScoring =
              "You've entered the maximum number of public leagues.";
          }
        } else {
          errors._error =
            typeof error === "string" ? e.error : JSON.stringify(e.error);
        }
        throw new SubmissionError(errors);
      }
      handleUnexpectedError(e, dispatch);
    },
  );

export const joinMockLeague = (values) => (dispatch) =>
  fetchPost("/api/entry-mock-join/", {
    entry: entryData(values),
    league: {
      size: parseInt(values.leagueSize, 10),
    },
  }).then(
    (d) => {
      dispatch(joinLeagueSuccess(d.data));
      updateActiveEntry(dispatch, d.data.active).then(() => navigate("/"));
    },
    (e) => {
      dispatch(joinLeagueError(e));
      const errors = {};
      // Handle errors we know about
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        if (seasonOver(e)) {
          errors._error = "This game has now finished.";
        } else {
          errors._error =
            typeof error === "string" ? e.error : JSON.stringify(e.error);
        }
        throw new SubmissionError(errors);
      }
      handleUnexpectedError(e, dispatch);
    },
  );

export const leagueDataFetch = (id) => (dispatch) =>
  new Promise((resolve) => {
    fetchGet(`/api/league/${id}/details`).then(
      (d) => {
        const actions = [
          fetchLeagueEntriesSuccess({
            entries: d.data.league_entries,
            league: d.data.league,
          }),
          fetchLeagueStandingsSuccess({
            standings: d.data.standings,
            league: d.data.league,
          }),
          fetchLeagueSuccess({
            league: d.data.league,
          }),
        ];
        if (d.data.matches) {
          actions.push(
            fetchLeagueMatchesSuccess({
              league: d.data.league,
              matches: d.data.matches,
            }),
          );
        }
        dispatch(actions);
        resolve();
      },
      (e) => {
        handleUnexpectedError(e, dispatch);
        resolve();
      },
    );
  });
