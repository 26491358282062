import PropTypes from "prop-types";
import SquadHeader from "../../squad/SquadHeader";
import { Proposed } from "./styles";

const ProposedTransactions = ({
  children,
  elementsShownLabel,
  numberOfElements,
}) => (
  <Proposed>
    <SquadHeader
      elementsShownLabel={elementsShownLabel}
      numberOfElements={numberOfElements}
    />
    {children}
  </Proposed>
);

ProposedTransactions.propTypes = {
  children: PropTypes.node.isRequired,
  elementsShownLabel: PropTypes.string.isRequired,
  numberOfElements: PropTypes.number.isRequired,
};

export default ProposedTransactions;
