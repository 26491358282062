import { CHAT_MESSAGE, FETCH_CHAT_MESSAGES_SUCCESS } from "./ActionTypes";
import { fetchGet, handleUnexpectedError } from "../helpers";

export const postMessage = (text) => ({
  type: CHAT_MESSAGE,
  text,
});

export const fetchChatMessagesSuccess = (data, league) => ({
  type: FETCH_CHAT_MESSAGES_SUCCESS,
  data: {
    leagueId: league.id,
    messages: data,
  },
});

export const fetchChatMessages = (league) => (dispatch) => {
  const url = `/api/chat/${league.id}/messages`;
  return fetchGet(url).then(
    (d) => dispatch(fetchChatMessagesSuccess(d.data, league)),
    (e) => handleUnexpectedError(e, dispatch),
  );
};
