import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import {
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  FETCH_DRAFT_CHOICES_SUCCESS,
} from "../actions/ActionTypes";
import {
  getElements,
  getElementsById,
  getFilteredElements,
  getSortedElements,
  propTypeElement,
} from "./elements";
import { getActiveLeagueElementStatus } from "./elementStatus";
import { getElementTypes } from "./elementTypes";
import { getActiveEntry } from "./entries";
import { getActiveLeague } from "./leagues";
import { getActiveLeagueEntries } from "./leagueEntries";
import { getSquadSelectionLimits } from "./settings";
import { getWatchlist } from "./watchlist";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.draftChoices;
export const l2g = (local) => ({ draftChoices: local });

const choicesById = (data) =>
  data.reduce(
    (byId, choice) => Object.assign(byId, { [choice.id]: choice }),
    {},
  );

const byLeagueId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DRAFT_CHOICES_SUCCESS: {
      // In case choices are received out of order we won't mutate state
      // if we have less elements
      const league = action.data.leagueId;

      // No info on this league yet
      if (!state[league]) {
        return {
          ...state,
          [league]: choicesById(action.data.choices),
        };
      }
      const choices = Object.keys(state[league]).map((id) => state[league][id]);
      const now = choices.reduce((memo, c) => (c.element ? memo + 1 : memo), 0);
      const proposed = action.data.choices.filter((c) => c.element).length;
      if (proposed >= now) {
        return {
          ...state,
          [league]: choicesById(action.data.choices),
        };
      }
      return state;
    }

    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.data.league];
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byLeagueId,
});

// Selectors
export const getAllDraftChoices = (state) => g2l(state).byLeagueId;

export const hasLeagueDraftStarted = (state, leagueId) => {
  const choices = getAllDraftChoices(state)[leagueId];
  return Boolean(choices && Object.keys(choices).length);
};

export const getDraftChoices = createSelector(
  getAllDraftChoices,
  getActiveLeague,
  (choices, league) => {
    if (league && choices[league.id]) {
      return Object.keys(choices[league.id])
        .map((key) => choices[league.id][key])
        .sort((a, b) => a.id - b.id);
    }
    return [];
  },
);

export const isDraftFetched = createSelector(
  getAllDraftChoices,
  getActiveLeague,
  (choices, league) => Boolean(league && choices[league.id]),
);

export const getDraftStatus = createSelector(
  getDraftChoices,
  getActiveEntry,
  (choices, entry) => {
    let lastChoice;
    let thisChoice;
    let nextChoice;
    let myNextChoice;
    let stage;
    const choicesLength = choices.length;
    if (!choicesLength) {
      stage = "pre";
    } else if (choices[choicesLength - 1].element) {
      stage = "post";
    } else {
      stage = "during";
    }
    choices.forEach((c) => {
      // An element has already been chosen. As we want the last one
      // fine to keep replacing it.
      if (c.element) {
        lastChoice = c;
      } else {
        // Next choice is the first choice after thisChoice
        if (thisChoice && !nextChoice) nextChoice = c;
        if (c.entry === entry.id && thisChoice && !myNextChoice)
          myNextChoice = c;

        // This choice is the first choice without an element
        if (!thisChoice) thisChoice = c;
      }
    });
    return {
      lastChoice,
      thisChoice,
      nextChoice,
      myNextChoice,
      stage,
      myPick: Boolean(
        stage === "during" && thisChoice && thisChoice.entry === entry.id,
      ),
    };
  },
);

export const getChosenElements = createSelector(getDraftChoices, (choices) =>
  choices.filter((c) => c.element).map((c) => c.element),
);

export const getMyChosenElements = createSelector(
  getDraftChoices,
  getActiveEntry,
  (choices, entry) =>
    choices
      .filter((c) => c.element && c.entry === entry.id)
      .map((c) => c.element),
);

export const getMyCompletedTypes = createSelector(
  getMyChosenElements,
  getElementsById,
  getElementTypes,
  getSquadSelectionLimits,
  (choices, elements, elementTypes, limits) => {
    // Get a count of how many we have
    const have = elementTypes.reduce(
      (memo, et) => Object.assign(memo, { [et.id]: 0 }),
      {},
    );
    choices.forEach((choice) => {
      have[elements[choice].element_type] += 1;
    });

    // Note any that are completed
    const completed = [];
    elementTypes.forEach((et) => {
      if (have[et.id] >= limits[et.id]) {
        completed.push(et.id);
      }
    });

    return completed;
  },
);

const onlyDraftableElements = (all, chosen, completedTypes, leagueStatus) =>
  all.filter(
    (e) =>
      chosen.indexOf(e.id) === -1 &&
      completedTypes.indexOf(e.element_type) === -1 &&
      leagueStatus[e.id] &&
      leagueStatus[e.id].status === "a" &&
      e.status !== "u",
  );

export const getDraftableElements = createSelector(
  getSortedElements,
  getChosenElements,
  getMyCompletedTypes,
  getActiveLeagueElementStatus,
  onlyDraftableElements,
);

export const getAllDraftableElements = createSelector(
  getElements,
  getChosenElements,
  getMyCompletedTypes,
  getActiveLeagueElementStatus,
  onlyDraftableElements,
);

export const getAllDraftableElementsById = createSelector(
  getAllDraftableElements,
  (elements) =>
    elements.reduce(
      (memo, element) => Object.assign(memo, { [element.id]: element }),
      {},
    ),
);

export const getDraftableWatchlist = createSelector(
  getWatchlist,
  getAllDraftableElementsById,
  (watchlist, elements) => watchlist.filter((id) => elements[id]),
);

export const getDraftedChoices = createSelector(getDraftChoices, (choices) =>
  choices.filter((choice) => choice.element),
);

export const getFilteredDraftedChoices = createSelector(
  getDraftedChoices,
  getFilteredElements,
  (choices, elements) => {
    const byId = elements.reduce(
      (memo, element) => Object.assign(memo, { [element.id]: element }),
      {},
    );
    return choices.filter((choice) => byId[choice.element]);
  },
);

const getDraftedTeamsValues = getFormValues("draftedTeams");

export const getViewedEntry = createSelector(
  getDraftedTeamsValues,
  getActiveLeagueEntries,
  getActiveEntry,
  (formValues, entries, active) => {
    // If no form values return the league entry which is active
    if (!formValues) {
      return entries.reduce(
        (memo, le) => (le.entry_id === active.id ? le : memo),
        null,
      );
    }
    const viewed = formValues.draftedTeamView;
    return entries.reduce(
      (memo, le) => (le.entry_id === viewed ? le : memo),
      null,
    );
  },
);

const getDraftedElementsForViewedTeam = createSelector(
  getViewedEntry,
  getDraftChoices,
  getElementsById,
  (entry, choices, elements) => {
    if (!entry) return [];
    const viewed = entry.entry_id;
    return choices
      .filter((c) => c.element && c.entry === viewed)
      .map((c) => elements[c.element]);
  },
);

export const getSquadForViewedTeam = createSelector(
  getDraftedElementsForViewedTeam,
  getElementTypes,
  getSquadSelectionLimits,
  (elements, types, limits) => {
    const data = {
      totalSelected: 0,
      elements: {},
    };
    types.forEach((et) => {
      data.elements[`et${et.id}`] = {
        drafted: [],
        need: limits[et.id],
      };
    });
    elements.forEach((e) => {
      data.totalSelected += 1;
      data.elements[`et${e.element_type}`].drafted.push(e);
    });
    return data;
  },
);

// PropTypes
export const propTypeDraftChoice = PropTypes.shape({
  id: PropTypes.number,
  index: PropTypes.number,
  element: PropTypes.number,
  pick: PropTypes.number,
  player_first_name: PropTypes.string,
  player_last_name: PropTypes.string,
  round: PropTypes.number,
  seconds_to_pick: PropTypes.number,
});

export const propTypeDraftStatus = PropTypes.shape({
  lastChoice: propTypeDraftChoice,
  myNextChoice: propTypeDraftChoice,
  myPick: PropTypes.bool,
  nextChoice: propTypeDraftChoice,
  stage: PropTypes.oneOf(["pre", "during", "post"]),
  thisChoice: propTypeDraftChoice,
});

export const propTypeSquadType = PropTypes.shape({
  need: PropTypes.number,
  drafted: PropTypes.arrayOf(propTypeElement),
});

export const propTypeSquadForViewedTeam = PropTypes.shape({
  totalSelected: PropTypes.number,
  elements: PropTypes.objectOf(propTypeSquadType),
});
