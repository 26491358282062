import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import DragHandle from "../DragHandle";
import Row from "../Row";
import { ISortableRow } from "./types";

const SortableRow: React.FC<ISortableRow> = ({ id, children, ...rest }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Row
      dragHandle={
        <DragHandle ref={setActivatorNodeRef} {...attributes} {...listeners} />
      }
      isDragging={isDragging}
      id={id}
      ref={setNodeRef}
      style={style}
      {...rest}
    >
      {children}
    </Row>
  );
};

export default SortableRow;
