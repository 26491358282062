import { Link } from "@gatsbyjs/reach-router";
import styled, { css } from "styled-components/macro";
import { INavLink } from "./types";
import { rgba } from "polished";

// So we can apply to button as well as Link/anchor elements
const NavLinkStyles = css`
  padding: 0 1.8rem;
  border-radius: 5px 5px 0 0;
  background-color: ${(props) => props.theme.colors.fantasy};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[1]};
  color: inherit;
  line-height: 4.8rem;
  cursor: pointer;
  transition: all 0.2s;
`;

export const NavLink = styled(Link)<INavLink>`
  ${NavLinkStyles}
  display: block;
  text-align: center;
  text-decoration: none;

  .is-active {
    background-color: #fff;
  }

  ${({ variant = "primary" }) =>
    variant === "secondary" &&
    css`
      background-color: #963cff;
      color: #fff;
    `};
`;

export const NavItem = styled.li`
  margin-right: ${(props) => props.theme.space[1]};
`;

// Style selectors inside dropdown because wrappers
// are injected by priority-nav
export const StyledNav = styled.nav`
  button {
    ${NavLinkStyles}
    border: 0;
  }

  .ism-nav__dropdown-wrapper {
    display: inline-block;

    ${NavItem} {
      margin-right: 0;
    }

    ${NavLink} {
      border-radius: 0;
      background-color: transparent;
      color: inherit;
    }
  }

  .ism-nav__dropdown {
    top: 100%;
    right: 0;
    width: 18rem;
    transform: scale(0);
    transform-origin: top right;
    transition: 0.1s;
    border-radius: 0 0 3px 3px;
    background-color: white;
    box-shadow: 0 2px 3px ${rgba("black", 0.2)};
    z-index: 100;

    &.show {
      transform: scale(1);
    }

    button {
      width: 100%;
    }
  }
`;
