import PropTypes from "prop-types";
import { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { bindActionCreators } from "redux";
import {
  draftDataFetch,
  draftElement,
  draftOnline,
  draftStart,
} from "../../actions/Draft";
import { elementDialogShow as eds } from "../../actions/Element";
import { watchlistAdd, watchlistRemove } from "../../actions/Watchlist";
import {
  getActiveChatMessages,
  propTypeChatMessage,
} from "../../reducers/chatMessages";
import {
  getDraftChoices,
  getDraftStatus,
  getDraftableElements,
  getMyCompletedTypes,
  isDraftFetched,
  propTypeDraftChoice,
  propTypeDraftStatus,
} from "../../reducers/draftChoices";
import {
  getElementTypesById,
  propTypeElementType,
} from "../../reducers/elementTypes";
import {
  getElementsById,
  getFilteredWatchedElements,
  getSortStat,
  propTypeElement,
  propTypeSortStat,
} from "../../reducers/elements";
import { getActiveEntry, propTypeEntry } from "../../reducers/entries";
import {
  getActiveLeagueEntries,
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../../reducers/leagueEntries";
import { getActiveLeague, propTypeLeague } from "../../reducers/leagues";
import { getSetting } from "../../reducers/settings";
import { getTeamsById, propTypeTeam } from "../../reducers/teams";
import { getWatchlist } from "../../reducers/watchlist";
import {
  getNext3EventsFixturesByTeam,
  propTypeEventTeamFixture,
} from "../../store/fixtures/slice";
import Button from "../Button";
import ChatWrapper from "../Chat";
import ElementListControl from "../ElementListControl";
import HelmetHead from "../HelmetHead";
import { Main, Secondary, Wrapper } from "../Layout";
import { PrimaryContainer } from "../PrimaryContainer";
import Tabs from "../Tabs";
import TabPanel from "../Tabs/TabPanel";
import Title from "../Title";
import WatchlistTable from "../WatchlistTable";
import SquadHeader from "../squad/SquadHeader";
import SquadPlayers from "../squad/SquadPlayers";
import DraftStatus from "./DraftStatus";
import Drafted from "./Drafted";
import DraftedTeams from "./DraftedTeams";

const DraftWrapper = ({
  addToWatchlist,
  choices,
  completedTypes,
  elementDialogShow,
  elementsById,
  entries,
  entriesById,
  entry,
  fetched,
  league,
  markOnline,
  messages,
  next3Fixtures,
  removeFromWatchlist,
  sortStat,
  squadSize,
  startDraft,
  teamsById,
  typesById,
  watchedElements,
  watchlist,
}) => {
  const status = useSelector(getDraftStatus);
  const dispatch = useDispatch();
  const draftMe = (elementId) =>
    dispatch(draftElement(status.thisChoice?.id, elementId));
  const draftableElements = useSelector(getDraftableElements);
  return (
    <>
      <Wrapper>
        <HelmetHead
          title="Draft Room - Fantasy Premier League Draft 2024/25"
          description="Build a watchlist of player targets in preparation for draft day. For further information, visit the official website of the Premier League."
        />
        <Main>
          <Box mx={2}>
            <Title>
              Draft Room
              {status.stage === "during" && (
                <span className="ism-draft-live"> - Live</span>
              )}
            </Title>
          </Box>

          {status.stage === "pre" ? (
            <div>
              <DraftStatus league={league} status={status} />
              {league.can_admin && process.env.NODE_ENV === "development" && (
                <Button onClick={startDraft}>Start Draft</Button>
              )}
            </div>
          ) : (
            <DraftStatus
              choices={choices}
              elementsById={elementsById}
              entriesById={entriesById}
              isAutoDraft={entry.auto_draft}
              league={league}
              markOnline={markOnline}
              status={status}
              squadSize={squadSize}
            />
          )}
          <PrimaryContainer id="ism-primary-container">
            <ElementListControl disabledTypes={completedTypes} />
            {fetched && (
              <Tabs isCentered={true}>
                <TabPanel label="All players" link="players">
                  <SquadHeader numberOfElements={draftableElements.length} />
                  <SquadPlayers
                    actionMe={draftMe}
                    actionLabel="Draft"
                    elements={draftableElements}
                    isDisabled={status.stage !== "during" || !status.myPick}
                  />
                </TabPanel>
                <TabPanel
                  label={`Watchlist (${watchlist.length})`}
                  link="watchlist"
                >
                  <SquadHeader numberOfElements={watchlist.length} />
                  <WatchlistTable
                    actionDisabled={status.stage !== "during" || !status.myPick}
                    actionLabel="Draft"
                    actionMe={draftMe}
                    next3Fixtures={next3Fixtures}
                  />
                </TabPanel>
                <TabPanel label="Drafted" link="drafted">
                  <SquadHeader numberOfElements={choices.length} />
                  <Drafted />
                </TabPanel>
              </Tabs>
            )}
          </PrimaryContainer>
        </Main>

        {fetched && (
          <Secondary>
            <DraftedTeams
              elementDialogShow={elementDialogShow}
              entries={entries}
              nextFixture={next3Fixtures[0]}
              teamsById={teamsById}
              typesById={typesById}
            />
          </Secondary>
        )}
      </Wrapper>

      {league.variety === "x" && (
        <Wrapper>
          <Main>
            <ChatWrapper messages={messages} />
          </Main>
        </Wrapper>
      )}
    </>
  );
};

DraftWrapper.propTypes = {
  addToWatchlist: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(propTypeDraftChoice).isRequired,
  completedTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  entries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  entry: propTypeEntry.isRequired,
  fetched: PropTypes.bool.isRequired,
  league: propTypeLeague.isRequired,
  markOnline: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(propTypeChatMessage).isRequired,
  next3Fixtures: PropTypes.arrayOf(propTypeEventTeamFixture).isRequired,
  removeFromWatchlist: PropTypes.func.isRequired,
  sortStat: propTypeSortStat.isRequired,
  squadSize: PropTypes.number.isRequired,
  startDraft: PropTypes.func.isRequired,
  status: propTypeDraftStatus.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
  watchedElements: PropTypes.arrayOf(propTypeElement).isRequired,
  watchlist: PropTypes.arrayOf(PropTypes.number).isRequired,
};

class DraftWrapperContainer extends Component {
  constructor(props) {
    super(props);
    this.backOnline = this.backOnline.bind(this);
  }

  componentDidMount() {
    this.props.fetchDraftData();
    window.addEventListener("online", this.backOnline);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.backOnline);
  }

  // I'm not convinced the online event actually fires when the
  // browser is back online ...
  backOnline() {
    this.props.fetchDraftData();
  }

  render() {
    return <DraftWrapper {...this.props} />;
  }
}

DraftWrapperContainer.propTypes = {
  fetchDraftData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  choices: getDraftChoices(state),
  completedTypes: getMyCompletedTypes(state),
  elementsById: getElementsById(state),
  entries: getActiveLeagueEntries(state),
  entriesById: getActiveLeagueEntriesByEntryId(state),
  entry: getActiveEntry(state),
  fetched: isDraftFetched(state),
  league: getActiveLeague(state),
  messages: getActiveChatMessages(state),
  next3Fixtures: getNext3EventsFixturesByTeam(state),
  sortStat: getSortStat(state),
  squadSize: getSetting(state, "squad", "size"),
  status: getDraftStatus(state),
  teamsById: getTeamsById(state),
  typesById: getElementTypesById(state),
  watchedElements: getFilteredWatchedElements(state),
  watchlist: getWatchlist(state),
});

const mapDispatchToProps = {
  addToWatchlist: (e) =>
    watchlistAdd(parseInt(e.currentTarget.getAttribute("data-id"), 10)),
  fetchDraftData: draftDataFetch,
  removeFromWatchlist: (e) =>
    watchlistRemove(parseInt(e.currentTarget.getAttribute("data-id"), 10)),
  startDraft: draftStart,
  markOnline: draftOnline,
  elementDialogShow: eds,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    ...bindActionCreators(mapDispatchToProps, dispatch),
  };
};

export default connect(
  mapStateToProps,
  null,
  mergeProps,
)(DraftWrapperContainer);
