import styled, { css } from "styled-components/macro";

export const DataList = styled.ul`
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export const DataListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${(props) => props.theme.space[2]};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const SharedHeadingStyles = css`
  flex-grow: 1;
  flex-shrink: 0;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: inherit;
`;

export const DataListHeading = styled.h4`
  ${SharedHeadingStyles}
  flex-basis: 65%;
`;

export const DataListHeadingTransaction = styled.h4`
  ${SharedHeadingStyles}
  flex-basis: 70%;
`;

const SharedValueStyles = css`
  flex-grow: 1;
  flex-shrink: 0;
  text-align: right;
`;

export const DataListValue = styled.div`
  ${SharedValueStyles}
  flex-basis: 35%;
`;

export const DataListValueTransaction = styled.div`
  ${SharedValueStyles}
  flex-basis: 30%;
`;
