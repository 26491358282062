import { hiDPI } from "polished";
import styled from "styled-components/macro";
import Pattern1437 from "../../../img/pattern/pattern-1-437.png";
import Pattern1874 from "../../../img/pattern/pattern-1-874.png";
import { VerticallyCenter } from "../../styles";

export const EntrySummaryWrap = styled.div`
  border-radius: ${(props) => props.theme.radii[1]};
  background-color: ${(props) => props.theme.colors.primary};
  background-repeat: no-repeat;
  background-image: url(${Pattern1437});
  background-position:
    -76px -10px,
    right -220px bottom -120px;
  background-size: 366px 415px;

  ${hiDPI(2)} {
    background-image: url(${Pattern1874});
  }
`;

export const EntrySummary = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: flex;
    margin: 0 10%;
  }
`;

export const EntrySummaryItem = styled.div`
  padding: ${(props) => props.theme.space[2]};
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex: 1;
  }
`;

export const EntrySummaryItemInner = styled.div`
  padding: 0 ${(props) => props.theme.space[1]};
  overflow: auto;
`;

export const EntrySummaryHeading = styled.h3`
  margin: 0 ${(props) => props.theme.space[1]};
  color: #fff;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const EntrySummaryBody = styled.div`
  position: relative;
`;

export const EntrySummaryExtra = styled.span`
  ${VerticallyCenter}
  right: 10%;
`;

export const EntrySummaryStat = styled.div`
  display: inline-block;
  width: 35%;
  padding: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 4rem;
  line-height: 1;
`;

export const EntrySummaryMovement = styled.span`
  margin-left: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
`;
