import styled from "styled-components/macro";

export const StyledTabs = styled.div`
  padding-right: ${(props) => props.theme.space[2]};
  padding-left: ${(props) => props.theme.space[2]};
  margin-bottom: ${(props) => props.theme.space[4]};

  [role="tabpanel"][aria-hidden="true"] {
    display: none;
  }
`;
