import styled from "styled-components/macro";

export const StyledDraftStart = styled.div`
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: #ebebe4;
  text-align: center;
`;

export const DraftStartHeading = styled.h4`
  margin-top: ${(props) => props.theme.space[2]};
  margin-bottom: ${(props) => props.theme.space[2]};
  color: ${(props) => props.theme.colors.darkPink};
  font-size: inherit;
  text-transform: uppercase;
`;

export const DraftStartDate = styled.p`
  padding: ${(props) => props.theme.space[2]};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.fantasy};
`;
