import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { ADD_TEAMS } from "../actions/ActionTypes";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.teams;
export const l2g = (local) => ({ teams: local });

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_TEAMS: {
      const newState = { ...state };
      action.data.forEach((team) => {
        newState[team.id] = team;
      });
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byId,
});

// Selectors
export const getTeamsById = (state) => g2l(state).byId;

export const getTeam = (state, id) => getTeamsById(state)[id];

export const getTeams = (state) => {
  const localState = getTeamsById(state);
  return Object.keys(localState)
    .map((key) => localState[key])
    .sort((a, b) => a.name.localeCompare(b.name));
};

// Prop Types
export const propTypeTeam = PropTypes.shape({
  name: PropTypes.string,
});
