import { RouteComponentProps } from "@gatsbyjs/reach-router";
import React from "react";
import { Box } from "rebass/styled-components";
import HelmetHead from "../HelmetHead";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import Fixtures from "./Fixtures";

const FixturesWrapper: React.FC<RouteComponentProps<{ eventId?: string }>> = ({
  eventId = 0,
}) => (
  <Wrapper>
    <HelmetHead
      title="View Latest Premier League Fixtures | Fantasy Premier League Draft 2024/25"
      description="To view the latest Gameweek fixtures and help choose your next Fantasy Premier League Draft team, visit the official website of the Premier League."
    />
    <Main>
      <Box mx={2}>
        <Title>Fixtures</Title>
      </Box>
      <Fixtures eventId={Number(eventId)} useLinks />
    </Main>
  </Wrapper>
);

export default FixturesWrapper;
