import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { FETCH_LEAGUE_STANDINGS_SUCCESS } from "../actions/ActionTypes";
import { getActiveLeague } from "./leagues";
import { getActiveLeagueEntry } from "./leagueEntries";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.leagueStandings;
export const l2g = (local) => ({ leagueStandings: local });

const byLeagueId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LEAGUE_STANDINGS_SUCCESS:
      return {
        ...state,
        [action.data.league.id]: action.data.standings,
      };

    default:
      return state;
  }
};

export default combineReducers({
  byLeagueId,
});

// Selectors
export const getAllLeagueStandings = (state) => g2l(state);

export const getActiveLeagueStandings = createSelector(
  getAllLeagueStandings,
  getActiveLeague,
  (all, league) => all.byLeagueId[league.id],
);

export const getActiveLeagueStanding = createSelector(
  getActiveLeagueStandings,
  getActiveLeagueEntry,
  (all, leagueEntry) => {
    if (all && leagueEntry) {
      const finds = all.filter((s) => s.league_entry === leagueEntry.id);
      return finds.length ? finds[0] : undefined;
    }
    return undefined;
  },
);

// PropTypes
export const propTypeLeagueStanding = PropTypes.shape({
  total: PropTypes.number,
});
