import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import { postMessage } from "../../actions/Chat";
import { propTypeChatMessage } from "../../reducers/chatMessages";
import { getActiveEntry, propTypeEntry } from "../../reducers/entries";
import {
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../../reducers/leagueEntries";
import Button from "../Button";
import { InputField } from "../FieldRenderers";
import ChatMessages from "./ChatMessages/ChatMessages";
import {
  ChatBody,
  ChatForm,
  ChatHeader,
  ChatHeading,
  MessageInput,
  MessageSubmit,
  MessageWrapper,
  SafetyLink,
  StyledChat,
  StyledChatWrap,
} from "./styles";

const Chat = ({ activeEntry, entriesById, handleSubmit, messages }) => (
  <StyledChatWrap>
    <StyledChat>
      <ChatHeader>
        <ChatHeading>Chat</ChatHeading>
        <SafetyLink href="#ism-safety">Your safety online</SafetyLink>
      </ChatHeader>
      <ChatBody>
        <ChatMessages
          activeEntry={activeEntry}
          entriesById={entriesById}
          messages={messages}
        />
        <ChatForm onSubmit={handleSubmit}>
          <MessageWrapper>
            <MessageInput>
              <Field
                id="message"
                name="message"
                component={InputField}
                type="text"
                label="Message"
                placeholder="Your message"
                hideLabel
              />
            </MessageInput>
            <MessageSubmit>
              <Button type="submit" variant="light" fullwidth="true">
                Send
              </Button>
            </MessageSubmit>
          </MessageWrapper>
        </ChatForm>
      </ChatBody>
    </StyledChat>
  </StyledChatWrap>
);

Chat.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(propTypeChatMessage).isRequired,
};

export { Chat as ChatTest };

const mapStateToProps = (state) => ({
  activeEntry: getActiveEntry(state),
  entriesById: getActiveLeagueEntriesByEntryId(state),
});

const mapDispatchToProps = {
  onSubmit: (values) => (dispatch) => {
    dispatch(postMessage(values.message));
    dispatch(reset("chat"));
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "chat",
  })(Chat),
);
