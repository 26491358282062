import React from "react";

interface IProps {
  link: string;
  label: string;
  children: React.ReactNode;
}

const TabPanel: React.FC<IProps> = (props) => <div>{props.children}</div>;

export default TabPanel;
