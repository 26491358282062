import { formatInTimeZone } from "date-fns-tz";
import { rgba, size } from "polished";
import React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { ReactComponent as BaseExternalIcon } from "../../img/icons/external.svg";
import tvIcon from "../../img/tv.png";
import tvIcon2x from "../../img/tv@2x.png";
import { IGroupFixture } from "../../store/fixtures/types";
import { ITeam } from "../../store/teams/types";
import { formatRawAsISO } from "../../utils/datetime";
import Badge from "../Badge";
import Dialog from "../Dialog";
import SubHeading from "../SubHeading";
import Table from "../Table";
import { VisuallyHidden } from "../styles";
import BroadcasterLogo from "./BroadcasterLogo";
import {
  FixtureBroadcastersContext,
  IBroadcasterInfo,
} from "./FixtureBroadcasters";

const StyledFixture = styled.div`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.primary, 0.08)};
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: relative;
  }
`;

const ExternalIcon = styled(BaseExternalIcon)`
  ${size(10)}
  margin-left: ${({ theme }) => theme.space[1]};
  fill: black;
  vertical-align: middle;
`;

const Broadcaster = styled.div`
  margin: 0.7rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24%;
    margin: 0;
    text-align: right;
  }
`;

const BroadcasterBox = styled.div`
  padding: 0 ${({ theme }) => theme.space[1]};
  text-align: center;
`;

const BroadcasterList = styled.ul`
  display: flex;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    display: block;
  }
`;

const BroadcasterItem = styled.li`
  padding-right: ${({ theme }) => theme.space[1]};
  padding-left: ${({ theme }) => theme.space[1]};
  flex: 1;
  :not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.grey};
    text-align: right;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    display: inline-block;
    :not(:last-child) {
      text-align: left;
    }
  }
`;

const TVLogo = styled.img`
  margin-right: ${({ theme }) => theme.space[1]};
  vertical-align: middle;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const TVButton = styled.button`
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: black;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: inherit;
  cursor: pointer;
`;

const Teams = styled.div`
  display: flex;
  margin: 0.7rem 0;
  align-items: center;
`;

const Team = styled.div`
  flex: 1 1 calc(50% - (4.4rem / 2));
`;

const TeamH = styled(Team)`
  text-align: right;
`;

const TeamName = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  vertical-align: middle;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.7rem;
  }
`;

const FixtureBadge = styled.div`
  display: inline-block;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }
`;

const FixtureKOTime = styled.time`
  flex: 1 0 6.6rem;
  display: inline-block;
  padding: 0.9rem ${({ theme }) => theme.space[2]};
  border: 1px solid #e5e5e5;
  line-height: 1.1;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.8rem 1.6rem;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

const FixtureScore = styled.div`
  flex: 1 0 6.6rem;
  display: flex;
  padding: 0.4rem;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const FixtureScoreTeam = styled.span`
  flex: 1;
  padding: 0.3rem 0;
  color: white;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
  :first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const TVLink = styled.a`
  display: inline-block;
  margin: ${({ theme }) => theme.space[1]} 0;
  padding: ${({ theme }) => theme.space[1]} 0.4rem;
  border: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  color: #333;
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
    font-size: inherit;
  }
`;

const ButtonText = styled.span`
  vertical-align: middle;
`;

interface IMultipleBroadcastersDialog {
  broadcasters: IBroadcasterInfo[];
  handleHide: () => void;
}

const MultipleBroadcastersDialog: React.FC<IMultipleBroadcastersDialog> = ({
  broadcasters,
  handleHide,
}) => (
  <Dialog closeDialog={handleHide}>
    <Dialog.Header closeDialog={handleHide}>TV Info</Dialog.Header>
    <Dialog.Body>
      <Box px={4} py={3}>
        <SubHeading>Broadcasters</SubHeading>
        <Box mx={-2}>
          <Table>
            <thead>
              <tr>
                <th>Broadcaster</th>
                <th>
                  <VisuallyHidden>Logo</VisuallyHidden>
                </th>
                <th>More info</th>
              </tr>
            </thead>
            <tbody>
              {broadcasters.map((broadcaster, i) => (
                <tr key={i}>
                  <td>
                    {broadcaster.name.substring(
                      broadcaster.name.indexOf(" - ") + 3,
                    )}
                  </td>
                  <td>
                    <BroadcasterLogo
                      abbreviation={broadcaster.abbreviation}
                      name={broadcaster.name}
                    />
                  </td>
                  <td>
                    <TVLink href={broadcaster.url} target="_blank">
                      <ButtonText>Visit site</ButtonText>
                      <ExternalIcon />
                    </TVLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Box>
    </Dialog.Body>
  </Dialog>
);

interface IFixtureProps {
  awayTeam: ITeam;
  fixture: IGroupFixture;
  hasScore?: boolean;
  homeTeam: ITeam;
}

const Fixture: React.FC<IFixtureProps> = ({ awayTeam, fixture, homeTeam }) => {
  const [openMulti, setOpenMulti] = React.useState(false);
  const fb = React.useContext(FixtureBroadcastersContext);
  const broadcasterInfo =
    fixture.event && fb[fixture.event] && fb[fixture.event][fixture.id]
      ? fb[fixture.event][fixture.id]
      : null;

  return (
    <StyledFixture>
      <Teams>
        <TeamH>
          <TeamName>{homeTeam.name}</TeamName>
          <FixtureBadge>
            <Badge
              team={homeTeam}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
        </TeamH>
        {fixture.started && fixture.team_h_score !== null ? (
          <FixtureScore>
            <FixtureScoreTeam>{fixture.team_h_score}</FixtureScoreTeam>
            <FixtureScoreTeam>{fixture.team_a_score}</FixtureScoreTeam>
          </FixtureScore>
        ) : fixture.kickoff_time ? (
          <FixtureKOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatInTimeZone(
              fixture.kickoffDate,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              "HH:mm",
            )}
          </FixtureKOTime>
        ) : (
          <FixtureKOTime as="span">-</FixtureKOTime>
        )}
        <Team>
          <FixtureBadge>
            <Badge
              team={awayTeam}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
          <TeamName>{awayTeam.name}</TeamName>
        </Team>
      </Teams>
      {broadcasterInfo && (
        <Broadcaster>
          {broadcasterInfo.length > 2 ? (
            <ButtonWrap>
              <TVButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMulti(true);
                }}
              >
                <TVLogo
                  src={tvIcon}
                  srcSet={`${tvIcon}, ${tvIcon2x} 2x`}
                  alt=""
                />
                <ButtonText>Multiple Broadcasters</ButtonText>
              </TVButton>
              {openMulti && (
                <MultipleBroadcastersDialog
                  broadcasters={broadcasterInfo}
                  handleHide={() => setOpenMulti(false)}
                />
              )}
            </ButtonWrap>
          ) : broadcasterInfo.length === 2 ? (
            <BroadcasterList>
              {broadcasterInfo.map((b, i) => (
                <BroadcasterItem key={i}>
                  <a
                    href={b.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <BroadcasterLogo
                      abbreviation={b.abbreviation}
                      name={b.name}
                    />
                    <ExternalIcon />
                  </a>
                </BroadcasterItem>
              ))}
            </BroadcasterList>
          ) : (
            <BroadcasterBox>
              <a
                href={broadcasterInfo[0].url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <BroadcasterLogo
                  abbreviation={broadcasterInfo[0].abbreviation}
                  name={broadcasterInfo[0].name}
                />
                <ExternalIcon />
              </a>
            </BroadcasterBox>
          )}
        </Broadcaster>
      )}
    </StyledFixture>
  );
};

export default Fixture;
