import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getFormSubmitErrors, reduxForm } from "redux-form";
import { joinMockLeague as onSubmit } from "../actions/League";
import Alert from "./Alert";
import Button from "./Button";
import EntryFields, { validateEntryFields } from "./EntryFields";
import Form from "./FocusErrorsForm";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import LeagueSizeField, { validateLeagueSizeField } from "./LeagueSizeField";
import TermsFields, { validateTermsFields } from "./TermsFields";
import Copy from "./Copy";
import Title from "./Title";
import { Fieldset, Legend } from "./FieldRenderers";

const JoinMockDraft = ({ error, handleSubmit, submitErrors, valid }) => (
  <Wrapper>
    {/* eslint-disable max-len */}
    <HelmetHead
      title="Create or Join - Fantasy Premier League Draft 2024/25"
      description="Create or join a league in Fantasy Premier League Draft, visit the official website of the Premier League."
    />
    {/* eslint-enable max-len */}
    <Main>
      <Copy>
        <Title>Join a Mock Draft</Title>

        <Form error={error} onSubmit={handleSubmit} errors={submitErrors}>
          {error && (
            <Alert type="error" canFocus>
              {error}
            </Alert>
          )}

          <EntryFields hideFaveTeam />

          <Fieldset>
            <Legend>League</Legend>
            <LeagueSizeField />
          </Fieldset>

          <TermsFields />

          <Button type="submit" disabled={!valid}>
            Join Mock Draft
          </Button>
        </Form>
      </Copy>
    </Main>
  </Wrapper>
);

JoinMockDraft.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  valid: PropTypes.bool.isRequired,
};

JoinMockDraft.defaultProps = {
  error: null,
  submitErrors: null,
};

const validate = (values) => {
  const errors = {};

  // Team validation
  Object.assign(errors, validateEntryFields(values));

  // League Size validation
  Object.assign(errors, validateLeagueSizeField(values));

  // Terms validation
  Object.assign(errors, validateTermsFields(values));

  return errors;
};

export { validate, JoinMockDraft as JoinMockDraftTest };

const formName = "joinMockDraft";

const mapStateToProps = (state) => ({
  submitErrors: getFormSubmitErrors(formName)(state),
});

const mapDispatchToProps = {
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    destroyOnUnmount: false,
    form: formName,
    validate,
  })(JoinMockDraft),
);
