const loadSVG = (url) => {
  fetch(url, { credentials: "same-origin" })
    .then((response) => response.text())
    .then((svg) => {
      const div = document.createElement("div");
      div.innerHTML = svg;
      div.style.display = "none";
      document.body.insertBefore(div, document.body.childNodes[0]);
    });
};

export default loadSVG;
