import styled from "styled-components/macro";
import Table from "../../Table";

export const EntryCell = styled.td`
  width: 25%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 13%;
  }
`;

export const TradeHeader = styled.th`
  width: 50%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 65%;
  }
`;

export const TradeCell = styled.td`
  width: 50%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 64%;
  }
`;

export const ActionCell = styled.td`
  width: 50%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 23%;
  }
`;

export const ProposedTradesTable = styled(Table)`
  table-layout: fixed;

  ${EntryCell}, ${TradeHeader}, ${ActionCell} {
    text-align: center;
  }

  ${TradeCell} {
    padding: 0;
  }
`;
