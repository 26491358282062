import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import { elementDialogShow as eds } from "../actions/Element";
import { leagueTransactionsFetchForEntry } from "../actions/Transaction";
import { getEntry, propTypeEntry } from "../reducers/entries";
import { getEventsToNext, propTypeEvent } from "../reducers/events";
import {
  getActiveLeagueEntries,
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../reducers/leagueEntries";
import { getActiveLeague, propTypeLeague } from "../reducers/leagues";
import {
  getForLeagueFreeAgentsFiltered,
  getForLeagueWaiversFiltered,
  propTypeTransactionInflated,
} from "../reducers/transactions";
import { formatRawAsDate } from "../utils/datetime";
import { getShortNameFromId } from "../utils/events";
import { FormFilter, FormFilters, SelectField } from "./FieldRenderers";
import HelmetHead from "./HelmetHead";
import { Main, Secondary, Wrapper } from "./Layout";
import LeagueTradesHistory from "./LeagueTradesHistory";
import { LinkButton } from "./LinkButton";
import SubHeading from "./SubHeading";
import Title from "./Title";
import Entry from "./entry/Entry";
import { StackTable } from "./styles";

export const getWaiverResultName = (key) => {
  const resultNames = {
    a: "Accepted",
    di: "Denied(player in not available)",
    do: "Denied(player out not available)",
  };
  return resultNames[key];
};

const TransactionCells = ({ elementDialogShow, entry, transaction }) => (
  <React.Fragment>
    <td data-label="GW">{getShortNameFromId(transaction.event, true)}</td>
    <td data-label="Manager">
      {entry.player_first_name} {entry.player_last_name}
    </td>
    <td data-label="In">
      <LinkButton onClick={() => elementDialogShow(transaction.element_in.id)}>
        <span>{transaction.element_in.web_name}</span>
      </LinkButton>
    </td>
    <td data-label="Out">
      <LinkButton onClick={() => elementDialogShow(transaction.element_out.id)}>
        <span>{transaction.element_out.web_name}</span>
      </LinkButton>
    </td>
  </React.Fragment>
);

TransactionCells.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  entry: propTypeLeagueEntry.isRequired,
  transaction: propTypeTransactionInflated.isRequired,
};

const EntryTransactions = ({
  elementDialogShow,
  entries,
  entriesById,
  entry,
  events,
  freeAgents,
  league,
  waivers,
}) => (
  <Wrapper>
    {/* eslint-disable max-len */}
    <HelmetHead
      title="Transaction History - Fantasy Premier League Draft 2024/25"
      description={`To view the transaction history of ${entry.player_first_name} ${entry.player_last_name}, including any transactions, visit the official website of the Premier League.`}
    />
    {/* eslint-enable max-len */}
    <Main>
      <Box mx={2}>
        <Title>Transaction History</Title>
      </Box>

      <form>
        <Box p={1}>
          <FormFilters>
            <FormFilter>
              <Field
                id="entryFilter"
                name="entryFilter"
                component={SelectField}
                label="Manager"
                parse={(value) => parseInt(value, 10)}
              >
                <option value="0">All</option>
                {entries.map((le) => (
                  <option key={le.entry_id} value={le.entry_id}>
                    {le.player_first_name} {le.player_last_name}
                  </option>
                ))}
              </Field>
            </FormFilter>
            <FormFilter>
              <Field
                id="eventFilter"
                name="eventFilter"
                component={SelectField}
                label="Gameweek"
                parse={(value) => parseInt(value, 10)}
              >
                <option value="0">All</option>
                {events.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </Field>
            </FormFilter>
          </FormFilters>
        </Box>
      </form>

      <SubHeading>Waivers History</SubHeading>
      <Box mb={4}>
        <StackTable>
          <thead>
            <tr>
              <th>GW</th>
              <th>Manager</th>
              <th>In</th>
              <th>Out</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {waivers.length ? (
              waivers.map((t) => (
                <tr key={t.id}>
                  <TransactionCells
                    elementDialogShow={elementDialogShow}
                    entry={entriesById[t.entry]}
                    transaction={t}
                  />
                  <td data-label="Result">{getWaiverResultName(t.result)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <p className="ism-align-center">
                    No waiver transactions have been made.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </StackTable>
      </Box>

      <SubHeading>Free Agents History</SubHeading>
      <Box mb={4}>
        <StackTable>
          <thead>
            <tr>
              <th>GW</th>
              <th>Manager</th>
              <th>In</th>
              <th>Out</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {freeAgents.length ? (
              freeAgents.map((t) => (
                <tr key={t.id}>
                  <TransactionCells
                    elementDialogShow={elementDialogShow}
                    entry={entriesById[t.entry]}
                    transaction={t}
                  />
                  <td data-label="Date">{formatRawAsDate(t.added)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <p className="ism-align-center">
                    No free agent transactions have been made.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </StackTable>
      </Box>
      {league.trades !== "n" && <LeagueTradesHistory />}
    </Main>
    <Secondary>
      <Entry entry={entry} />
    </Secondary>
  </Wrapper>
);

EntryTransactions.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  entry: propTypeEntry.isRequired,
  entries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  events: PropTypes.arrayOf(propTypeEvent).isRequired,
  freeAgents: PropTypes.arrayOf(propTypeTransactionInflated).isRequired,
  league: propTypeLeague.isRequired,
  waivers: PropTypes.arrayOf(propTypeTransactionInflated).isRequired,
};

export { EntryTransactions as EntryTransactionsTest };

class EntryTransactionsContainer extends Component {
  componentDidMount() {
    this.props.fetchTransactions(this.props.entryId);
  }

  render() {
    return <EntryTransactions {...this.props} />;
  }
}

EntryTransactionsContainer.propTypes = {
  entryId: PropTypes.number.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const entryId = Number(ownProps.entryId);
  const entry = getEntry(state, entryId);
  const league = getActiveLeague(state);
  return {
    entries: getActiveLeagueEntries(state),
    entriesById: getActiveLeagueEntriesByEntryId(state),
    entry,
    entryId,
    events: getEventsToNext(state),
    freeAgents: getForLeagueFreeAgentsFiltered(state, league.id),
    initialValues: {
      entryFilter: entryId,
    },
    league,
    waivers: getForLeagueWaiversFiltered(state, league.id),
  };
};

const mapDispatchToProps = {
  elementDialogShow: eds,
  fetchTransactions: leagueTransactionsFetchForEntry,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "transactionValues",
  })(EntryTransactionsContainer),
);
