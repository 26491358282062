import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { waiverChange } from "../../../../actions/Transaction";
import { getWaivers, getWaiversById } from "../../../../reducers/transactions";
import { ITransaction } from "../../../../store/transactions/types";
import DragHandle from "../../../sortable/DragHandle";
import Row from "../../../sortable/Row";
import SortableRow from "../../../sortable/SortableRow";
import WaiverCells from "../WaiverCells";

const WaiverRows: React.FC = () => {
  const waivers: ITransaction[] = useSelector(getWaivers);
  const waiversById = useSelector(getWaiversById);
  const dispatch = useDispatch();
  const [activeWaiver, setActiveWaiver] = useState<ITransaction | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragEndEvent) => {
    const { active } = event;
    setActiveWaiver(waiversById[active.id]);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const oldPriority =
        waivers.findIndex((item: any) => item.id === active.id) + 1;
      const newPriority =
        waivers.findIndex((item: any) => item.id === over.id) + 1;

      dispatch(waiverChange(oldPriority, newPriority));
    }
  };

  return (
    <DndContext
      accessibility={{ container: document.body }}
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement, restrictToVerticalAxis]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={waivers} strategy={verticalListSortingStrategy}>
        <tbody>
          {waivers.map((waiver) => (
            // TODO Rather than all these props could it take an item of either ITransaction or watchlist?
            <SortableRow
              changeAction={waiverChange}
              id={waiver.id}
              key={waiver.id}
              maxPriority={waivers.length}
              priority={waiver.priority}
            >
              <WaiverCells waiver={waiver} />
            </SortableRow>
          ))}
        </tbody>
      </SortableContext>
      <DragOverlay wrapperElement="tbody">
        {activeWaiver ? (
          <Row
            changeAction={waiverChange}
            dragHandle={<DragHandle isDragging />}
            id={activeWaiver.id}
            maxPriority={waivers.length}
            priority={activeWaiver.priority}
          >
            <WaiverCells waiver={activeWaiver} />
          </Row>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default WaiverRows;
