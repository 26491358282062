import { rgba } from "polished";
import React from "react";
import styled from "styled-components/macro";
import TabHeading from "./TabHeading";

const StyledDeadlineBar = styled.div`
  text-align: center;
`;

const Deadline = styled.time`
  font-family: ${({ theme }) => theme.fonts.bold};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 1.8rem;
  }
`;

const DeadlineBarInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space[2]} 0;

  ::before {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${rgba("white", 0.5)} 50%,
      ${rgba("white", 0)} 100%
    );
  }
`;

const Label = styled.h3`
  margin-right: 0.4rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[0]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: inherit;
  }
`;

const DeadlineBar = ({ label, deadlineISO, deadlineLocal, heading }) => (
  <StyledDeadlineBar>
    <TabHeading title={heading} isBlock={true} />
    <DeadlineBarInner>
      <Label>{label}:</Label>
      <Deadline dateTime={deadlineISO}>{deadlineLocal}</Deadline>
    </DeadlineBarInner>
  </StyledDeadlineBar>
);

export default DeadlineBar;
