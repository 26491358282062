import styled, { css } from "styled-components/macro";
import { IStyledRowProps } from "./types";

export const StyledRow = styled.tr<IStyledRowProps>`
  background-color: #fff;

  ${(props) =>
    props.isOverlay &&
    css`
      box-shadow:
        rgb(0 0 0 / 10%) 0px 20px 25px -5px,
        rgb(0 0 0 / 30%) 0px 10px 10px -5px;
      outline: #efefef solid 1px;
    `}

  ${(props) =>
    props.isDragging &&
    css`
      td {
        opacity: 0.2;
      }
    `}
`;

export const DragCell = styled.td`
  width: 7%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 4%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 3%;
  }
`;

export const PriorityCell = styled.td`
  width: 16%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 9%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 7%;
  }
`;
