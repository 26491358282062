import styled from "styled-components/macro";
import { ElementListFixture, ElementListTotal } from "./ElementList";
import Table, { PrimaryCell, StatusCell } from "./Table";
import {
  DraftedEvent,
  DraftedPick,
  DraftedPlayer,
  DraftedSortStat,
} from "./draft/Drafted/styles";

export const ElementTable = styled(Table)`
  table-layout: fixed;

  /* Needed to override Table.ts thead th specificity...but it's getting long! */
  thead {
    ${DraftedSortStat},
    ${DraftedEvent},
    ${DraftedPick},
    ${DraftedPlayer},
    ${ElementListTotal},
    ${ElementListFixture} {
      text-align: center;
    }
  }

  ${StatusCell} {
    padding: 0;
  }

  ${PrimaryCell} {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
`;

export const TeamShortName = styled.span`
  font-family: ${(props) => props.theme.fonts.bold};
  font-weight: 400;
`;
