import { Link } from "@gatsbyjs/reach-router";
import { hideVisually, rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { NextIcon, PrevIcon } from "./icons/Chevrons";
import { GradientBorder } from "./styles";

export const Pager = styled.div`
  display: flex;
`;

export const EventPager = styled.div`
  display: flex;
  margin-top: -3.8rem;
  padding: ${({ theme }) => theme.space[1]};

  ${GradientBorder}
`;

export const PagerItem = styled.div``;

export const PagerItemNext = styled(PagerItem)`
  margin-left: auto;
  text-align: right;
`;

const PagerStyles = css`
  display: flex;
  align-items: center;
  padding: 0.8rem ${({ theme }) => theme.space[4]};
  border: 0;
  border-radius: 8px;
  background: ${rgba("white", 0.6)};
  color: inherit;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const PagerButton = styled.button`
  ${PagerStyles}
`;

const PagerLink = styled(Link)`
  ${PagerStyles}
  text-decoration: none;
`;

const ButtonLabel = styled.span`
  @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${hideVisually}
  }
`;

export const PagerHeading = styled.h3`
  margin: ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2rem;
    text-align: center;
    margin: 0 33%;
    padding: 1.1rem 1rem;
  }
`;

interface IPagerButtonProps {
  onClick: () => void;
  label?: string;
}

export const PreviousButton: React.FC<IPagerButtonProps> = ({
  onClick,
  label = "Previous",
}) => (
  <PagerButton onClick={onClick}>
    <PrevIcon />
    <ButtonLabel>{label}</ButtonLabel>
  </PagerButton>
);

export const NextButton: React.FC<IPagerButtonProps> = ({
  onClick,
  label = "Next",
}) => (
  <PagerButton onClick={onClick}>
    <ButtonLabel>{label}</ButtonLabel>
    <NextIcon />
  </PagerButton>
);

interface IPagerLinkProps {
  onClick: () => void;
  label?: string;
  to: string;
}

export const PreviousLink: React.FC<IPagerLinkProps> = ({
  onClick,
  label = "Previous",
  to,
}) => (
  <PagerLink to={to} onClick={onClick}>
    <PrevIcon />
    <ButtonLabel>{label}</ButtonLabel>
  </PagerLink>
);

export const NextLink: React.FC<IPagerLinkProps> = ({
  onClick,
  label = "Next",
  to,
}) => (
  <PagerLink to={to} onClick={onClick}>
    <ButtonLabel>{label}</ButtonLabel>
    <NextIcon />
  </PagerLink>
);
