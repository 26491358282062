import { Link } from "@gatsbyjs/reach-router";
import Copy from "./Copy";
import { Main, Wrapper } from "./Layout";
import Title from "./Title";

export const NotFoundError = (props) => (
  <Wrapper>
    <Main>
      <Title>Page not found</Title>
      <Copy>
        <p>Sorry, but the page you were looking for can&apos;t be found.</p>
        <p>
          You can return to the <Link to="/">home page</Link> and try again or{" "}
          <Link to="/help/">contact us</Link> for further help.
        </p>
      </Copy>
    </Main>
  </Wrapper>
);

export const InternalError = (props) => (
  <Wrapper>
    <Main>
      <Title>Error</Title>
      <Copy>
        <p>
          Sorry, but there has been an unexpected error processing your request.
        </p>
        <p>
          You can return to the <Link to="/">home page</Link> and try again or{" "}
          <Link to="/help/">contact us</Link> for further help.
        </p>
      </Copy>
    </Main>
  </Wrapper>
);

export const UnavailableError = (props) => (
  <Wrapper>
    <Main>
      <Title>Error</Title>
      <Copy>
        <p>Sorry, but we are unable to load the requested page at this time.</p>
        <p>Please try again in a few minutes.</p>
      </Copy>
    </Main>
  </Wrapper>
);

export const Processing = (props) => (
  <Wrapper>
    <Main>
      <Title>Game updating</Title>
      <Copy>
        <p>Squads are currently being processed.</p>
        <p>
          Please try again in a few minutes when the updated teams should be
          available.
        </p>
      </Copy>
    </Main>
  </Wrapper>
);

export const SeasonOver = (props) => (
  <Wrapper>
    <Main>
      <Title>Season over</Title>
      <Copy>
        <p>This game has now finished.</p>
        <p>
          Thanks for your interest and come back in July for next season&quot;s
          game.
        </p>
      </Copy>
    </Main>
  </Wrapper>
);
