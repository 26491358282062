import { Link as RouterLink } from "@gatsbyjs/reach-router";
import styled, { css } from "styled-components/macro";

export const LinkStyles = css`
  position: relative;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-decoration: none;
  z-index: 1;

  :hover {
    text-decoration: none;

    ::after {
      content: "";
      position: absolute;
      bottom: 1px;
      right: 0;
      left: 0;
      height: 1px;
      background-image: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightPurple},
        ${({ theme }) => theme.colors.lightBlue}
      );
      z-index: -1;
    }
  }
`;

export const Hyperlink = styled.a`
  ${LinkStyles}
`;

export const BasicHyperlink = styled.a`
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const Link = styled(RouterLink)`
  ${LinkStyles}
`;

export const MoreLink = styled(RouterLink)`
  color: #1f61c0;
  font-family: ${(props) => props.theme.fonts.bold};
  text-decoration: none;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default Link;
