import React from "react";
import { Box } from "rebass/styled-components";
import { IElementFixture } from "../../../store/elements/types";
import { ITeamsById } from "../../../store/teams/types";
import { formatRawAsISO, formatRawAsLocal } from "../../../utils/datetime";
import { getShortNameFromId } from "../../../utils/events";
import { fixtureSuffix } from "../../../utils/fixtures";
import Table, { ScrollTable } from "../../Table";
import { ElementSubHeading } from "../styles";
import { DifficultyCell } from "./styles";

interface IElementFixtures {
  fixtures: Array<IElementFixture>;
  teamsById: ITeamsById;
}

const ElementFixtures: React.FC<IElementFixtures> = ({
  fixtures,
  teamsById,
}) => (
  <Box bg="white" mt={2}>
    <ElementSubHeading>Fixtures</ElementSubHeading>
    <ScrollTable>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>GW</th>
            <th>Opponent</th>
            <th>FDR</th>
          </tr>
        </thead>
        <tbody>
          {fixtures
            .filter((f) => f.event)
            .map((f: IElementFixture) => (
              <tr key={f.id}>
                <td>
                  <time dateTime={formatRawAsISO(f.kickoff_time)}>
                    {formatRawAsLocal(f.kickoff_time)}
                  </time>
                </td>
                <td>{getShortNameFromId(f.event, true)}</td>
                <td>
                  {teamsById[f.opponent].name} {fixtureSuffix(f.id, f.is_home)}
                </td>
                <DifficultyCell difficulty={f.difficulty}>
                  {f.difficulty}
                </DifficultyCell>
              </tr>
            ))}
        </tbody>
      </Table>
    </ScrollTable>
  </Box>
);

export default ElementFixtures;
