import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getActiveLeague } from "./leagues";
import {
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  FETCH_ELEMENT_STATUS_SUCCESS,
} from "../actions/ActionTypes";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.elementStatus;
export const l2g = (local) => ({ elementStatus: local });

const statusByElementId = (data) =>
  data.reduce(
    (byId, status) => Object.assign(byId, { [status.element]: status }),
    {},
  );

const byLeagueId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ELEMENT_STATUS_SUCCESS: {
      const league = action.data.league;
      return {
        ...state,
        [league]: statusByElementId(action.data.element_status),
      };
    }

    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.data.league];
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byLeagueId,
});

// Selectors
export const getAllElementStatus = (state) => g2l(state).byLeagueId;

export const getActiveLeagueElementStatus = createSelector(
  getAllElementStatus,
  getActiveLeague,
  (status, league) => {
    if (league && status[league.id]) {
      return status[league.id];
    }
    return {};
  },
);

export const propTypeElementStatus = PropTypes.shape({
  element: PropTypes.number.isRequired,
  owner: PropTypes.number,
  status: PropTypes.string.isRequired,
});
