import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDateAsISO = (dt) =>
  utcToZonedTime(dt, localTimeZone).toISOString();

export const formatDateAsLocal = (dt) =>
  formatInTimeZone(dt, localTimeZone, "E d MMM HH:mm zzz");

export const formatRawAsDate = (raw) =>
  formatInTimeZone(new Date(raw), localTimeZone, "d MMM");

export const formatRawAsISO = (raw) => formatDateAsISO(new Date(raw));

export const formatRawAsLocal = (raw) => formatDateAsLocal(new Date(raw));

export const getValidTimeZones = () =>
  Intl.supportedValuesOf("timeZone").filter((tz) => {
    if (tz.indexOf("/") === -1) return false;
    if (tz.indexOf("Etc/") === 0) return false;
    return true;
  });

export const getDefaultTZ = () => {
  const valid = getValidTimeZones();
  return valid.indexOf(localTimeZone) > -1 ? localTimeZone : "UTC";
};

export default formatDateAsLocal;
