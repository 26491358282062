import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { suggestElementTrade } from "../../../actions/Transaction";
import { getActiveEntry } from "../../../reducers/entries";
import { getActiveLeagueEntriesByEntryId } from "../../../reducers/leagueEntries";
import { tradesAvailableForActiveLeague } from "../../../reducers/leagues";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import { TradeButton, TradeButtonLabel } from "./styles";

interface IOwnProps {
  elementId: number;
  elStatus: any;
}

const ElementOwned: React.FC<IOwnProps> = ({ elementId, elStatus }) => {
  const entriesById = useSelector(getActiveLeagueEntriesByEntryId);
  const entry = useSelector(getActiveEntry);
  const canActiveLeagueTrade = useSelector(tradesAvailableForActiveLeague);

  const dispatch = useDispatch();

  const leagueEntry = entriesById[elStatus.owner];
  if (
    canActiveLeagueTrade &&
    elStatus.owner !== entry.id &&
    !elStatus.in_accepted_trade
  ) {
    return (
      <TradeButton
        type="button"
        onClick={() => dispatch(suggestElementTrade(elementId))}
      >
        <TradeButtonLabel>Trade - </TradeButtonLabel>
        <span>{leagueEntry.short_name || "??"}</span>
      </TradeButton>
    );
  }
  return <LeagueEntryShortName entryId={leagueEntry.entry_id} />;
};

export default ElementOwned;
