import React from "react";
import PropTypes from "prop-types";
import { propTypeElement } from "../../../reducers/elements";
import {
  propTypeTradeItem,
  propTypeTradeWarningElement,
} from "../../../reducers/transactions";
import ElementTradeWarning from "../ElementTradeWarning";
import {
  StyledTradeSummaryTable,
  TradeSummaryColumn,
  TradeSummaryName,
} from "./styles";

const TradeSummaryCell = ({ element, tradeWarningElements }) => {
  // Is the element id found in the tradeWarningElements object?
  const hasTradeWarning = tradeWarningElements
    ? Object.prototype.hasOwnProperty.call(tradeWarningElements, element.id)
    : false;
  return (
    <td>
      <TradeSummaryName>{element.web_name}</TradeSummaryName>
      {hasTradeWarning && <ElementTradeWarning />}
    </td>
  );
};

TradeSummaryCell.propTypes = {
  element: propTypeElement.isRequired,
  tradeWarningElements: PropTypes.objectOf(propTypeTradeWarningElement),
};

TradeSummaryCell.defaultProps = {
  tradeWarningElements: null,
};

const TradeSummaryTable = ({ tradeItems, elementsById, ...rest }) => (
  <StyledTradeSummaryTable>
    <thead>
      <tr>
        <TradeSummaryColumn>Offered</TradeSummaryColumn>
        <TradeSummaryColumn>Requested</TradeSummaryColumn>
      </tr>
    </thead>
    <tbody>
      {tradeItems.map((e) => (
        <tr key={e.element_out}>
          <TradeSummaryCell element={elementsById[e.element_out]} {...rest} />
          <TradeSummaryCell element={elementsById[e.element_in]} {...rest} />
        </tr>
      ))}
    </tbody>
  </StyledTradeSummaryTable>
);

TradeSummaryTable.propTypes = {
  tradeItems: PropTypes.arrayOf(propTypeTradeItem).isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
};

export default TradeSummaryTable;
