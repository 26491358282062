import styled from "styled-components/macro";

export const StyledSubmitBar = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.theme.space[4]};
  padding-right: ${(props) => props.theme.space[2]};
  padding-left: ${(props) => props.theme.space[2]};
`;

export const SubmitBarButtonWrap = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-right: calc(100% / 3);
    margin-bottom: 0;
    margin-left: calc(100% / 3);
  }
`;
