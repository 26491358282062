import React, { useEffect, useState } from "react";
import { IPitch } from "./types";
import { StyledPitch } from "./styles";

const Pitch: React.FC<IPitch> = ({ children, sponsor }) => {
  let [pitchSrc, setPitchSrc] = useState("");

  useEffect(() => {
    import(`../../img/pitches/pitch-${sponsor}.svg`).then((src) => {
      setPitchSrc(src.default);
    });
  }, [sponsor]);

  return (
    <StyledPitch pitch={pitchSrc} data-testid="pitch">
      {children}
    </StyledPitch>
  );
};

export default Pitch;
