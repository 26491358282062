import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import {
  ADD_CHAT_MESSAGE_SUCCESS,
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  DELETE_LEAGUE_ENTRY_SUCCESS,
  FETCH_CHAT_MESSAGES_SUCCESS,
} from "../actions/ActionTypes";
import { getActiveLeague } from "./leagues";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.chatMessages;
export const l2g = (local) => ({ chatMessages: local });

const byLeagueId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        [action.data.leagueId]: action.data.messages,
      };

    case ADD_CHAT_MESSAGE_SUCCESS: {
      if (state[action.data.league]) {
        return {
          ...state,
          [action.data.league]: [...state[action.data.league], action.data],
        };
      }
      return {
        ...state,
        [action.data.league]: [action.data],
      };
    }

    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      const newState = {
        ...state,
      };
      delete newState[action.data.league];
      return newState;
    }

    case DELETE_LEAGUE_ENTRY_SUCCESS: {
      const newState = { ...state };
      newState[action.data.league] = newState[action.data.league].filter(
        (e) => e.entry !== action.data.entry,
      );
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byLeagueId,
});

// Selectors
export const getMessagesByLeagueId = (state) => g2l(state).byLeagueId;

export const getChatMessages = (state, id) => getMessagesByLeagueId(state)[id];

export const getActiveChatMessages = createSelector(
  getMessagesByLeagueId,
  getActiveLeague,
  (messages, league) => messages[league.id],
);

// PropTypes
export const propTypeChatMessage = PropTypes.shape({
  id: PropTypes.id,
  entry: PropTypes.number,
  entry_name: PropTypes.string,
  player_first_name: PropTypes.string,
  player_last_name: PropTypes.string,
  posted: PropTypes.string,
  text: PropTypes.string,
});
