import styled from "styled-components/macro";

const SubHeading = styled.h3`
  margin-top: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2rem;
  }
`;

export default SubHeading;
