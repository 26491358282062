import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { elementDialogShow as eds } from "../../actions/Element";
import {
  getActiveLeagueElementStatus,
  propTypeElementStatus,
} from "../../reducers/elementStatus";
import {
  getElementTypesById,
  propTypeElementType,
} from "../../reducers/elementTypes";
import { getLatestInjuries, propTypeElement } from "../../reducers/elements";
import {
  getActiveLeagueEntriesByEntryId,
  propTypeLeagueEntry,
} from "../../reducers/leagueEntries";
import { getTeamsById, propTypeTeam } from "../../reducers/teams";
import ElementShirt from "../ElementShirt";
import ElementStatusButton from "../ElementStatusButton";
import LeagueEntryShortName from "../LeagueEntryShortName";
import Panel from "../Panel";
import { VisuallyHidden } from "../styles";
import {
  ElementStatBold,
  ElementStatBoldHeading,
  ElementStatInfo,
  ElementStatName,
  ElementStatShirt,
  ElementStatTable,
} from "./styles";

const ElementNews = ({
  element,
  elementDialogShow,
  elementType,
  owner,
  team,
}) => (
  <tr>
    <ElementStatInfo>
      <ElementStatusButton elementId={element.id} />
    </ElementStatInfo>
    <ElementStatShirt>
      <ElementShirt elementId={element.id} sizes="16px" />
    </ElementStatShirt>
    <ElementStatName>{element.web_name}</ElementStatName>
    <ElementStatBold>{elementType.singular_name_short}</ElementStatBold>
    <ElementStatBold>{team.short_name}</ElementStatBold>
    <ElementStatBold>
      {owner ? (
        <LeagueEntryShortName entryId={owner.entry_id} />
      ) : (
        <span>&nbsp;</span>
      )}
    </ElementStatBold>
  </tr>
);

ElementNews.propTypes = {
  element: propTypeElement.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementType: propTypeElementType.isRequired,
  owner: propTypeLeagueEntry,
  team: propTypeTeam.isRequired,
};

ElementNews.defaultProps = {
  owner: null,
};

const LatestElementsNews = ({
  activeLeagueElementStatus,
  elementDialogShow,
  elements,
  elementTypesById,
  entriesById,
  length,
  teamsById,
}) => {
  const getElementOwner = (element, leagueElementStatus, entries) =>
    leagueElementStatus[element.id] &&
    entries[leagueElementStatus[element.id].owner];

  return (
    <Panel headingText="Latest Injuries &amp; Suspensions">
      <Box mx={-2} mb={2}>
        <ElementStatTable>
          <thead>
            <tr>
              <ElementStatInfo>
                <VisuallyHidden>Info</VisuallyHidden>
              </ElementStatInfo>
              <ElementStatShirt>
                <VisuallyHidden>Shirt</VisuallyHidden>
              </ElementStatShirt>
              <ElementStatName>Player</ElementStatName>
              <ElementStatBoldHeading>Pos</ElementStatBoldHeading>
              <ElementStatBoldHeading>Club</ElementStatBoldHeading>
              <ElementStatBoldHeading>Mgr</ElementStatBoldHeading>
            </tr>
          </thead>
          <tbody>
            {elements.slice(0, length).map((element) => (
              <ElementNews
                element={element}
                elementDialogShow={elementDialogShow}
                elementType={elementTypesById[element.element_type]}
                key={element.id}
                owner={getElementOwner(
                  element,
                  activeLeagueElementStatus,
                  entriesById,
                )}
                team={teamsById[element.team]}
              />
            ))}
          </tbody>
        </ElementStatTable>
      </Box>
    </Panel>
  );
};

LatestElementsNews.propTypes = {
  activeLeagueElementStatus: PropTypes.objectOf(propTypeElementStatus)
    .isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementTypesById: PropTypes.objectOf(propTypeElementType).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  length: PropTypes.number,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

LatestElementsNews.defaultProps = {
  length: 16,
};

export { LatestElementsNews as LatestElementsNewsTest };

const mapStateToProps = (state) => ({
  activeLeagueElementStatus: getActiveLeagueElementStatus(state),
  elements: getLatestInjuries(state),
  elementTypesById: getElementTypesById(state),
  entriesById: getActiveLeagueEntriesByEntryId(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = {
  elementDialogShow: eds,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestElementsNews);
