import { Box } from "rebass/styled-components";
import { Field } from "redux-form";
import { SelectField } from "./FieldRenderers";

const LeagueScoringField = () => (
  <Box mb={4}>
    <Field
      id="leagueScoring"
      name="leagueScoring"
      component={SelectField}
      label="League Scoring"
    >
      <option value="h">Head-to-Head</option>
      <option value="c">Classic</option>
    </Field>
  </Box>
);

const validateLeagueScoringField = (values: any) => {
  interface IErrors {
    [key: string]: string;
  }
  const errors: IErrors = {};

  // League Scoring validation
  if (!values.leagueScoring) {
    errors.leagueScoring = "Required";
  }

  return errors;
};

export {
  validateLeagueScoringField,
  LeagueScoringField as LeagueScoringFieldTest,
};
export default LeagueScoringField;
