import PropTypes from "prop-types";
import { FETCH_EVENT_STATUS_SUCCESS } from "../actions/ActionTypes";

const reducer = (state = { status: [], leagues: "" }, action) => {
  switch (action.type) {
    case FETCH_EVENT_STATUS_SUCCESS: {
      return action.data;
    }

    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getEventStatus = (state) => state.eventStatus;

// PropTypes
export const propTypeEventStatus = PropTypes.shape({
  status: PropTypes.arrayOf(
    PropTypes.shape({
      bonus_added: PropTypes.bool,
      date: PropTypes.string,
      event: PropTypes.number,
      leagues_updated: PropTypes.bool,
      points: PropTypes.oneOf(["", "r", "", "p"]),
    }),
  ),
  leagues: PropTypes.oneOf(["", "Updating", "", "Updated"]),
});
