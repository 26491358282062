import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import { getNextEvent } from "../../reducers/circularProblems";
import { getNext3Events, propTypeEvent } from "../../reducers/events";
import { RootState } from "../../reducers/rootReducer";
import { getTeams } from "../../reducers/teams";
import {
  FixturesByEvent,
  IFixture,
  IFixtureGroup,
  IFixturesById,
  IGroupedFixturesByEvent,
  IState,
} from "../fixtures/types";
import { ITeam } from "../teams/types";
import { INextThree } from "./types";

export const initialState: IState = {
  byEvent: {},
};

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global: RootState) => global.fixtures;
export const l2g = (local: IState) => ({ fixtures: local });

const fixturesSlice = createSlice({
  name: "fixtures",
  initialState,
  reducers: {
    addFixtures(state, action: PayloadAction<IFixturesById>) {
      const byEvent = Object.keys(action.payload).reduce(
        (acc: IFixturesById, eventId: any) => ({
          ...acc,
          [eventId]: action.payload[eventId],
        }),
        {},
      );
      return {
        ...state,
        byEvent,
      };
    },
    updateFixtures(state, action) {
      return {
        ...state,
        byEvent: {
          ...state.byEvent,
          [action.payload.event]: action.payload.fixtures,
        },
      };
    },
  },
});

// Actions
export const { addFixtures, updateFixtures } = fixturesSlice.actions;

export default fixturesSlice.reducer;

// Selectors
export const getFixturesByEvent = (state: RootState): FixturesByEvent =>
  g2l(state).byEvent;

export const getGroupedFixturesByEvent = (state: RootState) => {
  const fixturesByEvent = getFixturesByEvent(state);
  const groupedFixturesByEvent: IGroupedFixturesByEvent = {};

  Object.keys(fixturesByEvent).forEach((eventId) => {
    const fixtures = fixturesByEvent[eventId];
    const groups: IFixtureGroup[] = [];
    let currentGroup: IFixtureGroup;
    fixtures.forEach((fixture: IFixture) => {
      if (fixture.kickoff_time) {
        const kickoffDate = new Date(fixture.kickoff_time);
        const fixtureDate = startOfDay(
          utcToZonedTime(
            kickoffDate,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          ),
        );
        if (currentGroup == null || !isEqual(fixtureDate, currentGroup.date)) {
          currentGroup = {
            date: fixtureDate,
            fixtures: [],
          };
          groups.push(currentGroup);
        }
        currentGroup.fixtures.push({ kickoffDate, ...fixture });
      }
    });
    groupedFixturesByEvent[eventId] = groups;
  });
  return groupedFixturesByEvent;
};

export const getEventFixturesByTeam = (
  teams: ITeam[],
  fixtures: FixturesByEvent,
  eventId: number,
) => {
  const eventData: any = teams.reduce(
    (memo, t) => Object.assign(memo, { [t.id]: [] }),
    {},
  );
  if (fixtures[eventId]) {
    fixtures[eventId].forEach((f) => {
      // Home team
      eventData[f.team_h].push({
        ...f,
        opponent: f.team_a,
        isHome: true,
      });
      // Away team
      eventData[f.team_a].push({
        ...f,
        opponent: f.team_h,
        isHome: false,
      });
    });
  }
  return eventData;
};

export const getFixturesByTeamForEvent = (
  state: RootState,
  eventId: number,
) => {
  const teams = getTeams(state);
  const all = getFixturesByEvent(state);

  return getEventFixturesByTeam(teams, all, eventId);
};

export const getNextEventFixturesByTeam = createSelector(
  getNextEvent,
  getFixturesByEvent,
  getTeams,
  (event, all, teams) =>
    getEventFixturesByTeam(teams, all, event ? event.id : 0),
);

export const getNext3EventsFixturesByTeam = createSelector(
  getNext3Events,
  getFixturesByEvent,
  getTeams,
  (events, all, teams) => {
    const data: INextThree[] = [];
    events.forEach((e) => {
      const eventData = getEventFixturesByTeam(teams, all, e.id);
      data.push({
        event: e,
        fixtures: eventData,
      });
    });
    return data;
  },
);

// PropTypes
const coreFixture = {
  id: PropTypes.number,
  team_a: PropTypes.number,
  team_h: PropTypes.number,
};

export const propTypeFixture = PropTypes.shape(coreFixture);

export const propTypeEventTeamFixture = PropTypes.shape({
  event: propTypeEvent,
  fixtures: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ...coreFixture,
        opponent: PropTypes.number,
        isHome: PropTypes.bool,
      }),
    ),
  ),
});

export const propTypeEventFixtures = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      ...coreFixture,
      opponent: PropTypes.number,
      isHome: PropTypes.bool,
    }),
  ),
);

export const propTypeTeamEventFixtures = PropTypes.arrayOf(
  PropTypes.shape({
    ...coreFixture,
    opponent: PropTypes.number,
    isHome: PropTypes.bool,
  }),
);

export const propTypeGroupedFixtureByEvent = PropTypes.shape({
  date: PropTypes.object.isRequired,
  fixtures: PropTypes.arrayOf(propTypeFixture).isRequired,
});
