// https://date-fns.org/v2.29.3/docs/format
// Use these date formats when displaying draft dates (match PL date format used for fixtures)
export const DISPLAY_DRAFT_DATE_FORMAT = "E d MMM";
export const DISPLAY_DRAFT_TIME_FORMAT = "kk:mm";
export const DISPLAY_DRAFT_DATE_TIME_FORMAT = `${DISPLAY_DRAFT_DATE_FORMAT} ${DISPLAY_DRAFT_TIME_FORMAT}`;

// Use these date formats when selecting draft dates (e.g. date picker)
export const SELECT_DRAFT_DATE_FORMAT = "E d MMM yyyy";
export const SELECT_DRAFT_TIME_FORMAT = "h:mm a";
export const SELECT_DRAFT_DATE_TIME_FORMAT = `${SELECT_DRAFT_DATE_FORMAT}, ${SELECT_DRAFT_TIME_FORMAT}`;

const DRAFT_DATE_FORMAT_OBJ = {
  SELECT_DRAFT_DATE_FORMAT,
};

export default DRAFT_DATE_FORMAT_OBJ;
