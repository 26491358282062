import React from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components/macro";
import GlobalStyle from "../GlobalStyle";
import "../fonts.css";
import { bootstrap } from "../store/bootstrap/actions";
import { getBootstrapped } from "../store/bootstrap/selectors";
import Theme from "../theme";
import GameHeader from "./GameHeader";
import HelmetHead from "./HelmetHead";
import Loader from "./Loader";
import Routes from "./Routes";
import ElementDialog from "./element/ElementDialog";
import FixtureBroadcasters from "./fixtures/FixtureBroadcasters";

const App: React.FC<OwnProps> = ({ children }) => {
  return (
    <React.StrictMode>
      <HelmetHead />
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
        <GameHeader />
        <FixtureBroadcasters>
          <Routes />
        </FixtureBroadcasters>
        <ElementDialog />
      </ThemeProvider>
    </React.StrictMode>
  );
};

interface IContainerPropsFromState {
  bootstrapped: boolean;
  dispatch: any;
}

type OwnProps = IContainerPropsFromState;

export class AppContainer extends React.Component<OwnProps> {
  componentDidMount() {
    if (!this.props.bootstrapped) {
      this.props.dispatch(bootstrap());
    }
  }
  render() {
    if (!this.props.bootstrapped) {
      return <Loader />;
    }
    return <App {...this.props} />;
  }
}

const mapStateToProps = (state: any) => ({
  bootstrapped: getBootstrapped(state.bootstrap),
});

export default connect(mapStateToProps)(AppContainer);
