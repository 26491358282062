import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { getActiveEntry } from "./entries";
import { getGame } from "./game";
import {
  ADD_LEAGUES,
  CREATE_LEAGUE_SUCCESS,
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  DRAFT_STARTED,
  EDIT_LEAGUE_SUCCESS,
  FETCH_LEAGUE_SUCCESS,
  JOIN_LEAGUE_SUCCESS,
  LOGIN_SUCCESS,
} from "../actions/ActionTypes";

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_LEAGUES: {
      const newState = { ...state };
      action.data.forEach((league) => {
        newState[league.id] = league;
      });
      return newState;
    }

    case LOGIN_SUCCESS: {
      const newState = { ...state };
      action.data.leagues.forEach((league) => {
        newState[league.id] = league;
      });
      return newState;
    }

    case CREATE_LEAGUE_SUCCESS:
    case EDIT_LEAGUE_SUCCESS:
    case FETCH_LEAGUE_SUCCESS:
    case JOIN_LEAGUE_SUCCESS:
      return {
        ...state,
        [action.data.league.id]: action.data.league,
      };

    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.data.league];
      return newState;
    }

    case DRAFT_STARTED: {
      if (!state[action.data.league]) return state;
      return {
        ...state,
        [action.data.league]: {
          ...state[action.data.league],
          draft_status: "during",
        },
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  byId,
});

// Selectors
export const getLeagues = (state) => state.leagues;

export const getLeague = (state, id) => getLeagues(state).byId[id];

// Add some extra data dependent on entry
export const getLeagueForEntry = (state, entry) => {
  if (!entry.league_set) return {};
  const league = Object.assign({}, getLeague(state, entry.league_set[0]));
  if (league) {
    if (league.admin_entry && entry.id === league.admin_entry) {
      league.can_admin = true;
    } else {
      league.can_admin = false;
    }
  }

  return league;
};

export const getActiveLeague = (state) => {
  const activeEntry = getActiveEntry(state);
  if (activeEntry) {
    return getLeagueForEntry(state, activeEntry);
  }
  return null;
};

export const tradesAvailableForActiveLeague = (state) => {
  const game = getGame(state);
  const league = getActiveLeague(state);
  if (!league || !game) return false;
  if (league.transaction_mode !== "waivers") return false;
  switch (league.trades) {
    case "y":
      return true;
    case "a":
    case "m":
      return game.trades_time_for_approval;
    default:
      return false;
  }
};

// PropTypes
export const propTypeLeague = PropTypes.shape({
  can_admin: PropTypes.bool,
  draft_dt: PropTypes.string,
  draft_tz_show: PropTypes.string,
  name: PropTypes.string,
});
