import styled, { css } from "styled-components/macro";
import { IStickyStatus } from "./types";

export const Idle = styled.div`
  padding: 0.8rem 10%;
  background-color: ${(props) => props.theme.colors.darkPink};
  text-align: center;
`;

export const IdleHeading = styled.h4`
  margin-bottom: ${(props) => props.theme.space[2]};
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-transform: uppercase;
`;

export const StickyStatus = styled.div<IStickyStatus>`
  margin-bottom: ${(props) => props.theme.space[4]};
  background-color: #fff;
  z-index: 200;

  ${(props) =>
    props.isStuck &&
    css`
      position: fixed;
      top: 0;
      box-shadow: 0 8px 8px -6px ${(props) => props.theme.colors.darkGrey};
    `}
`;
