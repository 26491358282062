import { navigate } from "@gatsbyjs/reach-router";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  handleUnexpectedError,
} from "../helpers";
import { getElementsById } from "../reducers/elements";
import { getActiveLeagueElementStatus } from "../reducers/elementStatus";
import { isEntryIdInActiveLeague } from "../reducers/leagueEntries";
import { getActiveLeague } from "../reducers/leagues";
import { getActiveEntryId } from "../reducers/player";
import {
  getFreeAgencyAPI,
  getProposedElementId,
  getTradeOfferAPI,
  getWaivers,
  getWaiversAPI,
} from "../reducers/transactions";
import { checkGame } from "../store/bootstrap/actions";
import {
  ADD_CURRENT_TRADES,
  ADD_LEAGUE_TRADES,
  ADD_LEAGUE_TRANSACTIONS,
  ADD_TRADES_FOR_APPROVAL,
  ADD_TRANSACTION_ERROR_ELEMENT,
  CANCEL_ELEMENT_TRANSACTION,
  PROPOSE_ELEMENT_TRANSACTION,
  PROPOSE_TRADE_INITIAL,
  TRANSFER_ADD,
  TRANSFER_ERROR_RESET,
  TRANSFER_REMOVE,
  TRANSFER_RESET,
  WAIVER_ADD,
  WAIVER_CHANGE,
  WAIVER_REMOVE,
  WAIVER_UPDATE,
} from "./ActionTypes";
import { fetchElementStatus, fetchElementStatusSuccess } from "./ElementStatus";
import { leagueDataFetch } from "./League";
import { addMyPicks, entryPublicFetch } from "./Pick";
import { watchlistUpdate } from "./Watchlist";

// Regular action creators

export const proposeElementTransaction = (element) => ({
  type: PROPOSE_ELEMENT_TRANSACTION,
  element,
});

export const actionCancelElementTransaction = () => ({
  type: CANCEL_ELEMENT_TRANSACTION,
});

export const proposeTradeRecipient = (entryId, elementId) => ({
  type: PROPOSE_TRADE_INITIAL,
  entryId,
  elementId,
});

export const addElementError = (element) => ({
  type: ADD_TRANSACTION_ERROR_ELEMENT,
  element,
});

export const addLeagueTrades = (leagueId, trades) => ({
  type: ADD_LEAGUE_TRADES,
  data: {
    leagueId,
    trades,
  },
});

export const addLeagueTransactions = (leagueId, transactions) => ({
  type: ADD_LEAGUE_TRANSACTIONS,
  data: {
    leagueId,
    transactions,
  },
});

export const actionWaiverAdd = (elementIn, elementOut) => ({
  type: WAIVER_ADD,
  data: {
    element_in: elementIn,
    element_out: elementOut,
  },
});

export const actionWaiverChange = (oldPriority, newPriority) => ({
  type: WAIVER_CHANGE,
  data: {
    oldPriority,
    newPriority,
  },
});

export const actionWaiverRemove = (priority) => ({
  type: WAIVER_REMOVE,
  priority,
});

export const actionWaiverUpdate = (data) => ({
  type: WAIVER_UPDATE,
  data,
});

export const actionTransferAdd = (elementIn, elementOut) => ({
  type: TRANSFER_ADD,
  data: {
    element_in: elementIn,
    element_out: elementOut,
  },
});

export const transferRemove = (element) => ({
  type: TRANSFER_REMOVE,
  element,
});

export const transferReset = () => ({
  type: TRANSFER_RESET,
});

export const transferErrorReset = () => ({
  type: TRANSFER_ERROR_RESET,
});

export const actionAddCurrentTrades = (trades) => ({
  type: ADD_CURRENT_TRADES,
  data: trades,
});

export const actionAddTradesForApproval = (trades) => ({
  type: ADD_TRADES_FOR_APPROVAL,
  data: trades,
});

// Thunk action creators

export const suggestElementTransaction = (element) => (dispatch) => {
  dispatch(proposeElementTransaction(element));
  navigate("/team/transactions/remove");
};

export const cancelElementTransaction = () => (dispatch) => {
  dispatch(actionCancelElementTransaction());
  navigate("/team/transactions");
};

export const transactionDataFetch = () => (dispatch, getState) => {
  dispatch(checkGame());
  const league = getActiveLeague(getState());
  const entryId = getActiveEntryId(getState());
  Promise.all([
    fetchGet(`/api/draft/entry/${entryId}/transactions`),
    dispatch(leagueDataFetch(league.id)),
    dispatch(fetchElementStatus(league)),
  ]).then(
    ([d]) => {
      dispatch([
        addMyPicks(d.data.picks, getElementsById(getState())),
        actionWaiverUpdate(d.data.waivers),
        watchlistUpdate(d.data.watchlist),
        transferErrorReset(),
        actionAddCurrentTrades(d.data.trades),
      ]);
    },
    (e) => {
      if (e.reason === "NOT_2XX" && e.response.status === 403) {
        if (e.error.detail && e.error.detail === "League is processing") {
          return navigate("/processing", { replace: true });
        }
      }
      return handleUnexpectedError(e, dispatch);
    },
  );
};

export const leagueTransactionsFetch = (leagueId) => (dispatch) =>
  fetchGet(`/api/draft/league/${leagueId}/transactions`).then(
    (d) => dispatch(addLeagueTransactions(leagueId, d.data.transactions)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const leagueTradesFetch = (leagueId) => (dispatch) =>
  fetchGet(`/api/draft/league/${leagueId}/trades`).then(
    (d) => dispatch(addLeagueTrades(leagueId, d.data.trades)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const eventTradesFetch = () => (dispatch, getState) => {
  const league = getActiveLeague(getState());
  const entryId = getActiveEntryId(getState());
  Promise.all([
    fetchGet(`/api/draft/entry/${entryId}/trades/current`),
    dispatch(leagueTradesFetch(league.id)),
  ]).then(
    (d) => actionAddCurrentTrades(d[0].data),
    (e) => handleUnexpectedError(e, dispatch),
  );
};

export const suggestElementTrade = (elementId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const status = getActiveLeagueElementStatus(getState());
    const elementStatus = status[elementId];
    if (elementStatus && elementStatus.status === "o") {
      dispatch(proposeTradeRecipient(elementStatus.owner, elementId));
      navigate("/team/transactions/trade");
      resolve();
    } else {
      reject();
    }
  });

export const updateFreeAgency = () => (dispatch, getState) => {
  const league = getActiveLeague(getState());
  const entryId = getActiveEntryId(getState());
  fetchPost(
    `/api/draft/entry/${entryId}/free-agency`,
    getFreeAgencyAPI(getState()),
  ).then(
    (d) => {
      dispatch([
        addMyPicks(d.data.picks, getElementsById(getState())),
        fetchElementStatusSuccess(d.data.element_league_status, league),
        transferReset(),
      ]);
      navigate("/team/my");
    },
    (e) => {
      // If element is no longer available update element_league_status
      // and show error else default error handler
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        if (e.error.errors && e.error.element_league_status) {
          const elementOwned = e.error.errors.reduce((m1, e1) => {
            if (e1.element_in) {
              return e1.element_in.reduce((m2, e2) => {
                const matches = e2.match(/Element (\d+) is not available/);
                return matches ? matches[1] : m2;
              }, m1);
            }
            return m1;
          }, null);
          if (elementOwned) {
            dispatch([
              fetchElementStatusSuccess(e.error.element_league_status, league),
              addElementError(parseInt(elementOwned, 10)),
            ]);
            return;
          }
        }
      }
      handleUnexpectedError(e, dispatch);
    },
  );
};

const updateWaivers = (dispatch, getState) =>
  fetchPost(
    `/api/draft/entry/${getActiveEntryId(getState())}/waivers`,
    getWaiversAPI(getState()),
  ).then(
    (d) => dispatch(actionWaiverUpdate(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const waiverAdd = (id) => (dispatch, getState) => {
  dispatch(actionWaiverAdd(getProposedElementId(getState()), id));
  navigate("/team/transactions#proposed");
  return updateWaivers(dispatch, getState);
};

export const waiverChange =
  (oldPriority, newPriority) => (dispatch, getState) => {
    // Bail early if things haven't changed or isn't valid
    if (oldPriority === newPriority) return null;
    if (oldPriority < 1 || newPriority < 1) return null;
    const numWaivers = getWaivers(getState()).length;
    if (newPriority > numWaivers || oldPriority > numWaivers) return null;

    dispatch(actionWaiverChange(oldPriority, newPriority));
    return updateWaivers(dispatch, getState);
  };

export const waiverRemove = (priority) => (dispatch, getState) => {
  dispatch(actionWaiverRemove(priority));
  return updateWaivers(dispatch, getState);
};

export const transferAdd = (id) => (dispatch, getState) => {
  dispatch(actionTransferAdd(getProposedElementId(getState()), id));
  navigate("/team/transactions#proposed");
};

export const leagueTransactionsFetchForEntry =
  (entryId) => (dispatch, getState) => {
    // Check the requested entry is in the active league
    if (!isEntryIdInActiveLeague(getState(), entryId)) {
      return navigate("/not-found");
    }
    const league = getActiveLeague(getState());
    return Promise.all([
      dispatch(leagueTradesFetch(league.id)),
      dispatch(entryPublicFetch(entryId)),
      dispatch(leagueTransactionsFetch(league.id)),
    ]);
  };

export const tradesForApprovalFetch = () => (dispatch, getState) =>
  fetchGet(
    `/api/draft/entry/${getActiveEntryId(getState())}/trades/for-approval`,
  ).then(
    (d) => dispatch(actionAddTradesForApproval(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const acceptTradeOffer = (tradeId) => (dispatch, getState) =>
  fetchPost(
    `/api/draft/entry/${getActiveEntryId(getState())}/trade/${tradeId}/accept`,
    {},
  ).then(
    (d) => {
      dispatch(actionAddCurrentTrades(d.data));
      if (d.response.status === 200) {
        navigate("/team/my");
      } else {
        navigate("/team/transactions#proposed");
      }
    },
    (error) => handleUnexpectedError(error, dispatch),
  );

export const makeTradeOffer = () => (dispatch, getState) =>
  fetchPost(
    `/api/draft/entry/${getActiveEntryId(getState())}/trades/`,
    getTradeOfferAPI(getState()),
  ).then(
    (d) => {
      dispatch(actionAddCurrentTrades(d.data));
      navigate("/team/transactions#proposed");
    },
    (error) => handleUnexpectedError(error, dispatch),
  );

export const rejectTradeOffer = (tradeId) => (dispatch, getState) =>
  fetchDelete(
    `/api/draft/entry/${getActiveEntryId(getState())}/trade/${tradeId}/reject`,
    {},
  ).then(
    (d) => dispatch(actionAddCurrentTrades(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const withdrawTradeOffer = (tradeId) => (dispatch, getState) =>
  fetchDelete(
    `/api/draft/entry/${getActiveEntryId(
      getState(),
    )}/trade/${tradeId}/withdraw`,
    {},
  ).then(
    (d) => dispatch(actionAddCurrentTrades(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

// Approving and Object to trades impact both currentTrades (if admin) and
// element league status (if trade is procssed), it my be that we need to fetch
// this data
export const approveAcceptedTrade = (tradeId) => (dispatch, getState) =>
  fetchPost(
    `/api/draft/entry/${getActiveEntryId(
      getState(),
    )}/trade/${tradeId}/decision`,
    {},
  ).then(
    (d) => dispatch(actionAddTradesForApproval(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const objectAcceptedTrade = (tradeId) => (dispatch, getState) =>
  fetchDelete(
    `/api/draft/entry/${getActiveEntryId(
      getState(),
    )}/trade/${tradeId}/decision`,
    {},
  ).then(
    (d) => dispatch(actionAddTradesForApproval(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );
