import styled from "styled-components/macro";
import Table from "../../Table";

export const PlayerCell = styled.th`
  width: 22%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 18%;
  }
`;

export const TransactionHeading = styled.th`
  width: 58%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 66%;
  }
`;

export const TransactionCell = styled.td``;

export const TransactionTypeCell = styled.th`
  width: 20%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 16%;
  }
`;

export const StyledRecentTransactionsTable = styled(Table)`
  table-layout: fixed;

  thead,
  tbody {
    th,
    td {
      text-align: center;
    }
  }

  ${TransactionCell} {
    padding: 0;
  }
`;
