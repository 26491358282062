import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeElementType } from "../../reducers/elementTypes";
import { propTypeElement } from "../../reducers/elements";
import TradeSquadTable from "./TradeSquadTable";
import { TradeEntry, TradeFormLegend, TradeTitle } from "./TradeWrapper/styles";

const TradeSquad = ({
  elementTypes,
  elementsByType,
  entryTeamName,
  entryFirstName,
  entryLastName,
  title,
  ...rest
}) => (
  <Box mb={4}>
    <fieldset>
      <TradeFormLegend>
        <TradeTitle>{title}</TradeTitle>
        <TradeEntry>
          {` - ${entryTeamName} (${entryFirstName} ${entryLastName})`}
        </TradeEntry>
      </TradeFormLegend>
      {elementTypes.map((et) => (
        <TradeSquadTable
          {...rest}
          elements={elementsByType[`et${et.id}`]}
          elementType={et}
          key={et.id}
        />
      ))}
    </fieldset>
  </Box>
);

TradeSquad.propTypes = {
  elementTypes: PropTypes.arrayOf(propTypeElementType).isRequired,
  elementsByType: PropTypes.objectOf(PropTypes.arrayOf(propTypeElement))
    .isRequired,
  entryTeamName: PropTypes.string,
  entryFirstName: PropTypes.string,
  entryLastName: PropTypes.string,
  title: PropTypes.string.isRequired,
};

TradeSquad.defaultProps = {
  entryTeamName: "",
  entryFirstName: "",
  entryLastName: "",
};

export default TradeSquad;
