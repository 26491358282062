import React from "react";

const Loader = () => (
  <div data-testid="loader" className="ism-loader">
    <div className="ism-loader__dot ism-loader__dot--1" />
    <div className="ism-loader__dot ism-loader__dot--2" />
    <div className="ism-loader__dot ism-loader__dot--3" />
    <div className="ism-loader__dot ism-loader__dot--4" />
    <div className="ism-loader__dot ism-loader__dot--5" />
    <div className="ism-loader__dot ism-loader__dot--6" />
    <div className="ism-loader__dot ism-loader__dot--7" />
    <div className="ism-loader__dot ism-loader__dot--8" />
    <div className="ism-loader__dot ism-loader__dot--9" />
    <div className="ism-loader__dot ism-loader__dot--10" />
    <div className="ism-loader__dot ism-loader__dot--11" />
    <div className="ism-loader__dot ism-loader__dot--12" />
  </div>
);

export default Loader;
