import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { getFixturesByEvent } from "../../store/fixtures/slice";
import { FixturesByEvent } from "../../store/fixtures/types";
import { fetchBroadcastersForFixtures } from "../../utils/pulse";

export const FixtureBroadcastersContext = React.createContext({} as State);

interface IOwnProps {
  children: React.ReactNode;
}

interface IPropsFromState {
  fixturesByEvent: FixturesByEvent;
}

export interface IBroadcasterInfo {
  abbreviation: string;
  name: string;
  url: string;
}

type Props = IOwnProps & IPropsFromState;

type State = Record<string, Record<string, IBroadcasterInfo[]>>;

class FixtureBroadcasters extends React.Component<Props, State> {
  public state: State = {};

  public componentDidMount() {
    this.fetchBroadcasters();
  }

  public componentDidUpdate() {
    this.fetchBroadcasters();
  }

  fetchBroadcasters = () => {
    Object.keys(this.props.fixturesByEvent).forEach(async (event) => {
      if (!this.state[event]) {
        // Initially set an empty object so we don't set more than once
        this.setState((prevState: any) => ({
          ...prevState,
          [event]: {},
        }));

        // Build up a map of opta codes to fixtures ids
        const fixtures = this.props.fixturesByEvent[event];

        // There are no fixtures in the event so no broadasters ...
        if (!fixtures.length) {
          return;
        }
        const optaMap: Record<string, number> = fixtures.reduce(
          (memo, f) => ({
            ...memo,
            [`g${f.code}`]: f.id,
          }),
          {},
        );

        const broadcasterData = await fetchBroadcastersForFixtures(
          moment(fixtures[0].kickoff_time!).format("YYYY-MM-DD"),
          moment(fixtures[fixtures.length - 1].kickoff_time!).format(
            "YYYY-MM-DD",
          ),
        ).catch((error) => {});

        if (broadcasterData && broadcasterData.content) {
          const newState = { ...this.state };
          broadcasterData.content.forEach((data) => {
            if (data.fixture && data.fixture.altIds && data.broadcasters) {
              const bData = data.broadcasters
                .filter(
                  (broadcasterData: IBroadcasterInfo) =>
                    !/^UK Radio -/.test(broadcasterData.name),
                )
                .map((broadcasterData: IBroadcasterInfo) => ({
                  abbreviation: broadcasterData.abbreviation,
                  name: broadcasterData.name,
                  url: broadcasterData.url,
                }));
              if (bData && bData.length) {
                newState[event][optaMap[data.fixture.altIds.opta]] = bData;
              }
            }
          });

          this.setState(newState);
        }
      }
    });
  };

  public render() {
    return (
      <FixtureBroadcastersContext.Provider value={this.state}>
        {this.props.children}
      </FixtureBroadcastersContext.Provider>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  fixturesByEvent: getFixturesByEvent(state),
});

export default connect(mapStateToProps)(FixtureBroadcasters);
