import PropTypes from "prop-types";
import TransactionElement from "./TransactionElement";

const TransactionElementGroup = ({ elementIds }) => (
  <div>
    {elementIds.map((id) => (
      <TransactionElement elementId={id} key={id} />
    ))}
  </div>
);

TransactionElementGroup.propTypes = {
  elementIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default TransactionElementGroup;
