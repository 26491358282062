import { format } from "date-fns";
import { hideVisually, hiDPI, rgba, size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { elementDialogShow } from "../../actions/Element";
import { refreshFixturesForEvent } from "../../actions/Fixture";
import pattern2Crop184 from "../../img/pattern/pattern-2-crop-184.png";
import pattern2Crop90 from "../../img/pattern/pattern-2-crop-90.png";
import logo from "../../img/pl-logo-lion.svg";
import { getCurrentEvent, getNextEvent } from "../../reducers/circularProblems";
import { getElementsById } from "../../reducers/elements";
import { getElementStatsByName } from "../../reducers/elementStats";
import { getEventsById } from "../../reducers/events";
import { RootState } from "../../reducers/rootReducer";
import { getTeamsById } from "../../reducers/teams";
import { IElementsById } from "../../store/elements/types";
import { IEvent, IEventsById } from "../../store/events/types";
import { getGroupedFixturesByEvent } from "../../store/fixtures/slice";
import {
  IFixtureStat,
  IFixtureStats,
  IGroupedFixturesByEvent,
} from "../../store/fixtures/types";
import { ITeamsById } from "../../store/teams/types";
import { formatRawAsISO, formatRawAsLocal } from "../../utils/datetime";
import { IStatsByName } from "../../utils/stats";
import { ButtonHyperlink } from "../ButtonLink";
import AddToCalendar from "../icons/AddToCalendar";
import {
  EventPager,
  NextButton,
  NextLink,
  PagerItem,
  PagerItemNext,
  PreviousButton,
  PreviousLink,
} from "../pager";
import { GradientBorder } from "../styles";
import TabHeading from "../TabHeading";
import { NewTabCopy } from "../Utils";
import Fixture from "./Fixture";

const StyledFixtures = styled.section`
  margin: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-repeat: no-repeat;
  background-position:
    right top,
    0,
    0;
  box-shadow: 0 8px 12px 0 ${rgba("#6b6b6b", 0.08)};
  background-image: url(${pattern2Crop90}),
    linear-gradient(to bottom, ${rgba("white", 0)} 80px, white 160px),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lightBlue},
      ${({ theme }) => theme.colors.darkBlue}
    );

  ${hiDPI(2)} {
    background-image: url(${pattern2Crop184}),
      linear-gradient(to bottom, ${rgba("white", 0)} 80px, white 160px),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-size:
      90px 60px,
      auto,
      auto;
  }
`;

const FixturesHeader = styled.div`
  ${GradientBorder}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.space[3]} ${theme.space[2]} ${theme.space[2]}`};
`;

const Heading = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2.4rem;
  }
`;

const FixtureLogo = styled.img`
  ${size(32)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(52)}
  }
`;

const EventHeading = styled.h3`
  margin: 1.2rem ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

const Deadline = styled.time`
  @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${hideVisually}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-left: 0.6rem;
    font-family: ${({ theme }) => theme.fonts.regular};
  }
`;

const Note = styled.p`
  padding: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }
`;

const FixtureDay = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${({ theme }) => theme.colors.lightGrey} 15%,
      ${({ theme }) => theme.colors.lightGrey} 49%,
      ${({ theme }) => theme.colors.lightGrey} 85%,
      ${rgba("white", 0)} 100%
    );
  }
`;

const FixtureList = styled.ul`
  margin: 0 0 ${({ theme }) => theme.space[4]};
  padding: 0;
  font-size: 1rem;
  list-style: none;
`;

const FixtureButton = styled.button<IStyledFixtureStatsProps>`
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  ${(props) =>
    props.isOpen &&
    css`
      background-color: ${props.theme.colors.lightPurple};
      color: white;
    `}
`;

const FixtureStatsWrap = styled.div<IStyledFixtureStatsProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: ${({ theme }) => `${theme.space[2]} 5%`};
  background-color: ${({ theme }) => theme.colors.lightGrey};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding-right: 15%;
    padding-left: 15%;
  }
`;

const FixtureStat = styled.li`
  margin-bottom: ${({ theme }) => theme.space[4]};
  background-color: white;
`;

const FixtureStatHeading = styled.h5`
  padding: 0.3rem ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
`;

const FixtureStatBody = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
`;

const FixtureStatList = styled.ul`
  flex: 1;
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  :first-child {
    border-right: 1px dotted black;
    text-align: right;
  }
`;

const FixtureStatItem = styled.li`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  border-bottom: 1px solid #e2e2e2;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  :last-child {
    border-bottom: 0;
  }
`;

const ElementButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[2]};

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface IFixtureStatsForTeamProps {
  elementsById: IElementsById;
  elementDialogShow: (elementId: number) => void;
  stats: IFixtureStat[];
}

const FixtureStatsForTeam: React.FC<IFixtureStatsForTeamProps> = ({
  elementsById,
  elementDialogShow,
  stats,
}) => (
  <FixtureStatList>
    {stats
      .filter((fs) => elementsById[fs.element])
      .map((fs) => (
        <FixtureStatItem key={fs.element}>
          <ElementButton onClick={() => elementDialogShow(fs.element)}>
            {elementsById[fs.element].web_name}
          </ElementButton>{" "}
          ({fs.value})
        </FixtureStatItem>
      ))}
  </FixtureStatList>
);

interface IFixtureStatsProps {
  elementsById: IElementsById;
  elementDialogShow: (id: number) => void;
  stats: IFixtureStats[];
  elementStatsByName: IStatsByName;
}

interface IStyledFixtureStatsProps {
  isOpen?: boolean;
}

type FixtureStatProps = IFixtureStatsProps & IStyledFixtureStatsProps;

const FixtureStats: React.FC<FixtureStatProps> = ({
  elementsById,
  isOpen = false,
  elementDialogShow,
  stats,
  elementStatsByName,
}) => {
  // Our render stats should contain only stats with data and a maximum
  // of 5 per team of BPS
  const renderStats = stats
    .filter((stat) => stat.h.length || stat.a.length)
    .map((stat) =>
      stat.s === "bps"
        ? {
            s: stat.s,
            h: stat.h.slice(0, 5),
            a: stat.a.slice(0, 5),
          }
        : stat,
    );
  return (
    <FixtureStatsWrap isOpen={isOpen}>
      <ul>
        {renderStats.map((stat) => (
          <FixtureStat key={stat.s}>
            <FixtureStatHeading>
              {elementStatsByName[stat.s].label}
            </FixtureStatHeading>
            <FixtureStatBody>
              <FixtureStatsForTeam
                stats={stat.h}
                elementsById={elementsById}
                elementDialogShow={elementDialogShow}
              />
              <FixtureStatsForTeam
                stats={stat.a}
                elementsById={elementsById}
                elementDialogShow={elementDialogShow}
              />
            </FixtureStatBody>
          </FixtureStat>
        ))}
      </ul>
    </FixtureStatsWrap>
  );
};

interface IOwnProps {
  eventId?: number;
  useLinks?: boolean;
}

interface IPropsFromDispatch {
  elementDialogShow: (elementId: number) => void;
  refreshFixtures: (eventId: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementsById: IElementsById;
  eventsById: IEventsById;
  groupedFixturesByEvent: IGroupedFixturesByEvent;
  nextEvent: IEvent | null;
  elementStatsByName: IStatsByName;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromDispatch & IPropsFromState;

interface IState {
  eventId: number;
  isOpen: Record<string, boolean>;
}

class Fixtures extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);
    let eventId = 0;
    if (props.eventId) {
      eventId = props.eventId;
    } else if (props.nextEvent) {
      eventId = props.nextEvent.id;
    } else if (props.currentEvent) {
      eventId = props.currentEvent.id;
    }
    this.state = { eventId, isOpen: {} };
  }

  public componentDidMount() {
    this.props.refreshFixtures(this.state.eventId);
  }

  public changeEvent = (eventId: number) => {
    this.props.refreshFixtures(eventId);
    this.setState({ eventId });
  };

  public toggle = (fixtureId: number) => {
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [fixtureId]: !this.state.isOpen[fixtureId],
      },
    });
  };

  public render() {
    const {
      elementsById,
      eventsById,
      groupedFixturesByEvent,
      elementDialogShow,
      elementStatsByName,
      teamsById,
      useLinks = false,
    } = this.props;
    const { eventId } = this.state;
    const event = eventsById[eventId];
    if (!event) {
      return null;
    }

    const eventFixtureGroups = groupedFixturesByEvent[eventId] || [];
    return (
      <StyledFixtures>
        <header>
          <FixturesHeader>
            <Heading>
              <FixtureLogo src={logo} alt="Premier League" />{" "}
              <span>Fixtures</span>
            </Heading>
            <ButtonHyperlink
              href="https://pl.ecal.com/"
              target="_blank"
              rel="noopener noreferrer"
              variant="light"
            >
              <Flex alignItems="center">
                <Box mr="0.8rem">
                  <AddToCalendar />
                </Box>
                Sync to Calendar
                <NewTabCopy />
              </Flex>
            </ButtonHyperlink>
          </FixturesHeader>
          <EventHeading role="status" aria-live="polite">
            <span>{event.name}:</span>
            <Deadline dateTime={formatRawAsISO(event.deadline_time)}>
              {formatRawAsLocal(event.deadline_time)}
            </Deadline>
          </EventHeading>
          <EventPager>
            {eventsById[eventId - 1] && (
              <PagerItem>
                {useLinks ? (
                  <PreviousLink
                    to={`/fixtures/${eventId - 1}`}
                    onClick={() => this.changeEvent(eventId - 1)}
                  />
                ) : (
                  <PreviousButton
                    onClick={() => this.changeEvent(eventId - 1)}
                  />
                )}
              </PagerItem>
            )}
            {eventsById[eventId + 1] && (
              <PagerItemNext>
                {useLinks ? (
                  <NextLink
                    to={`/fixtures/${eventId + 1}`}
                    onClick={() => this.changeEvent(eventId + 1)}
                  />
                ) : (
                  <NextButton onClick={() => this.changeEvent(eventId + 1)} />
                )}
              </PagerItemNext>
            )}
          </EventPager>
        </header>
        <Box p={2}>
          <Note>
            All times are shown in your <strong>local time</strong>
          </Note>
        </Box>
        {eventFixtureGroups.map((group, i) => (
          <FixtureDay key={i}>
            <TabHeading
              title={
                <time dateTime={group.date.toISOString()}>
                  {format(group.date, "EEEE d MMMM yyyy")}
                </time>
              }
              isCentered={true}
            />
            <FixtureList>
              {group.fixtures.map((f) => (
                <React.Fragment key={f.id}>
                  {f.started && f.team_h_score !== null ? (
                    <li>
                      <FixtureButton
                        onClick={() => this.toggle(f.id)}
                        isOpen={this.state.isOpen[f.id]}
                      >
                        <Fixture
                          homeTeam={teamsById[f.team_h]}
                          awayTeam={teamsById[f.team_a]}
                          fixture={f}
                        />
                      </FixtureButton>
                      <FixtureStats
                        stats={f.stats}
                        elementsById={elementsById}
                        elementDialogShow={elementDialogShow}
                        elementStatsByName={elementStatsByName}
                        isOpen={this.state.isOpen[f.id]}
                      />
                    </li>
                  ) : (
                    <li>
                      <Fixture
                        homeTeam={teamsById[f.team_h]}
                        awayTeam={teamsById[f.team_a]}
                        fixture={f}
                      />
                    </li>
                  )}
                </React.Fragment>
              ))}
            </FixtureList>
          </FixtureDay>
        ))}
      </StyledFixtures>
    );
  }
}

export { Fixtures as FixturesTest };

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => ({
  currentEvent: getCurrentEvent(state),
  elementsById: getElementsById(state),
  eventsById: getEventsById(state),
  groupedFixturesByEvent: getGroupedFixturesByEvent(state),
  nextEvent: getNextEvent(state),
  teamsById: getTeamsById(state),
  elementStatsByName: getElementStatsByName(state),
});

const mapDispatchToProps = {
  elementDialogShow,
  refreshFixtures: refreshFixturesForEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fixtures);
