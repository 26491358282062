import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";
import { propTypeHubStatus } from "../../../reducers/entries";
import { propTypeEvent } from "../../../reducers/events";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { formatRawAsISO, formatRawAsLocal } from "../../../utils/datetime";
import getOrdinal from "../../../utils/getOrdinal";
import Copy from "../../Copy";
import SubHeading from "../../SubHeading";
import TabHeadingLight from "../../TabHeadingLight";
import {
  DeadlineHeader,
  EventDeadlineLink,
  EventDeadlineTime,
  StyledEventDeadline,
  StyledEventDeadlines,
} from "./styles";

const EventDeadline = ({ deadlineISO, deadlineLocal, header, linkTo }) => (
  <StyledEventDeadline>
    {header}
    <EventDeadlineTime>
      <EventDeadlineLink to={linkTo}>
        <time dateTime={deadlineISO}>{deadlineLocal}</time>
      </EventDeadlineLink>
    </EventDeadlineTime>
  </StyledEventDeadline>
);

EventDeadline.propTypes = {
  deadlineISO: PropTypes.string.isRequired,
  deadlineLocal: PropTypes.string.isRequired,
  header: PropTypes.node.isRequired,
  linkTo: PropTypes.string.isRequired,
};

const EventDeadlines = ({
  activeLeagueEntry,
  nextEvent,
  nextWaiverDate,
  status,
}) => {
  if (!nextEvent) {
    return null;
  }
  const NextEventDeadlineHeader = (
    <DeadlineHeader>
      <TabHeadingLight>{nextEvent.name}</TabHeadingLight>
    </DeadlineHeader>
  );
  const NextWaiversDeadlineHeader = (
    <DeadlineHeader>
      <TabHeadingLight>
        <strong>Waivers</strong>{" "}
        {status === "waivers" && activeLeagueEntry.waiver_pick && (
          <span>you have {getOrdinal(activeLeagueEntry.waiver_pick)} pick</span>
        )}
      </TabHeadingLight>
    </DeadlineHeader>
  );

  if (nextEvent) {
    switch (status) {
      case "free_agency":
        return (
          <>
            <SubHeading>Upcoming Deadlines</SubHeading>
            <Box mb="4rem">
              <StyledEventDeadlines>
                <StyledEventDeadline>
                  <DeadlineHeader>
                    <TabHeadingLight>Free Agency</TabHeadingLight>
                  </DeadlineHeader>
                  <EventDeadlineTime>
                    <EventDeadlineLink to="/team/transactions/">
                      Open
                    </EventDeadlineLink>
                  </EventDeadlineTime>
                </StyledEventDeadline>
                <EventDeadline
                  header={NextEventDeadlineHeader}
                  deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
                  deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
                  linkTo="/team/my"
                />
              </StyledEventDeadlines>
            </Box>
          </>
        );
      default:
        return (
          <>
            <SubHeading>Upcoming Deadlines</SubHeading>
            <Box mb="4rem">
              <StyledEventDeadlines>
                <EventDeadline
                  header={NextWaiversDeadlineHeader}
                  deadlineISO={formatRawAsISO(nextWaiverDate)}
                  deadlineLocal={formatRawAsLocal(nextWaiverDate)}
                  linkTo="/team/transactions"
                />
                <EventDeadline
                  header={NextEventDeadlineHeader}
                  deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
                  deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
                  linkTo="/team/my"
                />
              </StyledEventDeadlines>
            </Box>
          </>
        );
    }
  }

  return (
    <div>
      <SubHeading>Upcoming Deadlines</SubHeading>
      <Copy>
        <p>There are no further deadlines this season.</p>
      </Copy>
    </div>
  );
};

EventDeadlines.propTypes = {
  activeLeagueEntry: propTypeLeagueEntry.isRequired,
  nextEvent: propTypeEvent,
  nextWaiverDate: PropTypes.instanceOf(Date),
  status: propTypeHubStatus.isRequired,
};

EventDeadlines.defaultProps = {
  nextEvent: null,
  nextWaiverDate: null,
};

export default EventDeadlines;
