import { rgba } from "polished";
import styled from "styled-components/macro";

const TextDivider = styled.div`
  margin: ${({ theme }) => theme.space[4]} 0;
  text-align: center;
  text-transform: lowercase;
  overflow: hidden;

  ::before,
  ::after {
    content: "";
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    background-color: ${rgba("black", 0.1)};
    vertical-align: middle;
  }

  ::before {
    right: ${({ theme }) => theme.space[2]};
    margin-left: -50%;
  }

  ::after {
    left: ${({ theme }) => theme.space[2]};
    margin-right: -50%;
  }
`;

export default TextDivider;
