import React from "react";
import PropTypes from "prop-types";
import DeadlineHeader from "../DeadlineHeader";
import ReceivedTradesTable from "./ReceivedTradesTable";

const ReceivedTrades = ({ deadlineISO, deadlineLocal, ...rest }) => (
  <div>
    <DeadlineHeader
      deadlineISO={deadlineISO}
      deadlineLocal={deadlineLocal}
      deadlineText="Trades must be accepted before"
      headingText="Received Trade Offers"
    />
    <ReceivedTradesTable {...rest} />
  </div>
);

ReceivedTrades.propTypes = {
  deadlineISO: PropTypes.string.isRequired,
  deadlineLocal: PropTypes.string.isRequired,
};

export default ReceivedTrades;
