import PropTypes from "prop-types";
import Button from "./Button";
import { ButtonGroup, ButtonGroupItem } from "./ButtonGroup";
import Dialog from "./Dialog";
import { ConfirmDialogBodyWrap } from "./Dialog/styles";

const ConfirmDialog = ({ children, closeDialog, header, onConfirm }) => {
  const ConfirmDialogBody = () => (
    <ConfirmDialogBodyWrap>
      {children}
      <ButtonGroup>
        <ButtonGroupItem isInline={true}>
          <Button onClick={onConfirm}>Confirm Deletion</Button>
        </ButtonGroupItem>
        <ButtonGroupItem isInline={true}>
          <Button onClick={closeDialog}>Cancel</Button>
        </ButtonGroupItem>
      </ButtonGroup>
    </ConfirmDialogBodyWrap>
  );

  return (
    <Dialog closeDialog={closeDialog}>
      <Dialog.Header closeDialog={closeDialog}>{header}</Dialog.Header>
      <ConfirmDialogBody>{children}</ConfirmDialogBody>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  children: PropTypes.node.isRequired,
  closeDialog: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
