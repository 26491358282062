import styled from "styled-components/macro";

export const LoginLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const ForgotLink = styled.a`
  display: inline-block;
  margin: 1.3rem ${(props) => props.theme.space[4]} 1.3rem 0;
  color: #1f61c0;
  font-family: ${(props) => props.theme.fonts.bold};
  text-decoration: none;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-right: 0;
    font-size: ${(props) => props.theme.fontSizes[0]};
  }
`;
