import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { leagueDataFetch } from "../../actions/League";
import { getActiveEntry, propTypeEntry } from "../../reducers/entries";
import {
  getCurrentEvent,
  getEvents,
  propTypeEvent,
} from "../../reducers/events";
import { getActiveLeague, propTypeLeague } from "../../reducers/leagues";
import {
  getActiveLeagueEntriesById,
  propTypeLeagueEntry,
} from "../../reducers/leagueEntries";
import {
  getFilteredActiveLeagueFixturesByEvent,
  getFilteredActiveLeagueResultsByEvent,
  getLeagueEntryFilter,
  propTypeLeagueMatch,
} from "../../reducers/leagueMatches";
import {
  getActiveLeagueStandings,
  propTypeLeagueStanding,
} from "../../reducers/leagueStandings";
import ClassicLeague from "./ClassicLeague";
import Entry from "../entry/Entry";
import H2HLeague from "./H2HLeague";
import HelmetHead from "../HelmetHead";
import Loader from "../Loader";
import { Main, Secondary, Wrapper } from "../Layout";
import Title from "../Title";

class LeagueWrapper extends Component {
  componentDidMount() {
    this.props.fetchLeagueData(this.props.league.id);
  }

  render() {
    if (!this.props.standings.length) {
      return <Loader />;
    }
    return (
      <Wrapper>
        <HelmetHead
          title="Leagues - Fantasy Premier League Draft 2024/25"
          description="For information on how Leagues operate, including types of scoring and size, visit the official website of the Premier League."
        />
        <Main>
          <Title>{this.props.league.name}</Title>
          {this.props.league.scoring === "h" && (
            <H2HLeague
              activeEntry={this.props.activeEntry}
              events={this.props.events}
              entriesById={this.props.entriesById}
              filteredLeagueEntryId={this.props.filteredLeagueEntryId}
              fixturesByEvent={this.props.fixturesByEvent}
              linkEventId={this.props.linkEventId}
              resultsByEvent={this.props.resultsByEvent}
              standings={this.props.standings}
            />
          )}
          {this.props.league.scoring === "c" && (
            <ClassicLeague
              activeEntry={this.props.activeEntry}
              entriesById={this.props.entriesById}
              linkEventId={this.props.linkEventId}
              standings={this.props.standings}
            />
          )}
        </Main>
        <Secondary>
          <Entry />
        </Secondary>
      </Wrapper>
    );
  }
}

LeagueWrapper.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  events: PropTypes.arrayOf(propTypeEvent).isRequired,
  fetchLeagueData: PropTypes.func.isRequired,
  filteredLeagueEntryId: PropTypes.number.isRequired,
  league: propTypeLeague.isRequired,
  linkEventId: PropTypes.number.isRequired,
  fixturesByEvent: PropTypes.objectOf(PropTypes.arrayOf(propTypeLeagueMatch)),
  resultsByEvent: PropTypes.objectOf(PropTypes.arrayOf(propTypeLeagueMatch)),
  standings: PropTypes.arrayOf(propTypeLeagueStanding),
};

LeagueWrapper.defaultProps = {
  fixturesByEvent: {},
  resultsByEvent: {},
  standings: [],
};

export { LeagueWrapper as LeagueWrapperTest };

const mapStateToProps = (state) => {
  const now = getCurrentEvent(state);
  const league = getActiveLeague(state);
  let linkEventId = 0;
  if (now && now.id >= league.start_event) {
    linkEventId = now.id;
  }
  return {
    activeEntry: getActiveEntry(state),
    entriesById: getActiveLeagueEntriesById(state),
    events: getEvents(state),
    filteredLeagueEntryId: getLeagueEntryFilter(state),
    fixturesByEvent: getFilteredActiveLeagueFixturesByEvent(state),
    league,
    linkEventId,
    resultsByEvent: getFilteredActiveLeagueResultsByEvent(state),
    standings: getActiveLeagueStandings(state),
  };
};

const mapDispatchToProps = {
  fetchLeagueData: leagueDataFetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueWrapper);
