import styled from "styled-components/macro";
import Table from "../../../Table";

export const EntriesTable = styled(Table)`
  td {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    font-size: inherit;
  }
`;
