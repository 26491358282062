import { propTypeLeague } from "../../../reducers/leagues";
import DraftStart from "../../DraftStart";
import Link from "../../Link";
import DraftRound from "../DraftRound";
import { PreDraft, PreDraftCopy, PreDraftUnit } from "./styles";

const PreDraftStatus = ({ league }) => (
  <>
    <DraftRound leagueName={league.name} />
    <PreDraft>
      <PreDraftUnit>
        <PreDraftCopy>
          The draft will automatically start at the designated time if the
          minimum number of teams has been met.
        </PreDraftCopy>
        <PreDraftCopy>
          A random order will be generated which will &quot;snake&quot; from
          round to round during the 15 rounds of the draft. In a snake draft the
          pick order is reversed each round.
        </PreDraftCopy>
        <Link to="/help">Learn more</Link>
      </PreDraftUnit>
      <PreDraftUnit>
        <DraftStart league={league} />
      </PreDraftUnit>
    </PreDraft>
  </>
);

PreDraftStatus.propTypes = {
  league: propTypeLeague.isRequired,
};

PreDraftStatus.defaultProps = {
  league: {
    draft_dt: undefined,
    draft_tz_show: undefined,
    name: undefined,
  },
  squadSize: null,
};

export default PreDraftStatus;
