import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { CheckboxField, LightForm } from "./FieldRenderers";

const AvailableElementsFilter = () => (
  <LightForm onSubmit={(e) => e.preventDefault()}>
    <Field
      id="showAvailable"
      name="showAvailable"
      component={CheckboxField}
      label="Show available"
    />
  </LightForm>
);

export { AvailableElementsFilter as AvailableElementsFilterTest };

export default connect()(
  reduxForm({
    form: "AvailableElementsFilter",
  })(AvailableElementsFilter),
);
