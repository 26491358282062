import styled from "styled-components/macro";
import { ITradeWarning } from "./types";
import { css } from "styled-components";
import { hideVisually } from "polished";

export const TradeWarning = styled.span<ITradeWarning>`
  ${(props) =>
    props.isVisual &&
    css`
      display: inline-block;
      margin-bottom: ${(props) => props.theme.space[4]};

      @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
        padding-right: ${(props) => props.theme.space[2]};
        padding-left: ${(props) => props.theme.space[2]};
      }
    `}
`;

export const TradeWarningDescription = styled.span<ITradeWarning>`
  ${({ isVisual, theme }) =>
    isVisual
      ? css`
          margin-left: ${theme.space[1]};
        `
      : css`
          ${hideVisually}
        `}
`;
