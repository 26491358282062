import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWatchlist } from "../reducers/watchlist";
import { watchlistAdd, watchlistRemove } from "../actions/Watchlist";
import { SquadButton, SquadButtonText, WatchIcon } from "./squad/styles";

interface IOwnProps {
  elementId: number;
}

const WatchlistButton: React.FC<IOwnProps> = ({ elementId }) => {
  const watchlist = useSelector(getWatchlist);
  const dispatch = useDispatch();
  return watchlist.includes(elementId) ? (
    <SquadButton
      type="button"
      variant="watch"
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => dispatch(watchlistRemove(elementId))}
    >
      <SquadButtonText>Remove</SquadButtonText>
      <WatchIcon
        isWatched={true}
        width="25px"
        height="14px"
        viewBox="0 0 25 14"
      >
        <use xlinkHref="#ism-watch" />
      </WatchIcon>
    </SquadButton>
  ) : (
    <SquadButton
      type="button"
      variant="watch"
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => dispatch(watchlistAdd(elementId))}
    >
      <SquadButtonText>Watch</SquadButtonText>
      <WatchIcon width="25px" height="14px" viewBox="0 0 25 14">
        <use xlinkHref="#ism-watch" />
      </WatchIcon>
    </SquadButton>
  );
};

export default WatchlistButton;
