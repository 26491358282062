import styled from "styled-components/macro";
import Table from "../../Table";

export const EntryCell = styled.td`
  width: 15%;
`;

export const TradeHeading = styled.th`
  width: 50%;
  text-align: center;
`;

export const TradeCell = styled.td`
  width: 50%;
`;

export const StatusHeading = styled.th`
  width: 20%;
`;

export const StatusCell = styled.td`
  width: 20%;
  border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
  text-align: center;
`;

export const StyledEventTradesTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};

  ${EntryCell}, ${TradeHeading}, ${StatusHeading} {
    text-align: center;
  }

  ${TradeCell} {
    padding: 0;
  }
`;
