import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { FETCH_ELEMENT_SUMMARY_SUCCESS } from "../actions/ActionTypes";
import { getElementStatsMatch } from "./elementStats";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.elementSummary;
export const l2g = (local) => ({ elementSummary: local });

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ELEMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        [action.data.element]: action.data.summary,
      };

    default:
      return state;
  }
};

export default combineReducers({
  byId,
});

// Selectors
export const getElementSummaries = (state) => g2l(state);

export const getElementSummary = (state, id) => {
  const summary = getElementSummaries(state).byId[id];
  const stats = getElementStatsMatch(state).slice(0);
  stats.unshift({ name: "total_points" });

  if (summary) {
    summary.totals = stats.reduce(
      (memo, s) => Object.assign(memo, { [s.name]: 0 }),
      {},
    );

    summary.history.forEach((h) => {
      stats.forEach((s) => {
        let value = h[s.name];
        // Convert strings to floats (which is what they must be)
        if (typeof value === "string") {
          value = parseFloat(value);
        }
        summary.totals[s.name] += value;
      });
    });

    // Limit floats to 1 decimal place
    Object.keys(summary.totals).forEach((stat) => {
      if (summary.totals[stat] % 1) {
        summary.totals[stat] = summary.totals[stat].toFixed(1);
      }
    });
  }

  return summary;
};

// PropTypes
export const propTypeElementSummaryFixture = PropTypes.shape({
  difficulty: PropTypes.number,
  is_home: PropTypes.boolean,
  opponent: PropTypes.number,
});

export const propTypeElementSummaryHistory = PropTypes.shape({
  opponent: PropTypes.number,
});

export const propTypeElementSummary = PropTypes.shape({
  fixtures: PropTypes.arrayOf(propTypeElementSummaryFixture),
  history: PropTypes.arrayOf(propTypeElementSummaryHistory),
  totals: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
});
