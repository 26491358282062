import { hiDPI, rgba, size } from "polished";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components/macro";
import { login } from "../../../actions/Auth";
import { ReactComponent as BaseDraftLogo } from "../../../img/draft-logo.svg";
import { ReactComponent as BaseLogoLight } from "../../../img/headline-logo-light.svg";
import pattern1_437 from "../../../img/pattern/pattern-1-437.png";
import pattern1_874 from "../../../img/pattern/pattern-1-874.png";
import { ReactComponent as AppleLogo } from "../../../img/social/apple.svg";
import { ReactComponent as FacebookLogo } from "../../../img/social/facebook.svg";
import { ReactComponent as GoogleLogo } from "../../../img/social/google.svg";
import { ReactComponent as TwitterLogo } from "../../../img/social/twitter.svg";
import { getPlayerData } from "../../../reducers/player";
import { IPlayer } from "../../../store/player/types";
import Alert from "../../Alert";
import Button from "../../Button";
import { ButtonHyperlink } from "../../ButtonLink";
import Copy from "../../Copy";
import { FieldWrap, Input } from "../../FieldRenderers";
import TextDivider from "../../TextDivider";
import { ForgotLink, LoginLabel } from "./styles";

const LoginForm = styled.form`
  margin: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    margin: 0;
    padding-top: 3rem;
  }
`;

const LoginTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 10%;
    margin-top: 2.3rem;
    margin-bottom: ${({ theme }) => theme.space[4]};
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 10%;
  }
`;

const Username = styled(FieldWrap)`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 30%;
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 28%;
  }
`;

const Password = styled(FieldWrap)`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 23%;
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 28%;
  }
`;

const LoginButtonWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 20%;
    margin-top: 2.3rem;
    margin-bottom: ${({ theme }) => theme.space[4]};
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 20%;
  }
`;

const LoginLinks = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 17%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 14%;
  }
`;

const LoginLink = styled.div`
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0;
    text-align: left;
  }
`;

const SocialLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 2%;
    margin-left: 2%;
  }
`;

const SocialButtonWrap = styled.div`
  flex: 0 0 50%;
  margin: ${({ theme }) => theme.space[1]} 0;
  padding: 0 ${({ theme }) => theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-basis: 25%;
  }
`;

const StyledSocialButton = styled.a<ISocialButtonStyleProps>`
  display: block;
  padding: 0 ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  box-shadow: 0 1px 8px 0 ${rgba("black", 0.12)};
  background-color: ${(props) => props.theme.colors.social[props.network].bg};
  color: ${(props) => props.theme.colors.social[props.network].color};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
  text-decoration: none;
  line-height: 4.4rem;

  > svg {
    vertical-align: middle;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SocialButtonText = styled.span`
  margin-left: ${({ theme }) => theme.space[2]};
  text-transform: capitalize;
`;

const Promo = styled.div`
  margin: ${({ theme }) => `0 ${theme.space[2]} ${theme.space[4]}`};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.primary};
  background-repeat: no-repeat;
  background-image: url(${pattern1_437}), url(${pattern1_437});
  background-position:
    left -78px bottom -260px,
    right -220px top -152px;
  background-size: 366px 415px;
  text-align: center;

  ${hiDPI(2)} {
    background-image: url(${pattern1_874}), url(${pattern1_874});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    margin: 0 0 4rem;
    padding: 2.2rem 6rem;
    text-align: left;
  }
`;

const PromoHead = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 20%;
    margin-bottom: 0;
  }
`;

const PromoBody = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 60%;
    margin-bottom: 0;
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

const PromoFoot = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 20%;
  }
`;

const LogoLight = styled(BaseLogoLight)`
  ${size(23, 99)}
  margin-bottom: ${({ theme }) => theme.space[1]};
`;

const DraftLogo = styled(BaseDraftLogo)`
  ${size(28, 130)}
`;

const PromoHeading = styled.h3`
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-size: 2.1rem;
  color: ${({ theme }) => theme.colors.fantasy};
`;

const PromoText = styled.p`
  margin-bottom: 0;
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
  }
`;

interface ISocialButtonStyleProps {
  network: string;
}

interface ISocialButtonProps {
  link: string;
  logo: React.ReactNode;
}

type SocialButtonProps = ISocialButtonProps & ISocialButtonStyleProps;

const SocialButton: React.FC<SocialButtonProps> = ({ link, logo, network }) => (
  <StyledSocialButton href={link} network={network}>
    {logo}
    <SocialButtonText>Login with {network}</SocialButtonText>
  </StyledSocialButton>
);

interface ILoginInput {
  name: string;
  value: string;
}

interface ILoginField {
  id: string;
  input: ILoginInput;
  label: string;
  type: string;
}

const LoginField: React.FC<ILoginField> = ({ id, input, label, type }) => (
  <div>
    <LoginLabel htmlFor={id}>{label}</LoginLabel>
    <Input {...input} id={id} type={type} />
  </div>
);

interface ILogin {
  error: string;
  handleSubmit: any;
  player?: IPlayer;
  valid: boolean;
}

const Login: React.FC<ILogin> = ({
  error,
  handleSubmit,
  player = {},
  valid,
}) => {
  // Constants to help build urls
  const app = "pldpl-web";
  const plusers = process.env.REACT_APP_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const register = `${plusers}/users/register?app=${app}&redirect_uri=${redirect}`;
  const passLook = `${plusers}/accounts/password-reset/`;
  const makeSocialLogin = (net: any) =>
    `${plusers}/accounts/${net}/login/?app=${app}&redirect_uri=${redirect}`;

  // Look for a login error from the users server
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  let usersError = "";
  if (query.state && query.state === "fail") {
    usersError = "Incorrect email or password";
  }

  // Don't handle in production or if PLUSERS_BASE is defined
  const onSubmit =
    process.env.NODE_ENV === "production" || plusers ? null : handleSubmit;

  if (player.id) {
    return (
      <Copy>
        <p>
          You are logged in as {player.first_name} {player.last_name}.
        </p>
      </Copy>
    );
  }

  return (
    <div>
      {(error || usersError) && (
        <Alert type="error" canFocus>
          {error && error}
          {usersError && usersError}
        </Alert>
      )}
      <LoginForm
        onSubmit={onSubmit}
        method="POST"
        action={`${plusers}/accounts/login/`}
      >
        <LoginTitle>Sign In</LoginTitle>
        <Username>
          <Field
            id="loginUsername"
            name="login"
            component={LoginField}
            type="email"
            label="Email Address"
          />
        </Username>
        <Password>
          <Field
            id="loginPassword"
            name="password"
            component={LoginField}
            type="password"
            label="Password"
          />
        </Password>
        <LoginButtonWrap>
          <Button type="submit" fullwidth="true" disabled={!valid}>
            Sign In
          </Button>
        </LoginButtonWrap>
        <LoginLinks>
          <LoginLink>
            <ForgotLink href={passLook}>Forgot your password?</ForgotLink>
          </LoginLink>
        </LoginLinks>
        <input type="hidden" name="app" value={app} />
        <input type="hidden" name="redirect_uri" value={redirect} />
      </LoginForm>

      <TextDivider>or login with</TextDivider>
      <SocialLogin>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("facebook")}
            network="facebook"
            logo={<FacebookLogo />}
          />
        </SocialButtonWrap>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("twitter")}
            network="x"
            logo={<TwitterLogo />}
          />
        </SocialButtonWrap>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("google")}
            network="google"
            logo={<GoogleLogo />}
          />
        </SocialButtonWrap>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("apple")}
            network="apple"
            logo={<AppleLogo />}
          />
        </SocialButtonWrap>
      </SocialLogin>

      <Promo>
        <PromoHead>
          <div>
            <LogoLight />
          </div>
          <div>
            <DraftLogo />
          </div>
        </PromoHead>
        <PromoBody>
          <PromoHeading>
            Register to Play Fantasy Premier League Draft
          </PromoHeading>
          <PromoText>
            Fed up with everyone in your league having Erling Haaland up front
            or Salah in midfield?{" "}
          </PromoText>
          <PromoText>
            <strong>
              Join a draft league where you will have sole ownership of players!
            </strong>
          </PromoText>
        </PromoBody>
        <PromoFoot>
          <ButtonHyperlink href={register} fullwidth="true">
            Sign Up Now
          </ButtonHyperlink>
        </PromoFoot>
      </Promo>
    </div>
  );
};

const validate = (values: any) => {
  interface IErrors {
    [key: string]: string;
  }

  const errors: IErrors = {};

  if (!values.loginUsername) {
    errors.loginUsername = "Required";
  }
  if (!values.loginPassword) {
    errors.loginPassword = "Required";
  }
  return errors;
};

// Named export for testing
export { Login as LoginTest, validate };

const mapStateToProps = (state: any) => ({
  player: getPlayerData(state),
});

const mapDispatchToProps = {
  onSubmit: login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "login",
    validate,
  })(Login),
);
