import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import { elementDialogShow } from "../../../actions/Element";
import { makeTradeOffer } from "../../../actions/Transaction";
import {
  getElementStats,
  propTypeElementStat,
} from "../../../reducers/elementStats";
import { getElementTypes } from "../../../reducers/elementTypes";
import { getElementsById, propTypeElement } from "../../../reducers/elements";
import { getActiveEntry, propTypeEntry } from "../../../reducers/entries";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { getMyElementsByType } from "../../../reducers/picks";
import { getTeamsById } from "../../../reducers/teams";
import {
  areTradesValid,
  getDisabledTradeElements,
  getElementsInTrade,
  getProposedTradeInitialElement,
  getProposedTradeRecipient,
  getRecipientElementsByType,
  getTradeStat,
  getTradeWarningElements,
  getValidTrades,
  propTypeTradeItem,
  propTypeTradeWarningElement,
} from "../../../reducers/transactions";
import { getNext3EventsFixturesByTeam } from "../../../store/fixtures/slice";
import Copy from "../../Copy";
import DialogManager from "../../DialogManager";
import { SelectField } from "../../FieldRenderers";
import { Main, Wrapper } from "../../Layout";
import SubmitBar from "../../SubmitBar/SubmitBar";
import Title from "../../Title";
import ElementTradeWarning from "../ElementTradeWarning";
import TradeActionDialog from "../TradeActionDialog";
import TradeSquad from "../TradeSquad";
import TradeSummaryTable from "../TradeSummaryTable";
import { Trade, TradeStatSelect, TradeUnit } from "./styles";

export const getTradeItemWarnings = (tradeWarningElements, tradeItems) => {
  const elementsInTrade = getElementsInTrade(tradeItems);
  const tradeItemWarnings = {};
  Object.keys(tradeWarningElements).forEach((el) => {
    /* To be included in this you need to be in the current trade and already have a warning */
    if (
      elementsInTrade.indexOf(parseInt(el, 10)) > -1 &&
      tradeWarningElements[el]
    ) {
      tradeItemWarnings[el] = tradeWarningElements[el];
    }
  });
  return tradeItemWarnings;
};

const TradeWrapper = ({
  activeEntry,
  canSave,
  elementsById,
  makeOffer,
  myElementsByType,
  proposedElementsByType,
  proposedRecipient,
  stats,
  tradeWarningElements,
  validTrades,
  ...rest
}) => {
  if (!proposedRecipient || !proposedRecipient.id) {
    return (
      <Copy>
        <p>No trade proposed</p>
      </Copy>
    );
  }
  return (
    <Wrapper>
      <Main isWide={true}>
        <Box mx={2}>
          <Title>Player Trades</Title>
        </Box>

        <form>
          <TradeStatSelect>
            <Field
              id="ism-trade-stat"
              component={SelectField}
              isInline
              label="Show"
              name="stat"
            >
              {stats.map((s) => (
                <option value={s.name} key={s.name}>
                  {s.label}
                </option>
              ))}
            </Field>
          </TradeStatSelect>

          <Trade>
            <TradeUnit>
              <TradeSquad
                {...rest}
                elementsByType={myElementsByType}
                entryTeamName={activeEntry.name}
                entryFirstName={activeEntry.player_first_name}
                entryLastName={activeEntry.player_last_name}
                tradeWarningElements={tradeWarningElements}
                title="Offer"
              />
            </TradeUnit>
            <TradeUnit>
              <TradeSquad
                {...rest}
                elementsByType={proposedElementsByType}
                entryTeamName={proposedRecipient.entry_name}
                entryFirstName={proposedRecipient.player_first_name}
                entryLastName={proposedRecipient.player_last_name}
                tradeWarningElements={tradeWarningElements}
                title="Request"
              />
            </TradeUnit>
          </Trade>
        </form>
        {Object.keys(tradeWarningElements).length > 0 && (
          <ElementTradeWarning isVisual />
        )}
        {canSave && (
          <Copy>
            <h3>Trade Summary</h3>
            <p>
              Add more players to this trade or click &quot;Propose Trade&quot;
              now to continue.
            </p>
            <TradeSummaryTable
              elementsById={elementsById}
              tradeItems={validTrades}
              tradeWarningElements={tradeWarningElements}
            />
          </Copy>
        )}
        <DialogManager
          render={(showDialog, handleShow, handleHide) => (
            <React.Fragment>
              <SubmitBar
                buttonText="Propose Trade"
                clickMe={handleShow}
                isDisabled={!canSave}
              />
              {showDialog && (
                <TradeActionDialog
                  buttonLabel="Offer Trade"
                  closeDialog={handleHide}
                  elementsById={elementsById}
                  header="Propose Trade"
                  onConfirm={makeOffer}
                  tradeItems={validTrades}
                  tradeWarningElements={getTradeItemWarnings(
                    tradeWarningElements,
                    validTrades,
                  )}
                >
                  <p>
                    Please confirm that you would like to offer the following
                    trade to{" "}
                    <strong>
                      {proposedRecipient.player_first_name}{" "}
                      {proposedRecipient.player_last_name}
                    </strong>
                    .
                  </p>
                </TradeActionDialog>
              )}
            </React.Fragment>
          )}
        />
      </Main>
    </Wrapper>
  );
};

TradeWrapper.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  canSave: PropTypes.bool.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  makeOffer: PropTypes.func.isRequired,
  myElementsByType: PropTypes.objectOf(PropTypes.arrayOf(propTypeElement))
    .isRequired,
  proposedElementsByType: PropTypes.objectOf(PropTypes.arrayOf(propTypeElement))
    .isRequired,
  proposedRecipient: propTypeLeagueEntry,
  stats: PropTypes.arrayOf(propTypeElementStat).isRequired,
  tradeWarningElements: PropTypes.objectOf(propTypeTradeWarningElement)
    .isRequired,
  validTrades: PropTypes.arrayOf(propTypeTradeItem).isRequired,
};

TradeWrapper.defaultProps = {
  proposedRecipient: null, // On bookmarked render
};

export { TradeWrapper as TradeWrapperTest };

const mapStateToProps = (state) => {
  const initialElement = getProposedTradeInitialElement(state);
  return {
    activeEntry: getActiveEntry(state),
    canSave: areTradesValid(state),
    tradeWarningElements: getTradeWarningElements(state),
    elementTypes: getElementTypes(state),
    disabledElements: getDisabledTradeElements(state),
    elementsById: getElementsById(state),
    initialValues: {
      [`ism-trade-${initialElement ? initialElement.id : 0}`]: true,
      stat: "total_points",
    },
    myElementsByType: getMyElementsByType(state),
    next3Fixtures: getNext3EventsFixturesByTeam(state),
    proposedRecipient: getProposedTradeRecipient(state),
    proposedElementsByType: getRecipientElementsByType(state),
    stats: getElementStats(state),
    teamsById: getTeamsById(state),
    tradeStat: getTradeStat(state),
    validTrades: getValidTrades(state),
  };
};

const mapDispatchToProps = {
  elementDialogShow,
  makeOffer: makeTradeOffer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "proposedTrade",
  })(TradeWrapper),
);
