import styled from "styled-components/macro";

const Title = styled.h2`
  /* Consider moving margins to Copy component */
  margin: ${({ theme }) => theme.space[4]} 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[4]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2.5rem;
  }
`;

export default Title;
