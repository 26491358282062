import styled from "styled-components/macro";

export const ElementListTotal = styled.th`
  width: 20%;
  text-align: center;
`;

export const ElementListPlayer = styled.th`
  width: 55%;
`;

export const ElementListFixture = styled.th`
  width: 25%;
  text-align: center;
`;
