import { navigate } from "@gatsbyjs/reach-router";
import { SubmissionError } from "redux-form";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  handleUnexpectedError,
} from "../helpers";
import { getDefaultURL } from "../reducers/player";
import {
  ACTIVE_ENTRY_CHANGE,
  ADD_ENTRY_HISTORY,
  DELETE_ENTRY_SUCCESS,
  EDIT_ENTRY_ERROR,
  EDIT_ENTRY_SUCCESS,
  ENTRY_UNSUBSCRIBED,
} from "./ActionTypes";
import { fetchChatMessages } from "./Chat";
import { fetchElementStatus } from "./ElementStatus";
import { leagueDataFetch } from "./League";
import { addPublicEntry } from "./Pick";
import { watchlistUpdate } from "./Watchlist";

// regular action creators
export const activeEntryChange = (data) => ({
  type: ACTIVE_ENTRY_CHANGE,
  data,
});

export const editEntryError = (error) => ({
  type: EDIT_ENTRY_ERROR,
  error,
});

export const editEntrySuccess = (data) => ({
  type: EDIT_ENTRY_SUCCESS,
  data,
});

export const deleteEntrySuccess = (data) => ({
  type: DELETE_ENTRY_SUCCESS,
  data,
});

export const entryUnsubscribed = (data) => ({
  type: ENTRY_UNSUBSCRIBED,
  data,
});

export const addEntryHistory = (entryId, history) => ({
  type: ADD_ENTRY_HISTORY,
  data: {
    entryId,
    history,
  },
});

// thunk action creators
export const updateActiveEntry = (dispatch, data) =>
  new Promise((resolve) => {
    if (data.entry) {
      Promise.all([
        dispatch(leagueDataFetch(data.league)),
        dispatch(fetchChatMessages({ id: data.league })),
        dispatch(fetchElementStatus({ id: data.league })),
      ]).then(() => {
        dispatch(activeEntryChange(data));
        resolve();
      });
      dispatch(watchlistUpdate(data.watchlist || []));
    } else {
      // Nothing to do resolve straight away
      resolve();
    }
  });

export const changeEntry = (id) => (dispatch) =>
  fetchGet(`/api/entry/${id}/change`).then(
    () => {
      // Due to nav issues changing an active team will usually cause a
      // reload. Just do it anyway to save lots of weirdness!
      // dispatch(updateActiveEntry(data.active)),
      window.location = "/";
      return null;
    },
    (e) => handleUnexpectedError(e, dispatch),
  );

const editEntryData = (data) => {
  const newData = {};
  if (data.entryName) {
    newData.name = data.entryName;
  }
  if (data.entryFaveTeam) {
    newData.favourite_team = parseInt(data.entryFaveTeam, 10) || null;
  }
  if (data.entryEmail) {
    newData.email = data.entryEmail || false;
  }
  return newData;
};

export const editEntry = (values, _, props) => (dispatch) =>
  fetchPost(`/api/entry/${props.entry.id}`, editEntryData(values)).then(
    (d) => {
      dispatch(editEntrySuccess(d.data));
      navigate("/");
    },
    (e) => {
      if (e.reason === "NOT_2XX" && e.response.status === 400) {
        dispatch(editEntryError(e.error));
        const errorText =
          typeof e.error === "string" ? e.error : JSON.stringify(e.error);
        throw new SubmissionError({ _error: errorText });
      }
      handleUnexpectedError(e, dispatch);
    },
  );

export const deleteEntry = (entryId) => (dispatch, getState) =>
  fetchDelete(`/api/entry/${entryId}`).then(
    (d) => {
      dispatch([deleteEntrySuccess(d.data), activeEntryChange(d.data)]);
      navigate(getDefaultURL(getState()));
    },
    (e) => handleUnexpectedError(e, dispatch),
  );

export const unsubscribeEntry = (signature) => (dispatch) =>
  fetchGet(`/api/entry/unsubscribe/${signature}`).then(
    (d) => dispatch(entryUnsubscribed(d.data)),
    (e) => handleUnexpectedError(e, dispatch),
  );

export const entryHistoryFetch = (entryId) => (dispatch) =>
  fetchGet(`/api/entry/${entryId}/history`).then(
    (d) =>
      dispatch([
        addPublicEntry(d.data.entry),
        addEntryHistory(entryId, d.data.history),
      ]),
    (e) => handleUnexpectedError(e, dispatch),
  );
