import { formatInTimeZone } from "date-fns-tz";
import { DISPLAY_DRAFT_DATE_TIME_FORMAT } from "../../constants";
import Countdown from "../Countdown";
import { IDraftStart } from "./types";
import { DraftStartDate, DraftStartHeading, StyledDraftStart } from "./styles";

const DraftStart = ({ league }: IDraftStart) => (
  <StyledDraftStart>
    <DraftStartHeading>Countdown to Draft</DraftStartHeading>
    <Countdown startDate={league.draft_dt} />
    <DraftStartDate>
      Draft date:{" "}
      {formatInTimeZone(
        league.draft_dt,
        league.draft_tz_show,
        `${DISPLAY_DRAFT_DATE_TIME_FORMAT} zzz`,
      )}
    </DraftStartDate>
  </StyledDraftStart>
);

export default DraftStart;
