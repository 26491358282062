import React from "react";
import { PrimaryIcon, SquadButton, SquadButtonText } from "./styles";

interface IActionElementButton {
  actionMe: (elementId: number) => React.MouseEventHandler;
  actionLabel: string;
  elementId: number;
  isDisabled?: boolean;
}

const ActionElementButton: React.FC<IActionElementButton> = ({
  actionLabel,
  actionMe,
  elementId,
  isDisabled = false,
}) => (
  <SquadButton
    variant="primary"
    type="button"
    disabled={isDisabled}
    onMouseDown={(e) => e.preventDefault()}
    onClick={() => actionMe(elementId)}
  >
    <SquadButtonText>{actionLabel}</SquadButtonText>
    <PrimaryIcon>
      <use xlinkHref="#ism-add" />
    </PrimaryIcon>
  </SquadButton>
);

export default ActionElementButton;
