import { Link } from "@gatsbyjs/reach-router";
import PropTypes from "prop-types";
import { Component } from "react";
import momentPropTypes from "react-moment-proptypes";
import { connect, useDispatch } from "react-redux";
import { Box } from "rebass/styled-components";
import { bindActionCreators } from "redux";
import { elementDialogShow } from "../../actions/Element";
import {
  acceptTradeOffer,
  cancelElementTransaction,
  rejectTradeOffer,
  suggestElementTrade,
  suggestElementTransaction,
  transactionDataFetch,
  transferAdd,
  transferRemove,
  updateFreeAgency,
  waiverAdd,
  waiverChange,
  waiverRemove,
  withdrawTradeOffer,
} from "../../actions/Transaction";
import { watchlistAdd, watchlistRemove } from "../../actions/Watchlist";
import {
  getElementTypes,
  getElementTypesById,
} from "../../reducers/elementTypes";
import { getElementsById, getSortStat } from "../../reducers/elements";
import { getActiveEntry } from "../../reducers/entries";
import { getNextEvent, propTypeEvent } from "../../reducers/events";
import { getActiveLeagueEntriesByEntryId } from "../../reducers/leagueEntries";
import {
  getActiveLeague,
  propTypeLeague,
  tradesAvailableForActiveLeague,
} from "../../reducers/leagues";
import { getMyElementsByType } from "../../reducers/picks";
import { getSetting } from "../../reducers/settings";
import { getTeamsById } from "../../reducers/teams";
import {
  getAvailableFilteredWatchedElements,
  getAvailableWatchlist,
  getCurrentTradesOfferedGrouped,
  getCurrentTradesReceivedGrouped,
  getElementStatusWithProposed,
  getErrorElement,
  getNextWaiverDate,
  getNotUnavailableElements,
  getProposedElement,
  getReplaceableElements,
  getTradeWarningElements,
  getTransfersWithElements,
  getWaiversWithElements,
  propTypeTrade,
} from "../../reducers/transactions";
import { getNext3EventsFixturesByTeam } from "../../store/fixtures/slice";
import {
  formatDateAsISO,
  formatDateAsLocal,
  formatRawAsLocal,
} from "../../utils/datetime";
import Copy from "../Copy";
import DeadlineBar from "../DeadlineBar";
import HelmetHead from "../HelmetHead";
import { Main, Secondary, Wrapper } from "../Layout";
import Loader from "../Loader";
import Title from "../Title";
import SquadList from "../squad/SquadList";
import ReceivedTrades from "./ReceivedTrades";
import TransactionAdd from "./TransactionAdd";
import TransactionRemove from "./TransactionRemove";

const TransactionWrapper = ({
  isRemoval,
  league,
  nextEvent,
  nextWaiverDate,
  tradesReceived,
  waiverHours,
  ...props
}) => {
  const dispatch = useDispatch();
  const proposeMe = (elementId) =>
    dispatch(suggestElementTransaction(elementId));
  const modeTitles = {
    waivers: "Waivers",
    "free-agency": "Free Agency",
    finished: "Season Over",
  };
  const waiverDeadlineISO = nextWaiverDate
    ? formatDateAsISO(nextWaiverDate)
    : null;
  const waiverDeadlineLocal = nextWaiverDate
    ? formatDateAsLocal(nextWaiverDate)
    : null;
  const modeDescriptions = {
    waivers: (
      <div>
        {nextWaiverDate && (
          <DeadlineBar
            headingText="Transaction Deadline"
            deadlineISO={waiverDeadlineISO}
            deadlineLocal={waiverDeadlineLocal}
          />
        )}
        <Copy>
          <p>
            A waiver request can be made for any unsigned player. Waiver
            requests are usually processed {waiverHours}
            hours before each deadline.
          </p>
          <p>
            Please refer to the <Link to="/help">rules</Link> for more
            information on waivers.
          </p>
        </Copy>
      </div>
    ),
    "free-agency": (
      <div>
        {nextEvent && (
          <DeadlineBar
            headingText="Free Agency Deadline"
            deadlineISO={nextEvent.deadline_time}
            deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
          />
        )}
        <Copy>
          <p>
            You can use free agency signings to bring in players who are neither
            owned by other managers or locked. Once confirmed the signed player
            is immediately available and the released player is locked until the
            next waiver round.
          </p>
          <p>
            Please refer to the <Link to="/help">rules</Link> for more
            information on free agency signings.
          </p>
        </Copy>
      </div>
    ),
  };
  if (!league.transaction_mode || !modeTitles[league.transaction_mode]) {
    return <Loader />;
  }
  return (
    <Wrapper>
      <HelmetHead
        title="Transactions - Fantasy Premier League Draft 2024/25"
        description="Change the players in your squad after the draft has completed using transactions, visit the official website of the Premier League."
      />
      <Main>
        <Box mx={2}>
          <Title>Transactions</Title>
        </Box>
        {tradesReceived.length > 0 && (
          <ReceivedTrades
            deadlineISO={waiverDeadlineISO}
            deadlineLocal={waiverDeadlineLocal}
            tradesReceived={tradesReceived}
            {...props}
          />
        )}
        {modeDescriptions[league.transaction_mode] &&
          (isRemoval ? (
            <TransactionRemove {...props} />
          ) : (
            <TransactionAdd league={league} proposeMe={proposeMe} {...props} />
          ))}
      </Main>
      <Secondary>
        <SquadList {...props} />
      </Secondary>
    </Wrapper>
  );
};

TransactionWrapper.propTypes = {
  isRemoval: PropTypes.bool.isRequired,
  league: propTypeLeague.isRequired,
  nextEvent: propTypeEvent,
  nextWaiverMoment: momentPropTypes.momentObj,
  tradesReceived: PropTypes.arrayOf(propTypeTrade).isRequired,
  waiverHours: PropTypes.number.isRequired,
};

TransactionWrapper.defaultProps = {
  nextEvent: null,
  nextWaiverMoment: null,
};

export { TransactionWrapper as TransactionWrapperTest };

class TransactionWrapperContainer extends Component {
  componentDidMount() {
    this.props.fetchTransactionData();
  }

  render() {
    return <TransactionWrapper {...this.props} />;
  }
}

TransactionWrapperContainer.propTypes = {
  fetchTransactionData: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  canActiveLeagueTrade: tradesAvailableForActiveLeague(state),
  elements: getNotUnavailableElements(state),
  elementsById: getElementsById(state),
  elementStatus: getElementStatusWithProposed(state),
  elementTypes: getElementTypes(state),
  entry: getActiveEntry(state),
  entriesById: getActiveLeagueEntriesByEntryId(state),
  errorElement: getErrorElement(state),
  league: getActiveLeague(state),
  locationHash: ownProps.location.hash,
  myElementsByType: getMyElementsByType(state),
  next3Fixtures: getNext3EventsFixturesByTeam(state),
  nextEvent: getNextEvent(state),
  nextWaiverDate: getNextWaiverDate(state),
  proposedElement: getProposedElement(state),
  replaceableElements: getReplaceableElements(state),
  isRemoval: Boolean(
    ownProps.location.pathname === "/team/transactions/remove",
  ),
  sortStat: getSortStat(state),
  teamsById: getTeamsById(state),
  transfers: getTransfersWithElements(state),
  tradesOffered: getCurrentTradesOfferedGrouped(state),
  tradesReceived: getCurrentTradesReceivedGrouped(state),
  tradeWarningElements: getTradeWarningElements(state),
  typesById: getElementTypesById(state),
  waiverHours: getSetting(
    state,
    "transactions",
    "waivers_before_deadline_hours",
  ),
  waivers: getWaiversWithElements(state),
  watchedElements: getAvailableFilteredWatchedElements(state),
  watchlist: getAvailableWatchlist(state),
});

const idFromEvent = (e) =>
  parseInt(e.currentTarget.getAttribute("data-id"), 10);

const mapDispatchToProps = {
  acceptTradeOffer: (e) => acceptTradeOffer(idFromEvent(e)),
  addToWatchlist: (e) => watchlistAdd(idFromEvent(e)),
  cancelMe: cancelElementTransaction,
  changeWaiver: waiverChange,
  elementDialogShow,
  fetchTransactionData: transactionDataFetch,
  rejectTradeOffer: (e) => rejectTradeOffer(idFromEvent(e)),
  removeFromWatchlist: (e) => watchlistRemove(idFromEvent(e)),
  removeTransfer: (e) => transferRemove(idFromEvent(e)),
  removeWaiver: (e) => waiverRemove(idFromEvent(e)),
  saveTransfers: updateFreeAgency,
  tradeMe: (e) => suggestElementTrade(idFromEvent(e)),
  withdrawTradeOffer: (e) => withdrawTradeOffer(idFromEvent(e)),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const props = {
    ...ownProps,
    ...stateProps,
    ...bindActionCreators(mapDispatchToProps, dispatch),
  };
  if (stateProps.league.transaction_mode === "waivers") {
    props.removeMe = (e) => dispatch(waiverAdd(idFromEvent(e)));
  } else if (stateProps.league.transaction_mode === "free-agency") {
    props.removeMe = (e) => dispatch(transferAdd(idFromEvent(e)));
  }
  return props;
};

export default connect(
  mapStateToProps,
  null,
  mergeProps,
)(TransactionWrapperContainer);
