import PropTypes from "prop-types";
import { propTypeEntry } from "../../reducers/entries";
import { propTypeEvent } from "../../reducers/events";
import { propTypeLeagueEntry } from "../../reducers/leagueEntries";
import { propTypeLeagueMatch } from "../../reducers/leagueMatches";
import { propTypeLeagueStanding } from "../../reducers/leagueStandings";
import EntryMatchesFilter from "../EntryMatchesFilter";
import Link from "../Link";
import Table from "../Table";
import Tabs from "../Tabs";
import TabPanel from "../Tabs/TabPanel";
import { StandingsRow, VisuallyHidden } from "../styles";
import H2HMatch from "./H2HMatch";
import { MatchesTable } from "../MatchesTable";
import Movement from "./Movement";

const H2HStanding = ({ activeEntry, entry, linkEventId, standing }) => {
  let entryCell = "";
  if (!entry.entry_id) {
    entryCell = (
      <td>
        <strong>AVERAGE</strong>
        <br />
        <span>AVERAGE</span>
      </td>
    );
  } else if (linkEventId) {
    entryCell = (
      <td>
        <Link to={`/entry/${entry.entry_id}/event/${linkEventId}`}>
          <strong>{entry.entry_name}</strong>
          <br />
          <span>
            {entry.player_first_name} {entry.player_second_name}
          </span>
        </Link>
      </td>
    );
  } else {
    entryCell = (
      <td>
        <strong>{entry.entry_name}</strong>
        <br />
        <span>
          {entry.player_first_name} {entry.player_second_name}
        </span>
      </td>
    );
  }
  return (
    <StandingsRow mine={activeEntry.id === entry.entry_id}>
      <td>
        {standing.rank || "-"}{" "}
        <Movement lastRank={standing.last_rank} rank={standing.rank} />
      </td>
      {entryCell}
      <td>{standing.matches_won}</td>
      <td>{standing.matches_drawn}</td>
      <td>{standing.matches_lost}</td>
      <td>{standing.points_for}</td>
      <td>{standing.total}</td>
    </StandingsRow>
  );
};
H2HStanding.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entry: propTypeLeagueEntry.isRequired,
  linkEventId: PropTypes.number.isRequired,
  standing: propTypeLeagueStanding.isRequired,
};

const H2HMatches = ({
  activeEntry,
  entriesById,
  event,
  linkEventId,
  matches,
  noHighlight,
}) => (
  <MatchesTable>
    <thead>
      <tr>
        <th colSpan="3">{event.name}</th>
      </tr>
    </thead>
    <tbody>
      {matches.map((match) => (
        <H2HMatch
          activeEntry={activeEntry}
          entry1={entriesById[match.league_entry_1]}
          entry2={entriesById[match.league_entry_2]}
          noHighlight={noHighlight}
          key={`${match.event}-${match.league_entry_1}`}
          linkEventId={linkEventId}
          match={match}
        />
      ))}
    </tbody>
  </MatchesTable>
);
H2HMatches.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  event: propTypeEvent.isRequired,
  linkEventId: PropTypes.number.isRequired,
  matches: PropTypes.arrayOf(propTypeLeagueMatch).isRequired,
  noHighlight: PropTypes.bool.isRequired,
};

const H2HLeague = ({
  activeEntry,
  entriesById,
  events,
  filteredLeagueEntryId,
  fixturesByEvent,
  linkEventId,
  resultsByEvent,
  standings,
}) => {
  const activeLeagueEntryId = Object.keys(entriesById).reduce(
    (id, leagueEntryId) =>
      entriesById[leagueEntryId].entry_id === activeEntry.id
        ? leagueEntryId
        : id,
    null,
  );
  const noHighlight =
    parseInt(activeLeagueEntryId, 10) === filteredLeagueEntryId ||
    Object.keys(entriesById).length === 2;
  return (
    <div>
      <Tabs theme="dark-b">
        <TabPanel label="Standings" link="standings">
          <Table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Team & Manager</th>
                <th>
                  <abbr title="Wins">W</abbr>
                </th>
                <th>
                  <abbr title="Draws">D</abbr>
                </th>
                <th>
                  <abbr title="Losses">L</abbr>
                </th>
                <th>
                  <abbr title="Total Score">+</abbr>
                  <VisuallyHidden>Score</VisuallyHidden>
                </th>
                <th>
                  <abbr title="Points">Pts</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {standings.map((standing) => (
                <H2HStanding
                  activeEntry={activeEntry}
                  entry={entriesById[standing.league_entry]}
                  key={standing.league_entry}
                  linkEventId={linkEventId}
                  standing={standing}
                />
              ))}
            </tbody>
          </Table>
        </TabPanel>
        <TabPanel label="Fixtures" link="matches">
          <EntryMatchesFilter />
          {events.map((event) => (
            <div key={event.id}>
              {fixturesByEvent[event.id] && (
                <H2HMatches
                  activeEntry={activeEntry}
                  entriesById={entriesById}
                  event={event}
                  linkEventId={linkEventId}
                  matches={fixturesByEvent[event.id]}
                  noHighlight={noHighlight}
                />
              )}
            </div>
          ))}
        </TabPanel>
        <TabPanel label="Results" link="matches">
          <EntryMatchesFilter />
          {events.reverse().map((event) => (
            <div key={event.id}>
              {resultsByEvent[event.id] && (
                <H2HMatches
                  activeEntry={activeEntry}
                  entriesById={entriesById}
                  event={event}
                  linkEventId={linkEventId}
                  matches={resultsByEvent[event.id]}
                  noHighlight={noHighlight}
                />
              )}
            </div>
          ))}
        </TabPanel>
      </Tabs>
    </div>
  );
};

H2HLeague.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  events: PropTypes.arrayOf(propTypeEvent).isRequired,
  filteredLeagueEntryId: PropTypes.number.isRequired,
  fixturesByEvent: PropTypes.objectOf(PropTypes.arrayOf(propTypeLeagueMatch))
    .isRequired,
  linkEventId: PropTypes.number.isRequired,
  resultsByEvent: PropTypes.objectOf(PropTypes.arrayOf(propTypeLeagueMatch))
    .isRequired,
  standings: PropTypes.arrayOf(propTypeLeagueStanding).isRequired,
};

export default H2HLeague;
