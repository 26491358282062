import arrayMove from "array-move";
import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import {
  ADD_CURRENT_TRADES,
  ADD_LEAGUE_TRADES,
  ADD_LEAGUE_TRANSACTIONS,
  ADD_TRADES_FOR_APPROVAL,
  ADD_TRANSACTION_ERROR_ELEMENT,
  CANCEL_ELEMENT_TRANSACTION,
  PROPOSE_ELEMENT_TRANSACTION,
  PROPOSE_TRADE_INITIAL,
  TRANSFER_ADD,
  TRANSFER_ERROR_RESET,
  TRANSFER_REMOVE,
  TRANSFER_RESET,
  WAIVER_ADD,
  WAIVER_CHANGE,
  WAIVER_REMOVE,
  WAIVER_UPDATE,
} from "../actions/ActionTypes";
import { getElementStat } from "./elementStats";
import { getActiveLeagueElementStatus } from "./elementStatus";
import { getElementTypes } from "./elementTypes";
import {
  getElement,
  getElements,
  getElementsById,
  getFilteredWatchedElements,
  getSortedElements,
  propTypeElement,
} from "./elements";
import { getNextEvent } from "./events";
import { getActiveLeagueEntriesByEntryId } from "./leagueEntries";
import { getMyElementIds, getMyElementsByType } from "./picks";
import { getActiveEntryId } from "./player";
import { getWatchlist } from "./watchlist";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.transactions;
export const l2g = (local) => ({ transactions: local });

const proposedElement = (state = null, action) => {
  switch (action.type) {
    case PROPOSE_ELEMENT_TRANSACTION:
      return action.element;

    case CANCEL_ELEMENT_TRANSACTION:
    case TRANSFER_RESET:
      return null;

    default:
      return state;
  }
};

const proposedTrade = (
  state = { initialElement: null, recipient: null },
  action,
) => {
  switch (action.type) {
    case PROPOSE_TRADE_INITIAL:
      return {
        ...state,
        initialElement: action.elementId,
        recipient: action.entryId,
      };
    default:
      return state;
  }
};

const errorElement = (state = null, action) => {
  switch (action.type) {
    case ADD_TRANSACTION_ERROR_ELEMENT:
      return action.element;

    case TRANSFER_REMOVE:
      return action.element === state ? null : state;

    case TRANSFER_RESET:
    case TRANSFER_ERROR_RESET:
      return null;

    default:
      return state;
  }
};

const reprioritize = (waivers) =>
  waivers.map((waiver, index) => ({
    ...waiver,
    priority: index + 1,
  }));

const waivers = (state = [], action) => {
  switch (action.type) {
    case WAIVER_ADD:
      return reprioritize([...state, action.data]);

    case WAIVER_CHANGE: {
      const newPri = action.data.newPriority;
      const oldPri = action.data.oldPriority;

      // Safety belts
      if (newPri === oldPri) return state;
      if (newPri < 1 || oldPri < 1) return state;
      if (newPri > state.length || oldPri > state.length) return state;

      return reprioritize(arrayMove(state, oldPri - 1, newPri - 1));
    }

    case WAIVER_REMOVE:
      if (state.length < action.priority) return state;
      return reprioritize([
        ...state.slice(0, action.priority - 1),
        ...state.slice(action.priority),
      ]);

    case WAIVER_UPDATE:
      return reprioritize(action.data);

    default:
      return state;
  }
};

const transfers = (state = [], action) => {
  switch (action.type) {
    case TRANSFER_ADD:
      return [...state, action.data];

    case TRANSFER_REMOVE:
      return state.filter((t) => t.element_in !== action.element);

    case TRANSFER_RESET:
      return [];

    default:
      return state;
  }
};

const byLeague = (state = {}, action) => {
  switch (action.type) {
    case ADD_LEAGUE_TRANSACTIONS: {
      const newState = { ...state };
      if (!newState[action.data.leagueId]) {
        newState[action.data.leagueId] = {};
      }
      newState[action.data.leagueId] = action.data.transactions;
      return newState;
    }

    default:
      return state;
  }
};

const tradesByLeague = (state = {}, action) => {
  switch (action.type) {
    case ADD_LEAGUE_TRADES: {
      const newState = { ...state };
      if (!newState[action.data.leagueId]) {
        newState[action.data.leagueId] = {};
      }
      newState[action.data.leagueId] = action.data.trades;
      return newState;
    }

    default:
      return state;
  }
};

const currentTrades = (state = [], action) => {
  switch (action.type) {
    case ADD_CURRENT_TRADES:
      return action.data;

    default:
      return state;
  }
};

const tradesForApproval = (state = [], action) => {
  switch (action.type) {
    case ADD_TRADES_FOR_APPROVAL:
      return action.data;

    default:
      return state;
  }
};

export default combineReducers({
  byLeague,
  currentTrades,
  errorElement,
  proposedElement,
  proposedTrade,
  tradesByLeague,
  tradesForApproval,
  transfers,
  waivers,
});

// Selectors
export const getProposedElementId = (state) => g2l(state).proposedElement;

export const getProposedElement = createSelector(
  getProposedElementId,
  getElementsById,
  (proposed, elements) => (proposed ? elements[proposed] : undefined),
);

export const getErrorElementId = (state) => g2l(state).errorElement;

export const getErrorElement = createSelector(
  getErrorElementId,
  getElementsById,
  (error, elements) => (error ? elements[error] : undefined),
);

export const getReplaceableElements = createSelector(
  getProposedElement,
  getMyElementIds,
  getElementsById,
  (proposed, mine, elements) => {
    if (!proposed) return [];
    return mine.reduce((els, id) => {
      if (elements[id].element_type === proposed.element_type) {
        els.push(elements[id]);
      }
      return els;
    }, []);
  },
);

export const getNextWaiverDate = (state) => {
  const event = getNextEvent(state);
  if (!event) return null;
  return new Date(event.waivers_time);
};

export const getWaivers = (state) => g2l(state).waivers;

export const getWaiversWithElements = createSelector(
  getWaivers,
  getElementsById,
  (proposed, elements) =>
    proposed.map((waiver) => ({
      ...waiver,
      element_in: elements[waiver.element_in],
      element_out: elements[waiver.element_out],
    })),
);

export const getWaiversById = createSelector(getWaivers, (waivers) =>
  waivers.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
);

export const getWaiver = (state, id) => getWaiversById(state)[id];

export const getProposedWaivers = createSelector(getWaivers, (proposed) =>
  proposed.filter((waiver) => waiver.result === "p"),
);

export const getElementIdsFromProposedWaivers = createSelector(
  getProposedWaivers,
  (proposed) => {
    const proposedElements = [];
    proposed.forEach((waiver) => {
      proposedElements.push(waiver.element_in, waiver.element_out);
    });
    return proposedElements;
  },
);

export const getWaiversAPI = createSelector(getWaivers, (proposedWaivers) =>
  proposedWaivers.map((waiver, index) => ({
    element_in: waiver.element_in,
    element_out: waiver.element_out,
    priority: index + 1,
  })),
);

const getAvailableElementsFilterValues = getFormValues(
  "AvailableElementsFilter",
);
const getTopAvailableElementsFilterValues = getFormValues(
  "topAvailableElementsFilter",
);

export const getNotUnavailableElements = createSelector(
  getSortedElements,
  getAvailableElementsFilterValues,
  getActiveLeagueElementStatus,
  (elements, formValues, elementStatus) =>
    elements.filter((e) => {
      if (e.status === "u") return false;
      if (!elementStatus[e.id]) return false;
      if (formValues && formValues.showAvailable) {
        return elementStatus[e.id].status === "a";
      }
      return true;
    }),
);

export const getAvailableWatchlist = createSelector(
  getWatchlist,
  getAvailableElementsFilterValues,
  getActiveLeagueElementStatus,
  (watchlist, formValues, elementStatus) =>
    watchlist.filter((id) => {
      if (formValues && formValues.showAvailable) {
        return elementStatus[id].status === "a";
      }
      return true;
    }),
);

export const getAvailableFilteredWatchedElements = createSelector(
  getFilteredWatchedElements,
  getAvailableElementsFilterValues,
  getActiveLeagueElementStatus,
  (watchlist, formValues, elementStatus) =>
    watchlist.filter((e) => {
      if (formValues && formValues.showAvailable) {
        return elementStatus[e.id].status === "a";
      }
      return true;
    }),
);

export const getTypeFilteredElements = createSelector(
  getElements,
  getTopAvailableElementsFilterValues,
  getActiveLeagueElementStatus,
  (elements, formValues, elementStatus) =>
    elements.filter((element) => {
      if (element.status === "u") return false;
      if (!elementStatus[element.id]) return false;
      if (elementStatus[element.id].status !== "a") return false;
      if (formValues && parseInt(formValues.filter, 10)) {
        return element.element_type === parseInt(formValues.filter, 10);
      }
      return true;
    }),
);

export const getTypeFilteredElementsByForm = createSelector(
  getTypeFilteredElements,
  (elements) => elements.sort((a, b) => b.form - a.form),
);

export const getTopElementsByForm = createSelector(
  getTypeFilteredElementsByForm,
  (elements) => elements.slice(0, 10),
);

export const getTransfers = (state) => g2l(state).transfers;

export const getTransfersWithElements = createSelector(
  getTransfers,
  getElementsById,
  (proposed, elements) =>
    proposed.map((transfer) => ({
      ...transfer,
      element_in: elements[transfer.element_in],
      element_out: elements[transfer.element_out],
    })),
);

// Seems a bit pointless but prefer a dedicated API selector ...
export const getFreeAgencyAPI = createSelector(getTransfers, (data) => data);

export const getElementStatusWithProposed = createSelector(
  getActiveLeagueElementStatus,
  getTransfers,
  (status, proposedTransfers) => {
    const proposedById = proposedTransfers.reduce(
      (memo, transfer) =>
        Object.assign(memo, {
          [transfer.element_in]: true,
          [transfer.element_out]: true,
        }),
      {},
    );
    return Object.keys(status).reduce(
      (memo, id) =>
        Object.assign(memo, {
          [id]: {
            ...status[id],
            proposed: Boolean(proposedById[id]),
          },
        }),
      {},
    );
  },
);

export const getForLeague = (state, leagueId) => {
  const forLeague = g2l(state).byLeague;
  if (forLeague[leagueId] && forLeague[leagueId].length) {
    return forLeague[leagueId].map((t) => ({
      ...t,
      element_in: getElement(state, t.element_in),
      element_out: getElement(state, t.element_out),
    }));
  }
  return [];
};

export const getForLeagueEvent = (state, leagueId, eventId) =>
  getForLeague(state, leagueId).filter((t) => t.event === eventId);

export const getForLeagueWaivers = (state, leagueId) =>
  getForLeague(state, leagueId)
    .filter((t) => t.kind === "w")
    .sort((a, b) => a.event - b.event || a.index - b.index);

export const getForLeagueWaiversEvent = (state, leagueId, eventId) =>
  getForLeagueWaivers(state, leagueId, eventId).filter(
    (t) => t.event === eventId,
  );

export const getForLeagueFreeAgents = (state, leagueId) =>
  getForLeague(state, leagueId)
    .filter((t) => t.kind === "f")
    .sort((a, b) => a.id - b.id);

export const getForLeagueFreeAgentsEvent = (state, leagueId, eventId) =>
  getForLeagueFreeAgents(state, leagueId, eventId).filter(
    (t) => t.event === eventId,
  );

const getleagueTransactionsValues = getFormValues("transactionValues");

const filterTransactions = (transactions, formValues) => {
  if (formValues) {
    let filteredTransactions = transactions;
    const entryFilterValue = parseInt(formValues.entryFilter, 10);
    const eventFilterValue = parseInt(formValues.eventFilter, 10);
    if (entryFilterValue) {
      filteredTransactions = filteredTransactions.filter(
        (t) => entryFilterValue === t.entry,
      );
    }
    if (eventFilterValue) {
      filteredTransactions = filteredTransactions.filter(
        (t) => eventFilterValue === t.event,
      );
    }
    return filteredTransactions;
  }
  return transactions;
};

export const getForLeagueWaiversFiltered = (state, leagueId) =>
  filterTransactions(
    getForLeagueWaivers(state, leagueId),
    getleagueTransactionsValues(state),
  );

export const getForLeagueFreeAgentsFiltered = (state, leagueId) =>
  filterTransactions(
    getForLeagueFreeAgents(state, leagueId),
    getleagueTransactionsValues(state),
  );

export const getProposedTrade = (state) => g2l(state).proposedTrade;

export const getProposedTradeRecipient = (state) => {
  const tradeData = getProposedTrade(state);
  const activeLeagueEntries = getActiveLeagueEntriesByEntryId(state);
  return activeLeagueEntries[tradeData.recipient] || null;
};

export const getProposedTradeInitialElement = createSelector(
  getProposedTrade,
  getElementsById,
  (tradeData, elements) => {
    if (!tradeData.initialElement) return null;
    return elements[tradeData.initialElement] || null;
  },
);

export const getRecipientElementIds = createSelector(
  getActiveLeagueElementStatus,
  getProposedTrade,
  (status, tradeData) => {
    if (!tradeData.recipient) return [];
    return Object.keys(status).reduce((memo, elementId) => {
      if (status[elementId].owner === tradeData.recipient) {
        memo.push(parseInt(elementId, 10));
      }
      return memo;
    }, []);
  },
);

export const getRecipientElementsByType = createSelector(
  getRecipientElementIds,
  getElementsById,
  getElementTypes,
  (elIds, elements, types) => {
    const keyFromId = (id) => `et${id}`;
    const data = types.reduce(
      (memo, et) => Object.assign(memo, { [keyFromId(et.id)]: [] }),
      {},
    );
    elIds.forEach((elId) => {
      const element = elements[elId];
      data[keyFromId(element.element_type)].push(element);
    });
    return data;
  },
);

const getProposedTradeValues = getFormValues("proposedTrade");
const keyFromId = (id) => `et${id}`;
const nameFromId = (id) => `ism-trade-${id}`;

export const getDisabledTradeElements = createSelector(
  getProposedTradeValues,
  getMyElementsByType,
  getRecipientElementsByType,
  getElementTypes,
  getActiveLeagueElementStatus,
  (formValues, mine, recipient, types, elementStatus) => {
    const disabledElements = {};

    // All accepted trade elements should be disabled
    const disabledIfAccepted = (el) => {
      if (elementStatus[el.id].in_accepted_trade) {
        disabledElements[el.id] = true;
      }
    };
    types.forEach((et) => {
      const key = keyFromId(et.id);
      mine[key].forEach((el) => disabledIfAccepted(el));
      recipient[key].forEach((el) => disabledIfAccepted(el));
    });

    if (!formValues) return disabledElements;

    const countFromPicks = (picks) =>
      picks.reduce((memo, el) => {
        if (formValues[nameFromId(el.id)]) return memo + 1;
        return memo;
      }, 0);

    const mismatchedTypes = {};
    types.forEach((et) => {
      const mineInvolved = countFromPicks(mine[keyFromId(et.id)]);
      const recipientInvolved = countFromPicks(recipient[keyFromId(et.id)]);
      if (mineInvolved !== recipientInvolved) {
        mismatchedTypes[et.id] = {
          mine: mineInvolved,
          recipient: recipientInvolved,
        };
      }
    });

    // No mismatches, everything is enabled
    if (!Object.keys(mismatchedTypes).length) return disabledElements;

    types.forEach((et) => {
      const mismatch = mismatchedTypes[et.id];
      const key = keyFromId(et.id);
      if (!mismatch) {
        // No mismatch so all disabled
        mine[key].forEach((el) => {
          disabledElements[el.id] = true;
        });
        recipient[key].forEach((el) => {
          disabledElements[el.id] = true;
        });
      } else if (mismatch.mine > mismatch.recipient) {
        // Need to choose from recipient
        mine[key].forEach((el) => {
          if (!formValues[nameFromId(el.id)]) {
            disabledElements[el.id] = true;
          }
        });
        recipient[key].forEach((el) => {
          if (formValues[nameFromId(el.id)]) {
            disabledElements[el.id] = true;
          }
        });
      } else if (mismatch.mine < mismatch.recipient) {
        // Need to choose from mine
        mine[key].forEach((el) => {
          if (formValues[nameFromId(el.id)]) {
            disabledElements[el.id] = true;
          }
        });
        recipient[key].forEach((el) => {
          if (!formValues[nameFromId(el.id)]) {
            disabledElements[el.id] = true;
          }
        });
      }
    });

    return disabledElements;
  },
);

export const getValidTrades = createSelector(
  getProposedTradeValues,
  getMyElementsByType,
  getRecipientElementsByType,
  getElementTypes,
  (formValues, mine, recipient) => {
    if (!formValues) return [];

    // There must be an even number of selected elements
    const startsWith = nameFromId("");
    const elCount = Object.keys(formValues).reduce(
      (m, key) =>
        key.indexOf(startsWith) === 0 && formValues[key] ? m + 1 : m,
      0,
    );
    if (elCount % 2) return []; // Uneven number

    const elementsOut = [];
    Object.keys(mine)
      .sort()
      .forEach((key) => {
        mine[key].forEach((e) => {
          if (formValues[nameFromId(e.id)]) elementsOut.push(e.id);
        });
      });

    const elementsIn = [];
    Object.keys(recipient)
      .sort()
      .forEach((key) => {
        recipient[key].forEach((e) => {
          if (formValues[nameFromId(e.id)]) elementsIn.push(e.id);
        });
      });

    const data = elementsIn.map((id, index) => ({
      element_in: id,
      element_out: elementsOut[index],
    }));
    return data;
  },
);

export const areTradesValid = createSelector(getValidTrades, (validTrades) =>
  Boolean(validTrades.length),
);

export const getTradeStat = (state) => {
  const formValues = getProposedTradeValues(state);
  if (formValues && formValues.stat) {
    return getElementStat(state, formValues.stat);
  }
  return getElementStat(state, "total_points");
};

export const getTradeOfferAPI = createSelector(
  getProposedTradeRecipient,
  getValidTrades,
  (recipient, validTrades) => ({
    receiver: recipient.entry_id,
    trades: validTrades,
  }),
);

export const getTradesByLeague = (state) => g2l(state).tradesByLeague;

export const getTradesForLeague = (state, leagueId) =>
  getTradesByLeague(state)[leagueId] || [];

export const getTradesForLeagueEvent = (state, leagueId, eventId) =>
  getTradesForLeague(state, leagueId).filter((t) => t.event === eventId);

export const getCurrentTrades = (state) => g2l(state).currentTrades;

// These are either offered to an individual or 'offered' to the league for
// acceptance
export const getCurrentTradesOffered = createSelector(
  getCurrentTrades,
  getActiveEntryId,
  (trades, entryId) =>
    trades.filter(
      (t) =>
        (t.offered_entry === entryId && (t.state === "o" || t.state === "a")) ||
        (t.received_entry === entryId && t.state === "a"),
    ),
);

export const getCurrentTradesReceived = createSelector(
  getCurrentTrades,
  getActiveEntryId,
  (trades, entryId) =>
    trades.filter((t) => t.received_entry === entryId && t.state === "o"),
);

export const addTradeGrouping = (trades) =>
  trades.map((trade) => {
    const tradeGrouped = { ...trade };
    tradeGrouped.elementsIn = tradeGrouped.tradeitem_set.map(
      (tradeItem) => tradeItem.element_in,
    );
    tradeGrouped.elementsOut = tradeGrouped.tradeitem_set.map(
      (tradeItem) => tradeItem.element_out,
    );
    return tradeGrouped;
  });

export const getCurrentTradesGrouped = createSelector(
  getCurrentTrades,
  (trades) => addTradeGrouping(trades),
);

export const getTradesForLeagueGrouped = (state, leagueId) =>
  addTradeGrouping(getTradesForLeague(state, leagueId));

const filterTrades = (trades, formValues) => {
  if (formValues) {
    let filteredTrades = trades;
    const entryFilterValue = parseInt(formValues.entryFilter, 10);
    const eventFilterValue = parseInt(formValues.eventFilter, 10);
    if (entryFilterValue) {
      filteredTrades = filteredTrades.filter(
        (t) =>
          entryFilterValue === t.offered_entry ||
          entryFilterValue === t.received_entry,
      );
    }
    if (eventFilterValue) {
      filteredTrades = filteredTrades.filter(
        (t) => eventFilterValue === t.event,
      );
    }
    return filteredTrades;
  }
  return trades;
};

export const getFilteredTradesForLeagueGrouped = (state, leagueId) =>
  filterTrades(
    getTradesForLeagueGrouped(state, leagueId),
    getleagueTransactionsValues(state),
  );

export const getTradesForLeagueEventGrouped = (state, leagueId, eventId) =>
  addTradeGrouping(getTradesForLeagueEvent(state, leagueId, eventId));

export const getCurrentTradesOfferedGrouped = createSelector(
  getCurrentTradesOffered,
  (tradesOffered) => addTradeGrouping(tradesOffered),
);

export const getCurrentTradesReceivedGrouped = createSelector(
  getCurrentTradesReceived,
  (tradesReceived) => addTradeGrouping(tradesReceived),
);

export const getTradesForApproval = (state) => g2l(state).tradesForApproval;

export const getTradesForApprovalGrouped = createSelector(
  getTradesForApproval,
  (approvalTrades) => addTradeGrouping(approvalTrades),
);

// These are all my trades with a state of 'offered' (but not accepted)
export const getCurrentProposedTrades = createSelector(
  getCurrentTrades,
  (trades) => trades.filter((t) => t.state === "o"),
);

export const getElementsInTrade = (tradeItems) => {
  const elements = [];
  tradeItems.forEach((item) => {
    elements.push(item.element_in, item.element_out);
  });
  return elements;
};

export const getElementsInTrades = (trades) => {
  const elements = [];
  trades.forEach((t) => {
    elements.push(...getElementsInTrade(t.tradeitem_set));
  });
  return elements;
};

export const getElementsInProposedTrades = createSelector(
  getCurrentProposedTrades,
  (trades) => getElementsInTrades(trades),
);

export const getElementsWithTrades = (trades) => {
  const elementsWithTrades = {};
  trades.forEach((t) => {
    t.tradeitem_set.forEach((item) => {
      [item.element_in, item.element_out].forEach((el) => {
        if (!elementsWithTrades[el]) {
          elementsWithTrades[el] = [];
        }
        elementsWithTrades[el].push(t.id);
      });
    });
  });
  return elementsWithTrades;
};

export const getTradeWarningElements = createSelector(
  getElements,
  getActiveLeagueElementStatus,
  getCurrentProposedTrades,
  getElementIdsFromProposedWaivers,
  (elements, elementStatus, trades, waiversElements) => {
    const warningElements = {};
    const elementsWithTrades = getElementsWithTrades(trades);
    elements.forEach((el) => {
      const elementInWaiver = waiversElements.indexOf(el.id) > -1;
      if (elementStatus[el.id] && elementStatus[el.id].in_accepted_trade) {
        warningElements[el.id] = {
          transactions: ["accepted-trade"],
        };
      } else if (elementsWithTrades[el.id]) {
        warningElements[el.id] = {
          tradeIds: elementsWithTrades[el.id],
          transactions: ["proposed-trade"],
        };
        if (elementInWaiver) {
          warningElements[el.id].transactions.push("proposed-waiver");
        }
      } else if (elementInWaiver) {
        warningElements[el.id] = {
          transactions: ["proposed-waiver"],
        };
      }
    });
    return warningElements;
  },
);

// PropTypes
export const propTypeWaiver = PropTypes.shape({
  element_in: PropTypes.number,
  element_out: PropTypes.number,
});

export const propTypeWaiverInflated = PropTypes.shape({
  element_in: propTypeElement,
  element_out: propTypeElement,
});

export const propTypeTradeStat = PropTypes.shape({
  abbreviation: PropTypes.string,
});

export const propTypeTransferInflated = PropTypes.shape({
  element_in: propTypeElement,
  element_out: propTypeElement,
});

export const propTypeTransactionInflated = PropTypes.shape({
  element_in: propTypeElement,
  element_out: propTypeElement,
});

export const propTypeTradeItem = PropTypes.shape({
  element_in: PropTypes.number,
  element_out: PropTypes.number,
});

const sharedTradePropTypes = {
  id: PropTypes.number,
  offered_entry: PropTypes.number,
  received_entry: PropTypes.number,
  state: PropTypes.oneOf([
    "o", // offered
    "w", // withdrawn
    "r", // rejected
    "a", // accepted
    "i", // invalid
    "v", // vetoed
    "e", // expired
    "p", // processed
  ]),
  tradeitem_set: PropTypes.arrayOf(propTypeTradeItem),
};

export const propTypeTrade = PropTypes.shape({
  ...sharedTradePropTypes,
});

export const propTypeTradeGrouped = PropTypes.shape({
  ...sharedTradePropTypes,
  elementsIn: PropTypes.arrayOf[PropTypes.number],
  elementsOut: PropTypes.arrayOf[PropTypes.number],
});

export const propTypeTradeWarningElement = PropTypes.shape({
  state: PropTypes.string,
});
