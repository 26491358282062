import styled from "styled-components/macro";

export const DraftedStatus = styled.th`
  width: 6%;
`;

export const DraftedElement = styled.th`
  width: 44%;
`;

export const DraftedSortStat = styled.th`
  width: 15%;
  text-align: center;
`;

export const DraftedEvent = styled.th`
  width: 15%;
  text-align: center;
`;

export const DraftedPick = styled.th`
  width: 15%;
  text-align: center;
`;

export const DraftedPlayer = styled.th`
  width: 15%;
  text-align: center;
`;
