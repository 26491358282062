import styled from "styled-components/macro";

export const Trade = styled.div`
  display: flex;
  margin: ${(props) => props.theme.space[4]} 0;
`;

export const TradeUnit = styled.div`
  flex: 0 0 50%;
`;

export const TradeFormLegend = styled.legend`
  margin: ${(props) => props.theme.space[2]};
  font-family: ${(props) => props.theme.fonts.bold};

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-right: 0;
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2rem;
  }
`;

export const TradeTitle = styled.span`
  text-transform: uppercase;
`;

export const TradeEntry = styled.span`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    display: inline;
  }
`;

export const TradeStatSelect = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: block;
  }
`;
