import styled from "styled-components/macro";
import Table from "./Table";

export const Entry1 = styled.td`
  margin-bottom: ${(props) => props.theme.space[4]};
  text-align: right;
`;

export const Entry2 = styled.td`
  text-align: left;
`;

export const Score = styled.td`
  text-align: center;
  white-space: nowrap;
`;

export const Tiebreak = styled.td`
  padding-bottom: ${(props) => props.theme.space[4]};
  text-align: center;
`;

export const MatchesTable = styled(Table)`
  ${Entry1}, ${Entry2} {
    width: 38%;
  }
  ${Score} {
    width: 24%;
  }
`;
