import { Link } from "@gatsbyjs/reach-router";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { ControlArrowRight } from "./icons/Arrows";
import { LinkStyles } from "./Link";

interface IBoldLinkStyles {
  color?: "white";
}

export const BoldLinkWrap = styled.div`
  margin: ${({ theme }) => theme.space[3]} 0;
`;

const BoldLinkStyles = css<IBoldLinkStyles>`
  ${LinkStyles}

  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const StyledBoldHyperlink = styled.a`
  ${BoldLinkStyles}
`;

const StyledBoldLink = styled(Link)`
  ${BoldLinkStyles}
`;

interface IBoldLinkProps {
  children: React.ReactNode;
  color?: "white";
  to: string;
}

interface IBoldHyperlinkProps {
  children: React.ReactNode;
  href: string;
}

export const BoldHyperlink: React.FC<IBoldHyperlinkProps> = ({
  children,
  href,
}) => (
  <StyledBoldHyperlink href={href}>
    {children}
    <ControlArrowRight />
  </StyledBoldHyperlink>
);

const BoldLink: React.FC<IBoldLinkProps> = ({ children, color, to }) => (
  <StyledBoldLink to={to} color={color}>
    {children}
    <ControlArrowRight />
  </StyledBoldLink>
);

export default BoldLink;
