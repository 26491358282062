import { hideVisually } from "polished";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { elementDialogShow } from "../actions/Element";
import { refreshFixturesForEvent } from "../actions/Fixture";
import { entryEventPicksFetch } from "../actions/Pick";
import { ReactComponent as BaseReload } from "../img/icons/reload.svg";
import {
  getElementsById,
  getElementStatsForEvent,
  getExplainElementEvent,
} from "../reducers/elements";
import { getElementStatsMatch } from "../reducers/elementStats";
import { getElementTypesById } from "../reducers/elementTypes";
import { getActiveEntry, getEntry } from "../reducers/entries";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
  propTypeEvent,
} from "../reducers/events";
import { getForEntryEvent, getFormationForEntryEvent } from "../reducers/picks";
import { getForEntryEvent as getSubs, propTypeSub } from "../reducers/subs";
import { getTeamsById } from "../reducers/teams";
import {
  getFixturesByTeamForEvent,
  getNext3EventsFixturesByTeam,
} from "../store/fixtures/slice";
import Button from "./Button";
import { ButtonGroup, ButtonGroupItem } from "./ButtonGroup";
import Dialog from "./Dialog";
import { ConfirmDialogBody } from "./Dialog/styles";
import ElementExplain from "./ElementExplain";
import Entry from "./entry/Entry";
import EventPager from "./EventPager";
import Fixtures from "./fixtures/Fixtures";
import { PatternWrapMain } from "./GraphicPatterns";
import HelmetHead from "./HelmetHead";
import { Main, Secondary, Wrapper } from "./Layout";
import ListView from "./ListView";
import Loader from "./Loader";
import PitchView from "./PitchView";
import Tabs from "./Tabs";
import TabPanel from "./Tabs/TabPanel";
import Title from "./Title";
import { TextGradient } from "./styles";
import SubHeading from "./SubHeading";
import Table from "./Table";

// TODO: Organise this file into its own folder with styles.ts etc.

const ScoreboardPoints = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};
  text-align: center;
`;

const ScoreboardPrimary = styled.div`
  width: 40%;
  margin: auto;
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 20%;
  }
`;

const PrimaryPanel = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: 2.5rem;

  ${(props) =>
    props.chipPlayed &&
    css`
      padding-top: 4rem;
    `}
`;

const PrimaryHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.space[1]};
  color: white;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.1rem;
`;

const PrimaryValue = styled.div`
  ${TextGradient}
  font-size: 4rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
`;

const ReloadButton = styled.button`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 1;
  cursor: pointer;
`;

const Reload = styled(BaseReload)`
  fill: #05fa87;
`;

const ReloadButtonText = styled.span`
  ${hideVisually}
`;

const EntryEventScoreboard = ({ event, fetchPicks, points }) => (
  <ScoreboardPoints>
    <ScoreboardPrimary>
      <PrimaryPanel>
        <PrimaryHeading>
          {event.finished ? "Final Points" : "Latest Points"}
        </PrimaryHeading>
        <PrimaryValue>{points}</PrimaryValue>
        {!event.finished && (
          <ReloadButton
            type="button"
            title="Reload Points"
            onClick={fetchPicks}
          >
            <ReloadButtonText>Reload Points</ReloadButtonText>
            <Reload />
          </ReloadButton>
        )}
      </PrimaryPanel>
    </ScoreboardPrimary>
  </ScoreboardPoints>
);

EntryEventScoreboard.propTypes = {
  event: propTypeEvent.isRequired,
  fetchPicks: PropTypes.func.isRequired,
  points: PropTypes.number.isRequired,
};

const SubsTable = ({ subs }) => {
  if (!(subs && subs.length)) return null;
  return (
    <div>
      <SubHeading as="h4">Automatic Substitutions</SubHeading>
      <Box mb={4}>
        <Table>
          <thead>
            <tr>
              <th>In</th>
              <th>Out</th>
            </tr>
          </thead>
          <tbody>
            {subs.map((s) => (
              <tr key={s.element_in.id}>
                <td>{s.element_in.web_name}</td>
                <td>{s.element_out.web_name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </div>
  );
};

SubsTable.propTypes = {
  subs: PropTypes.arrayOf(propTypeSub).isRequired,
};

class EntryEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementForMenu: null,
    };
    this.fetchPicks = () =>
      this.props.fetchPicks(this.props.entryId, this.props.event.id);
    this.handleShowMenuForElement = this.handleShowMenuForElement.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }
  componentDidMount() {
    this.fetchPicks();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // If entry or event has changed then we need to reassign our callback
    // and refresh data
    if (
      this.props.entryId !== nextProps.entryId ||
      this.props.event.id !== nextProps.event.id
    ) {
      this.fetchPicks = () =>
        this.props.fetchPicks(nextProps.entryId, nextProps.event.id);
      this.fetchPicks();
    }
  }

  pointsFromPicks() {
    const picks = this.props.picks;
    const elementStatsById = this.props.elementStatsById;
    return picks
      .slice(0, 11)
      .reduce(
        (memo, pick) =>
          memo + elementStatsById[pick.element].stats.total_points,
        0,
      );
  }

  handleShowMenuForElement(element) {
    this.setState({ elementForMenu: element });
  }

  handleHide() {
    this.setState({ elementForMenu: null });
  }

  renderMenu(explainData) {
    const element = this.state.elementForMenu;
    if (!element) return null;
    return (
      <Dialog closeDialog={this.handleHide}>
        <Dialog.Header closeDialog={this.handleHide}>
          {element.first_name} {element.second_name}
        </Dialog.Header>
        <Dialog.Body>
          <ConfirmDialogBody>
            <ElementExplain
              explainData={explainData(element.id, this.props.event.id)}
              teamsById={this.props.teamsById}
            />
            <ButtonGroup>
              <ButtonGroupItem>
                <Button
                  variant="tertiary"
                  fullwidth="true"
                  onClick={() => {
                    this.props.elementDialogShow(element.id);
                    this.handleHide();
                  }}
                >
                  View Information
                </Button>
              </ButtonGroupItem>
            </ButtonGroup>
          </ConfirmDialogBody>
        </Dialog.Body>
      </Dialog>
    );
  }

  render() {
    const {
      activeEntry,
      elementStats,
      elementStatsById,
      entry,
      explainData,
      event,
      next,
      now,
      picks,
      subs,
      ...rest
    } = this.props;
    if (!(entry && entry.name && picks.length)) {
      return <Loader />;
    }

    const backLink =
      now && now.id > 1 && event.id > entry.started_event
        ? `/entry/${entry.id}/event/${event.id - 1}`
        : null;

    let nextLink = null;

    if (event.id < now.id) {
      nextLink = `/entry/${entry.id}/event/${event.id + 1}`;
    } else if (next && activeEntry && activeEntry.id === entry.id) {
      nextLink = "/team/my";
    }

    const renderExtraListViewHeaders = () =>
      elementStats.map((s) => ({
        content: s.abbreviation,
        key: s.name,
      }));

    const renderExtraListViewCells = (element) =>
      elementStats.map((s) => ({
        content: elementStatsById[element.id].stats[s.name],
        key: `${element.id}-${s.name}`,
      }));

    return (
      <Wrapper>
        {/* eslint-disable max-len */}
        <HelmetHead
          title="View Points - Fantasy Premier League Draft 2024/25"
          description="To view the latest Fantasy Premier League Draft Gameweek points, visit the official website of the Premier League."
        />
        {/* eslint-enable max-len */}
        <Main>
          <Box mx={2}>
            <Title>Points - {entry.name}</Title>
          </Box>

          <PatternWrapMain>
            <EventPager
              backLink={backLink}
              nextLink={nextLink}
              entry={entry}
              event={event}
            />
            <EntryEventScoreboard
              event={event}
              fetchPicks={this.fetchPicks}
              points={this.pointsFromPicks()}
            />
            <Tabs isCentered={true}>
              <TabPanel label="Pitch View" link="pitch">
                <Box pt={4}>
                  <PitchView
                    elementStatsById={elementStatsById}
                    picks={picks}
                    renderElementMenu={this.handleShowMenuForElement}
                    {...rest}
                  />
                </Box>
              </TabPanel>
              <TabPanel label="List View" link="list">
                <Box my={4}>
                  <ListView
                    menuLinkTitle="View points"
                    picks={picks}
                    renderElementMenu={this.handleShowMenuForElement}
                    renderExtraListViewCells={renderExtraListViewCells}
                    renderExtraListViewHeaders={renderExtraListViewHeaders}
                    {...rest}
                  />
                </Box>
              </TabPanel>
            </Tabs>
          </PatternWrapMain>
          <SubsTable subs={subs} />
          <Fixtures eventId={event.id} />
        </Main>
        <Secondary>
          <Entry entry={entry} />
        </Secondary>
        {this.renderMenu(explainData)}
      </Wrapper>
    );
  }
}

// EntryEvent.propTypes = {
//   activeEntry: propTypeEntry.isRequired,
//   elementDialogShow: PropTypes.func.isRequired,
//   elementStats: PropTypes.arrayOf(propTypeElementStat).isRequired,
//   elementStatsById: PropTypes.objectOf(propTypeElementEventStat).isRequired,
//   entry: propTypeEntry.isRequired,
//   entryId: PropTypes.number.isRequired,
//   event: propTypeEvent.isRequired,
//   eventsById: PropTypes.objectOf(propTypeEvent).isRequired,
//   explainData: PropTypes.func.isRequired,
//   next: propTypeEvent,
//   now: propTypeEvent,
//   picks: PropTypes.arrayOf(propTypePick).isRequired,
//   refreshFixtures: PropTypes.func.isRequired,
//   fetchPicks: PropTypes.func.isRequired,
//   subs: PropTypes.arrayOf(propTypeSub).isRequired,
//   teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
// };

EntryEvent.defaultProps = {
  next: null,
  now: null, // because there may not be a current event?
};

export { EntryEvent as EntryEventTest };

const mapStateToProps = (state, ownProps) => {
  const entryId = Number(ownProps.entryId);
  const eventId = Number(ownProps.eventId);
  const eventsById = getEventsById(state);
  const next = getNextEvent(state);
  const now = getCurrentEvent(state);
  return {
    activeEntry: getActiveEntry(state),
    elementsById: getElementsById(state),
    elementStats: getElementStatsMatch(state),
    elementStatsById: getElementStatsForEvent(state, eventId),
    elementTypesById: getElementTypesById(state),
    entry: getEntry(state, entryId),
    entryId,
    event: eventsById[eventId],
    eventFixtures: getFixturesByTeamForEvent(state, eventId),
    eventsById,
    explainData: getExplainElementEvent.bind(null, state),
    formation: getFormationForEntryEvent(state, entryId, eventId),
    path: ownProps.path,
    next,
    next3Fixtures: getNext3EventsFixturesByTeam(state),
    now,
    picks: getForEntryEvent(state, entryId, eventId),
    subs: getSubs(state, entryId, eventId),
    teamsById: getTeamsById(state),
  };
};

const mapDispatchToProps = {
  elementDialogShow,
  fetchPicks: entryEventPicksFetch,
  refreshFixtures: refreshFixturesForEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryEvent);
