import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import bootstrap from "../store/bootstrap/slice";
import leagueCodes from "../store/leagueCodes/slice";
import chatMessages from "./chatMessages";
import draftChoices from "./draftChoices";
import elements from "./elements";
import elementStats from "./elementStats";
import elementStatus from "./elementStatus";
import elementSummary from "./elementSummary";
import elementTypes from "./elementTypes";
import entries from "./entries";
import events from "./events";
import eventStatus from "./eventStatus";
import fixtures from "../store/fixtures/slice";
import game from "./game";
import leagueEntries from "./leagueEntries";
import leagueMatches from "./leagueMatches";
import leagues from "./leagues";
import leagueStandings from "./leagueStandings";
import picks from "./picks";
import player from "./player";
import settings from "./settings";
import subs from "./subs";
import teams from "./teams";
import transactions from "./transactions";
import watchlist from "./watchlist";

const rootReducer = combineReducers({
  bootstrap,
  chatMessages,
  draftChoices,
  elements,
  elementStats,
  elementStatus,
  elementSummary,
  elementTypes,
  entries,
  events,
  eventStatus,
  fixtures,
  game,
  leagues,
  leagueCodes,
  leagueEntries,
  leagueMatches,
  leagueStandings,
  player,
  picks,
  settings,
  subs,
  teams,
  transactions,
  watchlist,
  form: formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
