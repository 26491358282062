import { hiDPI, rgba } from "polished";
import styled from "styled-components/macro";
import pattern1437 from "../../../img/pattern/pattern-1-437.png";
import pattern1874 from "../../../img/pattern/pattern-1-874.png";
import { Link } from "@gatsbyjs/reach-router";

export const DeadlineHeader = styled.div`
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    margin-top: -1.5rem;
  }
`;

export const StyledEventDeadlines = styled.div`
  border-radius: ${(props) => props.theme.radii[1]};
  background-color: ${(props) => props.theme.colors.primary};
  background-repeat: no-repeat;
  background-image: url(${pattern1437});
  background-position:
    -80px -50px,
    right -205px bottom -145px;
  background-size: 366px 415px;

  ${hiDPI(2)} {
    background-image: url(${pattern1874});
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    display: flex;
  }
`;

export const StyledEventDeadline = styled.div`
  margin: ${(props) => props.theme.space[3]} 0;
  text-align: center;

  :first-child {
    border-width: 0 0 1px;
    border-style: solid;
    border-color: ${rgba("#fff", 0.2)};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    flex: 1;

    :first-child {
      border-width: 0 1px 0 0;
    }
  }
`;

export const EventDeadlineLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const EventDeadlineHeading = styled.h4`
  padding: 0.4rem;
  background-color: ${(props) => props.theme.colors.darkPink};
  color: #fff;
  font-size: ${(props) => props.theme.fontSizes[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 60%;
  }
`;

export const EventDeadlineTime = styled.div`
  width: 90%;
  margin: auto;
  padding: 0.6rem;
  color: #fff;
  font-size: 2.2rem;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 75%;
  }
`;
