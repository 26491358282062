import { isAfter, isBefore } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { Field } from "redux-form";
import {
  SELECT_DRAFT_DATE_TIME_FORMAT,
  SELECT_DRAFT_TIME_FORMAT,
} from "../constants";
import { getLastDraftDate } from "../reducers/events";
import { getSetting } from "../reducers/settings";
import { ILeagueEntry } from "../store/leagueEntries/types";
import { ILeague } from "../store/leagues/types";
import { getValidTimeZones } from "../utils/datetime";
import {
  DateField,
  Fieldset,
  InputField,
  Legend,
  SelectDesciptionField,
  SelectField,
} from "./FieldRenderers";
import LeagueScoringField, {
  validateLeagueScoringField,
} from "./LeagueScoringField";

interface IProps {
  extraFields?: boolean;
  hidePickTime?: boolean;
  lastDraftDate: Date;
  league?: ILeague;
  leagueEntries?: ILeagueEntry[];
  leagueTradesValue: string;
  maxEntries: number;
  timeZoneValue: string;
}

const LeagueFields: React.FC<IProps> = ({
  extraFields = true,
  hidePickTime = false,
  lastDraftDate,
  league,
  leagueEntries = [],
  leagueTradesValue,
  maxEntries,
  timeZoneValue,
}) => {
  const sizeOptions = [];
  for (let i = 2; i <= maxEntries; i += 1) {
    sizeOptions.push(
      <option value={i} key={i}>
        {i}
      </option>,
    );
  }

  interface ITradesDescriptions {
    [key: string]: string;
  }

  const tradesDescriptions: ITradesDescriptions = {
    n: "Player trades will not be allowed in this league.",
    y: "Player trades will not require league approval.",
    a: "Player trade requests can be vetoed by the league administrator.",
    m: "Player trade requests can be vetoed by other league managers.",
  };
  const tradesDescription =
    tradesDescriptions[leagueTradesValue] &&
    tradesDescriptions[leagueTradesValue];

  const filterPassedTime = (time: string) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const lastDraftDateInTz = new Date(
      formatInTimeZone(
        lastDraftDate,
        timeZoneValue,
        SELECT_DRAFT_DATE_TIME_FORMAT,
      ),
    );
    const isAfterNow = currentDate.getTime() < selectedDate.getTime();
    const isBeforeLastDraftDate =
      lastDraftDateInTz.getTime() > selectedDate.getTime();
    return isAfterNow && isBeforeLastDraftDate;
  };

  return (
    <>
      <Fieldset>
        <Legend>League</Legend>
        <Box mb={4}>
          <Field
            id="leagueName"
            name="leagueName"
            component={InputField}
            type="text"
            label="League Name"
            maxLength="30"
          />
        </Box>
        {extraFields && (
          <>
            <Box mb={4}>
              <LeagueScoringField />
            </Box>
            <Box mb={4}>
              <Field
                id="leagueMinSize"
                name="leagueMinSize"
                component={SelectField}
                label="Minimum Teams"
                parse={(value: string) => parseInt(value, 10)}
              >
                {sizeOptions}
              </Field>
            </Box>
            <Box mb={4}>
              <Field
                id="leagueMaxSize"
                name="leagueMaxSize"
                component={SelectField}
                label="Maximum Teams"
                parse={(value: string) => parseInt(value, 10)}
              >
                {sizeOptions}
              </Field>
            </Box>
          </>
        )}
        {league && (
          <Box mb={4}>
            <Field
              id="leagueAdministrator"
              name="leagueAdministrator"
              component={SelectField}
              label="League Administrator"
            >
              {leagueEntries.length &&
                leagueEntries.map((le) => (
                  <option key={le.entry_id} value={le.entry_id}>
                    {le.player_first_name} {le.player_last_name}
                  </option>
                ))}
            </Field>
          </Box>
        )}
      </Fieldset>
      {extraFields && (
        <>
          <Fieldset>
            <Legend>Draft</Legend>
            <Box mb="4">
              <Field
                id="leagueDraftDate"
                name="leagueDraftDate"
                component={DateField}
                filterTime={filterPassedTime}
                hint="Draft Date and time must be in the future and more than 12 hours before the last Gameweek deadline"
                label="Draft Date and Time"
                placeholder="Click to select a date and time"
                dateFormat={SELECT_DRAFT_DATE_TIME_FORMAT}
                minDate={new Date()}
                maxDate={lastDraftDate}
                showTimeSelect={true}
                timeIntervals={15}
                timeFormat={SELECT_DRAFT_TIME_FORMAT}
              />
            </Box>
            <Box mb={4}>
              <Field
                id="leagueDraftTZ"
                name="leagueDraftTZ"
                component={SelectField}
                label="Time Zone"
              >
                {getValidTimeZones().map((tz: string) => (
                  <option value={tz} key={tz}>
                    {tz}
                  </option>
                ))}
              </Field>
            </Box>
            {!hidePickTime && (
              <Field
                id="leagueDraftPickTime"
                name="leagueDraftPickTime"
                component={SelectField}
                label="Pick time limit (seconds)"
              >
                <option value="">Please select</option>
                <option value={30}>30</option>
                <option value={60}>60</option>
                <option value={90}>90</option>
                <option value={120}>120</option>
              </Field>
            )}
          </Fieldset>
          <Fieldset>
            <Legend>Trades</Legend>
            <Box mb={4}>
              <Field
                id="leagueTrades"
                name="leagueTrades"
                component={SelectDesciptionField}
                label="Player Trades"
                hint={tradesDescription}
              >
                <option value="">Please select</option>
                <option value="n">None</option>
                <option value="y">All</option>
                <option value="a">Administrator veto</option>
                <option value="m">Manager veto</option>
              </Field>
            </Box>
          </Fieldset>
        </>
      )}
    </>
  );
};

const validateLeagueFields = (
  values: any,
  lastDraftDate: Date,
  extraFields: boolean = true,
) => {
  const { leagueDraftDate } = values;
  interface IErrors {
    [key: string]: string;
  }

  const errors: IErrors = {};
  let dateError = false;

  // League validation
  if (!values.leagueName) {
    errors.leagueName = "Required";
  }

  if (!extraFields) return errors;

  if (values.leagueMinSize > values.leagueMaxSize) {
    errors.leagueMinSize = "Minimum teams must not be more than maximum teams";
  }

  // Draft validation
  if (!leagueDraftDate) {
    errors.leagueDraftDate = "Required";
    dateError = true;
  }

  const draftDateUtc = zonedTimeToUtc(leagueDraftDate, values.leagueDraftTZ);

  if (!dateError) {
    if (isBefore(draftDateUtc, new Date())) {
      errors.leagueDraftDate = "Draft date can't be in the past";
    } else if (isAfter(draftDateUtc, lastDraftDate)) {
      errors.leagueDraftDate =
        "Draft date must be 12 hours before the end of the season";
    }
  }
  if (
    values.leagueDraftPickTime !== undefined &&
    values.leagueDraftPickTime < 1
  ) {
    errors.leagueDraftPickTime = "A time limit must be selected";
  }
  if (!values.leagueTrades) {
    errors.leagueTrades = "Required";
  }
  // League Scoring validation
  Object.assign(errors, validateLeagueScoringField(values));
  return errors;
};

export { validateLeagueFields, LeagueFields as LeagueFieldsTest };

const mapStateToProps = (state: any, ownProps: any) => ({
  lastDraftDate: getLastDraftDate(state),
  ownProps,
  maxEntries: getSetting(state, "league", "max_entries"),
});

export default connect(mapStateToProps, null)(LeagueFields);
