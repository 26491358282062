import { hiDPI } from "polished";
import styled from "styled-components/macro";
import pattern2Desktop from "../../img/pattern/pattern-2-d.png";
import pattern2Desktop2x from "../../img/pattern/pattern-2-d@2x.png";
import pattern2Mobile from "../../img/pattern/pattern-2-m.png";
import pattern2Mobile2x from "../../img/pattern/pattern-2-m@2x.png";
import { IPlayerCompProps } from "./types";

export const StyledGameHeader = styled.div`
  background-image: url(${pattern2Mobile}), url(${pattern2Mobile}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lightBlue},
      ${({ theme }) => theme.colors.darkBlue}
    );
  background-repeat: no-repeat;
  background-position:
    right 140px bottom -15px,
    right -18px bottom 27px,
    0;
  background-size:
    220px 312px,
    220px 312px,
    auto;

  ${hiDPI(2)} {
    background-image: url(${pattern2Mobile2x}), url(${pattern2Mobile2x}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-image: url(${pattern2Desktop}), url(${pattern2Desktop}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-position:
      50% -460px,
      right -73px bottom -24px,
      0;
    background-size:
      618px 873px,
      618px 873px,
      auto;

    ${hiDPI(2)} {
      background-image: url(${pattern2Desktop2x}), url(${pattern2Desktop2x}),
        linear-gradient(
          to right,
          ${({ theme }) => theme.colors.lightBlue},
          ${({ theme }) => theme.colors.darkBlue}
        );
    }
  }
`;

export const PlayerComp = styled.div<IPlayerCompProps>`
  /* Until we come up with a wrapper without Flex */
  flex: 1;
  background-image: url(${(props) => props.images.x1});
  background-repeat: no-repeat;
  background-position: right 2rem bottom;
  background-size: 175px 117px;

  ${hiDPI(2)} {
    background-image: url(${(props) => props.images.x2});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-size: 360px 240px;
  }
`;

export const GameTitle = styled.h1`
  margin: 0;
  padding: 2.2rem ${({ theme }) => theme.space[2]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 2.8rem;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: 5.5rem 0;
    font-size: 7.3rem;
  }
`;
