import { propTypeElement } from "../../reducers/elements";
import Media from "../Media";
import { ElementStatusBarContent, StyledElementStatusBar } from "./styles";

const ElementStatusBar = ({ element }) => (
  <StyledElementStatusBar copnr={element.chance_of_playing_next_round}>
    <Media
      img={
        <svg className="ism-icon--small" viewBox="0 0 32 28">
          <use xlinkHref="#ism-status-punch" />
        </svg>
      }
    >
      <ElementStatusBarContent>{element.news}</ElementStatusBarContent>
    </Media>
  </StyledElementStatusBar>
);

ElementStatusBar.propTypes = {
  element: propTypeElement.isRequired,
};

export default ElementStatusBar;
