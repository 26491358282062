import React from "react";
import { useSelector } from "react-redux";
import { getActiveLeagueEntriesByEntryId } from "../../reducers/leagueEntries";
import { ILeagueEntryShortName } from "./types";
import StyledLeagueEntryShortName from "./styles";

const LeagueEntryShortName: React.FC<ILeagueEntryShortName> = ({ entryId }) => {
  const entriesById = useSelector(getActiveLeagueEntriesByEntryId);
  const leagueEntry = entriesById[entryId];
  return (
    <StyledLeagueEntryShortName>
      {leagueEntry?.short_name ? leagueEntry.short_name : "??"}
    </StyledLeagueEntryShortName>
  );
};

export default LeagueEntryShortName;
