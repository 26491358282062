import { Box } from "rebass/styled-components";
import AvailableElementsFilter from "../../AvailableElementsFilter";
import {
  ElementsShown,
  ElementsShownNumber,
  ElementsShownText,
  FilterWrap,
  StyledSquadHeader,
} from "./styles";
import { SquadHeaderProps } from "./types";

const SquadHeader = ({
  elementsShownLabel = "players shown",
  hasFilter = false,
  numberOfElements,
}: SquadHeaderProps) => (
  <Box mb={4}>
    <StyledSquadHeader hasFilter={hasFilter}>
      {hasFilter && (
        <Box mb={2}>
          <FilterWrap>
            <AvailableElementsFilter />
          </FilterWrap>
        </Box>
      )}
      <ElementsShown>
        <ElementsShownText>
          <ElementsShownNumber>{numberOfElements}</ElementsShownNumber>{" "}
          {elementsShownLabel}
        </ElementsShownText>
      </ElementsShown>
    </StyledSquadHeader>
  </Box>
);

export default SquadHeader;
