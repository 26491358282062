import styled from "styled-components/macro";
import Table from "../../Table";

export const EntryCell = styled.td`
  width: 18%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 11%;
  }
`;

export const TradeCell = styled.td`
  width: 45%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 50%;
  }
`;

export const ActionHeading = styled.th`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 24%;
  }
`;

export const ActionCell = styled.td`
  border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export const TradeApprovalTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};

  thead th,
  ${EntryCell} {
    text-align: center;
  }

  ${ActionCell} {
    padding: 0;
  }
`;
