import { navigate } from "@gatsbyjs/reach-router";
import { SubmissionError } from "redux-form";
import { fetchGet, fetchPost } from "../helpers";
import { getDefaultURL } from "../reducers/player";
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
} from "./ActionTypes";
import { updateActiveEntry } from "./Entry";

// Regular action creators
export const loginError = (error) => ({ type: LOGIN_ERROR, error });

export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data });

export const logoutError = (error) => ({ type: LOGOUT_ERROR, error });

export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

// Async action creators
export const login = (values) => (dispatch, getState) =>
  fetchPost("/api/user/login/", {
    username: values.login,
    password: values.password,
  }).then(
    (d) => {
      dispatch(loginSuccess(d.data));
      updateActiveEntry(dispatch, d.data.active).then(() =>
        navigate(getDefaultURL(getState())),
      );
    },
    (e) => {
      dispatch(loginError(e));
      let errorText = e.error;
      if (e.error.non_field_errors) {
        errorText = "Incorrect username or password";
      } else if (typeof e.error !== "string") {
        errorText = JSON.stringify(e.error);
      }
      throw new SubmissionError({ _error: errorText });
    },
  );

export const logout = () => (dispatch) =>
  fetchGet("/api/user/logout").then(
    (d) => {
      // API can optionally supply a redirect. Often used to handle
      // third party login
      if (d.data.redirect) {
        window.location = d.data.redirect;
      } else {
        navigate("/");
        dispatch(logoutSuccess());
      }
    },
    (e) => dispatch(logoutError(e)),
  );
