import { useSelector } from "react-redux";
import { getElementStatusWithProposed } from "../../../reducers/transactions";
import ElementOwned from "../../transactions/ElementOwned";
import ActionElementButton from "../ActionElementButton";
import {
  ElementActionIcon,
  ElementActionLabel,
  LockedIcon,
  StyledElementAction,
} from "./styles";
import { IElementAction } from "./types";

const ElementAction = ({
  elementId,
  actionMe,
  actionLabel,
  isDisabled = false,
}: IElementAction) => {
  const elementStatus: any = useSelector(getElementStatusWithProposed);
  const elStatus = elementStatus[elementId];
  if (elStatus && elStatus.status === "o") {
    return <ElementOwned elementId={elementId} elStatus={elStatus} />;
  }
  if (elStatus && elStatus.proposed) {
    return (
      <StyledElementAction>
        <ElementActionLabel>Proposed</ElementActionLabel>
        <ElementActionIcon>
          <svg className="ism-icon--pending">
            <use xlinkHref="#ism-pending" />
          </svg>
        </ElementActionIcon>
      </StyledElementAction>
    );
  }
  if (!elStatus || elStatus.status === "a") {
    return (
      <ActionElementButton
        actionMe={actionMe}
        actionLabel={actionLabel}
        isDisabled={isDisabled}
        elementId={elementId}
      />
    );
  }
  return (
    <StyledElementAction>
      <ElementActionLabel>Locked</ElementActionLabel>
      <ElementActionIcon>
        <LockedIcon />
      </ElementActionIcon>
    </StyledElementAction>
  );
};

export default ElementAction;
