import { Link } from "@gatsbyjs/reach-router";
import PropTypes from "prop-types";
import { propTypeEntry } from "../../reducers/entries";
import { propTypeLeagueEntry } from "../../reducers/leagueEntries";
import { propTypeLeagueStanding } from "../../reducers/leagueStandings";
import EntryRow from "../EntryRow";
import Table from "../Table";
import Movement from "./Movement";

const ClassicStanding = ({ activeEntry, entry, linkEventId, standing }) => (
  <EntryRow isMe={activeEntry.id === entry.entry_id}>
    <td>
      {standing.rank || "-"}{" "}
      <Movement lastRank={standing.last_rank} rank={standing.rank} />
    </td>
    {linkEventId ? (
      <td>
        <Link to={`/entry/${entry.entry_id}/event/${linkEventId}`}>
          <strong>{entry.entry_name}</strong>
          <br />
          {entry.player_first_name} {entry.player_second_name}
        </Link>
      </td>
    ) : (
      <td>
        <strong>{entry.entry_name}</strong>
        <br />
        {entry.player_first_name} {entry.player_second_name}
      </td>
    )}
    <td>{standing.event_total}</td>
    <td>{standing.total}</td>
  </EntryRow>
);

ClassicStanding.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entry: propTypeLeagueEntry.isRequired,
  linkEventId: PropTypes.number.isRequired,
  standing: propTypeLeagueStanding.isRequired,
};

const ClassicLeague = ({
  activeEntry,
  entriesById,
  linkEventId,
  standings,
}) => (
  <Table>
    <thead className="ism-thead-bold">
      <tr>
        <th>Rank</th>
        <th>Team &amp; Manager</th>
        <th>GW</th>
        <th>TOT</th>
      </tr>
    </thead>
    <tbody>
      {standings.map((standing) => (
        <ClassicStanding
          activeEntry={activeEntry}
          entry={entriesById[standing.league_entry]}
          linkEventId={linkEventId}
          key={standing.league_entry}
          standing={standing}
        />
      ))}
    </tbody>
  </Table>
);

ClassicLeague.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  linkEventId: PropTypes.number.isRequired,
  standings: PropTypes.arrayOf(propTypeLeagueStanding).isRequired,
};

export default ClassicLeague;
