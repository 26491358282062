import PropTypes from "prop-types";
import Button from "../Button";
import { StyledSubmitBar, SubmitBarButtonWrap } from "./styles";

const SubmitBar = ({ buttonText, clickMe, isDisabled }) => (
  <StyledSubmitBar>
    <SubmitBarButtonWrap>
      <Button disabled={isDisabled} onClick={clickMe} fullwidth="true">
        {buttonText}
      </Button>
    </SubmitBarButtonWrap>
  </StyledSubmitBar>
);

SubmitBar.propTypes = {
  buttonText: PropTypes.string.isRequired,
  clickMe: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SubmitBar.defaultProps = {
  isDisabled: false,
};

export default SubmitBar;
