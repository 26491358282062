import PropTypes from "prop-types";
import { PanelBody, PanelHeading, StyledPanel } from "./styles";

const PanelCentredContent = ({ children }) => (
  <div className="ism-panel__centre">{children}</div>
);

PanelCentredContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PanelCentredContent };

const Panel = ({ children, headingText }) => {
  return (
    <StyledPanel>
      {headingText && (
        <PanelHeading data-testid="panelHeading">{headingText}</PanelHeading>
      )}
      <PanelBody>{children}</PanelBody>
    </StyledPanel>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  headingText: PropTypes.node,
};

export default Panel;
