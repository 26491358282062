import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/Auth";
import { getCurrentEvent } from "../../reducers/circularProblems";
import {
  getActiveEntry,
  getActiveEntryStatus,
  propTypeActiveEntryStatus,
  propTypeEntry,
} from "../../reducers/entries";
import { propTypeEvent } from "../../reducers/events";
import { NavItem, NavLink, StyledNav } from "./styles";

let priorityNav;
if (typeof window !== "undefined") {
  // eslint-disable-line
  priorityNav = require("priority-nav"); // eslint-disable-line
}

// Had to add styles for styled-components to work
const currentStyles = {
  backgroundColor: "white",
};

class Nav extends React.Component {
  static createBasicNavItem(link, text) {
    return (
      <NavItem key={link} data-testid="nav-item">
        <NavLink
          to={link}
          getProps={({ isCurrent, isPartiallyCurrent }) =>
            link === "/"
              ? isCurrent
                ? {
                    style: currentStyles,
                  }
                : null
              : isPartiallyCurrent
                ? {
                    style: currentStyles,
                  }
                : null
          }
        >
          {text}
        </NavLink>
      </NavItem>
    );
  }

  static fakeEscapeClick() {
    const e = new Event("keydown");
    e.keyCode = 27;
    e.which = e.keyCode;
    document.dispatchEvent(e);
  }

  constructor(props) {
    super(props);
    this.navItems = {
      newLeague: Nav.createBasicNavItem("/entry/join-create", "New League"),
      draft: Nav.createBasicNavItem("/draft", "Draft Room"),
      fantasy: (
        <NavItem key="fantasy" data-testid="nav-item">
          <NavLink
            as="a"
            variant="secondary"
            href="//fantasy.premierleague.com"
          >
            Fantasy
          </NavLink>
        </NavItem>
      ),
      fixtures: Nav.createBasicNavItem("/fixtures", "Fixtures"),
      help: Nav.createBasicNavItem("/help", "Help"),
      home: Nav.createBasicNavItem("/", "Status"),
      league: Nav.createBasicNavItem("/league", "League"),
      logout: (
        <NavItem key="logout" data-testid="nav-item">
          <NavLink as="button" onClick={this.props.handleLogout}>
            Sign Out
          </NavLink>
        </NavItem>
      ),
      myTeam: Nav.createBasicNavItem("/team/my", "Pick Team"),
      podcast: (
        <NavItem key="podcast" data-testid="nav-item">
          <NavLink as="a" href="//premierleague.com/official-fpl-podcast/">
            Podcast
          </NavLink>
        </NavItem>
      ),
      start: Nav.createBasicNavItem("/entry/join-create", "Start"),
      transactions: Nav.createBasicNavItem(
        "/team/transactions",
        "Transactions",
      ),
    };
    if (this.props.activeEntry && this.props.currentEvent) {
      this.navItems.points = Nav.createBasicNavItem(
        `/entry/${this.props.activeEntry.id}/event/${this.props.currentEvent.id}`,
        "Points",
      );
    }
    this.itemsForStatus = {
      logged_out: ["home", "fixtures", "podcast", "help", "fantasy"],
      no_entry: [
        "home",
        "start",
        "fixtures",
        "podcast",
        "help",
        "fantasy",
        "logout",
      ],
      preparing: [
        "home",
        "draft",
        "newLeague",
        "fixtures",
        "podcast",
        "help",
        "fantasy",
        "logout",
      ],
      drafting: [
        "home",
        "draft",
        "newLeague",
        "fixtures",
        "podcast",
        "help",
        "fantasy",
        "logout",
      ],
      picked: [
        "home",
        "myTeam",
        "transactions",
        "league",
        "fixtures",
        "draft",
        "podcast",
        "help",
        "fantasy",
        "logout",
      ],
      started: [
        "home",
        "points",
        "myTeam",
        "transactions",
        "league",
        "fixtures",
        "draft",
        "podcast",
        "help",
        "fantasy",
        "logout",
      ],
    };
  }

  componentDidMount() {
    this.initialisePriorityNav();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.status !== newProps.status) {
      // We can't re-render as react isn't in control so we'll need to
      // reload to get the correct nav :-(
      let reload = true;

      // Don't reload same entry at the start of the draft as navs are
      // the same
      if (
        newProps.activeEntry &&
        this.props.activeEntry &&
        newProps.activeEntry.id === this.props.activeEntry.id &&
        newProps.status === "drafting"
      ) {
        reload = false;
      }

      if (reload) window.location = "/";
    }
    // Also need to handle change to active entry as this needs to change
    // the points link. As react can't do this we'll need to reload.
    if (
      newProps.activeEntry &&
      this.props.activeEntry &&
      newProps.activeEntry.id !== this.props.activeEntry.id &&
      newProps.status === "started"
    ) {
      window.location = "/";
    }
  }

  shouldComponentUpdate() {
    // React can't update DOM as has been done by priorityNav
    return false;
  }

  componentWillUnmount() {
    document
      .querySelector(".ism-nav__dropdown")
      .removeEventListener("click", Nav.fakeEscapeClick);
  }

  initialisePriorityNav() {
    this.navEl = priorityNav.init({
      mainNavWrapper: ".ismjs-priority-nav",
      mainNav: ".ismjs-main-nav",
      navDropdownClassName: "ism-nav__dropdown",
      navDropdownToggleClassName: "ism-nav__dropdown-toggle",
      navDropdownLabel: "More",
      breakPoint: 0,
    });
    document
      .querySelector(".ism-nav__dropdown")
      .addEventListener("click", Nav.fakeEscapeClick);
  }

  render() {
    return (
      <StyledNav
        className="ismjs-priority-nav"
        ref={(navEl) => {
          this.navEl = navEl;
        }}
      >
        <ul className="ismjs-main-nav">
          {this.itemsForStatus[this.props.status].map(
            (item) => this.navItems[item],
          )}
        </ul>
      </StyledNav>
    );
  }
}

Nav.propTypes = {
  activeEntry: propTypeEntry,
  currentEvent: propTypeEvent,
  handleLogout: PropTypes.func.isRequired,
  status: propTypeActiveEntryStatus.isRequired,
};

Nav.defaultProps = {
  activeEntry: null,
  currentEvent: null,
};

export { Nav as NavTest };

const mapStateToProps = (state) => ({
  activeEntry: getActiveEntry(state),
  currentEvent: getCurrentEvent(state),
  status: getActiveEntryStatus(state),
});

const mapDispatchToProps = {
  handleLogout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
