import { useSelector } from "react-redux";
import { getShortNameFromId } from "../../utils/events";
import Abbr from "../Abbr/Abbr";
import { VisuallyHidden } from "../styles";
import { DragCell, PriorityCell } from "../sortable/Row/styles";
import { SquadTableWrapper } from "../squad/styles";
import WatchlistRows from "./WatchlistRows";
import {
  ActionCell,
  FixtureCell,
  PrimaryCell,
  SortCell,
  StatusCell,
} from "./WatchlistRows/WatchlistCells/styles";
import { StyledWatchlistTable } from "./styles";
import { IWatchlistTable } from "./types";
import { getSortStat } from "../../reducers/elements";
import { ISortStat } from "../../store/elements/types";

const WatchlistTable: React.FC<IWatchlistTable> = ({
  actionDisabled,
  actionMe,
  actionLabel,
  next3Fixtures,
}) => {
  const sortStat: ISortStat = useSelector(getSortStat);
  return (
    <SquadTableWrapper>
      <StyledWatchlistTable>
        <thead>
          <tr>
            <DragCell as="th">
              <VisuallyHidden>Drag Handle</VisuallyHidden>
            </DragCell>
            <PriorityCell as="th">Priority</PriorityCell>
            <StatusCell as="th">
              <VisuallyHidden>Info</VisuallyHidden>
            </StatusCell>
            <PrimaryCell as="th">Player</PrimaryCell>
            <SortCell as="th">
              {sortStat && (
                <Abbr
                  longName={sortStat.label}
                  shortName={sortStat.abbreviation}
                />
              )}
            </SortCell>
            {next3Fixtures.map((data) => (
              <FixtureCell as="th" key={data.event.id}>
                {getShortNameFromId(data.event.id)}
              </FixtureCell>
            ))}
            <ActionCell as="th">
              <Abbr longName="Watchlist" shortName="Watch" />
            </ActionCell>
            <ActionCell as="th">{actionLabel}</ActionCell>
          </tr>
        </thead>
        <WatchlistRows
          actionLabel={actionLabel}
          actionMe={actionMe}
          actionDisabled={actionDisabled}
        />
      </StyledWatchlistTable>
    </SquadTableWrapper>
  );
};

export { WatchlistTable as WatchlistTest };

export default WatchlistTable;
