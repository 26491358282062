import PropTypes from "prop-types";
import { propTypeEntry } from "../../reducers/entries";
import { propTypeLeagueEntry } from "../../reducers/leagueEntries";
import { propTypeLeagueMatch } from "../../reducers/leagueMatches";
import EntryRow from "../EntryRow";
import Link from "../Link";
import { Entry1, Entry2, Score } from "../MatchesTable";
import { Ellipsis } from "../styles";

const H2HMatchTeam = ({ entry, linkEventId, match }) => {
  if (!entry.entry_id) {
    return (
      <>
        <strong>AVERAGE</strong>
        <br />
        AVERAGE
      </>
    );
  }

  if (!linkEventId) {
    return (
      <>
        <strong>{entry.entry_name}</strong>
        <br />
        {`${entry.player_first_name} ${entry.player_last_name}`}
      </>
    );
  }

  if (match.event > linkEventId) {
    return (
      <Link to={`/entry/${entry.entry_id}/event/${linkEventId}`}>
        <strong>{entry.entry_name}</strong>
        <br />
        {`${entry.player_first_name} ${entry.player_last_name}`}
      </Link>
    );
  }

  return (
    <Link to={`/entry/${entry.entry_id}/event/${match.event}`}>
      <strong>{entry.entry_name}</strong>
      <br />
      {`${entry.player_first_name} ${entry.player_last_name}`}
    </Link>
  );
};
H2HMatchTeam.propTypes = {
  entry: propTypeLeagueEntry.isRequired,
  linkEventId: PropTypes.number.isRequired,
  match: propTypeLeagueMatch.isRequired,
};

export { H2HMatchTeam as H2HMatchTeamTest };

const H2HMatch = ({
  activeEntry,
  entry1,
  entry2,
  linkEventId,
  match,
  noHighlight,
}) => {
  const mine =
    (activeEntry.id === entry1.entry_id ||
      activeEntry.id === entry2.entry_id) &&
    !noHighlight;
  return (
    <EntryRow isMe={mine}>
      <Entry1>
        <Ellipsis>
          <H2HMatchTeam
            entry={entry1}
            linkEventId={linkEventId}
            match={match}
          />
        </Ellipsis>
      </Entry1>
      <Score>
        {match.started ? (
          <span>
            {match.league_entry_1_points} - {match.league_entry_2_points}
          </span>
        ) : (
          <span>v</span>
        )}
      </Score>
      <Entry2>
        <Ellipsis>
          <H2HMatchTeam
            entry={entry2}
            linkEventId={linkEventId}
            match={match}
          />
        </Ellipsis>
      </Entry2>
    </EntryRow>
  );
};
H2HMatch.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entry1: propTypeLeagueEntry.isRequired,
  entry2: propTypeLeagueEntry.isRequired,
  linkEventId: PropTypes.number.isRequired,
  match: propTypeLeagueMatch.isRequired,
  noHighlight: PropTypes.bool.isRequired,
};

export default H2HMatch;
