import styled from "styled-components/macro";

export const StyledSafety = styled.div`
  border-width: 0 0 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.lightGrey};
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-top: ${(props) => props.theme.space[4]};
    border-width: 1px;
  }
`;

export const SafetyLink = styled.a`
  display: block;
  margin-top: ${(props) => props.theme.space[1]};
`;

export const SafetyHeading = styled.h4`
  margin-top: 1.4rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-right: ${(props) => props.theme.space[2]};
    margin-left: ${(props) => props.theme.space[2]};
  }
`;

export const SafetyBody = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
  }
`;

export const SafetyUnit = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1;
    margin: 0 ${(props) => props.theme.space[2]};
  }
`;
