import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGet } from "../../helpers";

interface IFetchLeagueCodeData {
  id: number;
  code: string;
}

export const fetchLeagueCode = createAsyncThunk(
  "fetchLeagueCode",
  async (leagueId: number) => {
    const response = await fetchGet(`/api/league/${leagueId}/code/`);
    return {
      id: leagueId,
      code: response.data.code,
    } as IFetchLeagueCodeData;
  },
);

const initialState = {
  byLeagueId: {} as Record<string, string>,
};

const codeSlice = createSlice({
  name: "leagueCodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLeagueCode.fulfilled, (state, action) => {
      state.byLeagueId[action.payload.id] = action.payload.code;
    });
  },
});

export default codeSlice.reducer;
