import { Link } from "@gatsbyjs/reach-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { deleteEntry, editEntry as onSubmit } from "../actions/Entry";
import { getEntry, propTypeEntry } from "../reducers/entries";
import { getLeagueForEntry, propTypeLeague } from "../reducers/leagues";
import Button from "./Button";
import ConfirmDialog from "./ConfirmDialog";
import Copy from "./Copy";
import DialogManager from "./DialogManager";
import EntryFields, { validateEntryFields } from "./EntryFields";
import { Form } from "./FieldRenderers";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import Title from "./Title";

const EditEntry = ({
  confirmDelete,
  dirty,
  error,
  entry,
  league,
  handleSubmit,
  valid,
}) => {
  let deleteOption = "";
  if (league.draft_status !== "pre") {
    deleteOption = (
      <p>You can&apos;t delete your team as the draft has started.</p>
    );
  } else if (league.can_admin) {
    deleteOption = (
      <p>
        You&apos;re the league administrator. To remove your team you&apos;ll
        need to <Link to={`/league/${league.id}/edit`}>delete the league</Link>.
      </p>
    );
  } else {
    deleteOption = (
      <DialogManager
        render={(showDialog, handleShow, handleHide) => (
          <Form>
            <Copy>
              <p>You are able to delete your team until the draft starts.</p>
            </Copy>
            <Button onClick={handleShow}>Delete team</Button>
            {showDialog && (
              <ConfirmDialog
                closeDialog={handleHide}
                header="Delete your team?"
                onConfirm={() => confirmDelete(entry.id)}
              >
                <p>Are you sure you want to delete your team?</p>
              </ConfirmDialog>
            )}
          </Form>
        )}
      />
    );
  }

  return (
    <Wrapper>
      <Main>
        {/* eslint-disable max-len */}
        <HelmetHead
          title="Team Details - Fantasy Premier League Draft 2024/25"
          description={`To view the team details of ${entry.player_first_name} ${entry.player_last_name}, visit the official website of the Premier League.`}
        />
        {/* eslint-enable max-len */}
        <Copy>
          <Title>Edit Your Team</Title>

          <form onSubmit={handleSubmit}>
            {error && <p>{error}</p>}
            {league.scoring === "m" ? (
              <EntryFields hideFaveTeam />
            ) : (
              <EntryFields />
            )}

            <Button type="submit" disabled={!(valid && dirty)}>
              Update Team
            </Button>
          </form>

          <Title>Delete Your Team</Title>

          {deleteOption}
        </Copy>
      </Main>
    </Wrapper>
  );
};

EditEntry.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  entry: propTypeEntry.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  league: propTypeLeague.isRequired,
  valid: PropTypes.bool.isRequired,
};

EditEntry.defaultProps = {
  error: null,
};

const validate = (values) => validateEntryFields(values);

const initialValuesFromEntry = (entry) => ({
  entryName: entry.name,
  entryFaveTeam: entry.favourite_team || 0,
  entryEmail: entry.email,
});

export { EditEntry as EditEntryTest, initialValuesFromEntry, validate };

const mapStateToProps = (state, ownProps) => {
  const entry = getEntry(state, Number(ownProps.entryId));
  const league = getLeagueForEntry(state, entry);
  return {
    entry,
    league,
    initialValues: initialValuesFromEntry(entry),
  };
};

const mapDispatchToProps = {
  confirmDelete: deleteEntry,
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "EditEntry",
    validate,
  })(EditEntry),
);
