import PropTypes from "prop-types";
import { propTypeElementType } from "../../reducers/elementTypes";
import {
  propTypeElement,
  propTypeElementEventStat,
} from "../../reducers/elements";
import { propTypePick } from "../../reducers/picks";
import { propTypeTeam } from "../../reducers/teams";
import { propTypeEventFixtures } from "../../store/fixtures/slice";
import getStatusTitle from "../../utils/getStatusTitle";
import ElementShirt from "../ElementShirt";
import ElementStatus from "../ElementStatus";
import PitchElementData from "../PitchElementData";
import { UnstyledButton, VisuallyHidden } from "../styles";
import {
  ElementName,
  ElementValue,
  InfoControl,
  PrimaryControl,
  StyledPitchElement,
  StyledPitchElementData,
  StyledSub,
} from "./styles";

const PitchElement = ({
  actionSub,
  elementsById,
  elementDialogShow,
  elementStatsById,
  elementTypesById,
  eventFixtures,
  pick,
  renderElementMenu,
  teamsById,
}) => {
  const element = elementsById[pick.element];
  const elementType = elementTypesById[element.element_type];
  const elementStats = elementStatsById ? elementStatsById[element.id] : null;
  return (
    <StyledPitchElement subStatus={pick.subStatus}>
      <VisuallyHidden>{elementType.singular_name_short}</VisuallyHidden>
      <UnstyledButton
        onClick={() => renderElementMenu(element, pick)}
        type="button"
      >
        <ElementShirt
          elementId={element.id}
          hasShadow={true}
          sizes="(min-width: 1024px) 55px, (min-width: 610px) 44px, 33px"
        />
        <StyledPitchElementData>
          <ElementName element={element}>{element.web_name}</ElementName>
          <ElementValue>
            <PitchElementData
              element={element}
              elementStats={elementStats}
              teamEventFixtures={eventFixtures[element.team]}
              teamsById={teamsById}
            />
          </ElementValue>
        </StyledPitchElementData>
      </UnstyledButton>
      <div>
        {actionSub && pick.subStatus !== "invalid" && (
          <PrimaryControl>
            <UnstyledButton type="button" onClick={actionSub} data-id={pick.id}>
              <StyledSub />
              <VisuallyHidden>Switch player</VisuallyHidden>
            </UnstyledButton>
          </PrimaryControl>
        )}
        <InfoControl>
          <UnstyledButton
            type="button"
            onClick={() => elementDialogShow(element.id)}
            title={getStatusTitle(element.chance_of_playing_next_round)}
          >
            <ElementStatus elementId={element.id} />
          </UnstyledButton>
        </InfoControl>
      </div>
    </StyledPitchElement>
  );
};

PitchElement.propTypes = {
  actionSub: PropTypes.func,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementStatsById: PropTypes.objectOf(propTypeElementEventStat),
  elementTypesById: PropTypes.objectOf(propTypeElementType).isRequired,
  eventFixtures: propTypeEventFixtures.isRequired,
  pick: propTypePick.isRequired,
  renderElementMenu: PropTypes.func.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

PitchElement.defaultProps = {
  actionSub: null,
  elementStatsById: null,
  event: null,
};

export default PitchElement;
