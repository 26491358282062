import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBoostrapSuccessData, SliceState } from "./types";

const timeNow = () => Math.round(new Date().getTime() / 1000);

export const initialState: SliceState = {
  data: {
    player: {},
    entries: {},
    leagues: {},
    time: 0,
    active: {},
  },
  times: {
    server: null,
    browser: null,
    lastUpdated: null,
  },
  loading: false,
};

const bootstrapSlice = createSlice({
  name: "bootstrap",
  initialState,
  reducers: {
    bootstrapStart(state) {
      state.loading = true;
    },
    bootstrapSuccess(state, action: PayloadAction<IBoostrapSuccessData>) {
      const time = timeNow();
      state.loading = false;
      state.data = action.payload;
      state.times = {
        server: action.payload.time,
        browser: time,
        lastUpdated: time,
      };
    },
    bootstrapError(state) {
      state.loading = false;
    },
    boostrapUpdated(state) {
      const time = timeNow();

      state.times = {
        ...state.times,
        lastUpdated: time,
      };
    },
  },
});

// Actions
export const {
  bootstrapStart,
  bootstrapSuccess,
  bootstrapError,
  boostrapUpdated,
} = bootstrapSlice.actions;

export default bootstrapSlice.reducer;
