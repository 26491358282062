import { useSelector } from "react-redux";
import { getElement } from "../../../../reducers/elements";
import { RootState } from "../../../../reducers/rootReducer";
import ElementInTable from "../../../ElementInTable";
import ElementStatusButton from "../../../ElementStatusButton";
import LeagueEntryShortName from "../../../LeagueEntryShortName";
import { PrimaryCell, StatusCell } from "../../../Table";
import {
  DraftedEvent,
  DraftedPick,
  DraftedPlayer,
  DraftedSortStat,
} from "../styles";
import { IDraftedChoice } from "./types";

const DraftedChoice: React.FC<IDraftedChoice> = ({
  choice,
  elementId,
  sortStat,
}) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId),
  );
  return (
    <tr>
      <StatusCell>
        <ElementStatusButton elementId={elementId} />
      </StatusCell>
      <PrimaryCell>
        <ElementInTable elementId={elementId} />
      </PrimaryCell>
      <DraftedSortStat as="td">
        {sortStat && element[sortStat.name]}
      </DraftedSortStat>
      <DraftedEvent>{choice.round}</DraftedEvent>
      <DraftedPick>{choice.pick}</DraftedPick>
      <DraftedPlayer>
        <LeagueEntryShortName entryId={choice.entry} />
      </DraftedPlayer>
    </tr>
  );
};

export default DraftedChoice;
