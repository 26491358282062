import { navigate } from "@gatsbyjs/reach-router";
import { logout } from "./actions/Auth";

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const responseIsJSON = (response) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

const myFetch = (url, fetchArgs) =>
  new Promise((resolve, reject) => {
    fetch(url, fetchArgs).then(
      (response) => {
        if (responseIsJSON(response)) {
          response.json().then((responseData) => {
            if (response.ok) {
              resolve({
                data: responseData,
                response,
              });
            } else {
              reject({
                error: responseData,
                response,
                reason: "NOT_2XX",
              });
            }
          });
        } else {
          reject({
            error: response.statusText,
            response,
            reason: "NOT_JSON",
          });
        }
      },
      (error) => {
        reject({
          error,
          reason: "NETWORK",
        });
      },
    );
  });

export const fetchGet = (url) =>
  myFetch(url, {
    method: "GET",
    credentials: "same-origin",
  });

export const fetchPost = (url, data, method = "POST") =>
  myFetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
    credentials: "same-origin",
    body: JSON.stringify(data),
  });

export const fetchDelete = (url, data) => fetchPost(url, data, "DELETE");

export const handleUnexpectedError = (e, dispatch) => {
  if (e.response && e.response.status === 400 && e.error) {
    if (e.error.errors && e.error.errors.non_field_errors) {
      if (e.error.errors.non_field_errors.indexOf("Season over") > -1) {
        return navigate("/season-over");
      }
    }
  }
  if (e.response && e.response.status === 404) {
    return navigate("/not-found");
  }
  if (e.response && e.response.status === 403 && e.error) {
    if (e.error.reason && e.error.reason === "Deleted Account") {
      return dispatch(logout());
    }
  }
  return setTimeout(() => window.location.reload(true), 10000);
};
