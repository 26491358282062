import { rgba } from "polished";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import {
  elementDialogHide,
  fetchElementSummary,
} from "../../../actions/Element";
import { watchlistAdd, watchlistRemove } from "../../../actions/Watchlist";
import { getElementStatsMatch } from "../../../reducers/elementStats";
import { getElementSummary } from "../../../reducers/elementSummary";
import { getElementTypesById } from "../../../reducers/elementTypes";
import {
  getElement,
  getElementCount,
  getElementDialog,
} from "../../../reducers/elements";
import { getCurrentEvent, getNextEvent } from "../../../reducers/events";
import { RootState } from "../../../reducers/rootReducer";
import { getTeamsById } from "../../../reducers/teams";
import { getWatchlist } from "../../../reducers/watchlist";
import {
  IElement,
  IElementFixture,
  IElementStat,
  IHistory,
  ITotal,
  ITypesById,
} from "../../../store/elements/types";
import { IEvent } from "../../../store/events/types";
import { ITeamsById } from "../../../store/teams/types";
import { getStatDescription } from "../../../utils/stats";
import Button from "../../Button";
import Dialog from "../../Dialog";
import { CloseButton, CloseIcon } from "../../Dialog/styles";
import ElementStatusBar from "../../ElementStatusBar";
import Tabs from "../../Tabs";
import TabPanel from "../../Tabs/TabPanel";
import Tooltip, { TooltipLabel } from "../../Tooltip";
import { VisuallyHidden } from "../../styles";
import ElementFixtures from "../ElementFixtures";
import ElementHistory from "../ElementHistory";

const Summary = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const ElementTypeLabel = styled.span`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  border-radius: ${({ theme }) => `0 0 ${theme.radii[1]} ${theme.radii[1]}`};
  background-color: ${({ theme }) => theme.colors.primary};
  color: #05f0ff;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  line-height: 1;
`;

const ElementHeading = styled.h2`
  margin: 0 0 ${({ theme }) => theme.space[1]};
  font-size: 2.1rem;
`;

const Club = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

const Img = styled.img`
  display: block;
  margin: auto;
  height: 135px;
`;

const Glass = css`
  background-color: ${rgba("white", 0.75)};
  box-shadow: 0 4px 12px 0 ${rgba("black", 0.08)};
`;

const StatList = styled.ul`
  ${Glass}
  display: flex;
  margin: 0 0 ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[3]} 0;
  border-radius: ${({ theme }) => theme.radii[1]};
`;

const StatItem = styled.li`
  flex: 1;
  padding: 0 ${({ theme }) => theme.space[2]};
  text-align: center;

  :not(:first-child) {
    border-left: 1px solid #14c6ec;
  }
`;

const StatHeading = styled.h3`
  margin-bottom: ${({ theme }) => theme.space[1]};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
`;

const StatValue = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 1.6rem;
  }
`;

const ICTHeader = styled.div`
  ${Glass}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space[1]};
  border-radius: ${({ theme }) => `${theme.radii[1]} ${theme.radii[1]} 0 0`};
  min-height: 3.6rem;
`;

const ICTHeading = styled.h3`
  font-size: 1rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;

const ICTBody = styled.div`
  ${Glass}
  display: flex;
  border-radius: ${({ theme }) => `0 0 ${theme.radii[1]} ${theme.radii[1]}`};
`;

const ICTItem = styled.div`
  flex: 1;
  padding: 0.8rem 0.6rem;
  text-align: center;
`;

const ICTSubHeading = styled.h3`
  margin-bottom: 0.6rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
  text-align: center;
`;

interface ISummary {
  fixtures: Array<IElementFixture>;
  history: Array<IHistory>;
  totals: ITotal;
}

interface IProps {
  elementId: number;
  closeDialog: () => void;
  addToWatchlist: (e: React.MouseEvent<HTMLButtonElement>) => void;
  fetchData: (id: number) => void;
  removeFromWatchlist: (e: React.MouseEvent<HTMLButtonElement>) => void;
  summary: ISummary | null;
  element: IElement;
  elementCount: number;
  teamsById: ITeamsById;
  elementTypesById: ITypesById;
  watchlist: Array<number>;
  elementStats: IElementStat[];
  currentEvent: IEvent | null;
  nextEvent: IEvent | null;
}

const ElementDialog: React.FC<IProps> = ({
  addToWatchlist,
  closeDialog,
  element,
  elementCount,
  elementStats,
  removeFromWatchlist,
  summary,
  teamsById,
  elementTypesById,
  watchlist,
  currentEvent,
  nextEvent,
}) => {
  const getEventId = () => {
    if (currentEvent) {
      return currentEvent.id;
    }
    if (nextEvent) {
      return nextEvent.id;
    }
    return null;
  };

  const eventId = getEventId();
  const team = teamsById[element.team];
  const elementType = elementTypesById[element.element_type];
  return (
    <Dialog isElementDialog={true} closeDialog={closeDialog}>
      <CloseButton onClick={closeDialog}>
        <VisuallyHidden>Close</VisuallyHidden>
        <CloseIcon>
          <use xlinkHref="#ism-close" />
        </CloseIcon>
      </CloseButton>
      <Dialog.Body>
        {element.news && <ElementStatusBar element={element} />}
        <Summary>
          <Flex alignItems="center">
            <Box mx={2} flex="0 0 auto">
              <Img
                src={`//resources.premierleague.com/premierleague/photos/players/110x140/p${element.code}.png`}
                alt=""
                role="presentation"
              />
            </Box>
            <div>
              <ElementTypeLabel>{elementType.singular_name}</ElementTypeLabel>
              <ElementHeading>
                {element.first_name} {element.second_name}
              </ElementHeading>
              <Club>{team.name}</Club>
            </div>
          </Flex>

          <StatList>
            <StatItem>
              <StatHeading>Form</StatHeading>
              <StatValue>{element.form}</StatValue>
            </StatItem>
            <StatItem>
              <StatHeading>
                <Tooltip content="Gameweek">
                  <span>
                    <TooltipLabel>GW</TooltipLabel>
                    {eventId && ` ${eventId}`}
                  </span>
                </Tooltip>
              </StatHeading>
              <StatValue>{element.event_points}pts</StatValue>
            </StatItem>
            <StatItem>
              <StatHeading>Total</StatHeading>
              <StatValue>{element.total_points}pts</StatValue>
            </StatItem>
            <StatItem>
              <StatHeading>Rank</StatHeading>
              <StatValue>{element.draft_rank}</StatValue>
            </StatItem>
          </StatList>

          <Flex justifyContent="space-between" mb={4}>
            <Box width="77%">
              <Box mb="0.2rem">
                <ICTHeader>
                  <ICTHeading>
                    ICT Rank for {elementType.plural_name}
                  </ICTHeading>
                </ICTHeader>
              </Box>
              <ICTBody>
                <ICTItem>
                  <Tooltip content={getStatDescription("influence")}>
                    <TooltipLabel>
                      <ICTSubHeading>Influence</ICTSubHeading>
                    </TooltipLabel>
                  </Tooltip>
                  <div>
                    <strong>{element.influence_rank_type}</strong> of{" "}
                    {elementType.element_count}
                  </div>
                </ICTItem>
                <ICTItem>
                  <Tooltip content={getStatDescription("creativity")}>
                    <TooltipLabel>
                      <ICTSubHeading>Creativity</ICTSubHeading>
                    </TooltipLabel>
                  </Tooltip>
                  <div>
                    <strong>{element.creativity_rank_type}</strong> of{" "}
                    {elementType.element_count}
                  </div>
                </ICTItem>
                <ICTItem>
                  <Tooltip content={getStatDescription("threat")}>
                    <TooltipLabel>
                      <ICTSubHeading>Threat</ICTSubHeading>
                    </TooltipLabel>
                  </Tooltip>
                  <div>
                    <strong>{element.threat_rank_type}</strong> of{" "}
                    {elementType.element_count}
                  </div>
                </ICTItem>
                <ICTItem>
                  <Tooltip content={getStatDescription("ict_index")}>
                    <TooltipLabel>
                      <ICTSubHeading>ICT Index</ICTSubHeading>
                    </TooltipLabel>
                  </Tooltip>
                  <div>
                    <strong>{element.ict_index_rank_type}</strong> of{" "}
                    {elementType.element_count}
                  </div>
                </ICTItem>
              </ICTBody>
            </Box>
            <Box width="22%">
              <Box mb="0.2rem">
                <ICTHeader>
                  <ICTHeading>Overall ICT Rank</ICTHeading>
                </ICTHeader>
              </Box>
              <ICTBody>
                <ICTItem>
                  <Tooltip content={getStatDescription("ict_index")}>
                    <TooltipLabel>
                      <ICTSubHeading>ICT Index</ICTSubHeading>
                    </TooltipLabel>
                  </Tooltip>
                  <div>
                    <strong>{element.ict_index_rank}</strong> of {elementCount}
                  </div>
                </ICTItem>
              </ICTBody>
            </Box>
          </Flex>
        </Summary>

        <div>
          <Tabs isCentered={true}>
            <TabPanel label="History" link="history">
              {summary && (
                <ElementHistory
                  history={summary.history}
                  stats={elementStats}
                  totals={summary.totals}
                />
              )}
            </TabPanel>
            <TabPanel label="Fixtures" link="fixtures">
              {summary && (
                <ElementFixtures
                  fixtures={summary.fixtures}
                  teamsById={teamsById}
                />
              )}
            </TabPanel>
          </Tabs>
        </div>

        <Box mt={4} px={3} pb={4}>
          {watchlist.indexOf(element.id) === -1 ? (
            <Button dataId={element.id} onClick={addToWatchlist}>
              Add to Watchlist
            </Button>
          ) : (
            <Button dataId={element.id} onClick={removeFromWatchlist}>
              Remove from Watchlist
            </Button>
          )}
        </Box>
      </Dialog.Body>
    </Dialog>
  );
};

export { ElementDialog as ElementDialogTest };

class ElementDialogContainer extends Component<IProps> {
  componentDidUpdate(prevProps: any) {
    if (
      this.props.elementId > 0 &&
      this.props.elementId !== prevProps.elementId
    ) {
      this.props.fetchData(this.props.elementId);
    }
  }

  componentWillUnmount() {
    // In case is being unmounted via back button
    this.props.closeDialog();
  }

  render() {
    return this.props.elementId > 0 ? <ElementDialog {...this.props} /> : null;
  }
}

const mapStateToProps = (state: RootState) => {
  const elementId = getElementDialog(state);

  return {
    elementId,
    currentEvent: getCurrentEvent(state),
    nextEvent: getNextEvent(state),
    elementCount: getElementCount(state),
    elementStats: getElementStatsMatch(state),
    teamsById: getTeamsById(state),
    elementTypesById: getElementTypesById(state),
    watchlist: getWatchlist(state),
    element: getElement(state, elementId),
    summary:
      elementId > 0
        ? getElementSummary(state, elementId)
        : { fixtures: [], history: [], totals: {} },
  };
};

const mapDispatchToProps = {
  addToWatchlist: (e: React.MouseEvent<HTMLButtonElement>) => {
    return watchlistAdd(
      parseInt(e.currentTarget.getAttribute("data-id") ?? "0", 10),
    );
  },
  closeDialog: elementDialogHide,
  fetchData: fetchElementSummary,
  removeFromWatchlist: (e: React.MouseEvent<HTMLButtonElement>) => {
    return watchlistRemove(
      parseInt(e.currentTarget.getAttribute("data-id") ?? "0", 10),
    );
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementDialogContainer);
