import React from "react";
import { useSelector } from "react-redux";
import { getElementType } from "../../reducers/elementTypes";
import { getElement } from "../../reducers/elements";
import { RootState } from "../../reducers/rootReducer";
import { getTeam } from "../../reducers/teams";
import ElementShirt from "../ElementShirt";
import Media from "../Media";
import { ElementInfo, ElementName, ElementPosition } from "./styles";
import { IElementInTable } from "./types";

const ElementInTable: React.FC<IElementInTable> = ({ elementId }) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId),
  );
  const elementType = useSelector((state: RootState) =>
    getElementType(state, element?.element_type),
  );
  const team = useSelector((state: RootState) => getTeam(state, element?.team));

  if (!element) {
    return null;
  }

  const Shirt = <ElementShirt elementId={element.id} sizes="16px" />;
  return (
    <Media img={Shirt} centred={true}>
      <ElementName>{element.web_name}</ElementName>
      <ElementInfo>
        <span>{team.short_name}</span>
        {elementType && (
          <ElementPosition>{elementType.singular_name_short}</ElementPosition>
        )}
      </ElementInfo>
    </Media>
  );
};

export default ElementInTable;
