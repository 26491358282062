import styled from "styled-components/macro";
import Table from "../../Table";
import { TextGradient } from "../../styles";

export const EventStatusLeagues = styled.tr``;

export const EventStatusFinished = styled.td`
  color: #2aab6a;
`;

export const EventStatusTable = styled(Table)`
  th,
  td {
    font-size: 1.2rem;
    text-align: center;
  }

  td:nth-child(n + 2) {
    font-family: ${(props) => props.theme.fonts.bold};
    text-transform: uppercase;
  }

  tbody {
    th,
    td {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    ${EventStatusLeagues} {
      background-image: linear-gradient(
        to right,
        ${(props) => props.theme.colors.fantasy},
        ${(props) => props.theme.colors.lightBlue}
      );

      ${EventStatusFinished} {
        color: $fantasy;
      }
    }
  }
`;

export const EventStatusInfo = styled.span`
  padding: 0.4rem 1.6rem;
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.fantasy};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
`;

export const EventStatusLeagueInfo = styled(EventStatusInfo)`
  background-color: ${({ theme }) => theme.colors.primary};
  > span {
    ${TextGradient}
  }
`;
