import PropTypes from "prop-types";
import { propTypeElement } from "../../../reducers/elements";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import { LastChoiceElementName, StyledLastChoiceElement } from "./styles";

const LastChoiceElement = ({ elementsById, lastChoice }) => (
  <StyledLastChoiceElement>
    <LastChoiceElementName>
      {lastChoice.player_first_name} {lastChoice.player_last_name}{" "}
    </LastChoiceElementName>
    <LeagueEntryShortName entryId={lastChoice.entry} /> picked:
    <strong> {elementsById[lastChoice.element].web_name}</strong>
  </StyledLastChoiceElement>
);

LastChoiceElement.propTypes = {
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  lastChoice: PropTypes.shape({
    element: PropTypes.number.isRequired,
    player_first_name: PropTypes.string.isRequired,
    player_last_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default LastChoiceElement;
