import styled from "styled-components/macro";
import { LinkStyles } from "./Link";

// A button styled to look like a link (LinkButton)
// ...as opposed to a link styled to look like a button (ButtonLink)
export const LinkButton = styled.button`
  ${LinkStyles}
  display: inline-block;
  width: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: left;
`;
