import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { ADD_ENTRY_EVENT_SUBS } from "../actions/ActionTypes";
import { getElement, propTypeElement } from "./elements";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.subs;
export const l2g = (local) => ({ subs: local });

const byEventEntry = (state = {}, action) => {
  switch (action.type) {
    case ADD_ENTRY_EVENT_SUBS: {
      const newState = { ...state };
      const eventId = action.data.event;
      if (!newState[eventId]) {
        newState[eventId] = {};
      }
      newState[eventId][action.data.entry] = action.data.subs;
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byEventEntry,
});

// Selectors
export const getEventEntry = (state) => g2l(state).byEventEntry;

export const getForEntryEvent = (state, entryId, eventId) => {
  const full = getEventEntry(state);
  if (full[eventId] && full[eventId][entryId]) {
    return full[eventId][entryId].map((s) => ({
      event: s.event,
      element_in: getElement(state, s.element_in),
      element_out: getElement(state, s.element_out),
    }));
  }
  return [];
};

// PropTypes
export const propTypeSub = PropTypes.shape({
  event: PropTypes.number,
  element_in: propTypeElement,
  element_out: propTypeElement,
});
