import styled from "styled-components/macro";

export const PrimaryContainer = styled.div`
  margin-top: ${(props) => props.theme.space[2]};
  margin-bottom: ${(props) => props.theme.space[4]};
  padding-bottom: ${(props) => props.theme.space[2]};
  background-color: ${(props) => props.theme.colors.primary};
  overflow: auto;
`;

export const PrimaryContainerHeading = styled.h4`
  margin: ${(props) => props.theme.space[2]} 0;
  padding: 0 ${(props) => props.theme.space[2]};
  color: #fff;
  font-size: inherit;
`;

export const PrimaryContainerHeadingValue = styled.span`
  color: ${(props) => props.theme.colors.fantasy};
`;

export const PrimaryContainerValue = styled.span`
  color: ${(props) => props.theme.colors.fantasy};
`;
export const PrimaryContainerContent = styled.div`
  background-color: #fff;
  overflow: auto;
`;
