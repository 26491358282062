import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { ADD_ELEMENT_TYPES } from "../actions/ActionTypes";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.elementTypes;
export const l2g = (local) => ({ elementTypes: local });

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_ELEMENT_TYPES: {
      const newState = { ...state };
      action.data.forEach((et) => {
        newState[et.id] = et;
      });
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byId,
});

// Selectors
export const getElementTypesById = (state) => g2l(state).byId;

export const getElementType = (state, id) => getElementTypesById(state)[id];

export const getElementTypes = (state) => {
  const localState = getElementTypesById(state);
  return Object.keys(localState).map((key) => localState[key]);
};

// Prop Types
export const propTypeElementType = PropTypes.shape({
  singular_name: PropTypes.string,
});
