import React from "react";
import { useDispatch } from "react-redux";
import { waiverRemove } from "../../../../actions/Transaction";
import { idFromEvent } from "../../../../utils/transactions";
import {
  PrimaryIcon,
  SquadButton,
  SquadButtonText,
} from "../../../squad/styles";
import TransactionElement from "../../TransactionElement";
import { ActionCell, ProposedCellIn, ProposedCellOut } from "./styles";
import { IWaiverCells } from "./types";

const WaiverCells: React.FC<IWaiverCells> = ({ waiver }) => {
  const dispatch = useDispatch();
  return (
    <>
      <ProposedCellIn>
        <TransactionElement elementId={waiver.element_in} />
      </ProposedCellIn>
      <ProposedCellOut>
        <TransactionElement elementId={waiver.element_out} />
      </ProposedCellOut>
      <ActionCell>
        {/* TODO: Refactor ActionButton to take different types of click handler */}
        <SquadButton
          variant="primary"
          type="button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            dispatch(waiverRemove(idFromEvent(e)))
          }
          data-id={waiver.priority}
        >
          <SquadButtonText>Cancel</SquadButtonText>
          <PrimaryIcon>
            <use xlinkHref="#ism-cancel" />
          </PrimaryIcon>
        </SquadButton>
      </ActionCell>
    </>
  );
};

export default WaiverCells;
