import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import {
  getSquadForViewedTeam,
  getViewedEntry,
  propTypeSquadForViewedTeam,
  propTypeSquadType,
} from "../../../reducers/draftChoices";
import {
  getElementTypes,
  propTypeElementType,
} from "../../../reducers/elementTypes";
import { propTypeElement } from "../../../reducers/elements";
import { getActiveEntry } from "../../../reducers/entries";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { getSetting } from "../../../reducers/settings";
import { propTypeTeam } from "../../../reducers/teams";
import { propTypeEventTeamFixture } from "../../../store/fixtures/slice";
import { fixtureSuffix } from "../../../utils/fixtures";
import { ButtonBox } from "../../Button";
import ButtonLink from "../../ButtonLink";
import ElementInTable from "../../ElementInTable";
import {
  ElementListFixture,
  ElementListPlayer,
  ElementListTotal,
} from "../../ElementList";
import ElementStatusButton from "../../ElementStatusButton";
import { ElementTable } from "../../ElementTable";
import { Form, SelectField } from "../../FieldRenderers";
import { PatternWrap } from "../../GraphicPatterns";
import PlaceholderInTable from "../../PlaceholderInTable";
import SubHeading from "../../SubHeading";
import TabHeading from "../../TabHeading";
import { PrimaryCell, StatusCell } from "../../Table";
import { VisuallyHidden } from "../../styles";
import { DraftedInfo, DraftedPlayer, DraftedTotals } from "./styles";

const DraftedTeamElement = ({
  element,
  elementType,
  nextFixture,
  teamsById,
}) => {
  if (element) {
    return (
      <tr>
        <StatusCell>
          <ElementStatusButton elementId={element.id} />
        </StatusCell>
        <PrimaryCell>
          <ElementInTable elementId={element.id} />
        </PrimaryCell>
        <ElementListFixture as="td">
          {nextFixture && nextFixture.fixtures[element.team].length > 0 && (
            <ul key={element.id}>
              {nextFixture.fixtures[element.team].map((f) => (
                <li key={f.id}>
                  {teamsById[f.opponent].short_name}
                  {fixtureSuffix(f.id, f.isHome)}
                </li>
              ))}
            </ul>
          )}
        </ElementListFixture>
      </tr>
    );
  }
  return (
    <tr>
      <td>&nbsp;</td>
      <td colSpan="2">
        <PlaceholderInTable elementType={elementType} text="To be drafted" />
      </td>
    </tr>
  );
};

DraftedTeamElement.propTypes = {
  element: propTypeElement,
  elementDialogShow: PropTypes.func.isRequired,
  elementType: propTypeElementType,
  nextFixture: propTypeEventTeamFixture,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
};

DraftedTeamElement.defaultProps = {
  element: null,
  elementType: null,
  nextFixture: null,
};

const DraftedTeamPosition = ({
  elementDialogShow,
  elementType,
  nextFixture,
  teamsById,
  typesById,
  typeInSquad,
}) => {
  // Pad elements
  const elements = [];
  for (let i = 0; i < typeInSquad.need; i += 1) {
    elements.push(typeInSquad.drafted[i] || null);
  }
  return (
    <>
      <thead>
        <tr>
          <ElementListTotal>
            {typeInSquad.drafted.length} / {typeInSquad.need}
          </ElementListTotal>
          <ElementListPlayer>
            <VisuallyHidden>Player</VisuallyHidden>
          </ElementListPlayer>
          <ElementListFixture>FIX</ElementListFixture>
        </tr>
      </thead>
      <tbody>
        {elements.map((element, index) => (
          <DraftedTeamElement
            element={element}
            elementDialogShow={elementDialogShow}
            elementType={elementType}
            key={element ? `squad_${element.id}` : `squad_${index}`}
            nextFixture={nextFixture}
            teamsById={teamsById}
            typesById={typesById}
          />
        ))}
      </tbody>
    </>
  );
};

DraftedTeamPosition.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementType: propTypeElementType.isRequired,
  nextFixture: propTypeEventTeamFixture,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
  typeInSquad: propTypeSquadType.isRequired,
};

DraftedTeamPosition.defaultProps = {
  nextFixture: null,
};

const DraftedTeams = ({
  elementDialogShow,
  elementTypes,
  entries,
  nextFixture,
  squad,
  squadSize,
  teamsById,
  typesById,
  viewedEntry,
}) => (
  <div>
    {viewedEntry && (
      <PatternWrap>
        <Box mx={3} mb={4}>
          <SubHeading>Drafted Teams</SubHeading>
        </Box>
        <Form>
          <Box mb={2}>
            <Field
              id="draftedTeamView"
              name="draftedTeamView"
              component={SelectField}
              hideLabel
              label="Change Team"
              parse={(value) => parseInt(value, 10)}
            >
              {entries.map((le) => (
                <option key={le.entry_id} value={le.entry_id}>
                  {le.player_first_name} {le.player_last_name}
                </option>
              ))}
            </Field>
          </Box>
        </Form>
        <DraftedInfo>
          <DraftedPlayer>{viewedEntry.entry_name}</DraftedPlayer>
          <DraftedTotals>
            <strong>{squad.totalSelected}</strong> / {squadSize}
          </DraftedTotals>
        </DraftedInfo>
        {elementTypes.map((et) => (
          <div key={et.id}>
            <Box mb={1}>
              <TabHeading title={et.plural_name} hasBorder={true} />
            </Box>
            <Box mb={3}>
              <ElementTable>
                <DraftedTeamPosition
                  elementDialogShow={elementDialogShow}
                  elementType={et}
                  key={et.id}
                  nextFixture={nextFixture}
                  teamsById={teamsById}
                  typeInSquad={squad.elements[`et${et.id}`]}
                  typesById={typesById}
                />
              </ElementTable>
            </Box>
          </div>
        ))}
        <ButtonBox>
          <ButtonLink to="/player-list" theme="primary" fullwidth="true">
            <span>
              <span>Printable Player List</span>
            </span>
          </ButtonLink>
        </ButtonBox>
      </PatternWrap>
    )}
  </div>
);

DraftedTeams.propTypes = {
  elementDialogShow: PropTypes.func.isRequired,
  elementTypes: PropTypes.arrayOf(propTypeElementType).isRequired,
  entries: PropTypes.arrayOf(propTypeLeagueEntry).isRequired,
  nextFixture: propTypeEventTeamFixture,
  squad: propTypeSquadForViewedTeam.isRequired,
  squadSize: PropTypes.number.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
  typesById: PropTypes.objectOf(propTypeElementType).isRequired,
  viewedEntry: propTypeLeagueEntry.isRequired,
};

DraftedTeams.defaultProps = {
  nextFixture: null,
};

export { DraftedTeams as DraftedTeamsTest };

const mapStateToProps = (state) => {
  const activeEntry = getActiveEntry(state);
  return {
    elementTypes: getElementTypes(state),
    initialValues: {
      draftedTeamView: activeEntry.id,
    },
    squad: getSquadForViewedTeam(state),
    squadSize: getSetting(state, "squad", "size"),
    viewedEntry: getViewedEntry(state),
  };
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: "draftedTeams",
  })(DraftedTeams),
);
