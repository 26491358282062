import React from "react";
import PropTypes from "prop-types";

class FocusErrorsForm extends React.Component {
  componentDidUpdate() {
    if (this.props.error) {
      // probably should use an error class here instead!
      // for now just the first <p>
      const error = this.formEl.querySelector("p");
      error.focus();
    } else if (this.props.errors && Object.keys(this.props.errors).length > 0) {
      const errorNames = Object.keys(this.props.errors);
      const elToFocus = this.formEl.querySelector(
        errorNames.map((x) => `[name=${x}]`).join(", "),
      );
      elToFocus.focus();
    }
  }
  render() {
    const formProps = { ...this.props };
    delete formProps.errors;
    delete formProps.error;
    return (
      <form
        {...formProps}
        ref={(formEl) => {
          this.formEl = formEl;
        }}
      >
        {this.props.children}
      </form>
    );
  }
}

FocusErrorsForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.string,
  children: PropTypes.node,
};

FocusErrorsForm.defaultProps = {
  errors: {},
  error: null,
  children: null,
};

export default FocusErrorsForm;
