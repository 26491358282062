import PropTypes from "prop-types";
import {
  DraftRoundName,
  DraftRoundText,
  DraftRoundTextRound,
  StyledDraftRound,
} from "./styles";

const DraftRound = ({ isDuring, leagueName, round, squadSize }) => (
  <StyledDraftRound>
    <DraftRoundName>{leagueName}</DraftRoundName>
    {isDuring && (
      <DraftRoundText>
        Round <DraftRoundTextRound>{round}</DraftRoundTextRound> of {squadSize}
      </DraftRoundText>
    )}
  </StyledDraftRound>
);

DraftRound.propTypes = {
  isDuring: PropTypes.bool,
  leagueName: PropTypes.string.isRequired,
  round: PropTypes.number,
  squadSize: PropTypes.number,
};

DraftRound.defaultProps = {
  isDuring: false,
  leagueName: undefined,
  round: null,
  squadSize: null,
};

export default DraftRound;
