import styled from "styled-components/macro";
import { ActionCell, ProposedCell, ProposedWidth } from "./WaiverCells/styles";
import Table from "../../Table";
import { DragCell, PriorityCell } from "../../sortable/Row/styles";

const ProposedHead = styled.th`
  ${ProposedWidth}
  text-align: center;
  text-transform: uppercase;
`;

export const ProposedHeadIn = styled(ProposedHead)``;

export const ProposedHeadOut = styled(ProposedHead)``;

export const StyledWaiversTable = styled(Table)`
  table-layout: fixed;

  ${PriorityCell}, ${ProposedHead}, ${ActionCell} {
    text-align: center;
  }

  td {
    padding: ${(props) => props.theme.space[1]};
  }

  ${ProposedHeadIn} {
    color: #03aa68;
  }

  ${ProposedHeadOut} {
    color: #fc2c80;
  }

  ${DragCell} {
    padding: 0;
  }

  ${ProposedCell} {
    padding: 0 0 0 4px;
  }
`;
