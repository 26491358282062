import { rgba, size } from "polished";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Sub } from "../../img/icons/sub.svg";
import { InfoIcon } from "../ElementStatus";
import { IElementName, IStyledPitchElement } from "./types";

export const StyledPitchElement = styled.div<IStyledPitchElement>`
  position: relative;
  width: 6.4rem;
  margin: auto;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  text-align: center;
  background-color: ${({ theme, subStatus }) =>
    subStatus ? rgba(theme.colors.subStatus[subStatus], 0.6) : "transparent"};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 10.5rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 14rem;
    padding-right: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
  }
`;

export const PrimaryControl = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    position: absolute;
    display: block;
    top: 0;
    left: ${(props) => props.theme.space[2]};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    left: ${(props) => props.theme.space[4]};
  }
`;

export const InfoControl = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: absolute;
    top: 0;
    right: 1rem;
    display: block;

    ${InfoIcon} {
      box-shadow: 1px 1px 10px -4px ${rgba("black", 0.5)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    right: 2rem;
  }
`;

export const StyledSub = styled(Sub)`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(14)}
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(18)}
  }
`;

export const StyledPitchElementData = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
`;

export const ElementName = styled.div<IElementName>`
  padding: 0.2rem 0.2rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${(props) =>
    props.element.news &&
    props.element.chance_of_playing_next_round !== null &&
    css`
      background-color: ${props.theme.colors.elementStatus[
        props.element.chance_of_playing_next_round
      ].bg};
      color: ${props.theme.colors.elementStatus[
        props.element.chance_of_playing_next_round
      ].color};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

export const ElementValue = styled.div`
  padding: 0.2rem;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: ${rgba("white", 0.3)};
  background-color: rgba(233, 249, 241, 0.3);

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 1.1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;
