import { subHours } from "date-fns";
import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { ADD_EVENTS, ADD_GAME } from "../actions/ActionTypes";
import {
  getCurrentEvent as getCurrentEventSafe,
  getNextEvent as getNextEventSafe,
} from "./circularProblems";
import { getActiveLeague } from "./leagues";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.events;
export const l2g = (local) => ({ events: local });

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_EVENTS: {
      const newState = { ...state };
      action.data.data.forEach((event) => {
        newState[event.id] = event;
      });
      return newState;
    }

    default:
      return state;
  }
};

const current = (state = null, action) => {
  switch (action.type) {
    case ADD_GAME: {
      return action.data.current_event;
    }

    default:
      return state;
  }
};

const next = (state = null, action) => {
  switch (action.type) {
    case ADD_GAME: {
      return action.data.next_event;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  current,
  next,
});

// Selectors
export const getEventsById = (state) => g2l(state).byId;

export const getEvent = (state, id) => getEventsById(state)[id];

export const getEvents = (state) => {
  const localState = getEventsById(state);
  return Object.keys(localState).map((key) => localState[key]);
};

export const getCurrentEvent = (state) => getCurrentEventSafe(state);
export const getNextEvent = (state) => getNextEventSafe(state);

export const getEventsFromLeagueStart = createSelector(
  getEvents,
  getActiveLeague,
  (events, league) => events.slice(league.start_event - 1),
);

export const getEventsToNext = createSelector(
  getEventsFromLeagueStart,
  getNextEvent,
  (events, nxt) => (nxt ? events.filter((e) => e.id <= nxt.id) : events),
);

export const getNext3Events = createSelector(
  getEventsById,
  getNextEvent,
  (all, nxt) => {
    const events = [];
    if (nxt) {
      [0, 1, 2].forEach((step) => {
        if (all[nxt.id + step]) {
          events.push(all[nxt.id + step]);
        }
      });
    }
    return events;
  },
);

export const getLastEvent = (state) =>
  getEvent(state, Math.max(...Object.keys(getEventsById(state))));

export const getLastDraftDate = (state) => {
  const lastEvent = getLastEvent(state);
  return subHours(new Date(lastEvent.deadline_time), 12);
};

// PropTypes
export const propTypeEvent = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});
