import { size } from "polished";
import styled from "styled-components/macro";
import { IResult, resultChars } from "./types";

const getResultColor = (resultChar: resultChars) => {
  switch (resultChar) {
    case "W":
      return "#13cf00";
    case "L":
      return "#d81920";
    default:
      return "#76766f";
  }
};

export const StyledResult = styled.span<IResult>`
  ${size("3rem")}
  display: inline-block;
  margin-right: ${(props) => props.theme.space[1]};
  border-radius: 50%;
  background-color: ${({ resultChar }) => getResultColor(resultChar)};
  color: #fff;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[2]};
  line-height: 3rem;
  text-align: center;
  vertical-align: middle;
`;
