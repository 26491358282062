import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import {
  getElementTypes,
  propTypeElementType,
} from "../../reducers/elementTypes";
import { getElements, propTypeElement } from "../../reducers/elements";
import { getTeamsById, propTypeTeam } from "../../reducers/teams";
import { Main } from "../Layout";
import SubHeading from "../SubHeading";
import {
  Name,
  Points,
  PrintableElementList,
  PrintableElements,
  PrintableElementsUnit,
  Rank,
  Team,
} from "./styles";

const ElementListElement = ({ element, team }) => (
  <tr>
    <td>{element.web_name}</td>
    <td>{team.name}</td>
    <td>{element.draft_rank}</td>
    <td>{element.total_points}</td>
  </tr>
);

ElementListElement.propTypes = {
  element: propTypeElement.isRequired,
  team: propTypeTeam.isRequired,
};

const ElementListTable = ({ elements, teamsById }) => (
  <Box mb={4}>
    <PrintableElementList>
      <thead>
        <tr>
          <Name>Player</Name>
          <Team>Team</Team>
          <Rank>Draft Rank</Rank>
          <Points>Points</Points>
        </tr>
      </thead>
      <tbody>
        {elements.map((element) => (
          <ElementListElement
            element={element}
            key={element.id}
            team={teamsById[element.team]}
          />
        ))}
      </tbody>
    </PrintableElementList>
  </Box>
);

ElementListTable.propTypes = {
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

const ElementListPosition = ({ elements, elementType, teamsById }) => {
  const halfElements = Math.ceil(elements.length / 2);
  const firstHalfElements = elements.slice(0, halfElements);
  const secondHalfElements = elements.slice(halfElements, elements.length);
  return (
    <div>
      <SubHeading as="h2">{elementType.plural_name}</SubHeading>
      <PrintableElements>
        <PrintableElementsUnit>
          <ElementListTable
            elements={firstHalfElements}
            teamsById={teamsById}
          />
        </PrintableElementsUnit>
        <PrintableElementsUnit>
          <ElementListTable
            elements={secondHalfElements}
            teamsById={teamsById}
          />
        </PrintableElementsUnit>
      </PrintableElements>
    </div>
  );
};

ElementListPosition.propTypes = {
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementType: propTypeElementType.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

const ElementListWrapper = ({ elements, elementTypes, teamsById, ...rest }) => (
  <Main isWide={true}>
    {elementTypes.map((et) => (
      <ElementListPosition
        elements={elements
          .filter((e) => e.element_type === et.id)
          .sort((a, b) => a.draft_rank - b.draft_rank)}
        elementType={et}
        key={et.id}
        teamsById={teamsById}
      />
    ))}
  </Main>
);

ElementListWrapper.propTypes = {
  elements: PropTypes.arrayOf(propTypeElement).isRequired,
  elementTypes: PropTypes.arrayOf(propTypeElementType).isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

export { ElementListWrapper as ElementListWrapperTest };

const mapStateToProps = (state) => ({
  elements: getElements(state),
  elementTypes: getElementTypes(state),
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(ElementListWrapper);
