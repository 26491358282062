import { Box } from "rebass/styled-components";
import { propTypeEntry } from "../../reducers/entries";
import { propTypeEvent } from "../../reducers/events";
import BoldLink, { BoldLinkWrap } from "../BoldLink";
import {
  DataList,
  DataListHeading,
  DataListItem,
  DataListValue,
} from "../DataList";
import Link from "../Link";
import TabHeading from "../TabHeading";

const EntrySummary = ({ entry, currentEvent }) => (
  <div>
    <Box mb={2}>
      <TabHeading title="Points/Rankings" hasBorder={true} />
    </Box>
    <DataList>
      <DataListItem>
        <DataListHeading>Gameweek Points</DataListHeading>
        <DataListValue>
          {currentEvent && entry.started_event <= currentEvent.id && (
            <Link to={`/entry/${entry.id}/event/${currentEvent.id}`}>
              {entry.event_points}
            </Link>
          )}
        </DataListValue>
      </DataListItem>
      <DataListItem>
        <DataListHeading>Overall Points</DataListHeading>
        <DataListValue>{entry.overall_points}</DataListValue>
      </DataListItem>
    </DataList>
    <BoldLinkWrap>
      <BoldLink to={`/entry/${entry.id}/history`}>View History</BoldLink>
    </BoldLinkWrap>
  </div>
);

EntrySummary.propTypes = {
  currentEvent: propTypeEvent,
  entry: propTypeEntry.isRequired,
};

EntrySummary.defaultProps = {
  currentEvent: null,
};

export default EntrySummary;
