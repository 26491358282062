import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changeEntry } from "../actions/Entry";
import {
  getActiveEntry,
  getMyEntriesWithScoring,
  propTypeEntry,
  propTypeEntryWithScoring,
} from "../reducers/entries";
import {
  FieldLabel,
  Form,
  FormControl,
  FormInfo,
  Select,
  SelectWrap,
} from "./FieldRenderers";

const EntryOption = ({ entry }) => (
  <option value={entry.id}>{entry.name}</option>
);

EntryOption.propTypes = {
  entry: propTypeEntryWithScoring.isRequired,
};

const ActiveEntrySelect = ({
  activeEntry,
  entries,
  handleActiveEntryChange,
  isInline,
}) => {
  const mockEntries = entries.filter((entry) => entry.leagueScoring === "m");
  const otherEntries = entries.filter((entry) => entry.leagueScoring !== "m");
  return (
    <Form isInline={isInline}>
      <FormInfo>
        <label htmlFor="ismjs-active-selector">
          <FieldLabel>Current team</FieldLabel>
        </label>
      </FormInfo>
      <FormControl>
        <SelectWrap>
          <Select
            onChange={handleActiveEntryChange}
            id="ismjs-active-selector"
            value={activeEntry.id}
          >
            {otherEntries.map((entry) => (
              <EntryOption key={entry.id} entry={entry} />
            ))}
            {mockEntries.length > 0 && (
              <optgroup label="Mock Drafts">
                {mockEntries.map((entry) => (
                  <EntryOption key={entry.id} entry={entry} />
                ))}
              </optgroup>
            )}
          </Select>
        </SelectWrap>
      </FormControl>
    </Form>
  );
};

ActiveEntrySelect.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entries: PropTypes.arrayOf(propTypeEntryWithScoring).isRequired,
  handleActiveEntryChange: PropTypes.func.isRequired,
  isInline: PropTypes.bool,
};

ActiveEntrySelect.defaultProps = {
  isInline: false,
};

const mapStateToProps = (state) => ({
  activeEntry: getActiveEntry(state),
  entries: getMyEntriesWithScoring(state),
});

const mapDispatchToProps = {
  handleActiveEntryChange: (e) => changeEntry(e.target.value),
};

export { ActiveEntrySelect as ActiveEntrySelectTest };

export default connect(mapStateToProps, mapDispatchToProps)(ActiveEntrySelect);
