import { Component } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { entryHistoryFetch } from "../actions/Entry";
import { getEntry, getHistoryForEntry } from "../reducers/entries";
import { getEventsById } from "../reducers/events";
import HelmetHead from "./HelmetHead";
import { Main, Secondary, Wrapper } from "./Layout";
import Link from "./Link";
import Title from "./Title";
import Entry from "./entry/Entry";
import Table from "./Table";

class EntryHistory extends Component {
  componentDidMount() {
    this.props.fetchHistory(this.props.entryId);
  }
  render() {
    const { entry, eventsById, history } = this.props;

    return (
      <Wrapper>
        {/* eslint-disable max-len */}
        <HelmetHead
          title="Gameweek History - Fantasy Premier League Draft 2024/25"
          description={`To view the Gameweek history of ${entry.player_first_name} ${entry.player_last_name}, visit the official website of the Premier League.`}
        />
        {/* eslint-enable max-len */}
        <Main>
          <Box mx={2}>
            <Title>Gameweek History</Title>
          </Box>
          <Box>
            <Table>
              <thead>
                <tr>
                  <th>Round</th>
                  <th>Points</th>
                  <th>Total Points</th>
                </tr>
              </thead>
              <tbody>
                {history.length ? (
                  history.map((h) => (
                    <tr key={h.id}>
                      <td>
                        <Link to={`/entry/${entry.id}/event/${h.event}`}>
                          {eventsById[h.event].name}
                        </Link>
                      </td>
                      <td>{h.points}</td>
                      <td>{h.total_points}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <p className="ism-align-center">No Gameweek history.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Box>
        </Main>
        <Secondary>
          <Entry entry={entry} />
        </Secondary>
      </Wrapper>
    );
  }
}

// EntryHistory.propTypes = {
//   entry: propTypeEntry.isRequired,
//   entryId: PropTypes.number.isRequired,
//   eventsById: PropTypes.objectOf(propTypeEvent).isRequired,
//   fetchHistory: PropTypes.func.isRequired,
//   history: PropTypes.arrayOf(propTypeEntryHistory).isRequired,
// };

export { EntryHistory as EntryHistoryTest };

const mapStateToProps = (state, ownProps) => {
  const entryId = Number(ownProps.entryId);
  return {
    entry: getEntry(state, entryId),
    entryId,
    eventsById: getEventsById(state),
    history: getHistoryForEntry(state, entryId),
  };
};

const mapDispatchToProps = {
  fetchHistory: entryHistoryFetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryHistory);
