import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { ReactComponent as BaseMovementIcon } from "../../img/icons/movement.svg";
import { ReactComponent as BaseNewIcon } from "../../img/icons/new.svg";

const MovementIcon = styled(BaseMovementIcon)`
  ${(props) =>
    props.status &&
    css`
      fill: ${props.theme.colors.movement[props.status].bg};
      color: ${props.theme.colors.movement[props.status].color};
    `}

  ${(props) =>
    props.status === "down" &&
    css`
      transform: rotate(180deg);
    `}
`;

const NewIcon = styled(BaseNewIcon)`
  fill: ${({ theme }) => theme.colors.darkGrey};
`;

const Movement = ({ lastRank, rank }) => {
  let status = "";
  if (lastRank === null) {
    status = "new";
  } else if (rank === lastRank) {
    status = "same";
  } else if (rank < lastRank) {
    status = "up";
  } else if (rank > lastRank) {
    status = "down";
  }

  if (status === "new") {
    return <NewIcon title={status} />;
  }

  return <MovementIcon status={status} />;
};

Movement.propTypes = {
  lastRank: PropTypes.number,
  rank: PropTypes.number,
};

Movement.defaultProps = {
  lastRank: null,
  rank: null,
};

export default Movement;
