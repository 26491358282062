import React from "react";
import { Box } from "rebass/styled-components";
import playerComp1 from "../../img/player-comps/player-comp-1-1x.png";
import playerComp1x2 from "../../img/player-comps/player-comp-1-2x.png";
import playerComp2 from "../../img/player-comps/player-comp-2-1x.png";
import playerComp2x2 from "../../img/player-comps/player-comp-2-2x.png";
import playerComp3 from "../../img/player-comps/player-comp-3-1x.png";
import playerComp3x2 from "../../img/player-comps/player-comp-3-2x.png";
import playerComp4 from "../../img/player-comps/player-comp-4-1x.png";
import playerComp4x2 from "../../img/player-comps/player-comp-4-2x.png";
import playerComp5 from "../../img/player-comps/player-comp-5-1x.png";
import playerComp5x2 from "../../img/player-comps/player-comp-5-2x.png";
import { Wrapper } from "../Layout";
import Nav from "../Nav";
import { GameTitle, PlayerComp, StyledGameHeader } from "./styles";

export const getPlayerComp = () => {
  const playerImages = [
    {
      x1: playerComp1,
      x2: playerComp1x2,
    },
    {
      x1: playerComp2,
      x2: playerComp2x2,
    },
    {
      x1: playerComp3,
      x2: playerComp3x2,
    },
    {
      x1: playerComp4,
      x2: playerComp4x2,
    },
    {
      x1: playerComp5,
      x2: playerComp5x2,
    },
  ];
  const choice = Math.floor(Math.random() * playerImages.length);
  return playerImages[choice];
};

const GameHeader: React.FC = () => (
  <StyledGameHeader>
    <Wrapper>
      <PlayerComp images={getPlayerComp()}>
        <Box px={2}>
          <GameTitle>Draft</GameTitle>
          <Nav />
        </Box>
      </PlayerComp>
    </Wrapper>
  </StyledGameHeader>
);

export default GameHeader;
