import {
  ADD_DREAM_TEAM,
  ADD_TOP_ELEMENTS,
  ELEMENT_DIALOG_HIDE,
  ELEMENT_DIALOG_SHOW,
  FETCH_ELEMENT_SUMMARY_SUCCESS,
} from "./ActionTypes";
import { fetchGet, handleUnexpectedError } from "../helpers";

export const actionFetchElementSummarySuccess = (element, data) => ({
  type: FETCH_ELEMENT_SUMMARY_SUCCESS,
  data: {
    element,
    summary: data,
  },
});

export const addTopElements = (data) => ({
  type: ADD_TOP_ELEMENTS,
  data,
});

export const addDreamTeam = (elements, eventId = 0) => ({
  type: ADD_DREAM_TEAM,
  data: {
    elements,
    eventId,
  },
});

export const elementDialogShow = (id) => ({
  type: ELEMENT_DIALOG_SHOW,
  data: id,
});

export const elementDialogHide = () => ({
  type: ELEMENT_DIALOG_HIDE,
});

export const fetchElementSummary = (element) => (dispatch) =>
  fetchGet(`/api/element-summary/${element}`).then(
    (d) => dispatch(actionFetchElementSummarySuccess(element, d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const fetchTopElements = () => (dispatch) =>
  fetchGet("/api/top-elements").then(
    (d) => dispatch(addTopElements(d.data)),
    (error) => handleUnexpectedError(error, dispatch),
  );

export const fetchDreamTeam = (eventId) => (dispatch) => {
  const url = eventId ? `/api/dreamteam/${eventId}` : "/api/dreamteam";
  return fetchGet(url).then(
    (d) => dispatch(addDreamTeam(d.data.elements, eventId || 0)),
    (error) => handleUnexpectedError(error, dispatch),
  );
};
