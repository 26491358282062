import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { propTypeChatMessage } from "../../../reducers/chatMessages";
import { propTypeEntry } from "../../../reducers/entries";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import {
  ChatMessage,
  ChatMessageHeading,
  ChatMessageName,
  ChatMessageText,
  ChatMessageTime,
  StyledChatMessages,
} from "./styles";

class ChatMessages extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    // New message
    if (prevProps.messages.length !== this.props.messages.length) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
  }

  render() {
    return (
      <StyledChatMessages
        ref={(div) => {
          this.messageContainer = div;
        }}
      >
        <ul>
          {this.props.messages.map((m) => (
            <ChatMessage
              isMe={m.entry === this.props.activeEntry.id}
              key={`id=${m.id}`}
            >
              <ChatMessageHeading>
                <ChatMessageName>
                  {m.player_first_name} {m.player_last_name}{" "}
                  <LeagueEntryShortName entryId={m.entry} />
                </ChatMessageName>
                <ChatMessageTime>{` ${moment(m.posted).format(
                  "Do MMM HH:mm",
                )}`}</ChatMessageTime>
              </ChatMessageHeading>
              <ChatMessageText>{` ${m.text}`}</ChatMessageText>
            </ChatMessage>
          ))}
        </ul>
      </StyledChatMessages>
    );
  }
}

ChatMessages.propTypes = {
  activeEntry: propTypeEntry.isRequired,
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  messages: PropTypes.arrayOf(propTypeChatMessage).isRequired,
};

export default ChatMessages;
