import styled from "styled-components/macro";

export const StyledLeagueSummaryMatch = styled.div`
  margin-top: ${(props) => props.theme.space[2]};
  position: relative;
  margin-bottom: ${(props) => props.theme.space[4]};
  padding-top: ${(props) => props.theme.space[1]};
  border-width: 0 1px 1px;
  border-style: solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-color: ${(props) => props.theme.colors.lightGrey};

  ::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: ${(props) => props.theme.space[1]};
    background-color: ${(props) => props.theme.colors.primary};
    content: "";
  }
`;

export const MatchHeading = styled.h3`
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  background-color: ${(props) => props.theme.colors.fantasy};
  font-size: ${(props) => props.theme.fontSizes[2]};
`;
