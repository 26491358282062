import { TableWrap } from "../../Table";
import { VisuallyHidden } from "../../styles";
import { DragCell, PriorityCell } from "../../sortable/Row/styles";
import { ActionCell } from "./WaiverCells/styles";
import WaiverRows from "./WaiverRows/WaiverRows";
import { ProposedHeadIn, ProposedHeadOut, StyledWaiversTable } from "./styles";

const ProposedWaivers: React.FC = () => (
  <TableWrap>
    <StyledWaiversTable>
      <thead>
        <tr>
          <DragCell as="th">
            <VisuallyHidden>Drag</VisuallyHidden>
          </DragCell>
          <PriorityCell as="th">Priority</PriorityCell>
          <ProposedHeadIn>In</ProposedHeadIn>
          <ProposedHeadOut>Out</ProposedHeadOut>
          <ActionCell as="th">Cancel?</ActionCell>
        </tr>
      </thead>
      <WaiverRows />
    </StyledWaiversTable>
  </TableWrap>
);

export default ProposedWaivers;
