import React from "react";
import styled from "styled-components/macro";

const StyledLogo = styled.img`
  vertical-align: middle;
`;

interface ILogoProps {
  abbreviation: string;
  name: string;
}

const BroadcasterLogo: React.FC<ILogoProps> = ({ abbreviation, name }) => {
  const path =
    "https://resources.premierleague.com/premierleague/broadcasters/global";
  const abbr = abbreviation.toLowerCase();
  return (
    <StyledLogo
      srcSet={`${path}/${abbr}.png, ${path}/${abbr}@x2.png 2x`}
      src={`${path}/${abbr}.png`}
      alt={name}
    />
  );
};

export default BroadcasterLogo;
