import styled from "styled-components/macro";
import SubHeading from "../SubHeading";

export const EventPagerWrap = styled.div`
  overflow: auto;
  margin-bottom: ${(props) => props.theme.space[4]};
`;

export const EventPagerHeading = styled(SubHeading)`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    position: relative;
    margin-right: 25%;
    margin-left: 25%;
  }
`;
