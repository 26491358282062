import { hiDPI, rgba, size } from "polished";
import styled from "styled-components/macro";
import pattern2Crop184 from "../../img/pattern/pattern-2-crop-184.png";
import pattern2Crop90 from "../../img/pattern/pattern-2-crop-90.png";
import pattern1_874 from "../../img/pattern/pattern-1-874.png";
import pattern1_1236 from "../../img/pattern/pattern-1-1236.png";
import { css } from "styled-components/macro";

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(${rgba("#fff", 0.3)}, ${rgba("#fff", 0.8)});
  border-radius: 6px;
  z-index: 666;
`;

export const StyledDialog = styled.dialog<{ isElementDialog: boolean }>`
  max-width: 90%;
  max-height: 90%;
  padding: 0;
  border: 0;
  border-radius: ${({ theme }) => theme.radii[1]};
  /* Ugly hack to deal with sub-pixel rounding in chrome bug causing blurry content with transform */
  width: 640px;
  color: currentColor;
  box-shadow: 0 12px 24px 0 ${rgba("black", 0.25)};
  z-index: 667;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position:
    right top,
    0,
    0;
  background-image: url(${pattern2Crop90}),
    linear-gradient(
      to bottom,
      ${rgba("#fff", 0)} 10px,
      ${rgba("#fff", 0.2)} 30px,
      white 60px
    ),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.lightBlue},
      ${(props) => props.theme.colors.darkBlue}
    );

  ${hiDPI(2)} {
    background-image: url(${pattern2Crop184}),
      linear-gradient(
        to bottom,
        ${rgba("#fff", 0)} 20px,
        ${rgba("#fff", 0.2)} 45px,
        white 70px
      ),
      linear-gradient(
        to right,
        ${(props) => props.theme.colors.lightBlue},
        ${(props) => props.theme.colors.darkBlue}
      );
    background-size:
      90px 60px,
      auto,
      auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: 640px;
  }

  ${(props) =>
    props.isElementDialog &&
    css`
      max-height: calc(80vh - 40px);
      overflow-y: auto;
      background-image: url(${pattern2Crop90}),
        linear-gradient(to bottom, ${rgba("#fff", 0)} 240px, white 360px),
        linear-gradient(
          to right,
          ${(props) => props.theme.colors.lightBlue},
          ${(props) => props.theme.colors.darkBlue}
        );

      ${hiDPI(2)} {
        background-image: url(${pattern2Crop184}),
          linear-gradient(to bottom, ${rgba("#fff", 0)} 240px, white 360px),
          linear-gradient(
            to right,
            ${(props) => props.theme.colors.lightBlue},
            ${(props) => props.theme.colors.darkBlue}
          );
        background-size:
          90px 60px,
          auto,
          auto;
      }

      @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
        background-image: linear-gradient(
            to bottom,
            ${rgba("#fff", 0)} 240px,
            white 360px
          ),
          url(${pattern1_874}),
          linear-gradient(
            to right,
            ${(props) => props.theme.colors.lightBlue},
            ${(props) => props.theme.colors.darkBlue}
          );
        background-position:
          0 0,
          right -60px top -481px,
          0 0;
        background-size:
          auto,
          653px 925px,
          auto;

        ${hiDPI(2)} {
          background-image: linear-gradient(
              to bottom,
              ${rgba("#fff", 0)} 240px,
              white 360px
            ),
            url(${pattern1_1236}),
            linear-gradient(
              to right,
              ${(props) => props.theme.colors.lightBlue},
              ${(props) => props.theme.colors.darkBlue}
            );
          background-size:
            auto,
            653px 925px,
            auto;
        }
      }
    `}
`;

export const StyledDialogHeader = styled.div`
  position: relative;
`;

export const DialogTitle = styled.h2`
  padding-top: 2.4rem;
  font-size: ${(props) => props.theme.fontSizes[4]};
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background-color: transparent;
`;

export const CloseIcon = styled.svg`
  ${size("3.2rem")}
  color: inherit;
  fill: transparent;
`;

export const ConfirmDialogBodyWrap = styled.div`
  text-align: center;
`;

export const ConfirmDialogBody = styled.div`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[3]}`};
`;
