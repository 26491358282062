import {
  Location,
  navigate,
  RouteComponentProps,
  Router,
  WindowLocation,
} from "@gatsbyjs/reach-router";
import React, { lazy, Suspense } from "react";
import { connect, ConnectedComponent, useSelector } from "react-redux";
import { getActiveEntry, getActiveEntryStatus } from "../reducers/entries";
import { getLeagueForEntry } from "../reducers/leagues";
import { RootState } from "../reducers/rootReducer";
import { ActiveEntryStatus } from "../store/entries/types";
import Copy from "./Copy";
import CreateLeague from "./CreateLeague";
import DraftWrapper from "./draft/DraftWrapper";
import EditEntry from "./EditEntry";
import EditLeague from "./EditLeague";
import EntryEvent from "./EntryEvent";
import EntryHistory from "./EntryHistory";
import EntryTransactions from "./EntryTransactions";
import EntryUnsubscribe from "./EntryUnsubscribe";
import {
  InternalError,
  NotFoundError,
  Processing,
  SeasonOver,
  UnavailableError,
} from "./ErrorPages";
import FixturesWrapper from "./fixtures/FixturesWrapper";
import Login from "./home/Login";
import HomeChooser from "./HomeChooser";
import JoinMockDraft from "./JoinMockDraft";
import JoinOrCreateLeague from "./JoinOrCreateLeague";
import JoinPrivateLeague from "./JoinPrivateLeague";
import JoinPublicLeague from "./JoinPublicLeague";
import { Main, Wrapper } from "./Layout";
import LeagueWrapper from "./league/LeagueWrapper";
import LeagueInvite from "./LeagueInvite";
import MyTeam from "./MyTeam";
import TradeWrapper from "./transactions/TradeWrapper";
import TransactionWrapper from "./transactions/TransactionWrapper";
import ElementListWrapper from "./ElementListWrapper";
const Help = lazy(() => import("./help/Help"));

interface IRouteChangeProps {
  action: () => void;
}

interface IRouteChangeWorkerProps {
  location: WindowLocation;
}

type RouteChangeProps = IRouteChangeProps & IRouteChangeWorkerProps;

class OnRouteChangeWorker extends React.Component<RouteChangeProps> {
  componentDidUpdate(prevProps: RouteChangeProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action();
    }
  }

  render() {
    return null;
  }
}

const OnRouteChange: React.FC<IRouteChangeProps> = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
);

interface IProtectedRouteProps extends RouteComponentProps {
  as: React.ComponentType | ConnectedComponent<any, any>;
  entryStatus: ActiveEntryStatus;
}

interface IPropsFromState {
  entryStatus: ActiveEntryStatus;
}

// Player must be logged in
const PlayerRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  entryStatus,
  ...rest
}) =>
  entryStatus === "logged_out" ? (
    <Wrapper>
      <Main isWide={true}>
        <Login />
      </Main>
    </Wrapper>
  ) : (
    <Component {...rest} />
  );

// Player must have an entry
const EntryRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  entryStatus,
  ...rest
}) =>
  entryStatus === "logged_out" || entryStatus === "no_entry" ? (
    <Wrapper>
      <Main isWide={true}>
        <Login />
      </Main>
    </Wrapper>
  ) : (
    <Component {...rest} />
  );

// Player must have picks
const PicksRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  entryStatus,
  ...rest
}) =>
  entryStatus === "picked" || entryStatus === "started" ? (
    <Component {...rest} />
  ) : (
    <Wrapper>
      <Main isWide={true}>
        <Login />
      </Main>
    </Wrapper>
  );

// Player must admin league
const LeagueAdminRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  entryStatus,
  ...rest
}) => {
  const activeEntry = useSelector(getActiveEntry);
  const league = useSelector((state) =>
    activeEntry ? getLeagueForEntry(state, activeEntry) : null,
  );
  if (!league || !league.can_admin) {
    setTimeout(() => navigate("/", { replace: true }), 0);
    return null;
  }
  return <Component {...rest} />;
};

export const NotFound: React.FC<RouteComponentProps> = () => (
  <Wrapper>
    <Main isWide={true}>
      <Copy>
        <h4>Page not found</h4>
        <p>Sorry, but the page you were looking for can't be found.</p>
      </Copy>
    </Main>
  </Wrapper>
);

const Routes: React.FC<IPropsFromState> = ({ entryStatus }) => {
  return (
    <Suspense fallback={<div>loading</div>}>
      <Router>
        <HomeChooser path="/" />
        <Help path="/help" />
        <PlayerRoute
          as={JoinOrCreateLeague}
          entryStatus={entryStatus}
          path="/entry/join-create"
        />
        <PlayerRoute
          as={CreateLeague}
          entryStatus={entryStatus}
          path="/entry/create"
        />
        <PlayerRoute
          as={JoinPublicLeague}
          entryStatus={entryStatus}
          path="/entry/join-public"
        />
        <PlayerRoute
          as={JoinPrivateLeague}
          entryStatus={entryStatus}
          path="/entry/join-private"
        />
        <PlayerRoute
          as={JoinMockDraft}
          entryStatus={entryStatus}
          path="/entry/join-mock"
        />
        <EntryRoute as={DraftWrapper} entryStatus={entryStatus} path="/draft" />
        <EntryRoute
          as={EditEntry}
          entryStatus={entryStatus}
          path="/entry/:entryId/edit"
        />
        <PicksRoute
          path="/league"
          as={LeagueWrapper}
          entryStatus={entryStatus}
        />
        <PicksRoute as={MyTeam} entryStatus={entryStatus} path="/team/my" />
        <PicksRoute
          as={TransactionWrapper}
          entryStatus={entryStatus}
          path="/team/transactions"
        />
        <PicksRoute
          as={TransactionWrapper}
          entryStatus={entryStatus}
          path="/team/transactions/remove"
        />
        <PicksRoute
          path="/team/transactions/trade"
          as={TradeWrapper}
          entryStatus={entryStatus}
        />
        <LeagueAdminRoute
          as={EditLeague}
          entryStatus={entryStatus}
          path="/league/:leagueId/edit"
        />
        <LeagueAdminRoute
          as={LeagueInvite}
          entryStatus={entryStatus}
          path="/league/:leagueId/invite"
        />
        <EntryEvent path="/entry/:entryId/event/:eventId" />
        <EntryHistory path="/entry/:entryId/history" />
        <EntryTransactions path="/entry/:entryId/transactions" />
        <ElementListWrapper path="/player-list" />
        <EntryUnsubscribe path="/entry/unsubscribe/:signature" />
        <FixturesWrapper path="/fixtures" />
        <FixturesWrapper path="/fixtures/:eventId" />
        <NotFoundError path="/not-found" />
        <InternalError path="/internal-error" />
        <UnavailableError path="/unavailable" />
        <Processing path="/processing" />
        <SeasonOver path="/season-over" />
        <NotFound default />
      </Router>
      <OnRouteChange action={() => window.scrollTo(0, 0)} />
    </Suspense>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  entryStatus: getActiveEntryStatus(state),
});

export default connect(mapStateToProps)(Routes);
