import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import {
  getActiveChatMessages,
  propTypeChatMessage,
} from "../../reducers/chatMessages";
import { propTypeHubStatus } from "../../reducers/entries";
import { getCurrentEvent, getNextEvent } from "../../reducers/events";
import { getActiveLeagueEntry } from "../../reducers/leagueEntries";
import { getActiveEntryResultThisEvent } from "../../reducers/leagueMatches";
import {
  getActiveLeagueStanding,
  getActiveLeagueStandings,
  propTypeLeagueStanding,
} from "../../reducers/leagueStandings";
import { getActiveLeague, propTypeLeague } from "../../reducers/leagues";
import { getNextWaiverDate } from "../../reducers/transactions";
import { checkGame } from "../../store/bootstrap/actions";
import ChatWrapper from "../Chat";
import LatestAlert from "../LatestAlert";
import { Wrapper } from "../Layout";
import DreamTeamSummary from "./DreamTeamSummary";
import EntryEventSummary from "./EntryEventSummary";
import EntryHeader from "./EntryHeader";
import EventDeadlines from "./EventDeadlines";
import EventStatus from "./EventStatus";
import LatestElementsNews from "./LatestElementsNews";
import LeagueSummary from "./LeagueSummary";
import RecentTransactions from "./RecentTransactions";
import TopAvailableElements from "./TopAvailableElements";
import TopElementCarousel from "./TopElementCarousel";
import Trades from "./Trades";

const MainStatus = styled.div`
  padding-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: calc(100% / 3 * 2);
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

const SecondaryStatus = styled.div`
  padding-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: calc(100% / 3);
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

const Status = ({ activeLeague, messages, standings, status, ...rest }) => {
  switch (status) {
    case "points":
      return (
        <Wrapper>
          <MainStatus>
            <LatestAlert />
            <EntryHeader {...rest} />
            <EntryEventSummary
              activeLeague={activeLeague}
              status={status}
              {...rest}
            />
            <EventStatus />
            <Box mb="4rem">
              <TopElementCarousel />
            </Box>
            <EventDeadlines status={status} {...rest} />
            <Box mb="4rem">
              <TopAvailableElements {...rest} />
            </Box>
            {activeLeague.trades !== "n" && (
              <Box mb="4rem">
                <Trades activeLeague={activeLeague} />
              </Box>
            )}
            <Box mb="4rem">
              <RecentTransactions />
            </Box>
            {activeLeague.variety === "x" && (
              <ChatWrapper messages={messages} />
            )}
          </MainStatus>
          <SecondaryStatus>
            <LeagueSummary />
            <Box mb={4}>
              <DreamTeamSummary />
            </Box>
            <LatestElementsNews />
          </SecondaryStatus>
        </Wrapper>
      );
    case "finished":
      return (
        <Wrapper>
          <MainStatus>
            <LatestAlert />
            <EntryHeader {...rest} />
            <EntryEventSummary
              activeLeague={activeLeague}
              status={status}
              {...rest}
            />
            <EventStatus />
            <Box mb="4rem">
              <TopElementCarousel />
            </Box>
            <EventDeadlines status={status} {...rest} />
            {activeLeague.trades !== "n" && (
              <Box mb="4rem">
                <Trades activeLeague={activeLeague} />
              </Box>
            )}
            <Box mb="4rem">
              <RecentTransactions />
            </Box>
            {activeLeague.variety === "x" && (
              <ChatWrapper messages={messages} />
            )}
          </MainStatus>
          <SecondaryStatus>
            <LeagueSummary />
            <Box mb={4}>
              <DreamTeamSummary />
            </Box>
            <LatestElementsNews />
          </SecondaryStatus>
        </Wrapper>
      );
    default:
      return (
        <Wrapper>
          <MainStatus>
            <LatestAlert />
            <EntryHeader {...rest} />
            <EntryEventSummary
              activeLeague={activeLeague}
              status={status}
              {...rest}
            />
            <EventDeadlines status={status} {...rest} />
            <Box mb="4rem">
              <TopAvailableElements {...rest} />
            </Box>
            {activeLeague.trades !== "n" && (
              <Box mb="4rem">
                <Trades activeLeague={activeLeague} />
              </Box>
            )}
            <Box mb="4rem">
              <RecentTransactions />
            </Box>
            <Box mb="4rem">
              <TopElementCarousel />
            </Box>
            <EventStatus />
            {activeLeague.variety === "x" && (
              <ChatWrapper messages={messages} />
            )}
          </MainStatus>
          <SecondaryStatus>
            <LeagueSummary />
            <Box mb={4}>
              <DreamTeamSummary />
            </Box>
            <LatestElementsNews />
          </SecondaryStatus>
        </Wrapper>
      );
  }
};

Status.propTypes = {
  activeLeague: propTypeLeague.isRequired,
  messages: PropTypes.arrayOf(propTypeChatMessage).isRequired,
  standings: PropTypes.arrayOf(propTypeLeagueStanding),
  status: propTypeHubStatus.isRequired,
};

Status.defaultProps = {
  standings: [],
};

export { Status as StatusTest };

class StatusContainer extends React.Component {
  componentDidMount() {
    this.props.checkGame();
  }

  render() {
    return <Status {...this.props} />;
  }
}

StatusContainer.propTypes = {
  checkGame: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeLeague: getActiveLeague(state),
  activeLeagueEntry: getActiveLeagueEntry(state),
  activeStanding: getActiveLeagueStanding(state),
  currentEvent: getCurrentEvent(state),
  matchThis: getActiveEntryResultThisEvent(state),
  messages: getActiveChatMessages(state),
  nextEvent: getNextEvent(state),
  nextWaiverDate: getNextWaiverDate(state),
  resultThis: getActiveEntryResultThisEvent(state),
  standings: getActiveLeagueStandings(state),
});

const mapDispatchToProps = {
  checkGame,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusContainer);
