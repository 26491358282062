import styled from "styled-components/macro";

const StyledLeagueEntryShortName = styled.span`
  padding: 0.2rem ${(props) => props.theme.space[1]};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  color: #fff;
`;

export default StyledLeagueEntryShortName;
