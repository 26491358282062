import React from "react";
import { propTypeDraftStatus } from "../../reducers/draftChoices";
import DuringDraftStatus from "./DuringDraftStatus";
import PreDraftStatus from "./PreDraftStatus";
import PostDraftStatus from "./PostDraftStatus";

const DraftStatus = ({ status, ...rest }) => {
  if (status.stage === "pre") {
    return <PreDraftStatus status={status} {...rest} />;
  }
  if (status.stage === "during") {
    return <DuringDraftStatus status={status} {...rest} />;
  }
  return <PostDraftStatus status={status} {...rest} />;
};

DraftStatus.propTypes = {
  status: propTypeDraftStatus.isRequired,
};

export default DraftStatus;
