import Bench from "./Bench";
import Pitch from "./Pitch";
import PitchFormation from "./PitchFormation";

const PitchView = ({ ...props }) => (
  <Pitch sponsor="default">
    <PitchFormation {...props} />
    <Bench {...props} />
  </Pitch>
);

export default PitchView;
