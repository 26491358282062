import { propTypeElementEventStat } from "../reducers/elements";
import { propTypeTeamEventFixtures } from "../store/fixtures/slice";
import { fixtureSuffix } from "../utils/fixtures";

const PitchElementData = ({ elementStats, teamEventFixtures, teamsById }) => {
  let explainString = "";

  if (teamEventFixtures && teamEventFixtures.length) {
    if (teamEventFixtures.filter((f) => f.started).length && elementStats) {
      explainString = `${elementStats.stats.total_points}`;
    }

    const unstartedFixtures = teamEventFixtures.filter((f) => !f.started);
    if (unstartedFixtures.length) {
      const explainFixtures = unstartedFixtures
        .map(
          (f) =>
            `${teamsById[f.opponent].short_name} ${fixtureSuffix(
              f.id,
              f.isHome,
            )}`,
        )
        .join(", ");
      if (explainString !== "") {
        explainString += `, ${explainFixtures}`;
      } else {
        explainString = explainFixtures;
      }
    }
  }
  return explainString;
};

PitchElementData.propTypes = {
  elementStats: propTypeElementEventStat,
  teamEventFixtures: propTypeTeamEventFixtures,
};

PitchElementData.defaultProps = {
  elementStats: null,
  teamEventFixtures: null,
};

export default PitchElementData;
