import styled from "styled-components/macro";

export const StyledAbbr = styled.abbr`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ::after {
      content: attr(title);
    }
  }
`;

export const AbbrShort = styled.span`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: none;
  }
`;
