import { ellipsis } from "polished";
import styled from "styled-components/macro";

export const StyledTransactionElement = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
  }
`;

export const TransactionElementStatus = styled.div`
  flex: 1 1 20%;
`;

export const ElementBlock = styled.div`
  ${ellipsis()};
  flex: 1 1 80%;
  padding: 0.4rem ${(props) => props.theme.space[2]};
`;
