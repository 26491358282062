import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {
  CountdownTimer,
  CountdownTimerDivider,
  CountdownTimerLabel,
  CountdownTimerUnit,
  CountdownTimerValue,
} from "./styles";

class Countdown extends React.Component {
  static stateFromDuration(duration) {
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  constructor(props) {
    super(props);
    this.endMoment = moment(props.startDate);
    this.state = Countdown.stateFromDuration(this.durationToStartDate());
    this.setTimer();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.endMoment = moment(nextProps.startDate);
    this.setState(Countdown.stateFromDuration(this.durationToStartDate()));
    clearInterval(this.timer);
    this.setTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setTimer() {
    this.timer = setInterval(() => {
      this.setState(Countdown.stateFromDuration(this.durationToStartDate()));
    }, 1000);
  }

  durationToStartDate() {
    return moment.duration(this.endMoment.diff(moment.utc()));
  }

  render() {
    return (
      <CountdownTimer>
        <CountdownTimerUnit>
          <CountdownTimerValue>
            {Math.max(this.state.days, 0)}
          </CountdownTimerValue>
          <CountdownTimerLabel>Days</CountdownTimerLabel>
        </CountdownTimerUnit>
        <CountdownTimerDivider>:</CountdownTimerDivider>
        <CountdownTimerUnit>
          <CountdownTimerValue>
            {Math.max(this.state.hours, 0)}
          </CountdownTimerValue>
          <CountdownTimerLabel>Hrs</CountdownTimerLabel>
        </CountdownTimerUnit>
        <CountdownTimerDivider>:</CountdownTimerDivider>
        <CountdownTimerUnit>
          <CountdownTimerValue>
            {Math.max(this.state.minutes, 0)}
          </CountdownTimerValue>
          <CountdownTimerLabel>Mins</CountdownTimerLabel>
        </CountdownTimerUnit>
        <CountdownTimerDivider>:</CountdownTimerDivider>
        <CountdownTimerUnit>
          <CountdownTimerValue>
            {Math.max(this.state.seconds, 0)}
          </CountdownTimerValue>
          <CountdownTimerLabel>Secs</CountdownTimerLabel>
        </CountdownTimerUnit>
      </CountdownTimer>
    );
  }
}

Countdown.propTypes = {
  startDate: PropTypes.string.isRequired,
};

export default Countdown;
