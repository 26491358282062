import React from "react";
import { propTypeEntry } from "../../reducers/entries";
import Media from "../Media";

const EntryBar = ({ entry }) => (
  <>
    {entry.region_name && entry.region_code_short ? (
      <Media
        img={
          <img
            alt={entry.region_name}
            src={`/img/flags/${entry.region_code_short}.gif`}
          />
        }
      >
        <>
          <div className="ism-entry-bar__name">
            {entry.player_first_name} {entry.player_last_name}
          </div>
          <div className="ism-entry-bar__team">{entry.name}</div>
        </>
      </Media>
    ) : (
      <>
        <div className="ism-entry-bar__name">
          {entry.player_first_name} {entry.player_last_name}
        </div>
        <div className="ism-entry-bar__team">{entry.name}</div>
      </>
    )}
  </>
);

EntryBar.propTypes = {
  entry: propTypeEntry.isRequired,
};

export default EntryBar;
