const getOrdinal = (number) => {
  const ordinalByNumber = {
    1: "st",
    2: "nd",
    3: "rd",
  };
  const suffix = ordinalByNumber[number] || "th";
  return `${number}${suffix}`;
};

export default getOrdinal;
