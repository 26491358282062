import { createSelector } from "reselect";
import {
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  WATCHLIST_ADD,
  WATCHLIST_REMOVE,
  WATCHLIST_RESTRICT,
  WATCHLIST_UPDATE,
} from "../actions/ActionTypes";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.watchlist;
export const l2g = (local) => ({ watchlist: local });

const reducer = (state = [], action) => {
  switch (action.type) {
    case WATCHLIST_ADD: {
      if (state.indexOf(action.value) === -1) {
        return [...state, action.value];
      }
      return state;
    }

    case WATCHLIST_REMOVE: {
      const index = state.indexOf(action.value);
      if (index > -1) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
      return state;
    }

    case WATCHLIST_RESTRICT: {
      // Restrict watchlist to a list of allowed elements. Only return
      // newState if lengths are different
      const newState = state.filter((e) => action.allowed.indexOf(e) > -1);
      return state.length === newState.length ? state : newState;
    }

    case WATCHLIST_UPDATE:
      // Return existing state if we are sure they are exactly the same
      if (
        action.data.length === state.length &&
        action.data.every((v, i) => v === state[i])
      ) {
        return state;
      }
      return action.data;

    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS:
      return [];

    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getWatchlist = (state) => g2l(state);

export const getWatchlistAPI = createSelector(getWatchlist, (watchlist) =>
  watchlist.map((id, index) => ({ element: id, priority: index + 1 })),
);

export const getWatchlistPriorities = createSelector(
  getWatchlist,
  (watchlist) =>
    watchlist.reduce((priorityMap, id, index) => {
      Object.assign(priorityMap, { [id]: index + 1 });
      return priorityMap;
    }, {}),
);
