import React from "react";
import ElementStatus from "../ElementStatus";
import { useDispatch } from "react-redux";
import { elementDialogShow } from "../../actions/Element";
import { IElementStatusButton } from "./types";

const ElementStatusButton: React.FC<IElementStatusButton> = ({
  className = "ism-table--el__status-link",
  elementId,
}) => {
  const dispatch = useDispatch();
  return (
    <button
      className={className}
      onClick={() => dispatch(elementDialogShow(elementId))}
      type="button"
    >
      <ElementStatus elementId={elementId} />
    </button>
  );
};

export default ElementStatusButton;
