import PropTypes from "prop-types";
import React from "react";
import {
  DraftCountdownBar,
  DraftCountdownHeading,
  DraftCountdownLevel,
  DraftCountdownLevelWrap,
  DraftCountdownTimer,
} from "./styles";

class DraftCountdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.createStateFromSeconds(props.secondsToPick);
    this.startCountdown(props.secondsToPick, props.pickTimeLimit);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.nextIndex !== nextProps.nextIndex ||
      this.props.secondsToPick !== nextProps.secondsToPick
    ) {
      this.clear();
      this.setState(this.createStateFromSeconds(nextProps.secondsToPick));
      this.startCountdown(nextProps.secondsToPick, nextProps.pickTimeLimit);
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  clear() {
    if (this.countdown) {
      clearInterval(this.countdown);
    }
  }

  createStateFromSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainderSeconds = seconds % 60;
    return {
      minutes,
      seconds: remainderSeconds,
      shouldAnimate: seconds < this.props.secondsToPick,
    };
  }

  startCountdown(seconds, pickTime) {
    const end = Date.now() + seconds * 1000;
    this.countdown = setInterval(() => {
      const secondsLeft = Math.round((end - Date.now()) / 1000);
      // check if we should stop it
      if (secondsLeft < 0) {
        clearInterval(this.countdown);
        return;
      }
      this.setState(this.createStateFromSeconds(secondsLeft));
    }, 1000);
    this.percentTimeLeft = `${(seconds / pickTime) * 100}%`;
  }

  render() {
    const minutes = `${this.state.minutes < 10 ? "0" : ""}${
      this.state.minutes
    }`;
    const seconds = `${this.state.seconds < 10 ? "0" : ""}${
      this.state.seconds
    }`;
    const isMyPick = this.props.isMyPick;
    const styles = {
      width: this.percentTimeLeft,
      animationDuration: this.state.shouldAnimate
        ? `${this.props.secondsToPick}s`
        : null,
    };
    return (
      <div>
        {isMyPick ? (
          <DraftCountdownHeading isMyPick={isMyPick}>
            Pick Now!
          </DraftCountdownHeading>
        ) : (
          <DraftCountdownHeading>Pick Countdown</DraftCountdownHeading>
        )}
        <DraftCountdownBar>
          <DraftCountdownTimer>
            {minutes}:{seconds}
          </DraftCountdownTimer>
          <DraftCountdownLevelWrap>
            <DraftCountdownLevel
              isMyPick={isMyPick}
              isAnimating={this.state.shouldAnimate}
              style={styles}
            />
          </DraftCountdownLevelWrap>
        </DraftCountdownBar>
      </div>
    );
  }
}

DraftCountdown.propTypes = {
  isMyPick: PropTypes.bool,
  nextIndex: PropTypes.number,
  pickTimeLimit: PropTypes.number.isRequired,
  secondsToPick: PropTypes.number.isRequired,
};

DraftCountdown.defaultProps = {
  isMyPick: false,
  nextIndex: null,
};

export default DraftCountdown;
