import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { ADD_ELEMENT_STATS } from "../actions/ActionTypes";

// Utility functions to transform local state shape to global and visa versa
export const g2l = (global) => global.elementStats;
export const l2g = (local) => ({ elementStats: local });

const byName = (state = {}, action) => {
  switch (action.type) {
    case ADD_ELEMENT_STATS: {
      const newState = { ...state };
      action.data.forEach((stat) => {
        newState[stat.name] = stat;
      });
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byName,
});

// Selectors
export const getElementStatsByName = (state) => g2l(state).byName;

export const getElementStat = (state, name) =>
  getElementStatsByName(state)[name];

export const getElementStats = (state) => {
  const statsByName = getElementStatsByName(state);
  return Object.keys(statsByName).map((name) => statsByName[name]);
};

export const getElementStatsMatch = createSelector(getElementStats, (stats) =>
  stats
    .filter((stat) => stat.is_match_stat)
    .sort((a, b) => a.match_stat_order - b.match_stat_order),
);

// PropTypes
export const propTypeElementStat = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  abbreviation: PropTypes.string,
  is_match_stat: PropTypes.bool,
  sort: PropTypes.string,
});
