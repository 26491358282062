import { useSelector } from "react-redux";
import { getFilteredDraftedChoices } from "../../../reducers/draftChoices";
import { getSortStat } from "../../../reducers/elements";
import Abbr from "../../Abbr/Abbr";
import { ElementTable } from "../../ElementTable";
import { VisuallyHidden } from "../../styles";
import { SquadTableWrapper } from "../../squad/styles";
import DraftedChoice from "./DraftedChoice";
import {
  DraftedElement,
  DraftedEvent,
  DraftedPick,
  DraftedPlayer,
  DraftedSortStat,
  DraftedStatus,
} from "./styles";

const Drafted: React.FC = () => {
  const choices = useSelector(getFilteredDraftedChoices);
  const sortStat = useSelector(getSortStat);
  return (
    <SquadTableWrapper>
      <ElementTable>
        <thead>
          <tr>
            <DraftedStatus>
              <VisuallyHidden>Info</VisuallyHidden>
            </DraftedStatus>
            <DraftedElement>Player</DraftedElement>
            <DraftedSortStat>
              {sortStat && (
                <Abbr
                  longName={sortStat.label}
                  shortName={sortStat.abbreviation}
                />
              )}
            </DraftedSortStat>
            <DraftedEvent>Round</DraftedEvent>
            <DraftedPick>Pick</DraftedPick>
            <DraftedPlayer>Manager</DraftedPlayer>
          </tr>
        </thead>
        <tbody>
          {choices.map((c) => (
            <DraftedChoice
              key={c.id}
              choice={c}
              elementId={c.element}
              sortStat={sortStat}
            />
          ))}
        </tbody>
      </ElementTable>
    </SquadTableWrapper>
  );
};

export { Drafted as DraftedTest };

export default Drafted;
