import PropTypes from "prop-types";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import {
  DELETE_ENTRY_SUCCESS,
  DELETE_LEAGUE_ENTRY_SUCCESS,
  DELETE_LEAGUE_SUCCESS,
  FETCH_LEAGUE_ENTRIES_SUCCESS,
  LEAGUE_ENTRIES_CHANGED,
} from "../actions/ActionTypes";
import { getActiveEntry } from "./entries";
import { getActiveLeague } from "./leagues";

const byLeagueId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LEAGUE_ENTRIES_SUCCESS:
    case LEAGUE_ENTRIES_CHANGED:
      return {
        ...state,
        [action.data.league.id]: action.data.entries,
      };
    case DELETE_ENTRY_SUCCESS:
    case DELETE_LEAGUE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.data.league];
      return newState;
    }

    case DELETE_LEAGUE_ENTRY_SUCCESS: {
      const newState = { ...state };
      newState[action.data.league] = newState[action.data.league].filter(
        (e) => e.entry_id !== action.data.entry,
      );
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  byLeagueId,
});

// Selectors
export const getAllLeagueEntries = (state) => state.leagueEntries;

export const getActiveLeagueEntries = createSelector(
  getAllLeagueEntries,
  getActiveLeague,
  (all, league) => all.byLeagueId[league.id],
);

export const getActiveLeagueEntry = createSelector(
  getActiveLeagueEntries,
  getActiveEntry,
  (all, entry) => {
    if (all && entry) {
      const finds = all.filter((le) => le.entry_id === entry.id);
      return finds.length ? finds[0] : undefined;
    }
    return undefined;
  },
);

export const getActiveLeagueEntriesById = createSelector(
  getActiveLeagueEntries,
  (entries) =>
    entries.reduce(
      (memo, entry) => Object.assign(memo, { [entry.id]: entry }),
      {},
    ),
);

export const getActiveLeagueEntriesByEntryId = createSelector(
  getActiveLeagueEntries,
  (entries) =>
    entries.reduce(
      (memo, entry) => Object.assign(memo, { [entry.entry_id]: entry }),
      {},
    ),
);

export const getLeagueEntries = (state, id) =>
  getAllLeagueEntries(state).byLeagueId[id];

export const isEntryIdInActiveLeague = (state, entryId) => {
  const entries = getActiveLeagueEntries(state);
  if (!entries) return false;
  return entries.some((e) => e.entry_id === entryId);
};

// PropTypes
export const propTypeLeagueEntry = PropTypes.shape({
  entry_id: PropTypes.number,
  entry_name: PropTypes.string,
  id: PropTypes.number,
  joined_time: PropTypes.string,
  player_first_name: PropTypes.string,
  player_last_name: PropTypes.string,
  short_name: PropTypes.string,
  waiver_pick: PropTypes.number,
});
