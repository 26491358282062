import styled from "styled-components/macro";

export const PreDraft = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
  }
`;

export const PreDraftUnit = styled.div`
  margin: ${(props) => props.theme.space[4]} 0;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1;
  }
`;

export const PreDraftCopy = styled.p`
  margin-bottom: 0.6rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-right: ${(props) => props.theme.space[2]};
  }
`;
