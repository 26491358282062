import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const HelmetHead = ({ description, title }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
);

HelmetHead.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

HelmetHead.defaultProps = {
  description:
    "Fantasy Premier League Draft 2024/25. Free to play fantasy football game, set up your team at the official Premier League site.",
  title: "Fantasy Premier League Draft 2024/25",
};

export default HelmetHead;
