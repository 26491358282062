import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { watchlistChange } from "../../../actions/Watchlist";
import { getAvailableFilteredWatchedElements } from "../../../reducers/transactions";
import { getWatchlistPriorities } from "../../../reducers/watchlist";
import { IElement } from "../../../store/elements/types";
import DragHandle from "../../sortable/DragHandle";
import Row from "../../sortable/Row";
import SortableRow from "../../sortable/SortableRow";
import { IWatchlistAction } from "../types";
import WatchlistCells from "./WatchlistCells";

const WatchlistRows: React.FC<IWatchlistAction> = ({
  actionDisabled,
  actionLabel,
  actionMe,
}) => {
  const elements: IElement[] = useSelector(getAvailableFilteredWatchedElements);
  const priorities = useSelector(getWatchlistPriorities);
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState<UniqueIdentifier>(0);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragEndEvent) => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const oldPriority = priorities[active.id];
      const newPriority = priorities[over.id];

      dispatch(watchlistChange(oldPriority, newPriority));
    }
  };

  return (
    <DndContext
      accessibility={{ container: document.body }}
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement, restrictToVerticalAxis]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={elements} strategy={verticalListSortingStrategy}>
        <tbody>
          {elements.map((element) => (
            <SortableRow
              changeAction={watchlistChange}
              id={element.id}
              key={element.id}
              maxPriority={elements.length}
              priority={priorities[element.id]}
            >
              <WatchlistCells
                id={element.id}
                actionDisabled={actionDisabled}
                actionLabel={actionLabel}
                actionMe={actionMe}
              />
            </SortableRow>
          ))}
        </tbody>
      </SortableContext>
      <DragOverlay wrapperElement="tbody">
        {activeId ? (
          <Row
            changeAction={watchlistChange}
            dragHandle={<DragHandle isDragging />}
            id={activeId}
            isOverlay={true}
            maxPriority={elements.length}
            priority={priorities[activeId]}
          >
            <WatchlistCells
              id={activeId}
              actionDisabled={actionDisabled}
              actionLabel={actionLabel}
              actionMe={actionMe}
            />
          </Row>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default WatchlistRows;
