import React from "react";
import { useSelector } from "react-redux";
import { getElement, getSortStat } from "../../../../reducers/elements";
import { RootState } from "../../../../reducers/rootReducer";
import { getTeamsById } from "../../../../reducers/teams";
import { IElement, ISortStat } from "../../../../store/elements/types";
import { getNext3EventsFixturesByTeam } from "../../../../store/fixtures/slice";
import { ITeamsById } from "../../../../store/teams/types";
import { fixtureSuffix } from "../../../../utils/fixtures";
import ElementInTable from "../../../ElementInTable";
import ElementStatusButton from "../../../ElementStatusButton";
import { TeamShortName } from "../../../ElementTable";
import WatchlistButton from "../../../WatchlistButton";
import ElementAction from "../../../squad/ElementAction";
import {
  ActionCell,
  FixtureCell,
  PrimaryCell,
  SortCell,
  StatusCell,
} from "./styles";
import { IWatchlistCells } from "./types";

const WatchlistCells: React.FC<IWatchlistCells> = ({
  actionMe,
  actionLabel,
  actionDisabled,
  id,
}) => {
  const element: IElement = useSelector((state: RootState) =>
    getElement(state, Number(id)),
  );
  const sortStat: ISortStat = useSelector(getSortStat);
  const next3Fixtures = useSelector(getNext3EventsFixturesByTeam);
  const teamsById: ITeamsById = useSelector(getTeamsById);
  return (
    <>
      <StatusCell>
        <ElementStatusButton elementId={element.id} />
      </StatusCell>
      <PrimaryCell>
        <ElementInTable elementId={element.id} />
      </PrimaryCell>
      <SortCell>{sortStat && element[sortStat.name]}</SortCell>
      {next3Fixtures.map((data) => (
        <FixtureCell key={data.event.id}>
          <ul key={element.id}>
            {data.fixtures[element.team].map((f) => (
              <li key={f.id}>
                <TeamShortName>
                  {teamsById[f.opponent].short_name}
                </TeamShortName>
                {fixtureSuffix(f.id, f.isHome)}
              </li>
            ))}
          </ul>
        </FixtureCell>
      ))}
      <ActionCell>
        <WatchlistButton elementId={element.id} />
      </ActionCell>
      <ActionCell>
        <ElementAction
          actionMe={actionMe}
          actionLabel={actionLabel}
          elementId={element.id}
          isDisabled={actionDisabled}
        />
      </ActionCell>
    </>
  );
};

export default WatchlistCells;
