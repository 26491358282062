import { propTypeEntry } from "../../reducers/entries";
import BoldLink, { BoldLinkWrap } from "../BoldLink";
import {
  DataList,
  DataListHeadingTransaction,
  DataListItem,
  DataListValueTransaction,
} from "../DataList";
import Panel from "../Panel";

const EntryTransactions = ({ entry }) => (
  <div>
    <Panel headingText="Transactions">
      <DataList>
        <DataListItem>
          <DataListHeadingTransaction>
            Gameweek Transactions
          </DataListHeadingTransaction>
          <DataListValueTransaction>
            {entry.transactions_event}
          </DataListValueTransaction>
        </DataListItem>
        <DataListItem>
          <DataListHeadingTransaction>
            Total Transactions
          </DataListHeadingTransaction>
          <DataListValueTransaction>
            {entry.transactions_total}
          </DataListValueTransaction>
        </DataListItem>
      </DataList>
      <BoldLinkWrap>
        <BoldLink to={`/entry/${entry.id}/transactions`}>
          View Transactions
        </BoldLink>
      </BoldLinkWrap>
    </Panel>
  </div>
);

EntryTransactions.propTypes = {
  entry: propTypeEntry.isRequired,
};

export default EntryTransactions;
