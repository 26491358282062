import { hideVisually, size } from "polished";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components/macro";
import theme from "../theme";
import "./date-picker.css";
import { Field } from "redux-form";

export const StyledForm = styled.form`
  margin-right: ${({ theme }) => theme.space[4]};
  margin-left: ${({ theme }) => theme.space[4]};
`;

export const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
`;

export const Legend = styled.legend`
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[4]};
`;

// Use FieldWrap instead because it is ported from FPL
export const FieldWrap = styled.div`
  margin-bottom: ${theme.space[4]};
`;

interface ILabelStyles {
  hideLabel?: boolean;
  weight?: "light" | null;
}

export const FieldLabel = styled.span<ILabelStyles>`
  display: block;
  margin-bottom: ${({ theme }) => theme.space[1]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.5rem;
  cursor: pointer;

  ${(props) =>
    props.weight === "light" &&
    css`
      color: white;
    `}

  ${(props) =>
    props.hideLabel &&
    css`
      ${hideVisually}
    `}
`;

export const Hint = styled.p`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.space[1]};
`;

const fieldStyles = css`
  display: block;
  width: 100%;
  padding: 1.4rem ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 16px;
`;

export const Input = styled.input`
  ${fieldStyles}
`;

const StyledDatePicker = styled(DatePicker)`
  ${fieldStyles}
`;

export const NumberInput = styled.input`
  width: 40px;
  padding: ${(props) => props.theme.space[1]};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  border-radius: 0;
  background-color: ${(props) => props.theme.colors.lightGrey};
  font-size: ${(props) => props.theme.fontSizes[0]};

  &:hover,
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.darkPink};
  }
`;

export const SelectWrap = styled.div`
  position: relative;
  z-index: ${theme.zIndex.select.wrap};
  border: 1px solid ${theme.colors.darkGrey};
  background-color: white;

  ::after {
    content: "";
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11"><path fill="%23ff2882" fill-rule="evenodd" d="M333.611119,13.8033162 L323.818866,13.8033162 L323.818866,4.01855387 C323.818866,3.63156468 323.508977,3.3137085 323.116451,3.3137085 L321.016122,3.3137085 C320.630468,3.3137085 320.313708,3.62466907 320.313708,4.01855387 L320.313708,13.8033162 C320.313708,15.7450898 321.876915,17.3137085 323.81199,17.3137085 L333.611295,17.3137085 C333.996949,17.3137085 334.313708,17.0027481 334.313708,16.6088633 L334.313708,14.5012617 C334.313708,14.1142725 334.003821,13.8033162 333.611295,13.8033162 L333.611119,13.8033162 Z" transform="rotate(-45 159.157 388.967)"/></svg>')
      no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 20px;
    height: 12px;
    background-size: contain;
  }
`;

export const Select = styled.select`
  ${fieldStyles}
  position: relative;
  padding-right: 4rem;
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  z-index: ${theme.zIndex.select.select};
  cursor: pointer;

  ::-ms-expand {
    display: none;
  }
`;

const SearchWrap = styled.div`
  position: relative;
`;

const SearchInput = styled(Input)`
  -webkit-appearance: textfield;
`;

const SearchIcon = styled.span`
  ${size("30px")}
  content: "";
  background-size: contain;
  position: absolute;
  right: ${(props) => props.theme.space[2]};
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path fill="%23ff2882" fill-rule="evenodd" d="M336.970755,56.0679674 C341.317716,50.7637755 341.016717,42.9027411 336.068121,37.9544319 C330.794852,32.681856 322.220113,32.681856 316.954951,37.9544319 C311.681683,43.2270077 311.681683,51.7929168 316.954951,57.0650873 C319.591545,59.7013347 323.051561,61.0155064 326.511536,61.0155064 C329.551936,61.0155064 332.592336,60.0021731 335.070465,57.9676025 L340.707772,63.6041677 C340.969055,63.8654172 341.317433,64 341.657917,64 C341.998377,64 342.34675,63.8654172 342.608062,63.6041677 C343.130646,63.0816525 343.130646,62.2266829 342.608062,61.7041677 L336.970755,56.0679674 Z M318.847134,55.1654522 C314.627059,50.9459323 314.627059,44.0820178 318.847134,39.8547966 C320.953239,37.7489684 323.724341,36.6881301 326.495564,36.6881301 C329.266747,36.6881301 332.03801,37.7410644 334.152102,39.8547966 C338.372177,44.0743165 338.372177,50.938231 334.152102,55.1577509 L334.144185,55.1656711 C329.939919,59.385191 323.067399,59.385191 318.847324,55.1656711 L318.847134,55.1654522 Z" transform="translate(-313 -34)"/></svg>');
`;

interface IForm {
  isInline?: boolean;
}

export const FormInfo = styled.div``;

export const FormControl = styled.div``;

export const Form = styled.div<IForm>`
  padding: ${(props) => props.theme.space[2]};
  ${(props) =>
    props.isInline &&
    css`
      ${FormControl} {
        display: inline-block;
      }

      ${FormInfo} {
        display: inline-block;
        margin-right: ${(props) => props.theme.space[2]};
      }
    `}
`;

export const FormFilters = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: flex;
  }
`;

export const FormFilter = styled.div`
  margin: ${(props) => props.theme.space[1]};

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex: 1;
  }
`;

export const ReduxFormSelect = styled(Field)`
  ${fieldStyles}
  position: relative;
  padding-right: 4rem;
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  z-index: ${theme.zIndex.select.select};
  cursor: pointer;

  ::-ms-expand {
    display: none;
  }
`;

interface ILabelHintProps {
  hint?: React.ReactNode;
  id: string;
  label: React.ReactNode;
}

type LabelHintProps = ILabelStyles & ILabelHintProps;

export const LabelHint: React.FC<LabelHintProps> = ({
  hideLabel,
  hint,
  id,
  label,
  weight,
}) => (
  <label htmlFor={id}>
    <FieldLabel hideLabel={hideLabel} weight={weight}>
      {label}
    </FieldLabel>
    {hint && <Hint>{hint}</Hint>}
  </label>
);

interface ISearchField {
  input: IInputFieldInput;
  maxLength: number;
}

type SearchFieldProps = LabelHintProps & IFieldProps & ISearchField;

export const SearchField: React.FC<SearchFieldProps> = ({
  hint,
  id,
  input,
  label,
  maxLength,
  placeholder = "Search",
  weight,
  ...rest
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} weight={weight} />
    <SearchWrap>
      <SearchInput
        {...input}
        type="search"
        id={id}
        maxLength={maxLength}
        placeholder={placeholder}
        {...rest}
      />
      <SearchIcon />
    </SearchWrap>
  </>
);

interface IMeta {
  error: string;
  touched: boolean;
}

interface IFieldProps {
  disabled?: boolean;
  id: string;
  meta: IMeta;
  placeholder?: string;
  // Commenting out because these props are on ILabelHintProps.
  // Do we ever have a Field without a LabelHint component?
  // hint?: string;
  // label: React.ReactNode;
}

interface IDateInput {
  name: string;
  onChange: () => void;
  value: any;
}

interface IDateField {
  dateFormat: string;
  filterTime: (date: Date) => boolean;
  input: IDateInput;
  maxDate?: Date;
  minDate?: Date;
  showTimeSelect?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
}

type DateProps = IFieldProps & ILabelHintProps & IDateField;

interface IInputFieldInput {
  name: string;
  value: string;
}

interface IInputField {
  // className?: string;
  hideInfo: boolean;
  hideLabel: boolean;
  input: IInputFieldInput;
  maxLength: number;
  name: string;
  type: string;
  value?: string;
}

type InputProps = IFieldProps & ILabelHintProps & IInputField;

const DateField: React.FC<DateProps> = ({
  input,
  id,
  filterTime,
  label,
  meta: { touched, error },
  hint,
  placeholder,
  minDate,
  maxDate,
  showTimeSelect = false,
  timeFormat = "",
  timeIntervals = 0,
  dateFormat,
  disabled,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    {disabled ? (
      <Input {...input} value={input.value} disabled={disabled} />
    ) : (
      <StyledDatePicker
        calendarClassName="ism-datepicker"
        dateFormat={dateFormat}
        filterTime={filterTime}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals || undefined}
        maxDate={maxDate}
        minDate={minDate}
        onChange={input.onChange}
        placeholderText={placeholder}
        selected={input.value || null}
      />
    )}
    {touched && error && <Hint>{error}</Hint>}
  </>
);

const InputField: React.FC<InputProps> = ({
  disabled,
  hideLabel,
  hint,
  input,
  id,
  label,
  maxLength,
  meta: { touched, error },
  placeholder,
  type,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} hideLabel={hideLabel} />
    <Input
      {...input}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      placeholder={placeholder}
      type={type}
      value={input.value}
    />
    {touched && error && <Hint>{error}</Hint>}
  </>
);

const sharedCheckboxStyles = css`
  ${hideVisually()};

  :hover + span::before {
    border-color: ${({ theme }) => theme.colors.darkGrey};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"><path d="M28.051 68.625c-1.199 0-2.324-.449-3.149-1.273L5.254 47.102c-1.875-1.727-1.949-4.648-.149-6.523 1.727-1.875 4.649-1.949 6.524-.148l14.398 10.273c.825.75 2.102.602 2.774-.301L62.926 8.25c1.5-2.023 4.426-2.477 6.449-.898 2.023 1.5 2.477 4.425.898 6.449L31.727 66.75c-.825 1.051-2.024 1.727-3.301 1.801-.153.074-.227.074-.375.074z" fill="%23E8E8E8"/></svg>');
  }

  :focus + span {
    outline: -webkit-focus-ring-color auto 5px;
  }

  :checked + span::before {
    background-color: ${({ theme }) => theme.colors.pink};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"><path d="M28.051 68.625c-1.199 0-2.324-.449-3.149-1.273L5.254 47.102c-1.875-1.727-1.949-4.648-.149-6.523 1.727-1.875 4.649-1.949 6.524-.148l14.398 10.273c.825.75 2.102.602 2.774-.301L62.926 8.25c1.5-2.023 4.426-2.477 6.449-.898 2.023 1.5 2.477 4.425.898 6.449L31.727 66.75c-.825 1.051-2.024 1.727-3.301 1.801-.153.074-.227.074-.375.074z" fill="white" /></svg>');
  }
`;

const Checkbox = styled.input`
  ${sharedCheckboxStyles}
`;

export const ReduxFormCheckbox = styled(Field)`
  ${sharedCheckboxStyles}
`;

export const CheckboxText = styled.span`
  display: inline-block;
  position: relative;
  padding: 6px 0 6px 42px;
  line-height: 1.6;
  cursor: pointer;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    border: 2px solid #e5e5e5;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
`;

export const LightForm = styled.form`
  padding: 0 ${(props) => props.theme.space[4]};
  color: white;

  ${CheckboxText}::before {
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    background-color: #fff;
  }
`;

interface ICheckboxProps {
  id: string;
  input: any;
  isMini: boolean;
  label: string;
  meta: IMeta;
}

const CheckboxField: React.FC<ICheckboxProps> = ({
  id,
  input,
  label,
  meta: { touched, error },
}) => (
  <div>
    <label htmlFor={id}>
      <Checkbox {...input} type="checkbox" id={id} />
      <CheckboxText>{label}</CheckboxText>
    </label>
    {touched && error && <Hint>{error}</Hint>}
  </div>
);

interface ISelectInput {
  name: string;
  value: string;
}

interface ISelectDescription {
  input: ISelectInput;
}

type SelectDescriptionProps = IFieldProps & LabelHintProps & ISelectDescription;

const SelectDesciptionField: React.FC<SelectDescriptionProps> = ({
  children,
  hint,
  id,
  input,
  label,
  meta: { error, touched },
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} />
    <SelectWrap>
      <Select {...input} id={id}>
        {children}
      </Select>
    </SelectWrap>
    {touched && error && <Hint>{error}</Hint>}
    {hint && <Hint>{hint}</Hint>}
  </>
);

interface ISelectField {
  className?: string;
  hideLabel: boolean;
  input: ISelectInput;
  isInline: boolean;
  theme?: string;
}

type SelectProps = ILabelHintProps & IFieldProps & ISelectField & ILabelStyles;

const SelectField: React.FC<SelectProps> = ({
  children,
  disabled,
  hint,
  id,
  input,
  label,
  meta: { error, touched },
  weight,
}) => (
  <>
    <LabelHint id={id} label={label} hint={hint} weight={weight} />
    <SelectWrap>
      <Select {...input} id={id} disabled={disabled}>
        {children}
      </Select>
    </SelectWrap>
    {touched && error && <Hint>{error}</Hint>}
  </>
);

export {
  CheckboxField,
  DateField,
  InputField,
  SelectDesciptionField,
  SelectField,
};
