import {
  DRAFT_ELEMENT,
  DRAFT_STARTED,
  FETCH_DRAFT_CHOICES_SUCCESS,
  WATCHLIST_RESTRICT,
} from "./ActionTypes";
import { fetchElementStatusSuccess } from "./ElementStatus";
import { watchlistUpdate } from "./Watchlist";
import { getDraftableWatchlist } from "../reducers/draftChoices";
import { getActiveLeague } from "../reducers/leagues";
import { getActiveEntryId } from "../reducers/player";
import { fetchGet, fetchPost, handleUnexpectedError } from "../helpers";

export const draftElement = (id, element) => ({
  type: DRAFT_ELEMENT,
  data: {
    id,
    element,
  },
});

export const draftStarted = (league) => ({
  type: DRAFT_STARTED,
  data: { league: league.id },
});

export const fetchDraftChoicesSuccess = (data, league, entryId) => ({
  type: FETCH_DRAFT_CHOICES_SUCCESS,
  data: {
    leagueId: league.id,
    choices: data.choices,
    idle: data.idle,
    entryId,
  },
});

export const draftDataFetch = () => (dispatch, getState) => {
  const league = getActiveLeague(getState());
  const entryId = getActiveEntryId(getState());
  Promise.all([
    fetchGet(`/api/draft/${league.id}/choices`),
    fetchGet(`/api/watchlist/${entryId}`),
  ]).then(
    (d) => {
      dispatch([
        fetchDraftChoicesSuccess(d[0].data, league, entryId),
        fetchElementStatusSuccess(d[0].data.element_status, league),
        watchlistUpdate(d[1].data),
      ]);
      dispatch({
        type: WATCHLIST_RESTRICT,
        allowed: getDraftableWatchlist(getState()),
      });
    },
    (e) => handleUnexpectedError(e, dispatch),
  );
};

export const draftOnline = () => (dispatch, getState) => {
  const league = getActiveLeague(getState());
  const entryId = getActiveEntryId(getState());
  const url = `/api/entry/${entryId}/online`;
  return fetchGet(url).then(
    (d) => dispatch(fetchDraftChoicesSuccess(d.data, league, entryId)),
    (e) => handleUnexpectedError(e, dispatch),
  );
};

export const draftStart = () => (dispatch, getState) => {
  const league = getActiveLeague(getState());
  const url = `/api/draft/${league.id}/start`;
  return fetchPost(url).then(
    () => {},
    (e) => handleUnexpectedError(e, dispatch),
  );
};
