import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as ChevronRight } from "../../img/icons/chevron-right.svg";

export const StyledChevron = styled(ChevronRight)`
  ${size(16)}
`;

export const CollapsibleHeading = styled.h5``;

export const CollapsibleButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  border: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: #333;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: inherit;
  text-align: left;
  transition: 0.2s;

  :hover,
  :focus {
    background-color: ${(props) => props.theme.colors.darkPink};
    color: #fff;
  }

  &[aria-expanded="true"] {
    ${StyledChevron} {
      transform: rotate(90deg);
      fill: #fff;
    }
  }
`;

export const CollapsibleContent = styled.div`
  padding: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;

export const IconWrap = styled.div`
  padding: ${(props) => props.theme.space[2]};
`;

export const HeadingText = styled.span`
  padding: ${(props) => props.theme.space[2]};
`;
