import { useSelector } from "react-redux";
import { getElement } from "../../reducers/elements";
import { RootState } from "../../reducers/rootReducer";
import { getSetting } from "../../reducers/settings";
import { getTeam } from "../../reducers/teams";
import Shirt from "../Shirt";
import { IElementShirt } from "./types";

export const getElementShirtPath = (
  elementType: number,
  teamCode: number,
  shirtMode: string,
) => {
  const suffix = elementType === 1 ? `_${elementType}` : "";
  return `${process.env.PUBLIC_URL}/img/shirts/${shirtMode}/shirt_${teamCode}${suffix}`;
};

const ElementShirt = ({ elementId, hasShadow, sizes }: IElementShirt) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId),
  );
  const team = useSelector((state: RootState) => getTeam(state, element?.team));
  const exclusions = useSelector((state: RootState) =>
    getSetting(state, "ui", "special_shirt_exclusions"),
  );
  const useSpecialShirts = useSelector((state: RootState) =>
    getSetting(state, "ui", "use_special_shirts"),
  );

  let shirtMode = "standard";
  if (useSpecialShirts) {
    if (exclusions.indexOf(element.code) === -1) {
      shirtMode = "special";
    }
  }

  return (
    <Shirt
      hasShadow={hasShadow}
      path={getElementShirtPath(element.element_type, team.code, shirtMode)}
      sizes={sizes}
      team={team}
    />
  );
};

export default ElementShirt;
