import styled, { css } from "styled-components/macro";

export const ProposedWidth = css`
  width: 32%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 32%;
  }
`;

export const ProposedCell = styled.td`
  ${ProposedWidth};
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
  }
`;

export const ProposedCellIn = styled(ProposedCell)`
  ::before {
    background-color: #05fa87;
  }
`;

export const ProposedCellOut = styled(ProposedCell)`
  ::before {
    background-color: #fc2c80;
  }
`;

export const ActionCell = styled.td`
  width: 13%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 23%;
  }
`;
