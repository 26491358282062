import styled from "styled-components/macro";
import Table, { PrimaryCell, StatusCell } from "../../Table";

export const TopAvailableHeader = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    display: flex;
    align-items: center;
  }
`;

export const TopAvailableFormWrap = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    flex: 1 1 70%;
  }
`;

export const TopAvailableLinkWrap = styled.div`
  margin: ${({ theme }) =>
    `${theme.space[4]} ${theme.space[2]} ${theme.space[2]}`};

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    flex: 1 1 30%;
    margin: ${({ theme }) => `0 ${theme.space[2]} 0 0`};
    text-align: right;
  }
`;

export const TopAvailableStatus = styled.th`
  width: 10%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 6%;
  }
`;

export const TopAvailableElement = styled.th`
  width: 40%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 22%;
  }
`;

export const TopAvailableForm = styled.th`
  width: 16%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 10%;
  }
`;

export const TopAvailableFixture = styled.th`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: table-cell;
    width: 12%;
  }
`;

export const TopAvailableAction = styled.th`
  width: 16%;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 13%;
  }
`;

export const TopAvailableTable = styled(Table)`
  thead,
  tbody {
    th,
    td {
      text-align: center;
    }

    ${TopAvailableElement}, ${PrimaryCell} {
      text-align: left;
    }
  }

  ${StatusCell} {
    padding: 0;
  }
`;
