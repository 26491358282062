import React from "react";
import { propTypeLeague } from "../../reducers/leagues";
import EventTrades from "./EventTrades";
import TradesForApproval from "./TradesForApproval";

const Trades = ({ activeLeague }) => {
  if (activeLeague.scoring === "m") {
    return null;
  }
  return (
    <React.Fragment>
      {(activeLeague.trades === "a" || activeLeague.trades === "m") && (
        <TradesForApproval />
      )}
      <EventTrades />
    </React.Fragment>
  );
};

Trades.propTypes = {
  activeLeague: propTypeLeague.isRequired,
};

export default Trades;
