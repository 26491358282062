import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import {
  elementDialogShow as eds,
  fetchDreamTeam,
} from "../../actions/Element";
import { ReactComponent as DreamTeamIcon } from "../../img/icons/dreamteam.svg";
import {
  getElementTypesById,
  propTypeElementType,
} from "../../reducers/elementTypes";
import {
  getDreamTeamCurrent,
  getDreamTeamOverall,
  getElementsById,
  propTypeDreamTeam,
  propTypeElement,
} from "../../reducers/elements";
import { getCurrentEvent, propTypeEvent } from "../../reducers/events";
import { getTeamsById, propTypeTeam } from "../../reducers/teams";
import ElementShirt from "../ElementShirt";
import ElementStatusButton from "../ElementStatusButton";
import Panel from "../Panel";
import { VisuallyHidden } from "../styles";
import {
  ElementStatBold,
  ElementStatBoldHeading,
  ElementStatInfo,
  ElementStatLined,
  ElementStatName,
  ElementStatShirt,
  ElementStatTable,
} from "./styles";

const DreamTeamElement = ({
  element,
  elementDialogShow,
  elementType,
  points,
  team,
}) => (
  <tr>
    <ElementStatInfo>
      <ElementStatusButton elementId={element.id} />
    </ElementStatInfo>
    <ElementStatBold>{elementType.singular_name_short}</ElementStatBold>
    <ElementStatShirt>
      <ElementShirt elementId={element.id} sizes="16px" />
    </ElementStatShirt>
    <ElementStatName>{element.web_name}</ElementStatName>
    <ElementStatBold>{team.short_name}</ElementStatBold>
    <ElementStatLined>{points}</ElementStatLined>
  </tr>
);

DreamTeamElement.propTypes = {
  element: propTypeElement.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementType: propTypeElementType.isRequired,
  points: PropTypes.number.isRequired,
  team: propTypeTeam.isRequired,
};

const DreamTeamSummary = ({
  dreamTeam,
  elementDialogShow,
  elementsById,
  elementTypesById,
  now,
  teamsById,
}) => (
  <Panel
    headingText={
      <Flex alignItems="center">
        <DreamTeamIcon />
        <Box ml={2}>Team of the Week</Box>
      </Flex>
    }
  >
    <Box mx={-2} mb={2}>
      <ElementStatTable>
        <thead>
          <tr>
            <ElementStatInfo as="th">
              <VisuallyHidden>Info</VisuallyHidden>
            </ElementStatInfo>
            <ElementStatBoldHeading>Pos</ElementStatBoldHeading>
            <ElementStatShirt as="th">
              <VisuallyHidden>Shirt</VisuallyHidden>
            </ElementStatShirt>
            <ElementStatName as="th">Player</ElementStatName>
            <ElementStatBoldHeading>Club</ElementStatBoldHeading>
            <ElementStatBoldHeading>Pts</ElementStatBoldHeading>
          </tr>
        </thead>
        <tbody>
          {dreamTeam.elements.map((data) => (
            <DreamTeamElement
              element={elementsById[data.element_id]}
              elementDialogShow={elementDialogShow}
              elementType={
                elementTypesById[elementsById[data.element_id].element_type]
              }
              key={data.element_id}
              points={data.total_points}
              team={teamsById[elementsById[data.element_id].team]}
            />
          ))}
        </tbody>
      </ElementStatTable>
    </Box>
  </Panel>
);

DreamTeamSummary.propTypes = {
  dreamTeam: propTypeDreamTeam.isRequired,
  elementDialogShow: PropTypes.func.isRequired,
  elementsById: PropTypes.objectOf(propTypeElement).isRequired,
  elementTypesById: PropTypes.objectOf(propTypeElementType).isRequired,
  now: propTypeEvent.isRequired,
  teamsById: PropTypes.objectOf(propTypeTeam).isRequired,
};

export { DreamTeamSummary as DreamTeamSummaryTest };

class DreamTeamSummaryContainer extends React.Component {
  componentDidMount() {
    this.props.fetchDreamTeam(this.props.now ? this.props.now.id : 0);
  }

  render() {
    return <DreamTeamSummary {...this.props} />;
  }
}

DreamTeamSummaryContainer.propTypes = {
  fetchDreamTeam: PropTypes.func.isRequired,
  now: propTypeEvent.isRequired,
};

const mapStateToProps = (state) => {
  const now = getCurrentEvent(state);
  return {
    dreamTeam: now ? getDreamTeamCurrent(state) : getDreamTeamOverall(state),
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    now: getCurrentEvent(state),
    teamsById: getTeamsById(state),
  };
};

const mapDispatchToProps = {
  elementDialogShow: eds,
  fetchDreamTeam,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DreamTeamSummaryContainer);
