import styled from "styled-components/macro";

export const DraftedInfo = styled.div`
  padding: 0 ${(props) => props.theme.space[2]};
  margin-bottom: ${(props) => props.theme.space[4]};
`;

export const DraftedPlayer = styled.div`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
`;

export const DraftedTotals = styled.div`
  color: ${(props) => props.theme.colors.darkPink};
  font-size: 3rem;
`;
