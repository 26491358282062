import { StyledBenchHeading } from "./styles";
import { BenchHeadingProps } from "./types";

const BenchHeading = ({ children, position }: BenchHeadingProps) => (
  <StyledBenchHeading id={`ism-pos-label-${position}`} position={position}>
    {children}
  </StyledBenchHeading>
);

export default BenchHeading;
