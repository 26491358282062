import PropTypes from "prop-types";
import { propTypeLeagueEntry } from "../../../reducers/leagueEntries";
import { propTypeTrade } from "../../../reducers/transactions";
import LeagueEntryShortName from "../../LeagueEntryShortName";
import { TableWrap } from "../../Table";
import { PrimaryIcon, SquadButton, SquadButtonText } from "../../squad/styles";
import TransactionElementGroup from "../TransactionElementGroup";
import {
  StyledTransaction,
  TransactionUnit,
  TransactionUnitHeading,
} from "../styles";
import {
  ActionCell,
  EntryCell,
  ProposedTradesTable,
  TradeCell,
  TradeHeader,
} from "./styles";

const ProposedTrade = ({
  receivedEntry,
  trade,
  withdrawTradeOffer,
  ...rest
}) => (
  <tr>
    <EntryCell>
      <LeagueEntryShortName entryId={receivedEntry.entry_id} />
    </EntryCell>
    <TradeCell>
      <StyledTransaction>
        <TransactionUnit>
          <TransactionUnitHeading isIn={true}>Requested</TransactionUnitHeading>
          <TransactionElementGroup elementIds={trade.elementsIn} {...rest} />
        </TransactionUnit>
        <TransactionUnit>
          <TransactionUnitHeading>Offered</TransactionUnitHeading>
          <TransactionElementGroup elementIds={trade.elementsOut} {...rest} />
        </TransactionUnit>
      </StyledTransaction>
    </TradeCell>
    <ActionCell>
      {trade.state === "o" ? (
        <SquadButton
          variant="primary"
          data-id={trade.id}
          onClick={withdrawTradeOffer}
          type="button"
        >
          <SquadButtonText>Cancel</SquadButtonText>
          <PrimaryIcon>
            <use xlinkHref="#ism-cancel" />
          </PrimaryIcon>
        </SquadButton>
      ) : (
        <div>Accepted</div>
      )}
    </ActionCell>
  </tr>
);

ProposedTrade.propTypes = {
  receivedEntry: propTypeLeagueEntry.isRequired,
  trade: propTypeTrade.isRequired,
  withdrawTradeOffer: PropTypes.func.isRequired,
};

const ProposedTrades = ({ entriesById, trades, ...rest }) => (
  <TableWrap>
    <ProposedTradesTable>
      <thead>
        <tr>
          <EntryCell as="th">Offered to</EntryCell>
          <TradeHeader>Trade</TradeHeader>
          <ActionCell as="th">Cancel?</ActionCell>
        </tr>
      </thead>
      <tbody>
        {trades.map((trade) => (
          <ProposedTrade
            key={trade.id}
            receivedEntry={entriesById[trade.received_entry]}
            trade={trade}
            {...rest}
          />
        ))}
      </tbody>
    </ProposedTradesTable>
  </TableWrap>
);

ProposedTrades.propTypes = {
  entriesById: PropTypes.objectOf(propTypeLeagueEntry).isRequired,
  trades: PropTypes.arrayOf(propTypeTrade).isRequired,
};

export default ProposedTrades;
