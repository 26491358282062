import styled, { css, keyframes } from "styled-components/macro";
import { IDraftCountdown, IDraftCountdownLevel } from "./types";

const myPickColor = "#c0020d";

export const DraftCountdownHeading = styled.h4<IDraftCountdown>`
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: ${({ isMyPick, theme }) =>
    isMyPick ? myPickColor : theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes[2]};
  text-align: center;
  text-transform: uppercase;
`;

export const DraftCountdownBar = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.lightGrey};
`;

export const DraftCountdownTimer = styled.div`
  width: 25%;
  padding: ${(props) => props.theme.space[1]};
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 2.6rem;
  text-align: center;
`;

export const DraftCountdownLevelWrap = styled.div`
  display: flex;
  width: 75%;
`;

const countdownAnimation = keyframes`
  100% { 
    width: 0; 
  }
`;

export const DraftCountdownLevel = styled.div<IDraftCountdownLevel>`
  background-color: ${({ isMyPick, theme }) =>
    isMyPick ? myPickColor : theme.colors.lightPurple};

  // https://css-tricks.com/animate-to-an-inline-style/
  // also see DraftCountdown.js inline styles
  ${(props) =>
    props.isAnimating &&
    css`
      animation-name: ${countdownAnimation};
      animation-timing-function: linear;
    `}
`;
