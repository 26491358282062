import styled, { css } from "styled-components/macro";
import { IButtonGroupItem } from "./types";

export const ButtonGroup = styled.ul`
  padding: ${(props) => props.theme.space[2]};
`;

export const ButtonGroupItem = styled.li<IButtonGroupItem>`
  :not(:last-child) {
    margin-bottom: ${(props) => props.theme.space[2]};
  }

  ${(props) =>
    props.isInline &&
    css`
      display: inline-block;
      margin-right: ${(props) => props.theme.space[2]};
    `}
`;
