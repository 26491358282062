import PropTypes from "prop-types";
import { propTypeDraftChoice } from "../../reducers/draftChoices";
import getOrdinal from "../../utils/getOrdinal";
import {
  PickBox,
  PickBoxAside,
  PickBoxHeading,
  PickBoxMainCurrent,
} from "./styles";

const DraftCurrentPick = ({ isMyPick, thisChoice }) => (
  <PickBox isCurrent={true}>
    <PickBoxHeading>Picking now:</PickBoxHeading>

    {isMyPick ? (
      <PickBoxMainCurrent isMe={true}>Me</PickBoxMainCurrent>
    ) : (
      <PickBoxMainCurrent>
        {thisChoice.player_first_name} {thisChoice.player_last_name}
      </PickBoxMainCurrent>
    )}

    <PickBoxAside isCurrent={true}>{`(${getOrdinal(
      thisChoice.pick,
    )} this round)`}</PickBoxAside>
  </PickBox>
);

DraftCurrentPick.propTypes = {
  isMyPick: PropTypes.bool,
  thisChoice: propTypeDraftChoice.isRequired,
};

DraftCurrentPick.defaultProps = {
  isMyPick: false,
};

export default DraftCurrentPick;
