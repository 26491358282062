import { Box } from "rebass/styled-components";
import { propTypeEntry } from "../../reducers/entries";
import { PatternWrap } from "../GraphicPatterns";
import SubHeading from "../SubHeading";
import SquadTable from "./SquadTable";

const SquadList = ({ entry, ...rest }) => (
  <PatternWrap>
    <Box mx={3} mb={4}>
      <SubHeading>{entry.name}</SubHeading>
    </Box>
    <SquadTable {...rest} />
  </PatternWrap>
);
SquadList.propTypes = {
  entry: propTypeEntry.isRequired,
};

export default SquadList;
