import PropTypes from "prop-types";
import { propTypeElementType } from "../reducers/elementTypes";
import Media from "./Media";
import PlaceholderShirt from "./PlaceholderShirt";

const PlaceholderInTable = ({ elementType, text }) => {
  const Shirt = <PlaceholderShirt elementType={elementType} sizes="16px" />;
  return (
    <Media img={Shirt} centred={true}>
      <div className="ism-table--el__placeholder-text">{text}</div>
    </Media>
  );
};

PlaceholderInTable.propTypes = {
  elementType: propTypeElementType.isRequired,
  text: PropTypes.string.isRequired,
};

export default PlaceholderInTable;
