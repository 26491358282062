import styled from "styled-components/macro";
import { ITransactionHeading } from "./types";

export const Response = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-around;
  }
`;

export const ResponseButton = styled.div`
  margin: ${(props) => props.theme.space[3]} 0;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1 0 40%;
    margin: 0 5%;
  }
`;

export const StyledTransaction = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
  }
`;

export const TransactionUnit = styled.div`
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.lightGrey};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1;
    min-width: 0;
    border-width: 0 1px;
  }
`;

export const TransactionUnitHeading = styled.h3<ITransactionHeading>`
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
  color: ${({ isIn, theme }) => (isIn ? "#03aa68" : theme.colors.darkPink)};
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-align: center;
  text-transform: uppercase;
`;
