import styled from "styled-components/macro";

export const StyledDeadlineHeader = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};
  padding: ${(props) => props.theme.space[2]};
`;

export const DeadlineHeading = styled.h3`
  margin-bottom: ${(props) => props.theme.space[1]};

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-bottom: 0;
  }
`;

export const Deadline = styled.time`
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: inline;
  }
`;
