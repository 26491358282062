import React from "react";
import { useSelector } from "react-redux";
import { getTeamsById } from "../reducers/teams";
import { IFixture } from "../store/fixtures/types";
import { fixtureSuffix } from "../utils/fixtures";

interface IOwnProps {
  fixtures: Record<number, IFixture[]>;
  teamId: number;
}

const ElementEventFixtures: React.FC<IOwnProps> = ({ fixtures, teamId }) => {
  const teamsById = useSelector(getTeamsById);
  return (
    <ul>
      {fixtures[teamId].map((f) => (
        <li key={f.id}>
          <span>{teamsById[f.opponent].short_name}</span>
          {fixtureSuffix(f.id, f.isHome)}
        </li>
      ))}
    </ul>
  );
};

export default ElementEventFixtures;
