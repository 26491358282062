import React from "react";
import { Box } from "rebass/styled-components";
import { IElementStat, IHistory, ITotal } from "../../store/elements/types";
import { getShortNameFromId } from "../../utils/events";
import Table, { ScrollTable } from "../Table";
import { ElementSubHeading } from "./styles";

interface IElementFixtureHistory {
  detail: IHistory;
  stats: IElementStat[];
}

interface IElementHistory {
  history: Array<IHistory>;
  stats: Array<IElementStat>;
  totals: ITotal;
}

const ElementHistoryThisFixtureHistory: React.FC<IElementFixtureHistory> = ({
  detail,
  stats,
}) => (
  <tr>
    <td>{getShortNameFromId(detail.event, true)}</td>
    <td>{detail.detail}</td>
    <td>{detail.total_points}</td>
    {stats.map((s: IElementStat) => (
      <td key={s.name}>{detail[s.name]}</td>
    ))}
  </tr>
);

const ElementHistoryThisTable: React.FC<IElementHistory> = ({
  history,
  stats,
  totals,
}) => (
  <ScrollTable>
    <Table>
      <thead>
        <tr>
          <th>GW</th>
          <th>OPP</th>
          <th>PTS</th>
          {stats.map((s: IElementStat) => (
            <th key={s.name}>{s.abbreviation}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {history.map((h: IHistory) => (
          <ElementHistoryThisFixtureHistory
            key={h.id}
            detail={h}
            stats={stats}
          />
        ))}
      </tbody>
      {history.length > 0 && (
        <tbody>
          <tr>
            <td colSpan={2}>
              <strong>Totals</strong>
            </td>
            <td>{totals.total_points}</td>
            {stats.map((s: IElementStat) => (
              <td key={s.name}>{totals[s.name]}</td>
            ))}
          </tr>
        </tbody>
      )}
    </Table>
  </ScrollTable>
);

const ElementHistory: React.FC<IElementHistory> = ({
  history,
  stats,
  totals,
}) => (
  <Box bg="white" mt={2}>
    <ElementSubHeading>This Season</ElementSubHeading>
    <ElementHistoryThisTable history={history} stats={stats} totals={totals} />
  </Box>
);

export default ElementHistory;
