import styled from "styled-components/macro";
import Table from "../../Table";

export const EntryCell = styled.td`
  width: 20%;
`;

export const TradeHeading = styled.th`
  width: 54%;
`;

export const TradeCell = styled.td`
  width: 54%;
`;

export const ActionCell = styled.td`
  width: 26%;
`;

export const StyledReceivedTradesTable = styled(Table)`
  table-layout: fixed;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};

  ${EntryCell}, ${TradeHeading}, ${ActionCell} {
    text-align: center;
  }

  ${TradeCell} {
    padding: 0;
  }
`;
