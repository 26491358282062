import React from "react";
import Shirt from "./Shirt/Shirt";

interface IProps {
  elementType?: number;
  hasShadow?: boolean;
  sizes: string;
  useBlank?: boolean;
}

const PlaceholderShirt: React.FC<IProps> = ({
  elementType,
  hasShadow,
  sizes,
  useBlank,
}) => {
  const suffix = useBlank
    ? "_blank"
    : elementType === 1
      ? `_${elementType}`
      : "";
  return (
    <Shirt
      hasShadow={hasShadow}
      path={`${process.env.PUBLIC_URL}/img/shirts/standard/shirt_0${suffix}`}
      sizes={sizes}
    />
  );
};

export default PlaceholderShirt;
