import { propTypeElement } from "../../reducers/elements";
import TransactionElement from "./TransactionElement";
import {
  StyledTransaction,
  TransactionUnit,
  TransactionUnitHeading,
} from "./styles";

const TransactionElements = ({ elementIn, elementOut }) => (
  <StyledTransaction>
    <TransactionUnit>
      <TransactionUnitHeading isIn={true}>In</TransactionUnitHeading>
      <TransactionElement elementId={elementIn.id} />
    </TransactionUnit>
    <TransactionUnit>
      <TransactionUnitHeading>Out</TransactionUnitHeading>
      <TransactionElement elementId={elementOut.id} />
    </TransactionUnit>
  </StyledTransaction>
);

TransactionElements.propTypes = {
  elementIn: propTypeElement.isRequired,
  elementOut: propTypeElement.isRequired,
};

export default TransactionElements;
